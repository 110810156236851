import { BidPostingCard, BidWithdrawnPostingCard } from "@/components/common";
import { BidState, ListingPageBidCardBidFragment } from "@/gql";

type ListingPageBidCardV2Props = {
  bid: ListingPageBidCardBidFragment;
};

function ListingPageBidCardV2({ bid }: ListingPageBidCardV2Props) {
  const { state } = bid;

  if (state === BidState.Withdrawn) {
    return <BidWithdrawnPostingCard bid={bid} />;
  }

  return <BidPostingCard bid={bid} />;
}

const ListingPageBidCard = ({
  bid,
}: {
  readonly bid: ListingPageBidCardBidFragment;
}) => <ListingPageBidCardV2 bid={bid} />;

export default ListingPageBidCard;
