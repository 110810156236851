import { Flex } from "@chakra-ui/react";

import { StandingBidInReviewActivityCard } from "@/components/common";
import { StandingBidTransactions } from "@/components/postings";
import {
  StandingBidBuyerActivityStandingBidFragment,
  StandingBidState,
} from "@/gql";
import { useStandingBidsPricingInReview } from "@/hooks/featureFlags";

import { StandingBidBuyerDiscussions } from "./StandingBidBuyerDiscussions";

const StandingBidBuyerActivity = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBuyerActivityStandingBidFragment;
}) => {
  const isStandingBidsPricingInReviewEnabled = useStandingBidsPricingInReview();
  const isStandingBidInReview = standingBid.state === StandingBidState.InReview;
  return (
    <Flex w="full" direction="column" gap={8}>
      {isStandingBidInReview && isStandingBidsPricingInReviewEnabled && (
        <StandingBidInReviewActivityCard
          companyName={standingBid.company.name}
        />
      )}
      <StandingBidTransactions standingBid={standingBid} />
      {isStandingBidsPricingInReviewEnabled && isStandingBidInReview ? null : (
        <StandingBidBuyerDiscussions standingBid={standingBid} />
      )}
    </Flex>
  );
};

export default StandingBidBuyerActivity;
