import { filter, orderBy, pipe, update } from "lodash/fp";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, Box, Fade, Text, VStack } from "@chakra-ui/react";

import {
  ActivityGroup,
  ActivitySection,
  HiiveButton,
  ListingYourActivityDashboardPostingCard,
  BidYourActivityDashboardPostingCard,
  StandingBidYourActivityDashboardPostingCard,
  WithQuery,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  BidState,
  ListingState,
  StandingBidState,
  TransactionModificationAcknowledgment,
  TransactionState,
  UserActivityActivityFragment,
  UserActivityBidFragment,
  UserActivityListingFragment,
  UserActivityStandingBidFragment,
  UserActivityTransactionFragment,
  UserWithInstitutionFragment,
  useUserActivityMyActivityQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBroker, getIsInstitutionUser, makeUrl } from "@/utils";

import { ActiveTransactionsCard } from "./ActiveTransactionsCard";
import { BrokerDashboardBidCard } from "./BrokerDashboardBidCard";
import { DashboardCTA } from "./DashboardCTA";
import { DashboardNotifications } from "./DashboardNotifications";
import { DashboardSignDocumentAlerts } from "./DashboardSignDocumentAlerts";
import DashboardYourActivityEmptyState from "./DashboardYourActivityEmptyState";
import { DashboardYourActivitySkeleton } from "./DashboardYourActivitySkeleton";

const byPendingTransactions =
  (meId: string, myInstitutionId?: string | null) =>
  ({
    state,
    bid: { brokerCounterpartyId, counterpartyId, buyerId, brokerId },
    buyerInstitutionId,
    sellerInstitutionId,
  }: UserActivityTransactionFragment) => {
    const isCounterparty =
      counterpartyId === meId ||
      brokerCounterpartyId === meId ||
      (myInstitutionId && sellerInstitutionId === myInstitutionId);
    const isBidder =
      buyerId === meId ||
      brokerId === meId ||
      (myInstitutionId && buyerInstitutionId === myInstitutionId);

    if (isCounterparty) {
      const counterpartyClosedStates = [
        TransactionState.ClosedFeePaid,
        TransactionState.Cancelled,
        TransactionState.IssuerApprovalDeclined,
        TransactionState.Expired,
      ];
      return !counterpartyClosedStates.includes(state);
    }

    if (isBidder) {
      const bidderClosedStates = [
        TransactionState.ClosedFeePending,
        TransactionState.ClosedFeePaid,
        TransactionState.IssuerApprovalDeclined,
        TransactionState.Cancelled,
        TransactionState.Expired,
      ];
      return !bidderClosedStates.includes(state);
    }

    return false;
  };

const byListingsNotByMe =
  (meId: string) =>
  ({ sellerId }: UserActivityListingFragment) =>
    sellerId !== meId;

const byBidsNotByMe =
  (meId: string) =>
  ({ buyerId }: UserActivityBidFragment | UserActivityStandingBidFragment) =>
    buyerId !== meId;

const byTransactionsNotByMe =
  (meId: string) =>
  ({ buyerId, sellerId }: UserActivityTransactionFragment) =>
    buyerId !== meId && sellerId !== meId;

const filterMyInstitutionActivityNotByMe = (meId: string) =>
  pipe(
    update(`myInstitutionListings`, filter(byListingsNotByMe(meId))),
    update(`myInstitutionBids`, filter(byBidsNotByMe(meId))),
    update(`myInstitutionTransactions`, filter(byTransactionsNotByMe(meId))),
    update(`myInstitutionStandingBids`, filter(byBidsNotByMe(meId))),
  );

const filterOngoingTransactions = (
  meId: string,
  myInstitutionId?: string | null,
) =>
  pipe(
    update(`myActiveTransactions`, filter(byPendingTransactions(meId))),
    update(
      `myInstitutionTransactions`,
      filter(byPendingTransactions(meId, myInstitutionId)),
    ),
  );

const byStandingBidsWithOpenOrConditionallyCompletedState = (
  standingBid: UserActivityStandingBidFragment,
) =>
  standingBid.state === StandingBidState.Open ||
  standingBid.state === StandingBidState.ConditionallyCompleted;

const byBidsWithActiveOrCounteredOrInReviewState = (
  bid: UserActivityBidFragment,
) =>
  bid.state === BidState.Active ||
  bid.state === BidState.Countered ||
  bid.state === BidState.InReview;

const byListingsWithOpenInReviewOrConditionallySoldState = (
  listing: UserActivityListingFragment,
) =>
  listing.state === ListingState.Open ||
  listing.state === ListingState.InReview ||
  listing.state === ListingState.ConditionallySold;

const filterMyActivitiesWithOpenOrConditionallySoldState = pipe(
  update(
    `myListings`,
    filter(byListingsWithOpenInReviewOrConditionallySoldState),
  ),
  update(`myBids`, filter(byBidsWithActiveOrCounteredOrInReviewState)),
  update(
    `myInstitutionListings`,
    filter(byListingsWithOpenInReviewOrConditionallySoldState),
  ),
  update(
    `myInstitutionStandingBids`,
    filter(byStandingBidsWithOpenOrConditionallyCompletedState),
  ),
  update(
    `myInstitutionBids`,
    filter(byBidsWithActiveOrCounteredOrInReviewState),
  ),
);

const byTransactionsWithMeAsSeller =
  (meId: string) => (transaction: UserActivityTransactionFragment) =>
    transaction.bid.counterpartyId === meId ||
    transaction.bid.brokerCounterpartyId === meId;

const byTransactionsWithMyInstitutionAsSeller =
  (myInstitutionId: string) => (transaction: UserActivityTransactionFragment) =>
    transaction.sellerInstitutionId === myInstitutionId;

const byTransactionsWithMyInstitutionAsBuyer =
  (myInstitutionId: string) => (transaction: UserActivityTransactionFragment) =>
    transaction.buyerInstitutionId === myInstitutionId;

const byTransactionsWithMeAsBuyerOrBroker =
  (meId: string) => (transaction: UserActivityTransactionFragment) =>
    transaction.bid.buyerId === meId || transaction.bid.brokerId === meId;

const groupTransactionsBySalePurchase =
  (meId: string, myInstitutionId?: string | null) =>
  ({
    myActiveTransactions,
    myInstitutionTransactions,
    ...rest
  }: UserActivityActivityFragment) => ({
    myPendingSales: myActiveTransactions.filter(
      byTransactionsWithMeAsSeller(meId),
    ),
    myPendingPurchases: myActiveTransactions.filter(
      byTransactionsWithMeAsBuyerOrBroker(meId),
    ),
    myInstitutionPendingSales: !!myInstitutionId
      ? myInstitutionTransactions?.filter(
          byTransactionsWithMyInstitutionAsSeller(myInstitutionId),
        )
      : [],
    myInstitutionPendingPurchases: !!myInstitutionId
      ? myInstitutionTransactions?.filter(
          byTransactionsWithMyInstitutionAsBuyer(myInstitutionId),
        )
      : [],
    ...rest,
  });

const orderByUpdatedAt = orderBy([`updatedAt`], [`desc`]);

const orderItemsByUpdatedAt = pipe(
  update(`myPendingPurchases`, orderByUpdatedAt),
  update(`myPendingSales`, orderByUpdatedAt),
  update(`myListings`, orderByUpdatedAt),
  update(`myStandingBids`, orderByUpdatedAt),
  update(`myBids`, orderByUpdatedAt),
  update(`myInstitutionListings`, orderByUpdatedAt),
  update(`myInstitutionStandingBids`, orderByUpdatedAt),
  update(`myInstitutionBids`, orderByUpdatedAt),
);

const DashboardYourListings = withCurrentActor(
  ({
    listings,
    actor,
  }: {
    readonly listings: readonly UserActivityListingFragment[];
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`dashboard`);
    const isInstitutionUser = getIsInstitutionUser(actor);

    return (
      <ActivityGroup
        title={
          isInstitutionUser
            ? t(`your_listings_on_behalf_of`, {
                institutionName: actor.institution?.legalName,
              })
            : t`your_listings`
        }
      >
        {listings.map((listing) => (
          <ListingYourActivityDashboardPostingCard
            key={listing.id}
            listing={listing}
          />
        ))}
      </ActivityGroup>
    );
  },
);

const DashboardYourStandingBids = withCurrentActor(
  ({
    standingBids,
    actor,
  }: {
    readonly standingBids: readonly UserActivityStandingBidFragment[];
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`dashboard`);
    const isInstitutionUser = getIsInstitutionUser(actor);

    return (
      <ActivityGroup
        title={
          isInstitutionUser
            ? t(`your_standing_bids_on_behalf_of`, {
                institutionName: actor.institution?.legalName,
              })
            : t`your_standing_bids`
        }
      >
        {standingBids.map((standingBid) => (
          <StandingBidYourActivityDashboardPostingCard
            standingBid={standingBid}
            key={standingBid.id}
          />
        ))}
      </ActivityGroup>
    );
  },
);

const DashboardYourBids = ({
  bids,
}: {
  readonly bids: readonly UserActivityBidFragment[];
}) => {
  const actor = useCurrentActor();
  const { t } = useTranslation(`dashboard`);
  const isInstitutionUser = getIsInstitutionUser(actor);

  return (
    <ActivityGroup
      title={
        isInstitutionUser
          ? t(`your_bids_on_behalf_of`, {
              institutionName: actor.institution?.legalName,
            })
          : t`your_bids`
      }
    >
      {bids.map((bid) => (
        <BidYourActivityDashboardPostingCard key={bid.id} bid={bid} />
      ))}
    </ActivityGroup>
  );
};

const BrokerDashboardBids = ({
  bids,
}: {
  readonly bids: readonly UserActivityBidFragment[];
}) => {
  const { t } = useTranslation(`dashboard`);

  return (
    <ActivityGroup title={t`your_bids`}>
      {bids.map((bid) => (
        <BrokerDashboardBidCard bid={bid} key={bid.id} />
      ))}
    </ActivityGroup>
  );
};

const DashboardYourInstitutionListings = ({
  listings,
}: {
  readonly listings: readonly UserActivityListingFragment[];
}) => {
  const { t } = useTranslation(`dashboard`);
  const actor = useCurrentActor();

  return (
    <ActivityGroup
      title={t(`other_institution_listings`, {
        institutionName: actor.institution?.legalName,
      })}
    >
      {listings.map((listing) => (
        <ListingYourActivityDashboardPostingCard
          key={listing.id}
          listing={listing}
        />
      ))}
    </ActivityGroup>
  );
};

const DashboardYourInstitutionBids = ({
  bids,
}: {
  readonly bids: readonly UserActivityBidFragment[];
}) => {
  const { t } = useTranslation(`dashboard`);
  const actor = useCurrentActor();

  return (
    <ActivityGroup
      title={t(`other_institution_bids`, {
        institutionName: actor.institution?.legalName,
      })}
    >
      {bids.map((bid) => (
        <BidYourActivityDashboardPostingCard key={bid.id} bid={bid} />
      ))}
    </ActivityGroup>
  );
};

const DashboardYourInstitutionStandingBids = ({
  standingBids,
}: {
  readonly standingBids: readonly UserActivityStandingBidFragment[];
}) => {
  const { t } = useTranslation(`dashboard`);
  const actor = useCurrentActor();

  return (
    <ActivityGroup
      title={t(`other_institution_standing_bids`, {
        institutionName: actor.institution?.legalName,
      })}
    >
      {standingBids.map((standingBid) => (
        <StandingBidYourActivityDashboardPostingCard
          standingBid={standingBid}
          key={standingBid.id}
        />
      ))}
    </ActivityGroup>
  );
};

const DashboardYourTransactionModificationAcknowledgments = withCurrentActor(
  ({
    actor,
    transactionModificationAcknowledgments,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly transactionModificationAcknowledgments: readonly TransactionModificationAcknowledgment[];
  }) => {
    const router = useRouter();
    const { t } = useTranslation();

    const isShowBuyerCopy = (
      transactionModificationAcknowledgment: TransactionModificationAcknowledgment,
    ) => {
      const {
        transactionModification: {
          transaction: { buyerId, buyerBrokerId },
        },
      } = transactionModificationAcknowledgment;
      return actor.id === buyerId || actor.id === buyerBrokerId;
    };

    const bodyCopyKey = (
      transactionModificationAcknowledgment: TransactionModificationAcknowledgment,
    ) =>
      isShowBuyerCopy(transactionModificationAcknowledgment)
        ? `dashboard_pending_transaction_modification_buyer_body`
        : `dashboard_pending_transaction_modification_seller_body`;

    const getCompanyName = (
      transactionModificationAcknowledgment: TransactionModificationAcknowledgment,
    ) =>
      transactionModificationAcknowledgment.transactionModification.transaction
        .company.name;

    const buildOnClick =
      (
        transactionModificationAcknowledgment: TransactionModificationAcknowledgment,
      ) =>
      () => {
        const {
          transactionModification: { transaction },
        } = transactionModificationAcknowledgment;
        router.push(makeUrl(transaction));
      };

    return (
      <Box w="full">
        <Fade in>
          <VStack spacing={4} w="full">
            {transactionModificationAcknowledgments.map(
              (
                transactionModificationAcknowledgment: TransactionModificationAcknowledgment,
              ) => (
                <Card
                  variant="teal"
                  w="full"
                  data-testId="dashboard-document-sign-cta"
                  key={transactionModificationAcknowledgment.id}
                >
                  <CardBody w="full">
                    <VStack alignItems="flex-start">
                      <Text textStyle="deprecated-heading-xl">
                        {t(`dashboard_pending_transaction_modification_title`)}
                      </Text>

                      <Text textStyle="deprecated-text-sm">
                        {t(bodyCopyKey(transactionModificationAcknowledgment), {
                          companyName: getCompanyName(
                            transactionModificationAcknowledgment,
                          ),
                        })}
                      </Text>
                      <HiiveButton
                        onClick={buildOnClick(
                          transactionModificationAcknowledgment,
                        )}
                        variant="text-salmon"
                        px={0}
                        observabilityLabel="[DashboardYourTransactionModificationAcknowledgments] Review and Approve"
                      >
                        {t(`dashboard_pending_transaction_modification_cta`)}
                      </HiiveButton>
                    </VStack>
                  </CardBody>
                </Card>
              ),
            )}
          </VStack>
        </Fade>
      </Box>
    );
  },
);

const DashboardYourActivityContent = withCurrentActor(
  ({
    myActivity,
    actor,
  }: {
    readonly myActivity: UserActivityActivityFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isBroker = getIsBroker(actor);

    const activities = pipe([
      filterMyActivitiesWithOpenOrConditionallySoldState,
      filterOngoingTransactions(actor.id, actor.institutionId),
      filterMyInstitutionActivityNotByMe(actor.id),
      groupTransactionsBySalePurchase(actor.id, actor.institutionId),
      orderItemsByUpdatedAt,
    ])(myActivity);

    const hasActivity =
      activities.myPendingPurchases.length > 0 ||
      activities.myPendingSales.length > 0 ||
      activities.myListings.length > 0 ||
      activities.myBids.length > 0 ||
      activities.myStandingBids.length > 0 ||
      activities.myInstitutionListings.length > 0 ||
      activities.myInstitutionBids.length > 0 ||
      activities.myInstitutionStandingBids.length > 0 ||
      activities.myInstitutionPendingPurchases.length > 0 ||
      activities.myInstitutionPendingSales.length > 0 ||
      activities.myPendingTransactionModificationAcknowledgments.length > 0;

    return (
      <>
        <VStack w="full" spacing={4}>
          {!hasActivity && (
            <>
              <DashboardYourActivityEmptyState />
              <DashboardCTA />
            </>
          )}
          <DashboardYourTransactionModificationAcknowledgments
            transactionModificationAcknowledgments={
              activities.myPendingTransactionModificationAcknowledgments
            }
          />
          <DashboardSignDocumentAlerts />
          <ActiveTransactionsCard
            myTransactions={myActivity.myActiveTransactions}
            myInstitutionTransactions={myActivity.myInstitutionTransactions}
          />
          <DashboardNotifications />
        </VStack>
        <DashboardYourListings listings={activities.myListings} />
        {isBroker ? (
          <BrokerDashboardBids bids={activities.myBids} />
        ) : (
          <DashboardYourBids bids={activities.myBids} />
        )}
        <DashboardYourStandingBids standingBids={activities.myStandingBids} />
        <DashboardYourInstitutionListings
          listings={activities.myInstitutionListings}
        />
        <DashboardYourInstitutionBids bids={activities.myInstitutionBids} />
        <DashboardYourInstitutionStandingBids
          standingBids={activities.myInstitutionStandingBids}
        />
      </>
    );
  },
);

const DashboardYourActivity = () => {
  const actor = useCurrentActor();
  const { t } = useTranslation(`dashboard`);
  const isInstitution = getIsInstitutionUser(actor);

  const query = useUserActivityMyActivityQuery({
    fetchPolicy: `network-only`,
    variables: {
      isInstitution,
    },
  });

  return (
    <WithQuery query={query} fallback={<DashboardYourActivitySkeleton />}>
      {({ data: { myActivity } }) => (
        <ActivitySection title={t`your_activity`} data-dd-privacy="mask">
          <DashboardYourActivityContent myActivity={myActivity} />
        </ActivitySection>
      )}
    </WithQuery>
  );
};

export default DashboardYourActivity;
