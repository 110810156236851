import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text } from "@chakra-ui/react";

import { ActivityGroupEmptyState, HiiveButton } from "@/components/common";
import { CompanyMarketActivityBuyersBadge } from "@/components/companies";
import { UnaccreditedSellerCompanyPageMarketActivityCompanyFragment } from "@/gql";
import { useModal } from "@/hooks";
import { useRestrictiveInReview } from "@/hooks/featureFlags";
import { getAvailableCompanyActions } from "@/utils";

export const StandingBidsEmptyState = ({
  company,
  numberOfListings,
}: {
  readonly company: UnaccreditedSellerCompanyPageMarketActivityCompanyFragment;
  readonly numberOfListings: number;
}) => {
  const { t } = useTranslation();
  const restrictedInReview = useRestrictiveInReview();

  const { restricted } = company;
  const isInReview = restrictedInReview && restricted;

  const { canCreateListing } = getAvailableCompanyActions(company);
  const { onOpenModal, modals } = useModal();

  const showPlaceListingCTA =
    canCreateListing &&
    company.numWatchers > 0 &&
    numberOfListings === 0 &&
    !isInReview;

  return showPlaceListingCTA ? (
    <Card w="full">
      <CardBody>
        <Flex direction="column" gap={5}>
          <Flex direction="column" gap={4}>
            <CompanyMarketActivityBuyersBadge
              variant="rounded-plum"
              company={company}
            />
            <Text>
              {t(`potential_buyers_but_no_standing_bids`, {
                count: company.numWatchers,
                companyName: company.name,
              })}
              {` `}
              <Trans
                i18nKey="place_listing_to_notify_buyers"
                components={{
                  bold: <Text as="strong" textStyle="heading-md" />,
                }}
                values={{ count: company.numWatchers }}
              />
            </Text>
          </Flex>
          <HiiveButton
            flex="none"
            p={0}
            maxWidth="fit-content"
            variant="text-salmon"
            onClick={() =>
              onOpenModal(modals.createListingOnCompany(company))()
            }
            observabilityLabel="[StandingBidsEmptyState] Place a Listing CTA"
          >
            {t(`place_a_listing`)}
          </HiiveButton>
        </Flex>
      </CardBody>
    </Card>
  ) : (
    <ActivityGroupEmptyState
      message={t(`no_standing_bids`, {
        companyName: company.name,
      })}
    />
  );
};
