import { Trans, useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { WorkflowConditionCard } from "@/components/transactions";
import {
  ExecutionMilestone,
  TransactionExecutionPageWorkflowFragment,
} from "@/gql";

import MilestoneRow from "./MilestoneRow";
import { getMilestoneCopy, isCancelled } from "./utils";

const MilestonesCard = ({
  workflow,
  isBuySide,
}: {
  readonly workflow: TransactionExecutionPageWorkflowFragment;
  readonly isBuySide: boolean;
}) => {
  const { t } = useTranslation(`execution`);

  const { milestoneCompletions, milestoneOrder, conditions } = workflow;

  const lastCompletedMilestone =
    milestoneCompletions[milestoneCompletions.length - 1]?.milestone;

  const activeMilestone =
    milestoneOrder[milestoneOrder.indexOf(lastCompletedMilestone) + 1];

  const isCompleted = (milestone: ExecutionMilestone) =>
    milestoneCompletions.map((m) => m.milestone).includes(milestone);

  const getCompletedAt = (milestone: ExecutionMilestone) =>
    milestoneCompletions[
      milestoneCompletions.findIndex((m) => m.milestone === milestone)
    ]?.completedAt || null;

  const hasConditions = conditions ? conditions?.length > 0 : false;

  return (
    <Card w="full" variant="no-border-radius-base">
      <CardHeader py={4}>
        <HStack justify="space-between" flexWrap="wrap">
          <Text textStyle="heading-md">
            <Trans i18nKey="transaction_status" />
          </Text>
        </HStack>
      </CardHeader>
      <CardBody p={0} py={4} borderBottom="none">
        <VStack spacing={0} gap={2}>
          {milestoneOrder.map((milestone, index) => (
            <MilestoneRow
              key={milestone}
              milestone={milestone}
              copy={t(getMilestoneCopy(milestone))}
              showLine={index !== milestoneOrder.length - 1}
              isCompleted={!isCancelled(conditions) && isCompleted(milestone)}
              isActive={
                !isCancelled(conditions) && activeMilestone === milestone
              }
              completedAt={getCompletedAt(milestone)}
              conditions={conditions}
              isBuySide={isBuySide}
            />
          ))}
        </VStack>
      </CardBody>
      {hasConditions && (
        <CardBody pt={0} p={6}>
          {conditions.map(({ condition }) => (
            <WorkflowConditionCard
              key={condition}
              condition={condition}
              isBuySide={isBuySide}
            />
          ))}
        </CardBody>
      )}
    </Card>
  );
};

export default MilestonesCard;
