import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { useModal } from "@/hooks";
import { useTexasCopy } from "@/hooks/featureFlags";
import {
  abbrCountLabel,
  getBidLabel,
  getCountLabel,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getLongDocumentTitleByTransferMethod,
  getIsEitherFund,
  getIsOtherMethod,
} from "@/utils";

import { useAcceptCounterBidSequenceModalStepFormContext } from "./AcceptCounterBidSequenceModalStepFormContext";

const AcceptCounterBidSuccessModal = () => {
  const { t } = useTranslation();
  const { t: tBids } = useTranslation(`bids`);

  const { mutationData } = useAcceptCounterBidSequenceModalStepFormContext();
  const isTexasCopyEnabled = useTexasCopy();

  const { closeModal } = useModal();

  const bid = mutationData?.acceptCounteredBid.bid;
  const listing = bid?.listing;

  if (!bid || !listing) return null;
  const useLOITerms =
    !getIsEitherFund(bid.listing.transferMethod) &&
    !getIsOtherMethod(bid.listing.transferMethod);
  const textKey = getIsOtherMethod(bid.listing.transferMethod)
    ? `bid_accepted_congrats`
    : `bid_accepted_you_can_message`;

  const bidOrOrder = getBidLabel(bid.listing.transferMethod);
  const share = getCountLabel(bid.listing.transferMethod);
  const abbrShare = abbrCountLabel(bid.listing.transferMethod);
  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const documentTitle = getLongDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );
  return (
    <>
      <HiiveModalHeader>
        {isTexasCopyEnabled
          ? tBids(`countered_bid_accepted`, { bidOrOrder })
          : `Countered ${bidOrOrder} accepted!`}
      </HiiveModalHeader>
      <ModalBody>
        <Text
          textStyle={isTexasCopyEnabled ? `heading-md` : `heading-xl`}
          mb={4}
        >
          {`${bid.displayId}: ${numShares} ${share}s @ ${pricePerShare}/${abbrShare}`}
        </Text>
        {isTexasCopyEnabled ? (
          <VStack alignItems="flex-start" spacing={3}>
            <Text>{tBids`the_transaction_process_will_now_start`}</Text>
            <Text>
              <Trans
                ns="bids"
                i18nKey="for_support_reach_out"
                components={{
                  mailto: <SecuritySpecialistLink company={listing.company} />,
                }}
                values={{ companyName: listing.company.name }}
              />
            </Text>
          </VStack>
        ) : (
          <VStack mb={4} alignItems="start">
            {useLOITerms && (
              <Text>{t(`bid_accepted_next_steps`, { documentTitle })}</Text>
            )}
            <Text>{t(textKey)}</Text>
            <Text>
              {t(`for_support`)}
              {` `}
              <SecuritySpecialistLink company={listing.company} />.
            </Text>
          </VStack>
        )}
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[AcceptCounterBidSuccess/Submit]"
          submitText={isTexasCopyEnabled ? tBids`okay` : `Done`}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptCounterBidSuccessModal;
