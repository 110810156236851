import { sentenceCase } from "change-case";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import {
  Button,
  Text,
  VStack,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalBody,
} from "@chakra-ui/react";

import {
  ExecutionModalFooter,
  ExecutionModalHeader,
} from "@/components/common";
import {
  TransactionExecutionPageTransactionByIdDocument,
  useCompleteExecutionTaskMutation,
  ExecutionConfirmTransactionModificationApprovalStatus,
  ExecutionConfirmTransactionModificationTaskDataFragment,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { formatShares } from "@/utils";

const ConfirmTransactionModificationAcceptAction = ({
  taskId,
  data,
  companyName,
  transferMethod,
}: {
  readonly taskId: string;
  readonly data: ExecutionConfirmTransactionModificationTaskDataFragment;
  readonly companyName: string;
  readonly transferMethod: string;
}) => {
  const { t } = useTranslation(`execution`);

  const { numShares: newShares, pricePerShare: newPricePerShare } = data;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [completeTask, isLoading] = useMutationWithError(
    useCompleteExecutionTaskMutation({
      refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
    }),
    `completeExecutionTask`,
  );

  const handleComplete = async () => {
    const data = await completeTask({
      variables: {
        taskId,
        input: {
          confirmTransactionModification: {
            approvalStatus:
              ExecutionConfirmTransactionModificationApprovalStatus.Approved,
          },
        },
      },
    });

    if (data?.completeExecutionTask.task?.id) {
      onClose();
    }
  };

  return (
    <>
      <Button
        variant="rounded-solid-grey"
        size={{ base: `md`, md: `xl` }}
        onClick={onOpen}
      >
        {t(`accept`)}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" variant="texas">
        <ModalOverlay />
        <ModalContent height={{ base: `full`, md: `auto` }}>
          <ExecutionModalHeader closeModal={onClose}>
            <Text>{t(`accept_proposed_modified_transaction`)}</Text>
          </ExecutionModalHeader>
          <ModalBody>
            <VStack spacing={4} alignItems="start">
              <Text textStyle="heading-2xl">
                {t(`accept_proposed_modified_transaction_title`)}
              </Text>
              <Text>
                {t(`accept_proposed_modified_transaction_description`)}
              </Text>
              <Text>
                {t(`proposed_update`, {
                  shares: formatShares(newShares),
                  companyName,
                  price: currency(newPricePerShare, {
                    fromCents: true,
                  }).format(),
                  type: sentenceCase(transferMethod),
                })}
              </Text>
            </VStack>
          </ModalBody>
          <ExecutionModalFooter>
            <Button onClick={onClose} disabled={isLoading}>
              {t(`cancel`)}
            </Button>
            <Button onClick={handleComplete} isLoading={isLoading}>
              {t(`confirm`)}
            </Button>
          </ExecutionModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmTransactionModificationAcceptAction;
