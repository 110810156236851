export default {
  order: `Order`,
  bid: `Bid`,
  sh: `/sh`,
  placed: `Placed {{timestamp}}`,
  withdrawn: `Withdrawn`,
  submitted_to_hiive_for_review: `Submitted to Hiive for review {{timestamp}}`,
  placed_by_hiive: `Placed by Hiive {{timestamp}}`,
  expires: `Expires {{ timestamp }}`,
  withdrawn_timestamp: `Withdrawn {{ timestamp }}`,
  accepting_this_bid_counts_as: `Accepting this {{bidType}} counts as an Introduction Event under the <italic>Hiive Customer Terms and Conditions</italic>.`,
  once_accepted_the_transaction_process_will_begin: `Once accepted, the transaction process will begin.`,
  once_you_ve_accepted_the_transaction_process_will_begin: `Once you've accepted this {{bidType}}, the transaction process will begin.`,
  you_accepted_bid: `You accepted {{bidOrOrder}} {{bidId}}!`,
  accept_bid_bid_id: `Accept {{bidType}} {{bidId}}?`,
  the_transaction_process_will_now_start: `The transaction process will now start, and you will be notified of next steps.`,
  for_support_reach_out: `For support, reach out to our <mailto>{{companyName}} Security Specialist.</mailto>`,
  okay: `Okay!`,
  accept_counter_bid: `Accept {{bidType}} {{bidId}}?`,
  accepting_this_counter_bid_counts_as: `Accepting this {{bidType}} counts as an Introduction Event under the <italic>Hiive Customer Terms and Conditions</italic>.`,
  once_you_ve_accepted_this_counter_bid: `Once you've accepted this counter bid, the transaction process will begin.`,
  accept_counter_bid_confirmation: `Accept counter bid`,
  countered_bid_accepted: `Counter {{bidOrOrder}} accepted!`,
  bid_in_review_by_hiive: `In review by Hiive. Submitted {{insertedAt}}`,
  we_re_reviewing_your_bid: `We're reviewing your bid`,
  we_ll_keep_you_updated: `We'll keep you updated on your bid's progress. If we need more information, a member of our team will reach out to you.`,
  for_support_contact: `For support with your bid, contact <0>{{email}}</0>.`,
  why_is_my_posting_in_review_email_title: `Question about my {{companyName}} posting in review`,
};
