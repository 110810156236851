const number_of_latest_postings = 50;
const number_of_latest_listings = number_of_latest_postings;
const number_of_latest_standing_bids = number_of_latest_postings;
const listing_shares_rounding_factor = 2;
const high_fees_warning_threshold = 50_000;
const IS_PRODUCTION = process.env.NEXT_PUBLIC_INFRA_ENV === `production`;
const IS_LOCAL = process.env.NEXT_PUBLIC_INFRA_ENV === `local`;
const IS_STAGING = process.env.NEXT_PUBLIC_INFRA_ENV === `development`;
const AUTH0_ENABLED = process.env.NEXT_PUBLIC_AUTH0_ENABLED === `true`;
const DEV_AUTH_ENABLED = IS_PRODUCTION
  ? false
  : process.env.NEXT_PUBLIC_DEV_AUTH_ENABLED === `true`;
// IO Debouncing
const commission_calculation_debounce_time = 500;
const search_debounce_time = 275;

// Listing
const max_share_classes_for_listing = 3;
const default_listing_expire_after_weeks = 12;
const min_listing_size = {
  number: 25_000,
  text: `$25,000`,
};
const min_listing_size_for_partial_bids = {
  number: 25_000,
  text: `$25,000`,
};

// Standing Bid
const default_standing_bid_expire_after_days = 45;
const max_standing_bid_expire_after_days = 90;
const min_standing_bid_lot = {
  number: 25_000,
  text: `$25,000`,
};

// Bid
const min_bid_lot = {
  number: 25_000,
  text: `$25,000`,
};

// U16R
const max_holdings = 4;

// Metabase
const metabase_production_host = `metabase.hiive.com`;
const metabase_development_host = `metabase.hiive.dev`;

// Accounts & support
const email_legal = `legal@hiive.com`;
const email_connect = `connect@hiive.com`;
const email_accounts = `accounts@hiive.com`;
const email_support = `support@hiive.com`;
const email_security_specialist = `securityspecialist@hiive.com`;
const email_funds = `funds@hiive.com`;
const email_execution = `execution@hiive.com`;

const EMAIL_MAX_LEN = 256;
const EMAIL_REGEX =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!?@#$%^&*_0-9])(?=.{10,})/;

const JOB_TITLE_MAX_LEN = 255;

// Customer relationship summary website
const customer_relationship_summary_url = `https://www.hiive.com/form-crs`;

// Marketing website
const marketing_website_url = `https://hiive.com`;

const back_office_url_production = `https://back-office.hiive.com`;
const back_office_url_staging = `https://back-office.hiive.dev`;
const back_office_url_development = `http://localhost:3001`;

const back_office_url = {
  [`local`]: back_office_url_development,
  [`development`]: back_office_url_staging,
  [`preview`]: back_office_url_staging,
  [`production`]: back_office_url_production,
};

const fund_diligence_guide = `/articles/fund-diligence-guide`;
const hiive_faq_url = `/articles/hiive-fund-faq`;
const t_and_c_url = `/terms-and-conditions`;

const marketing_website_links = {
  calculate_price_of_shares: {
    title: `4 ways to calculate the price of your private start-up shares`,
    url: `${marketing_website_url}/market-articles/4-ways-to-calculate-the-price-of-your-private-start-up-shares`,
  },
  dive_into_vc_secondaries: {
    title: `A dive into VC secondaries`,
    url: `${marketing_website_url}/market-articles/what-are-vc-secondary-transactions`,
  },
  how_to_sell_private_shares: {
    title: `How to sell your shares in a private company`,
    url: `${marketing_website_url}/market-articles/selling-pre-ipo-shares`,
  },
  guide_transaction_approval_closing: {
    title: `Guide: Transaction Approval and Closing`,
    url: `/articles/transaction-approval-and-closing-guide`,
  },
  faq_transaction_approval_closing: {
    title: `FAQ: Transaction Approval and Closing`,
    url: `/articles/transactions-faq`,
  },
  faq_us_taxes: {
    title: `FAQ: US Taxes`,
    url: `/articles/us-taxes-faq`,
  },
  guide_carta_holdings_verification: {
    title: `Guide: Carta Holdings Verification`,
    url: `/articles/carta-holdings-verification-guide`,
  },
};

const MFA_METHODS = {
  APP: `authenticator app`,
  SMS: `text message`,
};

const HIIVE_BANKING_INFO = {
  BENEFICIARY_NAME: `Hiive Markets Limited`,
  BENEFICIARY_ADDRESS: `169 Madison Ave Suite 11524, New York, NY 10016`,
  BANK_ADDRESS: `420 Montgomery Street, San Francisco, CA 94104`,
  BANK_NAME: `Wells Fargo`,
  ROUTING_NUMBER: `02400`,
  SWIFT_CODE: `WFBIUS6SXXX`,
};

const NUM_ACTIONS_BROKER = 2;

const AUTH0_CONFIG = {
  domain: process.env.AUTH0_DOMAIN,
  clientId: process.env.AUTH0_CLIENT_ID,
  clientSecret: process.env.AUTH0_CLIENT_SECRET,
  secret: process.env.AUTH0_SECRET,
  authorizationParameters: {
    audience: process.env.AUTH0_AUDIENCE,
  },
};

const Z_INDEX_OVERRIDES = {
  DEV_HELPERS: 5000,
};

export default {
  AUTH0_CONFIG,
  AUTH0_ENABLED,
  DEV_AUTH_ENABLED,
  IS_LOCAL,
  IS_STAGING,
  IS_PRODUCTION,
  max_share_classes_for_listing,
  number_of_latest_listings,
  number_of_latest_standing_bids,
  listing_shares_rounding_factor,
  high_fees_warning_threshold,
  min_listing_size,
  min_listing_size_for_partial_bids,
  search_debounce_time,
  commission_calculation_debounce_time,
  default_listing_expire_after_weeks,
  min_standing_bid_lot,
  default_standing_bid_expire_after_days,
  max_standing_bid_expire_after_days,
  min_bid_lot,
  metabase_production_host,
  metabase_development_host,
  email_execution,
  email_legal,
  email_connect,
  email_accounts,
  email_support,
  email_security_specialist,
  email_funds,
  EMAIL_MAX_LEN,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  JOB_TITLE_MAX_LEN,
  Z_INDEX_OVERRIDES,

  customer_relationship_summary_url,

  marketing_website_url,
  marketing_website_links,
  back_office_url,

  fund_diligence_guide,
  hiive_faq_url,
  t_and_c_url,

  max_holdings,

  MFA_METHODS,
  HIIVE_BANKING_INFO,
  NUM_ACTIONS_BROKER,
};
