import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { Box, SimpleGrid } from "@chakra-ui/react";

import {
  BuyerCommissionBreakdown,
  FeeBreakdown,
  SensitiveText,
  SharePrice,
  TileHeader,
} from "@/components/common";
import {
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  FeeDiscountApplicationState,
  TransactionFeeBreakdownBuyerCommissionFragment,
  TransactionFeeBreakdownCommissionFragment,
} from "@/gql";
import { useBuySideFees } from "@/hooks/featureFlags";

interface TransactionModificationFeeBreakdownProps {
  readonly pricePerShare: number;
  readonly outdatedPricePerShare: number;
  readonly numberOfShares: number;
  readonly outdatedNumberOfShares: number;
  readonly sellerCommission: TransactionFeeBreakdownCommissionFragment;
  readonly buyerCommission?: TransactionFeeBreakdownBuyerCommissionFragment | null;
  readonly isBroker: boolean;
  readonly isBuyer: boolean;
  readonly pendingFeeDiscountApplications?:
    | readonly BidFeeDiscountBreakdownFeeDiscountApplicationFragment[]
    | null;
}

const TransactionModificationFeeBreakdown = ({
  pricePerShare,
  numberOfShares,
  sellerCommission,
  buyerCommission,
  outdatedNumberOfShares,
  outdatedPricePerShare,
  isBroker,
  isBuyer,
  pendingFeeDiscountApplications,
}: TransactionModificationFeeBreakdownProps) => {
  const { t } = useTranslation();
  const isBuySideFeesEnabled = useBuySideFees();

  if (isBuyer && isBuySideFeesEnabled && buyerCommission) {
    return (
      <BuyerCommissionBreakdown
        numShares={numberOfShares}
        pricePerShare={pricePerShare}
        commission={buyerCommission}
        buyerCommissionStructureId={buyerCommission.commissionStructure?.id}
      />
    );
  }

  if (isBuyer) {
    const grossProceeds = pricePerShare * numberOfShares;
    return (
      <Box borderColor="grey.200" borderWidth={0.5} borderRadius="md" w="full">
        <SimpleGrid
          display="grid"
          gridTemplateColumns="1fr min-content"
          gap={2}
          alignItems="baseline"
          p={6}
        >
          <TileHeader>
            <SharePrice
              numShares={outdatedNumberOfShares}
              pricePerShare={outdatedPricePerShare}
              newNumShares={numberOfShares}
              newPricePerShare={pricePerShare}
            />
          </TileHeader>
          <SensitiveText textAlign="right">
            {currency(grossProceeds, {
              fromCents: true,
            }).format()}
          </SensitiveText>
        </SimpleGrid>
      </Box>
    );
  }

  return (
    <FeeBreakdown
      numShares={outdatedNumberOfShares}
      pricePerShare={outdatedPricePerShare}
      newNumShares={numberOfShares}
      newPricePerShare={pricePerShare}
      feeDiscountApplications={pendingFeeDiscountApplications}
      feeDiscountApplicationState={FeeDiscountApplicationState.Pending}
      flatFeeAmount={sellerCommission.flatFeeAmount}
      commissionAmount={sellerCommission.commissionAmount}
      feeDiscountAmount={sellerCommission.feeDiscountAmount}
      netFees={sellerCommission.netFees}
      disclaimerVariant={isBroker ? `brokerSeller` : `seller`}
      {...(isBroker && {
        combineHiiveFeesLabel: t(`hiive_co_broker_fee`),
        combineHiiveFeesTooltip: t(`hiive_co_broker_fee_tooltip`),
        netProceedsTitle: t(`broker_seller_proceeds`),
        netProceedsSubtitle: t(`gross_of_your_brokerage_fees`),
        netProceedsTooltip: t(`broker_seller_proceeds_tooltip`),
      })}
    />
  );
};

export default TransactionModificationFeeBreakdown;
