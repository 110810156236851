import { useTranslation } from "react-i18next";

import { Flex, Text } from "@chakra-ui/react";

import {
  BuyerCommissionBreakdown,
  FocusedShareDetails,
} from "@/components/common";
import {
  BidInfoCardStatusTile,
  BidActions,
  BidInfoCardCounterBidShareDetails,
  BuyerBidInfoCard,
} from "@/components/postings";
import { BuyerCounterBidInfoCardFragment } from "@/gql";
import { useBuySideFees } from "@/hooks/featureFlags";

const BuyerCounterBidInfoCard = ({
  bid,
}: {
  readonly bid: BuyerCounterBidInfoCardFragment;
}) => {
  const { t } = useTranslation();
  const isBuySideFeesEnabled = useBuySideFees();

  const placedAt = bid.counteredAt || bid.expireAt;
  const { company } = bid;

  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        <Text textStyle="heading-sm">{t`bid_info_buyer_counter_bid`}</Text>
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header
            title={`Bid ${bid.displayId}`}
            company={company}
          >
            <BidInfoCardCounterBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>
          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                {isBuySideFeesEnabled && bid.counterBid?.buyerCommission && (
                  <BuyerCommissionBreakdown
                    numShares={bid.counterBid?.numShares}
                    pricePerShare={bid.counterBid?.pricePerShare}
                    commission={bid.counterBid?.buyerCommission}
                    buyerCommissionStructureId={
                      bid.counterBid?.buyerCommission.commissionStructure?.id
                    }
                  />
                )}
                {placedAt && (
                  <BidInfoCardStatusTile bid={bid} showCounterBidDetails />
                )}
              </Flex>
            </FocusedShareDetails.ContentSection>
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
      <BidActions bid={bid} isBuyer />
      <BuyerBidInfoCard bid={bid} />
    </Flex>
  );
};

export default BuyerCounterBidInfoCard;
