import { BoxProps } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { DocumentType, UserWithInstitutionFragment } from "@/gql";
import { useCountryList } from "@/hooks";

import { PandadocFormFrame } from "./PandadocFormFrame";

interface InstitutionalCustomerAgreementProps extends BoxProps {
  readonly actor: UserWithInstitutionFragment;
  readonly onFormSigning: () => void;
}

const InstitutionalCustomerAgreement = ({
  actor: { id, firstName, lastName, email, institution },
  onFormSigning,
  ...boxProps
}: InstitutionalCustomerAgreementProps) => {
  const countryList = useCountryList();

  if (!institution) return null;

  const {
    jurisdictionOfIncorporation,
    streetAddress,
    region,
    postalCode,
    country,
    legalName,
  } = institution;

  const maybeRegion = !!region ? `${region} ` : ``;

  const address = `${streetAddress}\n${maybeRegion}${postalCode}\n${countryList.getName(
    country.name,
  )}`;

  const jurisdiction = () => {
    if (
      jurisdictionOfIncorporation?.name &&
      countryList.getName(jurisdictionOfIncorporation.name)
    ) {
      return countryList.getName(jurisdictionOfIncorporation.name) as string; // Extra typing as this should return a string due to the check above
    }

    return ``;
  };

  const formData = {
    recipients: {
      Signer: {
        FirstName: firstName || ``,
        LastName: lastName || ``,
        Email: email,
      },
    },
    metadata: {
      user_id: id,
      document_type: DocumentType.MembershipAgreement,
    },
    fields: {
      CustomerName: legalName,
      CustomerEmail: email,
      Jurisdiction: jurisdiction(),
      Address: address || ``,
    },
  };
  return (
    <PandadocFormFrame
      title="Customer Agreement Embed"
      onCompleted={onFormSigning}
      formId={process.env.NEXT_PUBLIC_INSTITUTIONAL_AGREEMENT_FORM_ID || ``}
      data={formData}
      border="grey-border"
      w={{ base: `100%`, md: `900px` }}
      h="754px"
      {...boxProps}
    />
  );
};

export default withCurrentActor(InstitutionalCustomerAgreement);
