import { TFunction } from "i18next";
import { omit } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  Flex,
  Text,
  VStack,
  SimpleGrid,
  GridItem,
  CardBody,
  Card,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  OnboardingContainerV2,
  OnboardingInfoMessageContext,
} from "@/components/onboarding-v2";
import {
  FormPhoneNumberInput,
  FormTextInput,
  FormListBox,
} from "@/components/react-hook-form";
import {
  ReferredFromSources,
  SignupAttributionInput,
  UpdateUserAdditionalInformationMutation,
  useTransitionCurrentStepMutation,
  useUpdateUserAdditionalInformationMutation,
} from "@/gql";
import { useAnalytics, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import {
  getUTMCookieData,
  utmCookieDataToSegmentOption,
  validPhoneNumber,
} from "@/utils";

interface AdditionalDetailsFormValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly referredFrom: ReferredFromSources | null;
}

const initialValues: AdditionalDetailsFormValues = {
  firstName: ``,
  lastName: ``,
  phoneNumber: ``,
  referredFrom: null,
};

const mapAttribution = (attribution: SignupAttributionInput | null) =>
  attribution &&
  Object.keys(attribution).reduce(
    (acc, key: keyof SignupAttributionInput) => ({
      ...acc,
      [key]: omit(attribution[key], `v`),
    }),
    {},
  );

const mapVariables = (
  values: AdditionalDetailsFormValues,
  attribution: SignupAttributionInput | null,
) => {
  const signupAttribution = attribution
    ? { signup_attribution: mapAttribution(attribution) }
    : {};

  const input = {
    input: {
      ...values,
      referredFrom: values.referredFrom ?? ReferredFromSources.Other, // FE validation ensure option is selected
      ...signupAttribution,
    },
  };

  return input;
};

const validationSchema = Yup.object({
  firstName: Yup.string().required(`Required`),
  lastName: Yup.string().required(`Required`),
  phoneNumber: Yup.string()
    .required(`Required`)
    .test(
      `valid phone number`,
      `Please enter a valid phone number`,
      validPhoneNumber,
    ),
  referredFrom: Yup.string().required(`Required`),
});

const referredFromSourceToString = (
  referredFromSource: ReferredFromSources,
  t: TFunction<"onboarding", "additional_details">,
): string =>
  match(referredFromSource)
    .with(ReferredFromSources.Advertisement, () => t(`advertisement`))
    .with(ReferredFromSources.Friend, () => t(`friend_colleague`))
    .with(ReferredFromSources.News, () => t(`press_release`))
    .with(ReferredFromSources.EmailNewsletter, () => t(`newsletter`))
    .with(ReferredFromSources.Event, () => t(`event`))
    .with(ReferredFromSources.GoogleSearch, () => t(`google_search`))
    .with(ReferredFromSources.SocialMedia, () => t(`social_media`))
    .with(ReferredFromSources.Other, () => t(`other`))
    .exhaustive();

const AdditionalDetails = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `additional_details`,
  });
  const { resetReonboardingMessage } = useContext(OnboardingInfoMessageContext);
  const analytics = useAnalytics();
  const attribution = getUTMCookieData();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onSuccess = async ({
    updateUserAdditionalInformation,
  }: UpdateUserAdditionalInformationMutation) => {
    await transitionCurrentStepMutation();

    if (updateUserAdditionalInformation?.user) {
      const option = utmCookieDataToSegmentOption(attribution);
      analytics.identify(updateUserAdditionalInformation.user.id, {}, option);
    }
    resetReonboardingMessage();
  };

  const mutation = useUpdateUserAdditionalInformationMutation();
  const { handleSubmit, isLoading, control, formState } = useFormQL({
    initialValues,
    mapVariables: (formValues) => mapVariables(formValues, attribution),
    mutation,
    onSuccess,
    validationSchema,
  });

  return (
    <OnboardingContainerV2 canGoBack={false} metaTitle={t(`add_your_details`)}>
      <Flex direction="column" w={{ base: `full`, lg: `178` }}>
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {`${t(`add_your_details`)}`}
          </Text>
          <Text color="grey.900">{t(`complete_your_account`)}</Text>
        </VStack>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <SimpleGrid
                gridTemplateColumns="1fr 1fr"
                mt={5}
                rowGap={6}
                width={{ base: `full` }}
              >
                <GridItem colSpan={2}>
                  <SimpleGrid
                    gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
                    rowGap={{ base: 2, lg: 0 }}
                    columnGap={2}
                  >
                    <GridItem colSpan={1}>
                      <FormTextInput
                        control={control}
                        name="firstName"
                        placeholder={t`first`}
                        label={t(`first_name`)}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormTextInput
                        control={control}
                        name="lastName"
                        placeholder={t`last`}
                        label={t(`last_name`)}
                      />
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormPhoneNumberInput
                    control={control}
                    name="phoneNumber"
                    label={t(`telephone`)}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormListBox
                    control={control}
                    name="referredFrom"
                    label={t`hear_about_us`}
                    placeholder={t`select_option`}
                    itemToString={(item: ReferredFromSources) =>
                      referredFromSourceToString(item, t)
                    }
                    getItemKey={(item) => item}
                    items={[
                      ReferredFromSources.Friend,
                      ReferredFromSources.News,
                      ReferredFromSources.Event,
                      ReferredFromSources.EmailNewsletter,
                      ReferredFromSources.Advertisement,
                      ReferredFromSources.GoogleSearch,
                      ReferredFromSources.SocialMedia,
                      ReferredFromSources.Other,
                    ]}
                  />
                </GridItem>
              </SimpleGrid>
            </CardBody>
          </Card>
          <Flex w="full" justify="flex-end" mt={8}>
            <HiiveButton
              observabilityLabel="[AdditionalDetails/Submit]"
              variant="rounded-solid-salmon"
              type="submit"
              size="xl"
              maxW="unset"
              w={{ base: `full`, lg: `unset` }}
              isLoading={isLoading || isTransitioningCurrentStep}
              isDisabled={!formState?.isValid}
            >
              {t(`next`)}
            </HiiveButton>
          </Flex>
        </form>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default AdditionalDetails;
