import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { StepPropsV2 } from "@/components/form";
import { AcceptStandingBidModalStandingBidFragment } from "@/gql";
import { useModal } from "@/hooks";
import { useTexasCopy } from "@/hooks/featureFlags";
import { formatPricePerShare, formatShares } from "@/utils";

import { StepKeys } from "./steps";
import { AcceptStandingBidSequenceModalFormValues } from "./types";

interface AcceptStandingBidModalSuccessProps
  extends StepPropsV2<StepKeys, AcceptStandingBidSequenceModalFormValues> {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

const AcceptStandingBidSuccessModal = ({
  standingBid,
  values,
}: AcceptStandingBidModalSuccessProps) => {
  const { t } = useTranslation();
  const { t: tSB } = useTranslation(`standingBids`);
  const isTexasCopyEnabled = useTexasCopy();

  const numShares = formatShares(values.numShares);
  const pricePerShare = formatPricePerShare(standingBid.pricePerShare);

  const { closeModal } = useModal();

  return (
    <>
      <HiiveModalHeader>{tSB`standing_bid_accepted`}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" spacing={3}>
          <Text textStyle={isTexasCopyEnabled ? `heading-md` : `heading-xl`}>
            {`${standingBid.displayId}: ${numShares} shares @ ${pricePerShare}/sh`}
          </Text>
          {isTexasCopyEnabled ? (
            <>
              <Text>{tSB(`the_transaction_process_will_now_start`)}</Text>
              <Text>
                <Trans
                  ns="standingBids"
                  i18nKey="for_support_reach_out"
                  values={{ companyName: standingBid.company.name }}
                  components={{
                    mailto: (
                      <SecuritySpecialistLink company={standingBid.company} />
                    ),
                  }}
                />
              </Text>
            </>
          ) : (
            <>
              <Text mb={4}>{t(`bid_accepted_congrats`)}</Text>
              <VStack mb={4} spacing={6} alignItems="start">
                <Text>
                  {t(`for_support`)}
                  {` `}
                  <SecuritySpecialistLink company={standingBid.company} />.
                </Text>
              </VStack>
            </>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[AcceptStandingBidSuccess/Submit]"
          type="button"
          submitText={isTexasCopyEnabled ? tSB`okay` : `Done`}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptStandingBidSuccessModal;
