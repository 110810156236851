import { useContext } from "react";

import { CurrentActorContext } from "@/components/providers";

/**
 * This hook is used before the `AuthenticatedLayout` to handle cases where
 * we need to set values when the user updates and when the user is null.
 * It provides access to the current actor, which may be `null` before authentication is resolved.
 */
export const useNullableCurrentActor = () => {
  const actor = useContext(CurrentActorContext);

  return actor;
};

export const useCurrentActor = () => {
  const { actor } = useContext(CurrentActorContext);
  if (!actor) {
    throw new Error(`useCurrentActor must be used within authenticated layout`);
  }
  return actor;
};
