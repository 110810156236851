import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Flex, HStack } from "@chakra-ui/react";

import {
  ExecutionTaskStatus,
  TransactionExecutionPageTaskFragment,
  useCompleteExecutionTaskMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

import { TaskFileUpload } from "./TaskFileUpload";
import { Upload, UploadStatus } from "./types";
import { useDelayTaskCompletion } from "./useDelayTaskCompletion";

const UploadFilesTaskCard = ({
  task,
  delayCompletion = false,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly delayCompletion?: boolean;
}) => {
  const { t } = useTranslation();
  const { isCompleted, initialStatus } = useDelayTaskCompletion(
    task.status,
    delayCompletion,
  );
  const [isPendingCompletion, setIsPendingCompletion] = useState(false);

  const [completeTask] = useMutationWithError(
    useCompleteExecutionTaskMutation(),
    `completeExecutionTask`,
  );
  const [uploads, setUploads] = useState<Upload[]>(
    task.data?.__typename === `ExecutionUploadFilesTaskData` && task.data?.files
      ? task.data?.files?.map((file) => ({
          id: file.id,
          status: UploadStatus.Uploaded,
          name: file.filename,
          type: file.contentType,
        }))
      : [],
  );

  const handleUploadChange = (
    uploadUpdater: (prevUploads: Upload[]) => Upload[],
  ) => {
    setUploads(uploadUpdater);
  };

  const handleComplete = async () => {
    setIsPendingCompletion(true);
    const response = await completeTask({
      variables: {
        taskId: task.id,
      },
    });

    if (response?.completeExecutionTask.errors) {
      setIsPendingCompletion(false);
    }
  };

  const showCTA = initialStatus === ExecutionTaskStatus.InProgress;

  useEffect(() => {
    if (isCompleted) {
      setIsPendingCompletion(false);
    }
  }, [isCompleted]);

  const filesCurrentlyUploading =
    uploads.filter((upload) => upload.status === UploadStatus.Uploading)
      .length > 0;

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <TaskFileUpload
        taskId={task.id}
        uploads={uploads}
        onUploadChange={handleUploadChange}
        disabled={task.status !== ExecutionTaskStatus.InProgress}
        hideDropzone={isCompleted}
      />
      {showCTA && (
        <HStack w="full" justifyContent="end" mt={2}>
          <Button
            variant="rounded-solid-grey"
            size={{ base: `md`, md: `xl` }}
            isLoading={isPendingCompletion}
            isDisabled={uploads.length === 0 || filesCurrentlyUploading}
            onClick={() => {
              handleComplete();
            }}
          >
            {t(`submit`)}
          </Button>
        </HStack>
      )}
    </Flex>
  );
};

export default UploadFilesTaskCard;
