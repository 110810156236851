import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { Card, CardBody, Center, Flex, Spinner, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  OnboardingContainerV2,
  SlideAnimation,
  UnauthenticatedMessageWrapper,
} from "@/components/onboarding-v2";
import {
  CurrentContextDocument,
  useVerifyEmailTokenMutation,
  useRequestEmailVerificationMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { useMutationWithError, useCustomToast, useSession } from "@/hooks";
import { constants, ROUTES } from "@/utils";

const { AUTH0_ENABLED } = constants;

interface VerifyEmailPageProps {
  readonly verifyEmailToken?: string;
}

const AuthenticatedUnableToVerifyPage = () => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();

  const [requestEmailVerificationMutation] = useMutationWithError(
    useRequestEmailVerificationMutation({
      onCompleted: () => {
        successToast(t(`verification_email_sent`));
      },
    }),
    `requestEmailVerification`,
  );

  return (
    <OnboardingContainerV2 metaTitle={t(`unable_to_verify`)} canGoBack={false}>
      <Flex direction="column" w="full" maxW="45rem">
        <Flex direction="column" gap={8} mb={8}>
          <Text textStyle="heading-3xl">{t(`unable_to_verify`)}</Text>
          <SlideAnimation>
            <Card>
              <CardBody>
                <Flex direction="column" alignItems="flex-start" gap={2}>
                  <Text>
                    {t(`your_email_verification_link_may_be_expired`)}
                  </Text>
                  <HiiveButton
                    maxW="auto"
                    variant="text-gray"
                    p={0}
                    m={0}
                    onClick={() => requestEmailVerificationMutation()}
                    justifyContent="flex-start"
                    observabilityLabel="[VerifyEmailPage] Resend Email Verification"
                  >
                    {t(`resend_email_verification`)}
                  </HiiveButton>
                </Flex>
              </CardBody>
            </Card>
          </SlideAnimation>
        </Flex>
      </Flex>
    </OnboardingContainerV2>
  );
};

const UnauthenticatedUnableToVerifyPage = () => {
  const { t } = useTranslation();
  return (
    <Flex
      mt={32}
      mx="auto"
      direction="column"
      w="full"
      px={{ base: 4, lg: 7 }}
      py={{ base: 4, lg: 8 }}
      maxW="xl"
    >
      <Flex direction="column" gap={8} mb={8}>
        <Text textStyle="heading-3xl">{t(`unable_to_verify`)}</Text>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" alignItems="flex-start" gap={2}>
                <Text>{t(`your_email_verification_link_may_be_expired`)}</Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </Flex>
  );
};

const UnableToVerifyEmail = () => {
  const { t } = useTranslation();
  return (
    <Flex direction="column" gap={8} mb={8}>
      <Text textStyle="heading-3xl">{t(`unable_to_verify`)}</Text>
      <SlideAnimation>
        <Card>
          <CardBody>
            <Flex direction="column" alignItems="flex-start" gap={2}>
              <Text>{t(`your_email_verification_link_may_be_expired`)}</Text>
            </Flex>
          </CardBody>
        </Card>
      </SlideAnimation>
    </Flex>
  );
};

const useVerifyEmailToken = (verifyEmailToken?: string) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isVerifyingEmailToken, setIsVerifyingEmailToken] =
    useState<boolean>(true);
  const [verifyEmailTokenMutation] = useMutationWithError(
    useVerifyEmailTokenMutation(),
    `verifyEmailToken`,
  );
  const { isAuthenticated } = useSession();
  const hasTriggeredMutation = useRef<boolean>(false);

  const onSuccess = (res: {
    readonly verifyEmailToken: { readonly user?: UserWithInstitutionFragment };
  }) => {
    setIsVerified(!!res?.verifyEmailToken.user);
  };

  useEffect(() => {
    if (!verifyEmailToken || hasTriggeredMutation.current) return;

    hasTriggeredMutation.current = true;

    verifyEmailTokenMutation({
      variables: { token: verifyEmailToken },
      refetchQueries: isAuthenticated ? [CurrentContextDocument] : [],
    })
      .then(onSuccess)
      .finally(() => setIsVerifyingEmailToken(false));
  }, [verifyEmailToken]);

  return [isVerified, isVerifyingEmailToken];
};

const AuthenticatedVerifyEmailPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => router.replace(ROUTES.DASHBOARD), 2500);
  }, []);

  return (
    <OnboardingContainerV2 metaTitle={t(`email_verified`)} canGoBack={false}>
      <Flex direction="column" w="full" maxW="45rem">
        <Flex direction="column" gap={8} mb={8}>
          <Text textStyle="heading-3xl">{t(`email_verified`)}</Text>
          <SlideAnimation>
            <Card>
              <CardBody>
                <Flex direction="column" gap={2}>
                  <Text>{t(`thank_you`)}</Text>
                  <Text>{t(`one_moment_redirect`)}</Text>
                </Flex>
              </CardBody>
            </Card>
          </SlideAnimation>
        </Flex>
      </Flex>
    </OnboardingContainerV2>
  );
};

const UnauthenticatedVerifyEmailPage = () => {
  const { t } = useTranslation();
  const loginRoute = AUTH0_ENABLED ? ROUTES.AUTH0_LOGIN : ROUTES.DEFAULT_LOGIN;

  return (
    <Flex
      mt={32}
      mx="auto"
      direction="column"
      w="full"
      px={{ base: 4, lg: 7 }}
      py={{ base: 4, lg: 8 }}
      maxW="xl"
    >
      <Flex direction="column" gap={8} mb={8}>
        <Text textStyle="heading-3xl">{t(`email_verified`)}</Text>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Text>{t(`thank_you_sign_in`)}</Text>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
      <Flex justifyContent="flex-end" w="full">
        <HiiveButton
          as={Link}
          w={{ base: `full`, sm: `auto` }}
          maxW="auto"
          variant="rounded-solid-salmon"
          size="xl"
          href={loginRoute}
          observabilityLabel="[VerifyEmailPage] Sign In"
        >
          {t(`sign_in`)}
        </HiiveButton>
      </Flex>
    </Flex>
  );
};

const VerifyEmail = () => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => router.replace(ROUTES.DASHBOARD), 2500);
  }, []);

  return (
    <Flex direction="column" w="full" maxW="45rem">
      <Flex direction="column" gap={8} mb={8}>
        <Text textStyle="heading-3xl">{t(`email_verified`)}</Text>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={2}>
                <Text>{t(`thank_you`)}</Text>
                <Text>{t(`one_moment_redirect_while`)}</Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </Flex>
  );
};

const VerifyEmailPage = ({ verifyEmailToken }: VerifyEmailPageProps) => {
  const { isAuthenticated } = useSession();
  const [isVerified, isVerifyingEmailToken] =
    useVerifyEmailToken(verifyEmailToken);

  if (isVerifyingEmailToken) {
    return (
      <Center m={20} minH="80vh">
        <Spinner />
      </Center>
    );
  }

  if (AUTH0_ENABLED) {
    if (!isVerified) {
      return (
        <UnauthenticatedMessageWrapper>
          <UnableToVerifyEmail />;
        </UnauthenticatedMessageWrapper>
      );
    }

    return (
      <UnauthenticatedMessageWrapper>
        <VerifyEmail />
      </UnauthenticatedMessageWrapper>
    );
  }

  if (!isVerified) {
    return isAuthenticated ? (
      <AuthenticatedUnableToVerifyPage />
    ) : (
      <UnauthenticatedUnableToVerifyPage />
    );
  }

  return isAuthenticated ? (
    <AuthenticatedVerifyEmailPage />
  ) : (
    <UnauthenticatedVerifyEmailPage />
  );
};

export default VerifyEmailPage;
