import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { StepPropsV2 } from "@/components/form";
import { FlowKind, ModalKind } from "@/components/providers";
import { UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment } from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import { useTexasCopy } from "@/hooks/featureFlags";
import {
  formatPricePerShare,
  formatShares,
  getSecuritySpecialistContact,
  getShortDocumentTitleByTransferMethod,
  getTransferMethodByUnaccreditedSellerTransferMethodInput,
} from "@/utils";

import { StepKeys } from "./steps";
import { UnaccreditedSellerAcceptStandingBidSequenceModalFormValues } from "./types";

interface UnaccreditedSellerAcceptStandingBidSuccessModalProps
  extends StepPropsV2<
    StepKeys,
    UnaccreditedSellerAcceptStandingBidSequenceModalFormValues
  > {
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

const UnaccreditedSellerAcceptStandingBidSuccessModal = ({
  standingBid,
  values,
}: UnaccreditedSellerAcceptStandingBidSuccessModalProps) => {
  const { t: tSB } = useTranslation(`standingBids`);
  const isTexasCopyEnabled = useTexasCopy();

  const transferMethod =
    getTransferMethodByUnaccreditedSellerTransferMethodInput(
      values.transferMethod,
    );

  const shortDocumentTitle =
    getShortDocumentTitleByTransferMethod(transferMethod);

  const securitySpecialistEmail = getSecuritySpecialistContact(
    standingBid.company,
  );

  const { showFlow } = useFlowLauncher();

  const { closeModal, addModalCloseHandler, removeModalCloseHandler } =
    useModal();

  useEffect(() => {
    addModalCloseHandler(ModalKind.UnaccreditedSellerAcceptStandingBid, () => {
      showFlow(FlowKind.U16RTransactionCelebration);
    });

    return () => {
      removeModalCloseHandler(ModalKind.UnaccreditedSellerAcceptStandingBid);
    };
  }, []);

  return (
    <>
      <HiiveModalHeader>{tSB`standing_bid_accepted`}</HiiveModalHeader>
      <ModalBody>
        {isTexasCopyEnabled ? (
          <VStack alignItems="flex-start" spacing={3}>
            <Text textStyle="heading-md">
              {tSB(`standing_bid_values_summary`, {
                standingBidId: standingBid.displayId,
                companyName: standingBid.company.name,
                numShares: formatShares(values.numShares),
                pricePerShare: formatPricePerShare(values.pricePerShare, false),
              })}
            </Text>
            <Text>{tSB`the_transaction_process_will_now_start`}</Text>
            <Text>
              <Trans
                ns="bids"
                i18nKey="for_support_reach_out"
                values={{ companyName: standingBid.company.name }}
                components={{
                  mailto: (
                    <SecuritySpecialistLink company={standingBid.company} />
                  ),
                }}
              />
            </Text>
          </VStack>
        ) : (
          <VStack alignItems="flex-start">
            <Text textStyle="heading-xl">
              {`You accepted ${standingBid.company.name} standing bid ${
                standingBid.shortId
              } for ${
                !!values.numShares && formatShares(values.numShares)
              } shares at ${formatPricePerShare(values.pricePerShare * 100)}/sh!`}
            </Text>
            <Text>
              The next step is for you and the buyer to execute the{` `}
              {shortDocumentTitle} for this transaction. You may message the
              bidder directly with any questions or comments.
            </Text>
            <Box>
              <Text as="span">You may also reach out anytime to the{` `}</Text>
              <MailtoLink
                fontWeight="medium"
                email={securitySpecialistEmail}
                subject={`Contact ${standingBid.company.name} Security Specialist`}
              >
                {`${standingBid.company.name} Security Specialist`}
              </MailtoLink>
              <Text as="span">{` `}for assistance on your transaction.</Text>
            </Box>
          </VStack>
        )}
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[UnaccreditedSellerAcceptStandingBidSuccess/Submit]"
          type="button"
          submitText={isTexasCopyEnabled ? tSB`okay` : `Okay`}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default UnaccreditedSellerAcceptStandingBidSuccessModal;
