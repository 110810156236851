/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid, Text } from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  OtherDetailsInput,
  StepPropsV2,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { StandingBidOtherDetailsTooltip } from "@/components/tooltip";
import { UserWithInstitutionFragment } from "@/gql";
import { useCustomToast, useModal, useStepValidator } from "@/hooks";
import { useStandingBidNotesUpdates } from "@/hooks/featureFlags";
import { constants, Nullable } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  ModifyStandingBidModalStepFormContext,
  useModifyStandingBidModalStepFormContext,
} from "./ModifyStandingBidModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { ModifyStandingBidModalFormValues } from "./types";

export const validationSchema = Yup.object().shape({
  confirmed: Yup.boolean().oneOf([true], `Required`),
  expireAt: Yup.string().required(`Required`),
  otherDetails: Yup.string().nullable(),
  muteNotifyWatchers: Yup.boolean().nullable(),
});

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, Nullable<ModifyStandingBidModalFormValues>> {
  readonly actor: UserWithInstitutionFragment;
}

const AdditionalDetails = ({
  actor,
  values,
  stepRouter,
  isSubmitting,
}: AdditionalDetailsProps) => {
  const { stepControls } = stepRouter;
  const { closeModal } = useModal();

  const { t } = useTranslation(`standingBids`);
  const isStandingBidUpdatesEnabled = useStandingBidNotesUpdates();

  const { successToast } = useCustomToast();

  const { submitMutation } = useModifyStandingBidModalStepFormContext();

  const onSuccess = () =>
    submitMutation().then(() => {
      successToast(`Standing Bid modified.`);
      closeModal();
    });

  useStepValidator({
    Context: ModifyStandingBidModalStepFormContext,
    stepKey: stepKeys.modifyStandingBidAdditionalDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const maxExpiryDate = datetime
    .add(`day`, constants.max_standing_bid_expire_after_days, dayjs())
    .toDate();

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          <GridItem colSpan={2}>
            <DateInput
              name="expireAt"
              label="Expiration date"
              maxDate={maxExpiryDate}
              tooltipContent={`By default, Hiive will set an expiry of ${constants.default_standing_bid_expire_after_days} days. After this date, your Standing Bid will be removed. If necessary, you can extend this length up to ${constants.max_standing_bid_expire_after_days} days, but we recommend to keep a date within a shorter timeline to help prevent your Standing Bid from becoming stale or out of date.`}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <OtherDetailsInput
              name="otherDetails"
              label="Additional Standing Bid notes"
              tooltipContent={<StandingBidOtherDetailsTooltip />}
            />
            {!actor.isHiiveUser && isStandingBidUpdatesEnabled && (
              <Text
                textStyle="text-sm"
                mt={1.5}
              >{t`this_note_will_be_visible_to_everyone`}</Text>
            )}
          </GridItem>
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptionsDropdown
                validationSchema={validationSchema}
                fieldNames={[`muteNotifyWatchers`]}
              >
                <CheckboxInput
                  name="muteNotifyWatchers"
                  label={t(`do_not_send_activity_notifications`)}
                />
              </HiiveAdvancedOptionsDropdown>
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <CheckboxInput
              name="confirmed"
              label={t(`place_standing_bid_confirm_intent_prompt`)}
              helperText={t(`place_standing_bid_confirm_intent_description`)}
            />
          </GridItem>
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[ModifyStandingBid/AdditionalDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveBackButton
          observabilityLabel="[ModifyStandingBid/AdditionalDetails/Back]"
          onClick={stepControls.previousStep}
        />
        <HiiveSubmitButton
          observabilityLabel="[ModifyStandingBid/AdditionalDetails/Submit]"
          submitText="Modify Standing Bid"
          isLoading={isSubmitting}
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default withCurrentActor(AdditionalDetails);
