import { ReactNode } from "react";

import { Flex, Center } from "@chakra-ui/react";

import { Logo, HiiveFooter } from "@/components/common";

const UnauthenticatedMessageWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Flex direction="column" h="100vh" justify="space-between" align="center">
    <Flex justify="center" flex="1" w="full" maxW="720px">
      <Flex direction="column" w="full" pt="56px">
        <Center>
          <Logo.Grey mb={{ base: 9, md: 14 }} width={40} />
        </Center>
        {children}
      </Flex>
    </Flex>
    <Center w="full" maxW="900px">
      <HiiveFooter />
    </Center>
  </Flex>
);

export default UnauthenticatedMessageWrapper;
