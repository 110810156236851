import { isEmpty } from "lodash";
import { useEffect } from "react";

import { NullableCurrentActorContext } from "@/components/providers";
import { useSession, useSignOut, useIsChangingRoute } from "@/hooks";
import constants from "@/utils/constants";

const { AUTH0_ENABLED } = constants;

export const useCheckAuthentication = ({
  actor,
  loading,
}: NullableCurrentActorContext) => {
  const { isAuthenticated } = useSession();
  const signOut = useSignOut();
  const isChangingRoute = useIsChangingRoute();
  const hasActor = !isEmpty(actor);

  const hasTokenButNoActor =
    isAuthenticated && !hasActor && !isChangingRoute() && !loading;

  useEffect(() => {
    if (!isAuthenticated && !AUTH0_ENABLED) {
      signOut({ redirectPath: window.location.pathname });
    }
  }, []);

  useEffect(() => {
    if (hasTokenButNoActor) {
      signOut();
    }
  }, [hasTokenButNoActor]);
};
