import { useTranslation } from "react-i18next";

import { Card, CardBody, Text, VStack } from "@chakra-ui/react";

import { Logo } from "@/components/common";

const EmptyActiveTransactionTabPanel = () => {
  const { t } = useTranslation(`execution`);

  return (
    <Card w="full">
      <CardBody>
        <VStack py={6}>
          <Logo.GreyIcon mb={5} width="32px" height="auto" />
          <Text textAlign="center" maxW="sm">
            {t(`no_transactions_in_progress`)}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default EmptyActiveTransactionTabPanel;
