import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";

import { HiiveTab } from "@/components/common";
import {
  Transaction,
  useCurrentContextQuery,
  UserActivityTransactionFragment,
} from "@/gql";
import { useTabRouter, useTransactionDetails } from "@/hooks";

import ActiveTransactionTabPanel from "./ActiveTransactionTabPanel";
import ActiveTransactionsCardHeader from "./ActiveTransactionsCardHeader";

enum ActiveTransactionTabs {
  MY_TRANSACTIONS = `MY_TRANSACTIONS`,
  OTHER_TRANSACTIONS = `OTHER_TRANSACTIONS`,
}

type InstitutionActiveTransactionsCardProps = {
  myInstitutionTransactions: UserActivityTransactionFragment[];
  myTransactions: UserActivityTransactionFragment[];
};

const filterOtherTransactions = (
  myTransactions: Pick<Transaction, "id">[] | undefined,
  institutionTransaction: Pick<Transaction, "id">,
) =>
  !myTransactions?.some(
    (myTransaction) => myTransaction?.id === institutionTransaction?.id,
  );

const InstitutionActiveTransactionsCard = ({
  myInstitutionTransactions,
  myTransactions,
}: InstitutionActiveTransactionsCardProps) => {
  const { t } = useTranslation(`dashboard`);
  const { tabControls, tabsInfo } = useTabRouter<ActiveTransactionTabs>({
    initialTab: ActiveTransactionTabs.MY_TRANSACTIONS,
  });

  const { data: currentContextData } = useCurrentContextQuery();
  const legalName =
    currentContextData?.currentContext?.currentActor.institution?.legalName;
  const { hasTerminalState } = useTransactionDetails();

  const otherTransactions = myInstitutionTransactions
    ?.filter((institutionTransaction: Transaction) =>
      filterOtherTransactions(myTransactions, institutionTransaction),
    )
    .filter((transaction) => !transaction.texasEnabled);

  const activeTransactions = [
    ...(myTransactions ?? []),
    ...(otherTransactions ?? []),
  ].filter((transaction) => !hasTerminalState(transaction));

  const activeMyTransactions = myTransactions?.filter(
    (transaction) => !hasTerminalState(transaction),
  );

  const activeOtherTransactions = otherTransactions?.filter(
    (transaction) => !hasTerminalState(transaction),
  );

  if (activeTransactions?.length === 0) return null;

  const showMyTransactions =
    tabsInfo.currentTabKey === ActiveTransactionTabs.MY_TRANSACTIONS;
  const showOtherTransactions =
    tabsInfo.currentTabKey === ActiveTransactionTabs.OTHER_TRANSACTIONS;

  return (
    <Card w="full">
      <ActiveTransactionsCardHeader
        transactionsCount={activeTransactions?.length}
      />
      <Tabs w="full">
        <Box p="6" pb="0">
          <TabList alignItems="center" border={0} pb={0}>
            <HiiveTab
              isActive={showMyTransactions}
              onClick={() =>
                tabControls.jumpToTab(ActiveTransactionTabs.MY_TRANSACTIONS)
              }
            >
              {t(`my_transactions`)}
            </HiiveTab>
            <HiiveTab
              isActive={showOtherTransactions}
              onClick={() =>
                tabControls.jumpToTab(ActiveTransactionTabs.OTHER_TRANSACTIONS)
              }
            >
              {t(`other_transactions`, {
                name: legalName,
              })}
            </HiiveTab>
          </TabList>
        </Box>
        <CardBody
          alignItems="start"
          as={VStack}
          maxH="sm"
          overflowY="auto"
          gap={4}
        >
          <TabPanels>
            {showMyTransactions && (
              <ActiveTransactionTabPanel transactions={activeMyTransactions} />
            )}
            {showOtherTransactions && (
              <ActiveTransactionTabPanel
                transactions={activeOtherTransactions}
              />
            )}
          </TabPanels>
        </CardBody>
      </Tabs>
    </Card>
  );
};

export default InstitutionActiveTransactionsCard;
