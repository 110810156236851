import { Bank } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Card,
  CardHeader,
  HStack,
  VStack,
  Text,
  Button,
  Badge,
  Center,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";

import { SimpleListbox } from "@/components/form";
import { useDelayTaskCompletion } from "@/components/transactions";
import {
  ExecutionEntityBankAccountFragment,
  ExecutionCollectBankAccountTaskDataFragment,
  ExecutionTaskStatus,
  useCompleteExecutionTaskMutation,
  BankAccountVerificationStatus,
  TransactionExecutionPageTaskFragment,
} from "@/gql";
import { useModal } from "@/hooks";

import { BankAccountSimpleListboxElement } from "./BankAccountSimpleListboxElement";

const renderListItem = (bankAccount: ExecutionEntityBankAccountFragment) => (
  <BankAccountSimpleListboxElement bankAccount={bankAccount} />
);

const CollectExistingAccountTaskCard = ({
  task,
  data,
  assignedBankAccount,
  delayCompletion,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionCollectBankAccountTaskDataFragment;
  readonly assignedBankAccount?: ExecutionEntityBankAccountFragment;
  readonly delayCompletion: boolean;
}) => {
  const { t } = useTranslation(`execution`);
  const { onOpenModal, modals } = useModal();

  const { isCompleted, initialStatus } = useDelayTaskCompletion(
    task.status,
    delayCompletion,
  );

  const { entity } = data;

  const bankAccounts =
    entity?.bankAccounts.filter(
      (account) =>
        account.verificationStatus === BankAccountVerificationStatus.Verified,
    ) ?? [];

  const [selectedAccount, setSelectedAccount] =
    useState<ExecutionEntityBankAccountFragment>();

  const [completeTask, { loading }] = useCompleteExecutionTaskMutation();

  const isInProgress = initialStatus === ExecutionTaskStatus.InProgress;

  const showBankAccountsDropdown = isInProgress;
  const showBankAccountStatus = isCompleted && !!assignedBankAccount;

  const onSubmit = async () => {
    const { data } = await completeTask({
      variables: {
        taskId: task.id,
        input: {
          collectBankAccount: {
            bankAccountId: selectedAccount?.id,
          },
        },
      },
    });

    if (data?.completeExecutionTask.task?.id) {
      onOpenModal(modals.collectExistingBankAccountSuccess());
    }
  };

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
      xs: false,
    },
    { ssr: false },
  );

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      {assignedBankAccount && (
        <Card variant="flat" bg="grey.15">
          <CardHeader
            px={4}
            py={3}
            as={HStack}
            w="full"
            justifyContent="space-between"
            bg="inherit"
            borderBottom="none"
            borderBottomRadius={6}
          >
            <HStack gap={{ base: 3, md: 4 }}>
              <Center>
                <Bank width="24px" height="24px" />
              </Center>
              <HStack>
                <Text textStyle="heading-md">{t(`bank_account`)}</Text>
                <Text textStyle="text-md">
                  {t(`bank_accounts.redacted`, {
                    ns: `postings`,
                  })}
                </Text>
                <Text textStyle="text-md">{assignedBankAccount.last4}</Text>
              </HStack>
            </HStack>
            {showBankAccountStatus &&
              match(assignedBankAccount?.verificationStatus)
                .with(BankAccountVerificationStatus.Verified, () => (
                  <Badge variant="status-active">
                    <Text textStyle="text-sm" lineHeight="unset">
                      {t(`verified`)}
                    </Text>
                  </Badge>
                ))
                .otherwise(() => (
                  <Badge variant="rounded-light-grey">
                    <Text textStyle="text-sm" lineHeight="unset">
                      {t(`unverified`)}
                    </Text>
                  </Badge>
                ))}
          </CardHeader>
        </Card>
      )}
      {showBankAccountsDropdown && (
        <VStack w="full">
          <Card
            px={3}
            py={2}
            as={VStack}
            variant="flat"
            w="full"
            spacing={0}
            alignItems="start"
            bg="grey.25"
          >
            <Text textStyle="heading-3xs" color="grey.600">
              {t(`bank_details_for`)}:
            </Text>
            <Text textStyle="text-sm" color="grey.600">
              {entity?.legalName} &#x2022;{` `}
              {entity?.jurisdictionOfFormation?.name}
            </Text>
          </Card>
          <SimpleListbox
            placeholder={t`select_bank_account`}
            value={selectedAccount}
            items={bankAccounts}
            inputContainerStyle={{
              height: `inherit`,
              minHeight: isMobileView ? 64 : 48,
            }}
            itemsContainerStyle={{
              marginTop: isMobileView ? 24 : 8,
            }}
            itemToString={(bankAccount) => bankAccount?.last4 ?? ``}
            getItemKey={(bankAccount) => bankAccount?.id ?? ``}
            onItemSelected={(bankAccount) => setSelectedAccount(bankAccount)}
            itemRenderer={renderListItem}
          />
        </VStack>
      )}
      {isInProgress && (
        <HStack w="full" justifyContent="flex-end" mt={2}>
          <Button
            isLoading={false}
            variant="rounded-outline-grey"
            size={{ base: `md`, md: `xl` }}
            onClick={onOpenModal(
              modals.collectBankAccount({ task, entity: data.entity }),
            )}
          >
            {t(`add_new_account`)}
          </Button>
          <Button
            variant="rounded-solid-grey"
            size={{ base: `md`, md: `xl` }}
            onClick={onSubmit}
            isDisabled={!selectedAccount}
            isLoading={loading}
          >
            {t(`confirm`)}
          </Button>
        </HStack>
      )}
    </Flex>
  );
};

export default CollectExistingAccountTaskCard;
