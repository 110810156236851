import { useTranslation } from "react-i18next";

import { Alert, AlertIcon, AlertDescription, Box } from "@chakra-ui/react";

type CompanyUnaccreditedSellerRestrictedAlertProps = {
  companyName: string;
};

export function CompanyUnaccreditedSellerRestrictedAlert({
  companyName,
}: CompanyUnaccreditedSellerRestrictedAlertProps) {
  const { t } = useTranslation(`company`);
  return (
    <Alert alignItems="flex-start" status="info" variant="infoGrey">
      <AlertIcon w={3} h={3} mt="6px" />
      <Box>
        <AlertDescription fontSize={14}>
          {t(`company_does_not_allow_most_direct_transfers`, {
            companyName,
          })}
        </AlertDescription>
      </Box>
    </Alert>
  );
}
