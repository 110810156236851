import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  AcceptStandingBidModalStandingBidFragment,
  AcceptStandingBidMutation,
} from "@/gql";
import { useTexasCopy } from "@/hooks/featureFlags";
import { constants, formatPricePerShare, formatShares, makeUrl } from "@/utils";

import { useAcceptStandingBidSequenceModalContext } from "./AcceptStandingBidSequenceModalContext";
import { StepKeys } from "./steps";
import { AcceptStandingBidSequenceModalFormValues } from "./types";

interface AcceptStandingBidConfirmationModalProps
  extends StepPropsV2<StepKeys, AcceptStandingBidSequenceModalFormValues> {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

const AcceptStandingBidConfirmationModal = ({
  stepRouter,
  values,
  standingBid,
  isSubmitting,
}: AcceptStandingBidConfirmationModalProps) => {
  const { t } = useTranslation();
  const { t: tSB } = useTranslation(`standingBids`);
  const router = useRouter();
  const { submitMutation } = useAcceptStandingBidSequenceModalContext();
  const isTexasCopyEnabled = useTexasCopy();

  const { stepControls } = stepRouter;

  const onClickSubmit = () =>
    submitMutation().then((response: AcceptStandingBidMutation) => {
      stepControls.nextStep();

      const transaction = response.acceptStandingBid?.bid?.transaction;

      if (!transaction) return;

      router.push(makeUrl(transaction));
    });

  const numShares = formatShares(values.numShares);
  const pricePerShare = formatPricePerShare(standingBid.pricePerShare);

  return (
    <div data-testid="standing-bid-accept-confirmation-modal">
      <HiiveModalHeader>
        {isTexasCopyEnabled
          ? tSB(`accept_standing_bid_bid_id`, { bidId: standingBid.displayId })
          : t(`are_you_sure_accept`, { bidType: `bid` })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle={isTexasCopyEnabled ? `heading-md` : `heading-lg`}>
            {`${standingBid.displayId}: ${numShares} shares @ ${pricePerShare}/sh`}
          </Text>
          {isTexasCopyEnabled ? (
            <Text>
              <Trans
                ns="standingBids"
                i18nKey="accepting_this_standing_bid_counts_as"
                components={{
                  italic: (
                    <Link
                      key="terms-and-conditions"
                      href={constants.t_and_c_url}
                      target="_blank"
                      textDecorationLine="underline"
                    />
                  ),
                }}
              />
              <Text mt={3}>{tSB`once_you_ve_accepted_this_standing_bid`}</Text>
            </Text>
          ) : (
            <Text>
              <Trans
                i18nKey="accept_bid_terms_and_conditions"
                components={{
                  italic: (
                    <Link
                      key="terms-and-conditions"
                      href={constants.t_and_c_url}
                      target="_blank"
                      textDecorationLine="underline"
                    />
                  ),
                }}
              />
            </Text>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[AcceptStandingBid/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          observabilityLabel="[AcceptStandingBid/Submit]"
          isLoading={isSubmitting}
          submitText={
            isTexasCopyEnabled
              ? tSB`accept_standing_bid`
              : t(`yes_accept_bid`, { bidType: `bid` })
          }
          type="button"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </div>
  );
};

export default AcceptStandingBidConfirmationModal;
