import xor from "lodash/xor";
import { Trans, useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { BidActionsBidFragment, UserWithInstitutionFragment } from "@/gql";
import { useModal } from "@/hooks";
import { useTexasCopy } from "@/hooks/featureFlags";
import {
  constants,
  getAvailableBidActions,
  getIsUnaccreditedSeller,
  getShortDocumentTitleByTransferMethod,
  getIsEitherFund,
  getIsOtherMethod,
} from "@/utils";

type Action = {
  readonly ctaText: string;
  readonly onClick: () => void;
};

const BidActionCard = ({
  title,
  description,
  action: { onClick, ctaText },
}: {
  readonly title: string;
  readonly description: React.ReactNode;
  readonly action: Action;
}) => (
  <Card h="full">
    <CardBody py={7}>
      <Flex
        wrap="wrap"
        justify="center"
        alignContent="space-between"
        h="full"
        gap={{
          base: 4,
          sm: 5,
        }}
      >
        <Flex direction="column" gap={3} flex="1 1 60%">
          <Text
            w="full"
            as="h5"
            textTransform="uppercase"
            textStyle="heading-sm"
          >
            {title}
          </Text>
          <Text textStyle="text-normal">{description}</Text>
        </Flex>
        <HiiveButton
          my="auto"
          variant="rounded-solid-salmon"
          size="xl"
          onClick={onClick}
          observabilityLabel="[BidActions] CTA"
        >
          {ctaText}
        </HiiveButton>
      </Flex>
    </CardBody>
  </Card>
);

const BidActionTOS = withCurrentActor(
  ({
    actor,
    bid,
    isBuyer,
    onClick,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly bid: BidActionsBidFragment;
    readonly isBuyer: boolean;
    readonly onClick: () => void;
  }) => {
    const { t } = useTranslation();
    const { t: tBids } = useTranslation(`bids`);
    const isTexasCopyEnabled = useTexasCopy();
    const buyerOrSeller = isBuyer ? `seller` : `buyer`;
    const documentTitle = getShortDocumentTitleByTransferMethod(
      bid.listing.transferMethod,
    );
    const loiTerms =
      getIsEitherFund(bid.listing.transferMethod) ||
      getIsOtherMethod(bid.listing.transferMethod)
        ? ``
        : t(`loi_terms`, { documentTitle, buyerOrSeller });
    const sellerOnly = getIsUnaccreditedSeller(actor)
      ? t(`seller_only_terms`)
      : ``;
    const key =
      getIsOtherMethod(bid.listing.transferMethod) && sellerOnly
        ? `seller_accept_bid_terms_and_conditions`
        : `old_accept_bid_terms_and_conditions`;

    return (
      <BidActionCard
        title="Accept Bid"
        description={
          isTexasCopyEnabled ? (
            <>
              <Trans
                ns="bids"
                i18nKey="accepting_this_bid_counts_as"
                values={{ bidType: `bid` }}
                components={{
                  italic: (
                    <Link
                      key="terms-and-conditions"
                      target="_blank"
                      textDecorationLine="underline"
                      href={constants.t_and_c_url}
                    />
                  ),
                }}
              />
              <Text
                mt={2}
              >{tBids`once_accepted_the_transaction_process_will_begin`}</Text>
            </>
          ) : (
            <Trans
              i18nKey={key}
              values={{ loiTerms, sellerOnly }}
              components={{
                italic: (
                  <Link
                    key="terms-and-conditions"
                    target="_blank"
                    textDecorationLine="underline"
                    href={constants.t_and_c_url}
                  />
                ),
              }}
            />
          )
        }
        action={{
          ctaText: `Accept`,
          onClick,
        }}
      />
    );
  },
);

const BidActionCounterBid = ({
  description,
  actionCTAText,
  onClick,
}: {
  readonly description: string;
  readonly actionCTAText: string;
  readonly onClick: () => void;
}) => (
  <BidActionCard
    title="Counter Bid Offer"
    description={description}
    action={{
      ctaText: actionCTAText,
      onClick,
    }}
  />
);

const BidActions = ({
  bid,
  isBuyer,
}: {
  readonly bid: BidActionsBidFragment;
  readonly isBuyer: boolean;
}) => {
  const { modals, onOpenModal } = useModal();

  const { canAcceptBid, canCounterBid, canAcceptCounterBid, hasCounterBid } =
    getAvailableBidActions(bid);

  const isLimitedToOneAction = xor([canAcceptBid], [canCounterBid]).length;

  if (!canAcceptBid && !canAcceptCounterBid && !canCounterBid) return null;

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(18rem, 1fr))"
      gap={isLimitedToOneAction ? 0 : 4}
    >
      <GridItem>
        {(canAcceptBid || canAcceptCounterBid) && (
          <>
            {isBuyer && (
              <BidActionTOS
                bid={bid}
                isBuyer={isBuyer}
                onClick={onOpenModal(modals.acceptCounterBid(bid))}
              />
            )}
            {!isBuyer &&
              !hasCounterBid && ( // seller can accept original bid from original bid card, this removes CTA duplication
                <BidActionTOS
                  bid={bid}
                  isBuyer={isBuyer}
                  onClick={onOpenModal(modals.acceptBid(bid))}
                />
              )}
          </>
        )}
      </GridItem>

      {(canCounterBid || canAcceptCounterBid) && (
        <GridItem>
          {isBuyer ? (
            <BidActionCounterBid
              description="Make a counter offer to the seller to negotiate an alternative price."
              actionCTAText="Counter Seller's Offer"
              onClick={onOpenModal(
                modals.modifyBid(bid.listing.company, bid.listing, bid),
              )}
            />
          ) : (
            <BidActionCounterBid
              description="Make a counter offer to the buyer to negotiate an alternative price."
              actionCTAText="Counter Bid"
              onClick={onOpenModal(modals.counterBid(bid))}
            />
          )}
        </GridItem>
      )}
    </Grid>
  );
};

export default BidActions;
