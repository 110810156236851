import { useMemo } from "react";

import { client, createClient } from "@/apollo-client";

import { getApiUrl } from "./useServerPreview";
import { useSession } from "./useSession";

export type ApolloClientConfig = {
  apiUrl: string | null;
  token?: string;
};

export function useApolloClient() {
  const { token } = useSession();
  return useMemo(() => {
    const apiUrl = getApiUrl();
    if (token) {
      return createClient({
        apiUrl: apiUrl || ``,
        token,
      });
    }
    return client;
  }, [token]);
}
