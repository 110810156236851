import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Card, HStack, VStack, Text, Flex, Button } from "@chakra-ui/react";

import {
  InternalEntityCombobox,
  InternalEntityFormValues,
} from "@/components/entities";
import { useDelayTaskCompletion } from "@/components/transactions";
import {
  TransactionExecutionPageTaskFragment,
  useCompleteExecutionTaskMutation,
  TransactionExecutionPageTransactionByIdDocument,
  ExecutionCollectEntityTaskData,
} from "@/gql";
import {
  useModal,
  useMutationWithError,
  useTransactionExecution,
} from "@/hooks";

import CompletedEntityCard from "./CompletedEntityCard";

function EntityCollectionHiiveTaskCard({
  task,
  delayCompletion = false,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly delayCompletion?: boolean;
}) {
  const { t } = useTranslation(`execution`);

  const { isCompleted } = useDelayTaskCompletion(task.status, delayCompletion);
  const [isPendingCompletion, setIsPendingCompletion] = useState(false);

  const { onOpenModal, modals } = useModal();

  const [completeTask] = useMutationWithError(
    useCompleteExecutionTaskMutation({
      refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
    }),
    `completeExecutionTask`,
  );

  const { isBuyer } = useTransactionExecution();

  const validationSchema = Yup.object()
    .shape({
      entityId: Yup.string().required(),
      searchText: Yup.string().notRequired(),
    })
    .required();

  const methods = useForm<InternalEntityFormValues>({
    defaultValues: {},
    resolver: yupResolver(
      validationSchema,
    ) as unknown as Resolver<InternalEntityFormValues>,
  });

  const {
    formState: { isValid },
    getValues,
  } = methods;

  const handleComplete = async () => {
    const { entityId } = getValues();

    const response = await completeTask({
      variables: {
        taskId: task.id,
        input: {
          collectEntity: { entityId },
        },
      },
    });

    if (response?.completeExecutionTask.errors) {
      setIsPendingCompletion(false);
    }
  };

  const { entity } = task.data as ExecutionCollectEntityTaskData;

  useEffect(() => {
    if (isCompleted) {
      setIsPendingCompletion(false);
    }
  }, [isCompleted]);

  if (isCompleted && entity) return <CompletedEntityCard entity={entity} />;

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <Card variant="flat" border={0} mb={2}>
        <VStack w="full" alignItems="flex-start">
          <HStack>
            <Text textStyle="heading-md">{t(`the_transacting_party`)}</Text>
          </HStack>
          <FormProvider {...methods}>
            <InternalEntityCombobox
              placeholder={t(`search_for_party`)}
              hideDate={false}
            />
          </FormProvider>
        </VStack>
      </Card>
      <HStack w="full" justifyContent="space-between" mt={2}>
        <Flex
          direction={{
            base: `column`,
            md: `row`,
          }}
          gap={{
            base: 0,
            md: 1,
          }}
        >
          <Text textStyle={{ base: `text-xs`, md: `text-md` }} color="grey.500">
            {t(
              isBuyer
                ? `need_to_add_new_buying_entity`
                : `need_to_add_new_selling_entity`,
            )}
          </Text>
          <Button
            p={0}
            textStyle={{ base: `text-xs`, md: `text-md` }}
            fontWeight={500}
            fontSize="inherit"
            variant="text-grey"
            onClick={onOpenModal(modals.newEntity(task, true))}
          >
            <Text
              textStyle={{ base: `text-xs`, md: `text-md` }}
              fontSize={{ base: 12, md: `md` }}
              fontWeight={{ base: `inherit`, md: 500 }}
            >
              {t(`entities.add_new_entity`, {
                ns: `account`,
              })}
            </Text>
          </Button>
        </Flex>
        <Button
          onClick={handleComplete}
          variant="rounded-solid-grey"
          size={{ base: `md`, md: `xl` }}
          isLoading={isPendingCompletion}
          isDisabled={!isValid}
        >
          {t(`confirm`)}
        </Button>
      </HStack>
    </Flex>
  );
}

export default EntityCollectionHiiveTaskCard;
