import { useTranslation, Trans } from "react-i18next";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Card,
  CardBody,
  Text,
  VStack,
} from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { constants } from "@/utils";

const { email_connect } = constants;

function InReviewActivityAlert() {
  const { t } = useTranslation(`bids`);
  return (
    <Alert alignItems="flex-start" status="info" variant="infoGrey" p={4}>
      <AlertIcon boxSize={13} mt={1} />
      <Box>
        <AlertTitle textStyle="text-sm">
          {t(`we_re_reviewing_your_bid`)}
        </AlertTitle>
        <AlertDescription textStyle="text-sm">
          {t(`we_ll_keep_you_updated`)}
        </AlertDescription>
      </Box>
    </Alert>
  );
}

export function BidInReviewActivityCard({
  companyName,
}: {
  readonly companyName: string;
}) {
  const { t } = useTranslation(`bids`);

  return (
    <Card>
      <CardBody w="full">
        <VStack alignItems="flex-start" gap={6}>
          <InReviewActivityAlert />
          <Text>
            <Trans
              i18nKey="for_support_contact"
              ns="bids"
              components={[
                <MailtoLink
                  key="mail_to_link"
                  email={email_connect}
                  subject={t(`why_is_my_posting_in_review_email_title`, {
                    companyName,
                  })}
                  fontWeight={500}
                />,
              ]}
              values={{ email: email_connect }}
            />
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
