import { useField } from "formik";
import { useTranslation } from "react-i18next";

import {
  VStack,
  FormControl,
  Textarea,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";

import { TooltipFormLabel } from "@/components/form";

export function ExplainSellSharesDirectlyInput() {
  const { t } = useTranslation(`company`);
  const name = `userPlacedExplanation`;
  const [field, { touched, error }] = useField(name);

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <VStack alignItems="flex-start" gap={2}>
        <TooltipFormLabel htmlFor={name} tooltipSize="lg">
          {t(`why_do_you_believe`)}
        </TooltipFormLabel>
        <VStack alignItems="flex-start" gap={1} w="full">
          <Textarea
            {...field}
            maxLength={1000}
            className="textArea"
            placeholder={t(`direct_shares_example`)}
          />
          <FormErrorMessage>{error}</FormErrorMessage>
        </VStack>
        <FormHelperText>
          {t(`this_information_is_for_internal_purposed`)}
        </FormHelperText>
      </VStack>
    </FormControl>
  );
}
