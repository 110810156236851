import dayjs from "dayjs";
import { ReactNode } from "react";
import { Trans } from "react-i18next";

import { Text, VStack, Link } from "@chakra-ui/react";

import { constants } from "@/utils";

const FooterLink = ({
  href,
  children,
}: {
  readonly href: string;
  readonly children?: ReactNode; // optional for use with i18n Trans component
}) => (
  <Link href={href} textDecoration="underline" target="_blank">
    {children}
  </Link>
);

export const HiiveFooter = () => {
  const currentYear = dayjs().year();

  return (
    <VStack py={8} maxW="50rem" px={4} spacing={4}>
      <Text textAlign="center" textStyle="text-xs">
        <Trans
          i18nKey="signup_footer_disclaimer"
          components={[
            <FooterLink key="finra" href="https://www.finra.org/" />,
            <FooterLink key="sipc" href="https://www.sipc.org/" />,
            <FooterLink
              key="broker-check"
              href="https://brokercheck.finra.org/firm/summary/316580"
            />,
          ]}
        />
      </Text>
      <Text textAlign="center" textStyle="text-xs">
        <Trans
          i18nKey="signup_footer_copyright"
          components={[
            <FooterLink
              key="terms"
              href={`${constants.marketing_website_url}/terms`}
            />,
            <FooterLink
              key="privacy"
              href={`${constants.marketing_website_url}/privacy`}
            />,
            <FooterLink
              key="disclosures"
              href={`${constants.marketing_website_url}/disclosures`}
            />,
          ]}
          values={{ year: currentYear }}
        />
      </Text>
    </VStack>
  );
};
