import { useCurrentContextQuery, UserActivityTransactionFragment } from "@/gql";

import IndividualActiveTransactionsCard from "./IndividualActiveTransactionsCard";
import InstitutionActiveTransactionsCard from "./InstitutionActiveTransactionsCard";

type ActiveTransactionsCardProps = {
  myInstitutionTransactions?: UserActivityTransactionFragment[];
  myTransactions: UserActivityTransactionFragment[];
};

const ActiveTransactionsCard = ({
  myTransactions,
  myInstitutionTransactions,
}: ActiveTransactionsCardProps) => {
  const { data } = useCurrentContextQuery();
  const currentActor = data?.currentContext?.currentActor;
  const isInstitution = !!currentActor?.institutionId;

  return isInstitution && myInstitutionTransactions ? (
    <InstitutionActiveTransactionsCard
      myInstitutionTransactions={myInstitutionTransactions}
      myTransactions={myTransactions}
    />
  ) : (
    <IndividualActiveTransactionsCard myTransactions={myTransactions} />
  );
};

export default ActiveTransactionsCard;
