export default {
  requested: `Requested`,
  in_progress: `In Progress`,
  closed: `Closed`,
  standing_bid_inquiry: `Standing Bid Inquiry`,
  listing_inquiry: `Listing Inquiry`,
  inquiry: `Inquiry`,
  reply: `Reply`,
  inquiries: `Inquiries`,
  standing_bid_inquiries_empty_state: `You have no active inquiries on your Standing Bid, you will be notified when you receive a message from an interested seller.`,
  inquiry_request: `Inquiry request`,
  buyer_initiated_inquiry_listing: `A buyer has initiated an inquiry in relation to your Listing.`,
  seller_initiated_inquiry_listing: `A seller has initiated an inquiry in relation to your Bid on their Listing.`,
  seller_initiated_inquiry_standing_bid: `A seller has initiated an inquiry in relation to your Standing Bid.`,
  seller_initiated_inquiry_standing_bid_institution: `A seller has initiated an inquiry in relation to {{legalName}}'s Standing Bid.`,
  your_institution: `your institution`,
  accepting_this_inquiry_request: `{{initiated}} Accepting this inquiry request counts as an Introduction Event under the <0>{{termsAndConditions}}</0>`,
  seller_only_hiive_terms_and_conditions: `Hiive (Seller Only) Customer Terms and Conditions.`,
  hiive_terms_and_conditions: `Hiive Customer Terms and Conditions.`,
  accept: `Accept`,
};
