import numeral from "numeral";
import { match } from "ts-pattern";

import {
  ShareTypeV2,
  StandingBidAcceptsSharesOptions,
  StandingBidAcceptsTransferMethodsOptions,
  TransferMethod,
  UnaccreditedSellerTransferMethodInput,
} from "@/gql";

// TODO: This can be removed after Server is updated
enum TempStandingBidAcceptsTransferMethodsOptions {
  Direct = `DIRECT`,
  Fund = `FUND`,
  Other = `OTHER`,
}

const gqlTransferMethodToTemp = (
  transferMethod:
    | StandingBidAcceptsTransferMethodsOptions
    | TempStandingBidAcceptsTransferMethodsOptions,
) =>
  match(transferMethod)
    .with(
      StandingBidAcceptsTransferMethodsOptions.Direct,
      () => TempStandingBidAcceptsTransferMethodsOptions.Direct,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.Fund,
      () => TempStandingBidAcceptsTransferMethodsOptions.Fund,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.Other,
      () => TempStandingBidAcceptsTransferMethodsOptions.Other,
    )
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in gqlTransferMethodToTemp: ${transferMethod}`,
      );
    });

export const gqlTransferMethodOptionsToTemp = (
  transferMethods:
    | StandingBidAcceptsTransferMethodsOptions[]
    | TempStandingBidAcceptsTransferMethodsOptions[],
) => [
  ...new Set(
    transferMethods.map((transferMethod) =>
      gqlTransferMethodToTemp(transferMethod),
    ),
  ),
];

export const transferMethodToString = (transferMethod: TransferMethod) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `Direct`)
    .with(TransferMethod.Fund, () => `Fund`)
    .with(TransferMethod.HiiveFund, () => `Fund`)
    .with(TransferMethod.Unknown, () => `TBD`)
    .with(TransferMethod.Other, () => `Other`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in transferMethodToString: ${transferMethod}`,
      );
    });

export const transferMethodToLongString = (transferMethod: TransferMethod) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `Direct`)
    .with(TransferMethod.Fund, () => `Via a Fund`)
    .with(TransferMethod.HiiveFund, () => `Via Hiive Fund`)
    .with(TransferMethod.Unknown, () => `To Be Determined`)
    .with(TransferMethod.Other, () => `Other`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in transferMethodToLongString: ${transferMethod}`,
      );
    });

export const standingBidAcceptsTransferMethodOptionsToString = (
  transferMethod: TempStandingBidAcceptsTransferMethodsOptions,
) =>
  match(transferMethod)
    .with(TempStandingBidAcceptsTransferMethodsOptions.Direct, () => `Direct`)
    .with(TempStandingBidAcceptsTransferMethodsOptions.Fund, () => `Fund`)
    .with(TempStandingBidAcceptsTransferMethodsOptions.Other, () => `Other`)
    .exhaustive();

export const standingBidAcceptsTransferMethodOptionsToLongString = (
  transferMethod:
    | StandingBidAcceptsTransferMethodsOptions
    | TempStandingBidAcceptsTransferMethodsOptions
    | UnaccreditedSellerTransferMethodInput,
) =>
  match(transferMethod)
    .with(TempStandingBidAcceptsTransferMethodsOptions.Direct, () => `Direct`)
    .with(TempStandingBidAcceptsTransferMethodsOptions.Fund, () => `Fund`)
    .with(TempStandingBidAcceptsTransferMethodsOptions.Other, () => `Other`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in standingBidAcceptsTransferMethodOptionsToLongString: ${transferMethod}`,
      );
    });

export const shareTypeToString = (
  shareType: ShareTypeV2,
  useAbbreviation = false,
) =>
  ({
    [ShareTypeV2.Common]: `Common`,
    [ShareTypeV2.Pref]: `Preferred`,
    [ShareTypeV2.Mix]: `Mix`,
    [ShareTypeV2.RestrictedStockUnits]: useAbbreviation
      ? `RSU`
      : `Restricted Stock Units`,
  })[shareType];

export const acceptsSharesToString = (
  acceptsShares: StandingBidAcceptsSharesOptions,
): string =>
  match(acceptsShares)
    .with(StandingBidAcceptsSharesOptions.Both, () => `Any`)
    .with(StandingBidAcceptsSharesOptions.Common, () => `Common`)
    .with(StandingBidAcceptsSharesOptions.Pref, () => `Preferred`)
    .exhaustive();

export const acceptsTransferMethodsToString = (
  acceptsTransferMethods: readonly TempStandingBidAcceptsTransferMethodsOptions[],
) =>
  [
    ...new Set(
      acceptsTransferMethods.map(
        (transferMethod) =>
          ({
            [TempStandingBidAcceptsTransferMethodsOptions.Direct]: `Direct`,
            [TempStandingBidAcceptsTransferMethodsOptions.Fund]: `Fund`,
            [TempStandingBidAcceptsTransferMethodsOptions.Other]: `Other`,
          })[transferMethod],
      ),
    ),
  ]
    .sort()
    .join(`, `);

export const lot = (
  numOfShares: number,
  pricePerShare: number,
  format?: string,
) => {
  const inputString = format || `$0,0[.]00`;
  return `${numeral((pricePerShare / 100) * numOfShares).format(inputString)}`;
};
