import { Card, CardBody, VStack } from "@chakra-ui/react";

import {
  ExecutionTaskStatus,
  TransactionState,
  UserActivityTransactionFragment,
} from "@/gql";
import { useTransactionDetails } from "@/hooks";

import ActiveTransactionItemCard from "./ActiveTransactionItemCard";
import ActiveTransactionsCardHeader from "./ActiveTransactionsCardHeader";

type IndividualActiveTransactionsCardProps = {
  myTransactions: UserActivityTransactionFragment[];
};

const IndividualActiveTransactionsCard = ({
  myTransactions,
}: IndividualActiveTransactionsCardProps) => {
  const { isBuyer, hasTerminalState } = useTransactionDetails();

  const activeTransactions = myTransactions?.filter(
    (transaction) => !hasTerminalState(transaction),
  );

  if (activeTransactions?.length === 0) {
    return null;
  }

  return (
    <Card w="full">
      <ActiveTransactionsCardHeader
        transactionsCount={activeTransactions?.length}
      />
      <CardBody as={VStack} gap={4} maxH="xs" overflowY="auto">
        {activeTransactions
          ?.filter((transaction) =>
            isBuyer(transaction)
              ? transaction.state !== TransactionState.ClosedFeePaid
              : true,
          )
          .map((transaction) => (
            <ActiveTransactionItemCard
              key={transaction.id}
              taskCount={
                transaction.myTasks.filter(
                  (task) => task.status === ExecutionTaskStatus.InProgress,
                )?.length
              }
              transaction={transaction}
            />
          ))}
      </CardBody>
    </Card>
  );
};

export default IndividualActiveTransactionsCard;
