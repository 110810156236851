import {
  GetAvailableListingActionsListingFragment,
  ListingPermission,
  ListingState,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getIsListingOpen = (listing: GetAvailableListingActionsListingFragment) =>
  listing.state === ListingState.Open;

const getIsListingInReview = ({
  state,
}: GetAvailableListingActionsListingFragment) =>
  state === ListingState.InReview;

const isListingActionPermitted = (
  listing: GetAvailableListingActionsListingFragment,
  restrictiveInReview: boolean,
  permission: ListingPermission,
) => {
  const isOpen = getIsListingOpen(listing);
  const isInReview = restrictiveInReview && getIsListingInReview(listing);

  const isActionable = isOpen || isInReview;
  const isPermitted = iHaveEntityPermission(listing, permission);

  return isActionable && isPermitted;
};

const getCanWithdrawListing = (
  listing: GetAvailableListingActionsListingFragment,
  restrictiveInReview: boolean,
) =>
  isListingActionPermitted(
    listing,
    restrictiveInReview,
    ListingPermission.WithdrawListing,
  );

const getCanModifyListing = (
  listing: GetAvailableListingActionsListingFragment,
  restrictiveInReview: boolean,
) =>
  isListingActionPermitted(
    listing,
    restrictiveInReview,
    ListingPermission.ModifyListing,
  );

export const getAvailableListingActions = (
  listing: GetAvailableListingActionsListingFragment,
  restrictiveInReview: boolean,
) => {
  const canWithdrawListing = getCanWithdrawListing(
    listing,
    restrictiveInReview,
  );

  const canModifyListing = getCanModifyListing(listing, restrictiveInReview);

  return {
    canWithdrawListing,
    canModifyListing,
    canActOnListing: canModifyListing || canWithdrawListing,
  };
};
