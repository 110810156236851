import { useTranslation } from "react-i18next";

import NextLink from "next/link";
import { useRouter } from "next/router";

import {
  Badge,
  Card,
  CardFooter,
  HStack,
  List,
  Link,
  Text,
  Box,
} from "@chakra-ui/react";

import {
  ExecutionTaskStatus,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";

type TransactionExecutionPageExecutionLinkProps = {
  transaction: TransactionExecutionPageTransactionFragment;
};

type ExecutionLinkProps = {
  activeBadge?: boolean;
  label: React.ReactNode;
  count?: number;
  url: string;
};

const ExecutionLink = ({
  activeBadge = false,
  label,
  count,
  url,
}: ExecutionLinkProps) => {
  const { asPath } = useRouter();

  const isActive = url === asPath;

  return (
    <Link
      as={NextLink}
      color={isActive ? `grey.900` : `grey.500`}
      href={url}
      scroll={false}
      variant="execution"
    >
      <HStack h="full">
        <Text>{label}</Text>
        {count && (
          <Badge
            border={!activeBadge ? `.25px solid` : undefined}
            borderColor={!activeBadge ? `grey.200` : undefined}
            bg={!activeBadge ? `grey.50` : `salmon.900`}
            color={!activeBadge ? `grey.900` : `white`}
            className="badge"
          >
            <Text className="count">{count}</Text>
          </Badge>
        )}
        {isActive && <Box className="indicator" />}
      </HStack>
    </Link>
  );
};

const TransactionExecutionPageExecutionLink = ({
  transaction,
}: TransactionExecutionPageExecutionLinkProps) => {
  const { t } = useTranslation();

  const numInProgressSteps = transaction.myTasks.filter(
    (task) => task.status === ExecutionTaskStatus.InProgress,
  )?.length;

  const documentsCount = transaction?.fileUploads?.length;

  return (
    <Card
      variant="no-border-radius-base"
      borderRadius={0}
      borderBottomRadius="md"
      borderTop="none"
      position={{ base: `sticky`, md: `unset` }}
      top="var(--chakra-sizes-mobile-navbar-height)"
      zIndex="sticky"
    >
      <CardFooter
        overflow="auto"
        py={0}
        px={0}
        bg="grey.15"
        css={{
          "&::-webkit-scrollbar": {
            display: `none`,
          },
        }}
      >
        <List as={HStack} h="12" gap={0}>
          <ExecutionLink
            activeBadge
            count={numInProgressSteps}
            label={t(`tasks`)}
            url={`/transactions/${transaction.id}/tasks`}
          />
          <ExecutionLink
            label={t(`details`)}
            url={`/transactions/${transaction.id}/details`}
          />
          <ExecutionLink
            count={documentsCount}
            label={t(`documents`)}
            url={`/transactions/${transaction.id}/documents`}
          />
        </List>
      </CardFooter>
    </Card>
  );
};

export default TransactionExecutionPageExecutionLink;
