import { useContext } from "react";

import { TransactionExecutionPageContext } from "@/components/providers";

const useTransactionExecution = () => {
  const context = useContext(TransactionExecutionPageContext);

  if (context?.transaction === undefined) {
    throw new Error(
      `transaction is not defined in the TransactionExecutionPageContext provider`,
    );
  }

  return context;
};

export default useTransactionExecution;
