import { useTranslation, Trans } from "react-i18next";

import { Box, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { CreateListingSuccessModalListingFragment } from "@/gql";
import { useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getListingNumOfShares,
  constants,
} from "@/utils";

const { email_connect } = constants;

type CreateListingInReviewSuccessModalContentProps = {
  numShares: number;
  pricePerShare: number;
  companyName: string;
  displayId: string;
};

type CreateListingInReviewSuccessModalProps = {
  listing: CreateListingSuccessModalListingFragment;
};

function CreateListingInReviewSuccessModalContent({
  numShares,
  pricePerShare,
  companyName,
  displayId,
}: CreateListingInReviewSuccessModalContentProps) {
  const { closeModal } = useModal();
  const { t } = useTranslation(`listings`);
  const numSharesFormatted = formatShares(numShares);
  const pricePerShareFormatted = formatPricePerShare(pricePerShare);

  return (
    <Box>
      <HiiveModalHeader>
        {t(`were_reviewing_your_listing`, {
          companyName,
        })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-xl">
            {t(`were_reviewing_your_listing_subheading`, {
              displayId,
              numShares: numSharesFormatted,
              pricePerShare: pricePerShareFormatted,
            })}
          </Text>
          <Text>{t(`currently_reviewing_your_listing`)}</Text>
          <Text as="span">
            <Trans
              i18nKey="to_support_your_listing_connect"
              ns="listings"
              components={[
                <MailtoLink
                  key="mail_to_link"
                  email={email_connect}
                  subject={t(`why_is_my_posting_in_review_email_title`, {
                    companyName,
                  })}
                  fontWeight="medium"
                />,
              ]}
              values={{ connectEmail: email_connect }}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[CreateInReviewListingSuccess/Submit]"
          type="submit"
          submitText={t(`okay`)}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </Box>
  );
}

export function CreateListingInReviewSuccessModal({
  listing,
}: CreateListingInReviewSuccessModalProps) {
  const { company, listingPricePerShare, displayId } = listing;
  const { name: companyName } = company;

  const numShares = getListingNumOfShares(listing, false);

  return (
    <CreateListingInReviewSuccessModalContent
      numShares={numShares}
      pricePerShare={listingPricePerShare ?? 0}
      companyName={companyName}
      displayId={displayId}
    />
  );
}
