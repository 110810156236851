import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { ModalBody, VStack, Button, Text } from "@chakra-ui/react";

import {
  ExecutionModalHeader,
  ExecutionModalFooter,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  TransactionExecutionPageTaskFragment,
  UserWithInstitutionFragment,
  useCompleteExecutionTaskMutation,
  EntityType,
} from "@/gql";

import EntityForm, { EntityFormValues } from "./EntityForm";
import IndividualEntityWarningCard from "./IndividualEntityWarningCard";
import PartyTypeRadio, { PartyType } from "./PartyTypeRadio";

const validationSchema = Yup.object().shape({
  legalName: Yup.string().required(),
  jurisdictionOfFormationId: Yup.string().required(),
  type: Yup.string().required(),
  otherType: Yup.string().when(`type`, {
    is: (type: EntityType) => type === EntityType.Other,
    then: (schema) => schema.required(),
  }),
});

const NewEntityModalForm = withCurrentActor(
  ({
    task,
    isRepresented = false,
    onClose,
    onCompleted,
    actor,
  }: {
    task: TransactionExecutionPageTaskFragment;
    isRepresented?: boolean;
    onClose?: () => void;
    onCompleted: () => void;
    actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`account`);

    const methods = useForm<EntityFormValues>({
      defaultValues: {
        legalName: ``,
        jurisdictionOfFormationId: ``,
        type: `` as EntityType,
        otherType: undefined,
        internal: isRepresented,
      },
      resolver: yupResolver(
        validationSchema,
      ) as unknown as Resolver<EntityFormValues>,
    });

    const { formState, getValues, setValue, reset } = methods;

    const isInstitution = !!actor.institution;

    const [broadPartyType, setBroadPartyType] = useState<PartyType | null>(
      isInstitution || isRepresented ? PartyType.Entity : null,
    );
    const [completeTask, { loading }] = useCompleteExecutionTaskMutation();

    const isIndividualParty = broadPartyType === PartyType.Individual;

    const individualFullName = `${actor.firstName} ${actor.lastName}`;

    const handleChangePartyType = (value: PartyType) => {
      setBroadPartyType(value as PartyType);
      if (value === PartyType.Individual) {
        setValue(`legalName`, individualFullName);
        setValue(`jurisdictionOfFormationId`, actor.country?.id ?? ``);
        setValue(`type`, EntityType.Individual, { shouldValidate: true });
      } else {
        reset();
      }
    };

    const handleSubmit = async () => {
      const input = {
        collectEntity: getValues(),
      };

      await completeTask({
        variables: {
          taskId: task.id,
          input,
        },
      }).catch(() => {
        // handle error state
      });

      onCompleted();
    };

    const showRadioPicker = !isInstitution && !isRepresented;

    return (
      <form
        autoComplete="off"
        style={{ display: `flex`, flexDirection: `column`, height: `100%` }}
      >
        <ExecutionModalHeader closeModal={onClose}>
          <Text>{t(`entities.create_new_entity`)}</Text>
        </ExecutionModalHeader>
        <ModalBody border="none" p={0} px={8} py={8} w="full">
          <VStack gap={6} w="full">
            {showRadioPicker && (
              <VStack w="full" align="start" gap={2}>
                <Text textStyle="heading-md">{t(`entities.select_type`)}</Text>
                <PartyTypeRadio
                  actor={actor}
                  value={broadPartyType}
                  onChange={handleChangePartyType}
                />
              </VStack>
            )}
            <VStack align="start" spacing={0} w="full">
              {match({
                type: broadPartyType,
              })
                .with(
                  {
                    type: PartyType.Individual,
                  },
                  () => <IndividualEntityWarningCard />,
                )
                .with(
                  {
                    type: PartyType.Entity,
                  },
                  () => (
                    <FormProvider {...methods}>
                      <EntityForm />
                    </FormProvider>
                  ),
                )
                .otherwise(() => null)}
            </VStack>
          </VStack>
        </ModalBody>
        <ExecutionModalFooter>
          <Button onClick={onClose}>{t(`entities.cancel`)}</Button>
          <Button
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={!formState.isValid || isIndividualParty}
          >
            {t(`entities.confirm`)}
          </Button>
        </ExecutionModalFooter>
      </form>
    );
  },
);

export default NewEntityModalForm;
