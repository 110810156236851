export default {
  account_number: `Account Number`,
  add_entity: `Add Entity`,
  add_new: `Add New`,
  assign_entity: `Assign Entity`,
  beneficiary_details: `Beneficiary Details`,
  beneficiary_bank_details: `Beneficiary Bank Details`,
  change: `Change`,
  clear: `Clear`,
  confirm_buyer_information: `Confirm Buyer Information`,
  confirm_multiple_buyer_information_description: `Select or add the individual or entity that will own the shares and appear on the share certificates (or other proof of ownership) at closing. If another individual will purchase the shares, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_multiple_selling_entity_description: `Select or add the entity that owns the shares and is named on the share certificates (or other proof of ownership). If you need to use multiple entities to complete this transaction, contact <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details.`,
  confirm_multiple_seller_information_description: `Select or add the individual or entity that owns the shares and is named on the share certificates (or other proof of ownership). If another individual owns the shares, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_purchasing_entity: `Confirm Purchasing Entity`,
  confirm_purchasing_party: `Confirm Purchasing Party`,
  confirm_multiple_purchasing_entity_description: `Select or add the entity that will own the shares and appear on the share certificates (or other proof of ownership) at closing. If you are completing this transaction using multiple buying entities, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details. `,
  confirm_single_buyer_information_description: `Please confirm that you are purchasing the shares personally under the name specified below. If an entity or trust will purchase the shares instead, select “Change” in order to specify the purchasing entity. If a different individual will purchase the shares personally, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_single_purchasing_entity_description: `Please confirm the name of the purchasing entity below, or click “Change” in order to specify a different purchasing entity . If you are completing this transaction using multiple buying entities, email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details.  `,
  confirm_selling_entity: `Confirm Selling Entity`,
  confirm_selling_party: `Confirm Selling Party`,
  confirm_seller_information: `Confirm Seller Information`,
  confirm_single_seller_information_description: `Please confirm that you hold the shares personally and that your name appears as below on the share certificates (or other proof of ownership). If you hold the shares via a holding company or other legal entity, select “Change” below in order to specify the selling entity. If another individual owns the shares personally, please email <a>execution@hiive.com</a> with your transaction ID {{displayId}} and details.`,
  confirm_single_selling_entity_information_description: `Please confirm that the entity below is named on the share certificates (or other proof of ownership) for the shares you are selling. If you need to use multiple entities to complete this transaction, contact <a>execution@hiive.com</a> with your transaction ID {{displayId}} and entity details.`,
  couldnt_link_entity: `Could not link entity`,
  entity_type_buyer_description: `Enter the legal name of the entity that will purchase the shares.`,
  entity_type_seller_description: `Enter the name of the legal entity that holds the shares you are selling.`,
  payment_amount: `Payment Amount`,
  payment_received: `Payment Received`,
  routing_number: `Routing Number`,
  search: `Search for existing parties`,
  select_or_add_party: `Select or add the individual or entity that will own the shares and appear on the share certificates (or other proof of ownership) at closing.`,
  select_entity_required: `Selecting an entity is required`,
  settlement: `Settlement`,
  settlement_description_introduction: `Transaction payments are received and settled by Hiive through its regulated reserve bank account, held at {{ bankName }} bank and reserved exclusively for the receipt and transmission of customer funds.`,
  settlement_description_closing: `
    To close this transaction, please <bold>wire</bold> the payment amount to the following bank account.
    <bold>We cannot accept transfers via ACH</bold>. This account number is unique to you and this transaction.
    For questions contact <a>{{email}}</a>`,
  swift_code: `SWIFT Code`,
  transacting_party: `Transacting Party`,
  transacting_entity: `Transacting Entity`,
  transaction_id_required: `Transaction id required`,
  new_bank_account_title: `Where is your bank account located?`,
  new_bank_account_description: `
    Please specify the country where your financial institution is based. It's important that the selected country is for the specific bank account and financial institution you intend to use for this transaction.
    <br />
    If you have a US-based account, <bold>please ensure that the routing number you provide is valid for ACH transfers</bold>.
    Some banks use different routing numbers for wire transfers and ACH transfers, and Hiive completes settlement through Same Day ACH for all US-based accounts.
    If you prefer to receive the funds via wire or if your transfer requires ‘For Further Credit' instructions, please contact {{email}}.
    <br />
    Accounts from all other countries will receive a wire.
  `,
  new_bank_account_form_placeholder: `Select Country`,
  country_of_account: `Country of Account`,
  shares_sold_at: `{{numShares}} shares sold at <b>{{pps}}/sh</b>`,
  shares_sold_at_v2: `{{numShares}} shares sold at <b>{{pps}}</b>/sh`,
  shares_sold_from: `{{numShares}} shares sold from <b>{{minPps}}/sh</b> to <b>{{maxPps}}/sh</b>`,
  shares_sold_from_v2: `{{numShares}} shares sold from <b>{{minPps}}</b>/sh to <b>{{maxPps}}</b>/sh`,
  your_pending_sales_on_behalf: `Your Pending Sales on behalf of {{institutionName}}`,
  your_pending_sales: `Your Pending Sales`,
  your_pending_purchases_on_behalf: `Your Pending Purchases on behalf of {{institutionName}}`,
  your_pending_purchases: `Your Pending Purchases`,
  other_institution_pending_purchases: `Other {{institutionName}} Pending purchases`,
  other_institution_pending_sales: `Other {{institutionName}} Pending Sales`,
};
