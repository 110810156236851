/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  AcceptCounterBidModalBidFragment,
  AcceptCounteredBidMutation,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import { useTexasCopy } from "@/hooks/featureFlags";
import {
  abbrCountLabel,
  getBidLabel,
  constants,
  getCountLabel,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getShortDocumentTitleByTransferMethod,
  getIsEitherFund,
  getIsOtherMethod,
  makeUrl,
} from "@/utils";

import {
  AcceptCounterBidSequenceModalStepFormContext,
  useAcceptCounterBidSequenceModalStepFormContext,
} from "./AcceptCounterBidSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { AcceptCounterBidSequenceModalFormValues } from "./types";

const validationSchema = Yup.object().shape({
  bidId: Yup.string().nullable().required(`Required`),
});

interface AcceptCounterBidModalProps
  extends StepPropsV2<StepKeys, AcceptCounterBidSequenceModalFormValues> {
  readonly bid: AcceptCounterBidModalBidFragment;
}

const AcceptCounterBidModal = ({
  bid,
  stepRouter: { stepControls },
  values,
  isSubmitting,
}: AcceptCounterBidModalProps) => {
  const { t } = useTranslation();
  const { t: tBids } = useTranslation(`bids`);

  const { closeModal } = useModal();
  const router = useRouter();
  const isTexasCopyEnabled = useTexasCopy();

  const bidType = `counter ${getBidLabel(bid.listing.transferMethod).toLowerCase()}`;
  const share = getCountLabel(bid.listing.transferMethod);
  const abbrShare = abbrCountLabel(bid.listing.transferMethod);
  const documentTitle = getShortDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );
  const useLOITerms =
    !getIsEitherFund(bid.listing.transferMethod) &&
    !getIsOtherMethod(bid.listing.transferMethod);
  const formattedNumShares = bid.counterNumShares
    ? formatShares(bid.counterNumShares)
    : formatShares(getBidNumSharesActual(bid));
  const formattedSharePrice =
    bid.counterPricePerShare && formatPricePerShare(bid.counterPricePerShare);

  const textKey = getIsOtherMethod(bid.listing.transferMethod)
    ? `accept_counter_bid_terms_and_conditions`
    : `seller_accept_bid_terms_and_conditions`;

  const { submitMutation } = useAcceptCounterBidSequenceModalStepFormContext();

  const onSuccess = () =>
    submitMutation().then((response: AcceptCounteredBidMutation) => {
      const transaction = response.acceptCounteredBid.bid?.transaction;

      if (!transaction) return;

      router.push(makeUrl(transaction));
      stepControls.nextStep();
    });

  useStepValidator({
    stepKey: stepKeys.acceptCounterBid,
    validator: {
      validationSchema,
      onSuccess,
    },
    Context: AcceptCounterBidSequenceModalStepFormContext,
    values,
  });

  return (
    <>
      <HiiveModalHeader>
        {isTexasCopyEnabled
          ? tBids(`accept_counter_bid`, { bidId: bid.displayId, bidType })
          : t(`are_you_sure_accept`, { bidType })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack align="flex-start">
          <Text textStyle={isTexasCopyEnabled ? `heading-md` : `heading-lg`}>
            {`${bid.displayId}: ${formattedNumShares} ${share}s @ ${formattedSharePrice}/${abbrShare}`}
          </Text>

          {isTexasCopyEnabled ? (
            <>
              <Text>
                <Trans
                  ns="bids"
                  i18nKey="accepting_this_counter_bid_counts_as"
                  values={{ bidType }}
                  components={{
                    italic: (
                      <Link
                        key="terms-and-conditions"
                        href={constants.t_and_c_url}
                        target="_blank"
                        textDecorationLine="underline"
                      />
                    ),
                  }}
                />
              </Text>
              <Text>{tBids`once_you_ve_accepted_this_counter_bid`}</Text>
            </>
          ) : (
            <Text>
              <Trans
                i18nKey={textKey}
                components={{
                  italic: (
                    <Link
                      key="terms-and-conditions"
                      href={constants.t_and_c_url}
                      target="_blank"
                      textDecorationLine="underline"
                    />
                  ),
                }}
              />
            </Text>
          )}

          {useLOITerms && !isTexasCopyEnabled && (
            <Text>
              {t(`loi_terms`, { documentTitle, buyerOrSeller: `seller` })}
            </Text>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[AcceptCounterBid/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          observabilityLabel="[AcceptCounterBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText={
            isTexasCopyEnabled
              ? tBids`accept_counter_bid_confirmation`
              : t(`yes_accept_bid`, { bidType })
          }
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptCounterBidModal;
