const AUTH0_ROUTES = {
  AUTH0_LOGIN: `/auth/login`,
  AUTH0_LOGOUT: `/auth/logout`,
};

export const ROUTES = {
  ...AUTH0_ROUTES,
  ACCOUNT_PASSWORD_UPDATE_CONFIRMED: `/account/password-update-confirmed`,
  ACCOUNT_REQUEST_UPDATED_EMAIL_VERIFICATION: `/account/request-updated-email-verification`,
  ACCOUNT_SETTINGS_CHANGE_EMAIL: `/account/settings/change-email`,
  ACCOUNT_SETTINGS_CONTACT: `/account/settings/contact`,
  DASHBOARD: `/dashboard`,
  DEFAULT_LOGIN: `/login`,
  FORGOT_PASSWORD: `/forgot-password`,
  INDEX: `/`,
  SIGN_UP: `/signup`,
  WELCOME: `/welcome`,
};

const UNAUTHENTICATED_DYNAMIC_ROUTES = [
  `/alternate-user/`,
  `/alternate-signer/`,
  `/invitation/`,
  `/reset-password/`,
  `/verify-email/`,
];

const UNAUTHENTICATED_STATIC_ROUTES = [
  `/something-went-wrong`,
  `/password-update-confirmed`,
  `/page-not-found`,
  `/maintenance`,
];

const regex = new RegExp(
  `^(${UNAUTHENTICATED_DYNAMIC_ROUTES.join(`|`)})[^/]+$`,
);

export const isUnauthenticatedRoute = (route: string) =>
  UNAUTHENTICATED_STATIC_ROUTES.includes(route) || regex.test(route);
