import { defineStyleConfig, theme } from "@chakra-ui/react";

export const Link = defineStyleConfig({
  variants: {
    menu: {
      _active: {
        bg: `grey.75`,
      },
      _focus: {
        bg: `grey.50`,
      },
      _hover: {
        bg: `grey.50`,
        textDecoration: `none`,
      },
      borderRadius: `lg`,
      borderWidth: 1,
      display: `block`,
      px: 5,
      py: 2,
      textAlign: `left`,
      textStyle: `text-md`,
      transition: `.2s all ease-in-out`,
      w: `full`,
    },
    "text-grey": {
      fontWeight: `medium`,
      color: `grey.900`,
      _hover: {
        textDecoration: `underline`,
        color: `grey.800`,
      },
      _focus: {
        borderRadius: `md`,
        boxShadow: `focus`,
      },
      _active: {
        color: `grey.700`,
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    chunky: {
      textDecoration: `underline`,
      fontWeight: `bold`,
      _hover: {
        color: `grey.600`,
      },
    },
    unstyled: {
      _hover: {
        textDecoration: `none`,
      },
      _focus: {
        boxShadow: `none`,
      },
    },
    execution: {
      _active: {
        bg: `grey.50`,
      },
      _focus: {
        boxShadow: `focus`,
      },
      _hover: {
        bg: `grey.25`,
        borderColor: `grey.25`,
        textDecoration: `none`,
      },
      borderColor: `grey.15`,
      h: `full`,
      paddingX: theme.space[6],
      position: `relative`,
      [`.indicator`]: {
        h: `4px`,
        w: `full`,
        position: `absolute`,
        left: `50%`,
        transform: `translateX(-50%)`,
        bottom: `0px`,
        width: `100%`,
        height: `4px`,
        backgroundColor: `grey.900`,
        borderTopLeftRadius: `4px`,
        borderTopRightRadius: `4px`,
      },
      textStyle: `heading-md`,
      [`.badge`]: {
        [`.count`]: { textStyle: `heading-xs` },
        paddingY: 0,
      },
    },
  },
});
