import { createContext, ReactNode, useMemo } from "react";

import { useCurrentContextQuery, UserWithInstitutionFragment } from "@/gql";
import { useSession } from "@/hooks";

export type CurrentActorContext = UserWithInstitutionFragment;

export type NullableCurrentActorContext = {
  actor: CurrentActorContext | null;
  loading: boolean;
};

type CurrentActorProviderProps = {
  children: ReactNode;
};

const DEFAULT_CURRENT_ACTOR = {
  actor: null,
  loading: true,
};

export const CurrentActorContext = createContext<NullableCurrentActorContext>(
  DEFAULT_CURRENT_ACTOR,
);

export const CurrentActorProvider = ({
  children,
}: CurrentActorProviderProps) => {
  const { isAuthenticated } = useSession();

  const { data, loading } = useCurrentContextQuery({
    skip: !isAuthenticated,
  });

  const value = useMemo(
    () => ({
      actor: data?.currentContext?.currentActor || null,
      loading,
    }),
    [data, loading],
  );

  return (
    <CurrentActorContext.Provider value={value}>
      {children}
    </CurrentActorContext.Provider>
  );
};
