import { CheckCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Box, Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  ExecutionModalFooter,
  ExecutionModalHeader,
} from "@/components/common";
import { useColors } from "@/hooks";

type NewEntityModalConfirmationProps = {
  onClose: () => void;
};

const NewEntityModalConfirmation = ({
  onClose,
}: NewEntityModalConfirmationProps) => {
  const { t } = useTranslation(`account`);
  const [green600, emerald100] = useColors([`green.600`, `emerald.100`]);

  return (
    <>
      <ExecutionModalHeader closeModal={onClose}>
        <Text>{t(`entities.create_new_entity`)}</Text>
      </ExecutionModalHeader>
      <ModalBody
        minH={374}
        justifyContent="center"
        as={VStack}
        gap={8}
        border="none"
        p={0}
        px={8}
        py={8}
        w="full"
      >
        <Box
          borderColor={emerald100}
          borderWidth={22}
          borderRadius="full"
          bgColor={emerald100}
        >
          <CheckCircle fill={green600} size={64} weight="fill" />
        </Box>
        <VStack gap={4}>
          <Text textStyle="heading-3xl">
            {t(`entities.new_entity_created`)}
          </Text>
          <Text textStyle="text-sm">
            {t(`entities.new_entity_added_footer_text`)}
          </Text>
        </VStack>
      </ModalBody>
      <ExecutionModalFooter>
        <Button onClick={onClose}>{t(`entities.done`)}</Button>
      </ExecutionModalFooter>
    </>
  );
};

export default NewEntityModalConfirmation;
