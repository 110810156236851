import { useTranslation } from "react-i18next";

import { Divider, Text, VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  TransactionExecutionPageTransactionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useBuySideFees } from "@/hooks/featureFlags";
import {
  getTransactionIsBuySide,
  getTransactionIsSellSide,
} from "@/hooks/transaction";
import { getIsFeeDiscountAdmin } from "@/utils";

import {
  BuySideTransactionBreakdown,
  SellSideTransactionBreakdown,
} from "./Task/components";

const TransactionSummaryCard = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionExecutionPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`execution`);
    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    const buySideFeesEnabled = useBuySideFees();
    const isSellSide = getTransactionIsSellSide({ actor, transaction });
    const isFeeDiscountAdmin = getIsFeeDiscountAdmin(actor);

    const showBothFeesForFeeDiscountAdminSeller =
      buySideFeesEnabled && isSellSide && isFeeDiscountAdmin;

    if (showBothFeesForFeeDiscountAdminSeller) {
      return (
        <VStack alignItems="flex-start" spacing={4}>
          <Text textStyle="heading-md">{t(`buyer_transaction_summary`)}</Text>
          <BuySideTransactionBreakdown
            numShares={transaction.numShares}
            pricePerShare={transaction.pricePerShare}
            commission={transaction.buyerCommission}
          />
          <Divider borderColor="grey.200" ml={-6} width="calc(100% + 3rem)" />
          <Text textStyle="heading-md">{t(`seller_transaction_summary`)}</Text>
          <SellSideTransactionBreakdown
            newShares={transaction.numShares}
            newPricePerShare={transaction.pricePerShare}
            numShares={transaction.numShares}
            pricePerShare={transaction.pricePerShare}
            commission={transaction.sellerCommission}
            feeDiscountApplications={
              transaction.sellerFeeDiscountApplications || []
            }
          />
        </VStack>
      );
    }

    return (
      <VStack alignItems="flex-start" spacing={4}>
        <Text textStyle="heading-md">{t(`transaction_summary`)}</Text>
        {isBuySide ? (
          <BuySideTransactionBreakdown
            numShares={transaction.numShares}
            pricePerShare={transaction.pricePerShare}
            commission={transaction.buyerCommission}
          />
        ) : (
          <SellSideTransactionBreakdown
            newShares={transaction.numShares}
            newPricePerShare={transaction.pricePerShare}
            numShares={transaction.numShares}
            pricePerShare={transaction.pricePerShare}
            commission={transaction.sellerCommission}
            feeDiscountApplications={
              transaction.sellerFeeDiscountApplications || []
            }
          />
        )}
      </VStack>
    );
  },
);

export default TransactionSummaryCard;
