import { useTranslation } from "react-i18next";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  InquiryPostingCard,
  WithQuery,
} from "@/components/common";
import {
  MessageTopicType,
  StandingBidBuyerDiscussionsStandingBidFragment,
  useMyDiscussionListDiscussionsQuery,
  MyDiscussionListDiscussionFragment,
} from "@/gql";

import { StandingBidBuyerDiscussionsSkeleton } from "./StandingBidBuyerDiscussionsSkeleton";

type StandingBidBuyerDiscussionsListProps = {
  myDiscussions: MyDiscussionListDiscussionFragment[];
};

const DiscussionsEmptyState = () => {
  const { t } = useTranslation(`discussions`);
  return (
    <ActivityGroupEmptyState message={t`standing_bid_inquiries_empty_state`} />
  );
};

function StandingBidBuyerDiscussionsList({
  myDiscussions,
}: StandingBidBuyerDiscussionsListProps) {
  const { t } = useTranslation(`discussions`);

  return (
    <ActivityGroup
      title={t(`inquiries`)}
      emptyState={<DiscussionsEmptyState />}
    >
      {myDiscussions.map((discussion) => (
        <InquiryPostingCard key={discussion.id} discussion={discussion} />
      ))}
    </ActivityGroup>
  );
}

export const StandingBidBuyerDiscussions = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBuyerDiscussionsStandingBidFragment;
}) => {
  const query = useMyDiscussionListDiscussionsQuery({
    variables: {
      topicFilter: { type: MessageTopicType.StandingBid, id: standingBid.id },
    },
  });

  return (
    <WithQuery query={query} fallback={<StandingBidBuyerDiscussionsSkeleton />}>
      {({ data: { myDiscussions } }) => (
        <StandingBidBuyerDiscussionsList myDiscussions={myDiscussions} />
      )}
    </WithQuery>
  );
};
