import { useMemo, createContext, ReactNode, useEffect } from "react";

import { FrontChatLauncher } from "@/components/front";
import { withCurrentActor, WithCurrentActorProps } from "@/components/hoc";
import { InvestorType } from "@/gql";
import {
  useFrontChatU16r,
  useFrontChatTrader,
  useFrontChatU16rOnboarding,
} from "@/hooks/featureFlags";
import { EnsureFrontChatIsShutdown, useFrontChatRef } from "@/hooks/front";

export const FrontChatContext = createContext<{
  ensureFrontChatIsShutdown: EnsureFrontChatIsShutdown | undefined;
}>({
  ensureFrontChatIsShutdown: undefined,
});

const FRONT_CHAT_ID = process.env.NEXT_PUBLIC_FRONT_CHAT_ID;

type FrontChatProviderProps = {
  readonly children: ReactNode;
} & WithCurrentActorProps;

const useIsFrontChatEnabled = ({ actor }: WithCurrentActorProps) => {
  const isFrontChatU16rEnabled = useFrontChatU16r();
  const isFrontChatTraderEnabled = useFrontChatTrader();
  const isFrontChatU16rOnboardingEnabled = useFrontChatU16rOnboarding();

  if (actor.investorType === InvestorType.UnaccreditedSeller) {
    return (
      isFrontChatU16rEnabled &&
      (isFrontChatU16rOnboardingEnabled || actor.onboardingComplete)
    );
  }

  if (actor.investorType === InvestorType.Trader) {
    return isFrontChatTraderEnabled && actor.onboardingComplete;
  }

  return false;
};

const FrontChatProvider = ({ children, actor }: FrontChatProviderProps) => {
  const frontChatHook = useFrontChatRef();

  const { ensureFrontChatIsShutdown, isInitialized, initialize, frontChat } =
    frontChatHook;

  const contextValue = useMemo(
    () => ({ ensureFrontChatIsShutdown }),
    [ensureFrontChatIsShutdown],
  );

  const isFrontChatEnabled = useIsFrontChatEnabled({ actor });

  useEffect(() => {
    const shouldInitializeChat = FRONT_CHAT_ID && initialize && !isInitialized;

    if (shouldInitializeChat && isFrontChatEnabled) {
      initialize({
        chatId: FRONT_CHAT_ID,
        userId: actor.id,
        userHash: actor.chatIdHash,
        useDefaultLauncher: false,
        name: actor.name,
        contact: {
          email: actor.email,
        },
      });
    }
  }, [isInitialized, initialize, frontChat, isFrontChatEnabled]);

  return (
    <FrontChatContext.Provider value={contextValue}>
      <>
        {frontChat && (
          <FrontChatLauncher
            isInitialized={isInitialized}
            frontChat={frontChat}
          />
        )}
        {children}
      </>
    </FrontChatContext.Provider>
  );
};

export default withCurrentActor(FrontChatProvider);
