import { useField } from "formik";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormListbox } from "@/components/form";
import { useActiveBuyerCommissionStructuresQuery } from "@/gql";

interface BuyerCommissionStructureInputProps {
  initialBuyerCommissionStructureId?: string | null;
}

export const BuyerCommissionStructureInput = ({
  initialBuyerCommissionStructureId,
}: BuyerCommissionStructureInputProps) => {
  const { t } = useTranslation();
  const { data, loading } = useActiveBuyerCommissionStructuresQuery();
  const [{ value }, , { setValue }] = useField(`buyerCommissionStructure`);

  const EMPTY_OPTION = {
    id: ``,
    key: t(`none`),
  } as const;

  const items = useMemo(
    () => [EMPTY_OPTION, ...(data?.activeBuyerCommissionStructures ?? [])],
    [data?.activeBuyerCommissionStructures],
  );

  useEffect(() => {
    if (!value) {
      setValue(EMPTY_OPTION);
    }
    if (!items || !initialBuyerCommissionStructureId) return;

    const selectedStructure = items.find(
      (structure) => structure.id === initialBuyerCommissionStructureId,
    );
    if (selectedStructure) {
      setValue(selectedStructure);
    }
  }, [initialBuyerCommissionStructureId, items]);

  return (
    <FormListbox
      onSelectItem={setValue}
      name="buyerCommissionStructure"
      label={t(`buy_side_fees`)}
      itemToString={(item) => item.key}
      getItemKey={(item) => item.id}
      items={items}
      isLoading={loading}
    />
  );
};
