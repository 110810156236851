import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Center,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import {
  ExecutionModalHeader,
  HiiveButton,
  MailtoLink,
} from "@/components/common";
import { AnvilIcon } from "@/components/icons";
import {
  AlternateUserPageAlternateUserTaskInfoFragment,
  ExecutionTaskActionType,
  ExecutionTaskStatus,
  useAlternateUserPageExecutionTaskActionMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { formatCurrency, formatPricePerShare, formatShares } from "@/utils";

import { AnvilEmbedEvent } from "./types";

function TransactionDetailsHeader({
  companyName,
  displayId,
  logoUrl,
}: {
  companyName: string;
  displayId: string;
  logoUrl: string;
}) {
  const { t } = useTranslation(`execution`);
  return (
    <HStack
      borderBottom="1px solid"
      borderColor="grey.75"
      w="full"
      justifyContent="space-between"
      py={2}
      px={4}
    >
      <HStack spacing={3}>
        <Box
          minW={6}
          border="1px solid"
          borderRadius="md"
          p={1}
          borderColor="grey.75"
        >
          <Image
            h={{ base: 5, sm: 6 }}
            maxW={5}
            maxH={5}
            objectFit="contain"
            src={logoUrl}
          />
        </Box>
        <Text color="grey.900" textStyle="heading-md">
          {companyName}
        </Text>
      </HStack>
      <HStack spacing={1}>
        <Text color="grey.900" textStyle="heading-3xs">
          {t(`transaction`)}
        </Text>
        <Text textStyle="text-xs">{displayId}</Text>
      </HStack>
    </HStack>
  );
}

function TransactionDetailRow({
  label,
  value,
}: {
  readonly label: string;
  readonly value: string;
}) {
  return (
    <HStack w="full" px={4} py={0} justifyContent="space-between">
      <Text color="grey.700" textStyle="heading-xs">
        {label}
      </Text>
      <Text color="grey.700" textStyle="text-sm">
        {value}
      </Text>
    </HStack>
  );
}

function AnvilTaskWrapper({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation(`execution`);
  return (
    <VStack
      spacing={3}
      alignItems="flex-start"
      background="white"
      borderRadius="md"
      px={4}
      py={3}
    >
      <HStack>
        <Box
          borderRadius="lg"
          border="1px solid"
          borderColor="grey.75"
          px={1}
          py={1.5}
        >
          <AnvilIcon width="14px" />
        </Box>
        <Text color="grey.900" textStyle="heading-md">
          {t(`sign_document`)}
        </Text>
      </HStack>
      <VStack
        borderRadius="md"
        w="full"
        bg="grey.50"
        border="1px solid"
        borderColor="grey.100"
        py={5}
        px={5}
        spacing={6}
      >
        {children}
      </VStack>
      <Text color="grey.900" textStyle="text-sm">
        <Trans
          components={{
            a: <MailtoLink email="execution@hiive.com" />,
            bold: <Text as="span" textStyle="heading-xs" />,
          }}
          i18nKey="contact_our_email"
          ns="execution"
          values={{
            email: `execution@hiive.com`,
          }}
        />
      </Text>
    </VStack>
  );
}

function AnvilSignDocumentTask({
  alternateUserTaskInfo,
  token,
  onTaskCompletedLocally,
}: {
  readonly alternateUserTaskInfo: AlternateUserPageAlternateUserTaskInfoFragment;
  readonly token: string;
  readonly onTaskCompletedLocally: () => void;
}) {
  const { t } = useTranslation(`execution`);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const [executionWorkflowAction, isSubmitting] = useMutationWithError(
    useAlternateUserPageExecutionTaskActionMutation(),
    `executionAlternateUserTaskAction`,
  );

  const {
    task: { status: taskStatus },
    inviter: { firstName, lastName },
    transaction: {
      numShares,
      pricePerShare,
      company: { name: companyName, logoUrl },
      displayId,
    },
  } = alternateUserTaskInfo;

  const numSharesFormatted = formatShares(numShares);
  const ppsFormatted = formatPricePerShare(pricePerShare);
  const grossProceedsFormatted = formatCurrency(numShares * pricePerShare, {
    fromCents: true,
  });

  const {
    isOpen: isModalOpen,
    onOpen: handleOpen,
    onClose: handleModalClose,
  } = useDisclosure();

  const isInProgress = taskStatus === ExecutionTaskStatus.InProgress;

  const handleClickSignDocument = async () => {
    const response = await executionWorkflowAction({
      variables: {
        token,
        type: ExecutionTaskActionType.AnvilSignDocumentGenerateEmbedUrl,
        input: {},
      },
    });

    handleOpen();

    setEmbedUrl(
      response?.executionAlternateUserTaskAction?.result?.__typename ===
        `ExecutionTaskActionAnvilSignDocumentGenerateEmbedUrl`
        ? response?.executionAlternateUserTaskAction?.result?.url
        : null,
    );
  };

  const handleEvent = (event: AnvilEmbedEvent) => {
    if (event.action === `signerComplete`) {
      handleModalClose();
      onTaskCompletedLocally();
    }
  };

  return (
    <>
      <AnvilTaskWrapper>
        {isInProgress ? (
          <Text color="grey.900" textStyle="heading-lg">
            {t(`inviter_has_asked_to_sign`, {
              name: `${firstName} ${lastName}`,
            })}
          </Text>
        ) : (
          <VStack>
            <Text color="grey.900" textStyle="heading-lg">
              {t(`document_signed`)}
            </Text>
            <Text color="grey.900" textStyle="text-lg">
              {t(`document_signed_description`, {
                name: `${firstName} ${lastName}`,
              })}
            </Text>
          </VStack>
        )}
        <VStack
          spacing={2}
          alignItems="flex-start"
          bg="white"
          w="full"
          border="1px solid"
          borderColor="grey.100"
          borderRadius="md"
          pb={4}
        >
          <TransactionDetailsHeader
            companyName={companyName}
            displayId={displayId}
            logoUrl={logoUrl}
          />
          <TransactionDetailRow
            label={t(`shares`)}
            value={`${numSharesFormatted} @ ${ppsFormatted}`}
          />
          <TransactionDetailRow
            label={t(`gross_proceeds`)}
            value={grossProceedsFormatted}
          />
        </VStack>
        {isInProgress && (
          <HiiveButton
            observabilityLabel="[NextAlternateUserPage] Sign Document"
            variant="rounded-solid-grey"
            size="xl"
            onClick={handleClickSignDocument}
            isLoading={isSubmitting}
          >
            {t(`sign_document`)}
          </HiiveButton>
        )}
      </AnvilTaskWrapper>

      <Modal variant="texas" isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent h="6xl" w="6xl">
          <ExecutionModalHeader closeModal={handleModalClose}>
            {t(`document_signing`)}
          </ExecutionModalHeader>
          <ModalBody p={0} px={0} overflow="hidden">
            {!!embedUrl ? (
              <Box
                as={AnvilEmbedFrame}
                onEvent={(event: AnvilEmbedEvent) => handleEvent(event)}
                iframeURL={embedUrl}
                w="full"
                h="full"
              />
            ) : (
              <Center mt={12}>
                <Spinner />
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AnvilSignDocumentTask;
