export const BOX_STYLES = {
  alignItems: `baseline`,
  gridTemplateColumns: `1fr min-content`,
  borderColor: `grey.200`,
  borderWidth: 0.5,
  borderBottomWidth: 0,
  px: 6,
  py: 4,
  overflow: `hidden`,
};

export const FIRST_ITEM_STYLES = {
  borderTopRadius: `md`,
};

export const LAST_ITEM_STYLES = {
  borderBottomRadius: `md`,
  borderBottomWidth: BOX_STYLES.borderWidth,
};
