import { useTranslation } from "react-i18next";

import { HStack, Radio, Text, VStack } from "@chakra-ui/react";

import { CustomTooltip, FormRadioInput } from "@/components/form";
import { CompanyUnaccreditedSellerRestrictedAlert } from "@/components/listings";

import { transferTypeChoices } from "./types";

type TransferTypeChoiceInputProps = {
  isRestricted?: boolean;
  companyName?: string;
};

const TransferTypeChoiceInput = ({
  isRestricted,
  companyName,
}: TransferTypeChoiceInputProps) => {
  const { t } = useTranslation();

  const shouldRenderRestrictedAlert = isRestricted && companyName;
  return (
    <FormRadioInput
      label={t(`are_you_able_to_transfer`)}
      name="transferTypeChoice"
    >
      <VStack
        alignItems="flex-start"
        spacing={4}
        mt={shouldRenderRestrictedAlert ? 2 : 0}
      >
        {shouldRenderRestrictedAlert && (
          <CompanyUnaccreditedSellerRestrictedAlert companyName={companyName} />
        )}
        <HStack spacing={6} pt={2} data-testid="transfer-type-choice">
          <Radio variant="base" value={transferTypeChoices.Yes}>
            <CustomTooltip tooltipContent={t(`transfer_type_choice_direct`)}>
              <Text variant="tooltip">{t(`yes`)}</Text>
            </CustomTooltip>
          </Radio>
          <Radio variant="base" value={transferTypeChoices.Other}>
            <CustomTooltip tooltipContent={t(`transfer_type_choice_indirect`)}>
              <Text variant="tooltip">{t(`no`)}</Text>
            </CustomTooltip>
          </Radio>
          {!isRestricted && (
            <Radio variant="base" value={transferTypeChoices.DontKnow}>
              <CustomTooltip tooltipContent={t(`transfer_type_choice_unknown`)}>
                <Text variant="tooltip">{t(`i_dont_know`)}</Text>
              </CustomTooltip>
            </Radio>
          )}
        </HStack>
      </VStack>
    </FormRadioInput>
  );
};

export default TransferTypeChoiceInput;
