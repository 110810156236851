import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
  UnaccreditedSellerAcceptStandingBidV2Mutation,
} from "@/gql";
import { useTexasCopy } from "@/hooks/featureFlags";
import {
  appendSellerCompanyIdToUrl,
  formatPricePerShare,
  formatShares,
  getFullDocumentTitleByTransferMethod,
  getTransferMethodByUnaccreditedSellerTransferMethodInput,
  makeUrl,
} from "@/utils";

import { useUnaccreditedSellerAcceptStandingBidSequenceModalContext } from "./UnaccreditedSellerAcceptStandingBidSequenceModalContext";
import { StepKeys } from "./steps";
import { UnaccreditedSellerAcceptStandingBidSequenceModalFormValues } from "./types";

interface UnaccreditedSellerAcceptStandingBidConfirmationModalProps
  extends StepPropsV2<
    StepKeys,
    UnaccreditedSellerAcceptStandingBidSequenceModalFormValues
  > {
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

const UnaccreditedSellerAcceptStandingBidConfirmationModal = ({
  stepRouter,
  standingBid,
  isSubmitting,
  values,
}: UnaccreditedSellerAcceptStandingBidConfirmationModalProps) => {
  const { t } = useTranslation();
  const { t: tSB } = useTranslation(`standingBids`);
  const router = useRouter();
  const isTexasCopyEnabled = useTexasCopy();

  const { submitMutation } =
    useUnaccreditedSellerAcceptStandingBidSequenceModalContext();

  const { stepControls } = stepRouter;

  const onClickSubmit = () =>
    submitMutation().then(
      (response: UnaccreditedSellerAcceptStandingBidV2Mutation) => {
        stepControls.nextStep();
        const acceptedBid = response.unaccreditedSellerAcceptStandingBidV2.bid;
        if (!acceptedBid) return;

        router.push(
          appendSellerCompanyIdToUrl(
            makeUrl(acceptedBid),
            standingBid.company.id,
          ),
        );
      },
    );

  const transferMethod =
    getTransferMethodByUnaccreditedSellerTransferMethodInput(
      values.transferMethod,
    );

  const documentTitle = getFullDocumentTitleByTransferMethod(transferMethod);

  return (
    <>
      <HiiveModalHeader>
        {isTexasCopyEnabled
          ? tSB(`accept_standing_bid_bid_id`, { bidId: standingBid.displayId })
          : t(`are_you_sure_accept`, { bidType: `bid` })}
      </HiiveModalHeader>
      <ModalBody>
        {isTexasCopyEnabled ? (
          <VStack alignItems="flex-start" spacing={3}>
            <Text textStyle="heading-md">
              {tSB(`standing_bid_values_summary`, {
                standingBidId: standingBid.displayId,
                companyName: standingBid.company.name,
                numShares: formatShares(values.numShares),
                pricePerShare: formatPricePerShare(values.pricePerShare, false),
              })}
            </Text>
            <Text>
              <Trans
                ns="standingBids"
                i18nKey="accepting_this_standing_bid_counts_as"
                components={{
                  italic: (
                    <Link
                      href="/terms-and-conditions"
                      textDecor="underline"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Text>
            <Text>{tSB`once_you_ve_accepted_this_standing_bid`}</Text>
          </VStack>
        ) : (
          <VStack alignItems="flex-start">
            <Text textStyle="heading-2xl">
              Are you sure you want to accept this standing bid?
            </Text>
            <Text>
              By accepting this bid, you indicate to the buyer that you intend
              to sell at this price and quantity.
            </Text>
            <Text>
              After you accept the bid, you and the buyer will execute an {` `}
              {documentTitle}
              {` `}
              to document your agreement to sell.
            </Text>
            <Text>
              The sale may be conditional on issuer approval and other
              processes. If this sale closes, at closing, you will owe Hiive a
              fee as set out in the{` `}
              <Link
                href="/terms-and-conditions"
                textDecor="underline"
                target="_blank"
              >
                Terms & Conditions
              </Link>
              .
            </Text>
            <Text>
              {formatShares(values.numShares)}
              {` `}
              shares of {standingBid.company.name} @{` `}
              {formatPricePerShare(values.pricePerShare, false)}/sh{` `}
              {transferMethod}
            </Text>
          </VStack>
        )}
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[UnaccreditedSellerAcceptStandingBidConfirmation/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          observabilityLabel="[UnaccreditedSellerAcceptStandingBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText={isTexasCopyEnabled ? tSB`accept_standing_bid` : `Confirm`}
          type="button"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};

export default UnaccreditedSellerAcceptStandingBidConfirmationModal;
