import currency from "currency.js";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  FeeBreakdownBuyerCommissionInfo,
  FullDivider,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  ShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  FixedValueInput,
  FormikQL,
  FormNumberInput,
  FormNumberInputControl,
  FormFieldError,
  MoneyInput,
} from "@/components/form";
import {
  bidTimeLimitSchema,
  ListingBuyerRoundingDisclaimer,
} from "@/components/postings";
import {
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  CompletedTransfersCardMyActivityDocument,
  ListingPageListingByIdDocument,
  ModifyBidModalBidFragment,
  ModifyBidModalListingFragment,
  ModifyBidMutation,
  SignDocumentAlertMessagesMyActivityDocument,
  TransferMethod,
  useModifyBidMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { useBuySideFees } from "@/hooks/featureFlags";
import {
  getBidLabel,
  getCountLabel,
  constants,
  formatShares,
  formatPricePerShare,
  getNumOfShares,
  getNumSharesAvailableRounded,
  getPricePerShare,
  hoursFromNow,
  getIsEitherFund,
  getIsHiiveFund,
} from "@/utils";

interface ModifyBidModalFormValues {
  readonly numShares: number;
  readonly pricePerShare: number;
  readonly timeLimit: number;
}

const createInitialValues = (
  bid: ModifyBidModalBidFragment,
): ModifyBidModalFormValues => ({
  numShares: bid.numShares,
  pricePerShare: bid.pricePerShare / 100,
  timeLimit:
    hoursFromNow(bid.expireAt) < 24
      ? 1
      : Math.ceil(hoursFromNow(bid.expireAt) / 24),
});

const createValidationSchema = ({
  listing,
}: {
  readonly listing: ModifyBidModalListingFragment;
}) => {
  const orderOrBid = getBidLabel(listing.transferMethod).toLowerCase();
  const share = getCountLabel(listing.transferMethod);

  return Yup.object().shape({
    pricePerShare: Yup.number()
      .nullable()
      .test(
        `min-bid-size`,
        `The ${orderOrBid} size can't be less than ${currency(
          constants.min_bid_lot.number,
        ).format()}`,
        function test(value: number) {
          const { numShares } = this.parent;

          return value * numShares >= constants.min_bid_lot.number;
        },
      )
      .required(`Price per ${share} is required`),
    numShares: Yup.number()
      .max(
        listing ? getNumSharesAvailableRounded(listing) : 0,
        `Can't request more ${share}s than a listing has`,
      )
      .required(`Required`),
    timeLimit: bidTimeLimitSchema,
    confirmed: Yup.boolean(),
  });
};

const createMapVariables =
  (bidId: string) =>
  ({ timeLimit, ...values }: ModifyBidModalFormValues) => ({
    bidId,
    input: {
      ...values,
      pricePerShare: currency(values.pricePerShare).intValue,
      timeLimit: timeLimit * 24,
    },
  });

const ModalShareDetails = ({
  listing,
}: {
  readonly listing: ModifyBidModalListingFragment;
}) => {
  const numberOfShares = getNumOfShares(listing, true);
  const pricePerShare = getPricePerShare(listing);

  return (
    <ShareDetails variant="listing" title="Listing Details">
      <ShareDetailsStats
        numberOfShares={numberOfShares}
        pricePerShare={pricePerShare}
      />
      <ListingBuyerRoundingDisclaimer />
    </ShareDetails>
  );
};

const BidModalHeader = ({
  bid,
}: {
  readonly bid: ModifyBidModalBidFragment;
}) => {
  const label = getBidLabel(bid.listing.transferMethod);

  return (
    <HiiveModalHeader>
      Modify {label} on {` `}
      {bid.listing.company.name}
    </HiiveModalHeader>
  );
};

const NumAndPricePerShare = ({
  listing,
}: {
  readonly listing: ModifyBidModalListingFragment;
}) => {
  const { t } = useTranslation();
  const pricePerShare = getPricePerShare(listing) as number;
  const listingNumShares = formatShares(getNumSharesAvailableRounded(listing));
  const { transferMethod } = listing;
  const label = getIsEitherFund(listing.transferMethod)
    ? `number_of_fund_units`
    : `number_of_shares`;

  return (
    <>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        {listing.acceptPartialBid ? (
          <FormNumberInput name="numShares" label={t(label)} />
        ) : (
          <FixedValueInput
            label={t(label)}
            name="numShares"
            fixedValue={listingNumShares}
          />
        )}
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        {match(transferMethod)
          .with(TransferMethod.HiiveFund, () => (
            <>
              <Text fontWeight="medium">{t(`price_per_fund_unit`)}</Text>
              <Text mt={4}>{formatPricePerShare(pricePerShare)}</Text>
              <FormNumberInputControl name="pricePerShare">
                <FormFieldError name="pricePerShare" />
              </FormNumberInputControl>
            </>
          ))
          .with(TransferMethod.Fund, () => (
            <MoneyInput name="pricePerShare" label={t(`price_per_fund_unit`)} />
          ))
          .otherwise(() => (
            <MoneyInput name="pricePerShare" label={t(`price_per_share`)} />
          ))}
      </GridItem>
    </>
  );
};

interface ModifyBidModalProps {
  readonly bid: ModifyBidModalBidFragment;
}

const ModifyBidModal = ({ bid }: ModifyBidModalProps) => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const router = useRouter();
  const { closeModal } = useModal();

  const buySideFeesEnabled = useBuySideFees();

  const mutation = useModifyBidMutation({
    refetchQueries: [
      BidPageBidByIdDocument,
      ListingPageListingByIdDocument,
      BidPageMyActivityDocument,
      UserActivityMyActivityDocument,
      SignDocumentAlertMessagesMyActivityDocument,
      CompletedTransfersCardMyActivityDocument,
    ],
  });

  const onSuccess = (response: ModifyBidMutation) => {
    successToast(`Bid modified.`);
    closeModal();
    router.push(`/listings/bids/${response?.modifyBid?.bid?.id}`);
  };

  const validationSchema = createValidationSchema({
    listing: bid.listing,
  });

  const initialValues = createInitialValues(bid);

  const mapVariables = createMapVariables(bid.id);

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`modifyBid`]}
        mapVariables={mapVariables}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <BidModalHeader bid={bid} />
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={6} w="full">
                <GridItem colSpan={2}>
                  <VStack w="full" alignItems="flex-start" spacing={5}>
                    {!getIsHiiveFund(bid.listing.transferMethod) && (
                      <Text textStyle="heading-md">
                        Offer at, below or above the Seller&apos;s ask.
                      </Text>
                    )}
                    <ModalShareDetails listing={bid.listing} />
                  </VStack>
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
                <NumAndPricePerShare listing={bid.listing} />
                <GridItem colSpan={2}>
                  <FormNumberInput
                    name="timeLimit"
                    label={
                      t(`bid_valid_for_days`, {
                        bidLabel: getBidLabel(bid.listing.transferMethod),
                      }) as string
                    }
                  />
                </GridItem>
                {buySideFeesEnabled &&
                  bid.listing.buyerCommissionStructureId && (
                    <GridItem colSpan={2}>
                      <FeeBreakdownBuyerCommissionInfo
                        numShares={values.numShares}
                        pricePerShare={values.pricePerShare}
                        buyerCommissionStructureId={
                          bid.listing.buyerCommissionStructureId
                        }
                      />
                    </GridItem>
                  )}
              </SimpleGrid>
            </ModalBody>

            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  observabilityLabel="[ModifyBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                observabilityLabel="[ModifyBid/Submit]"
                submitText="Modify"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default ModifyBidModal;
