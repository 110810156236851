import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import {
  PlaceStandingBidSuccessModalStandingBidFragment,
  StandingBid,
  StandingBidState,
} from "@/gql";
import { useModal } from "@/hooks";
import { useStandingBidsPricingInReview } from "@/hooks/featureFlags";
import {
  formatPricePerShare,
  formatShares,
  getSecuritySpecialistContact,
} from "@/utils";

type StandingBidContentType = {
  readonly pricePerShare: number;
  readonly company: Pick<StandingBid[`company`], "id" | "name">;
};

const PlaceStandingBidSuccessModalBrokerContent = ({
  numShares,
  standingBid,
}: {
  readonly numShares: number;
  readonly standingBid: StandingBidContentType;
}) => {
  const { t } = useTranslation(`standingBids`);
  return (
    <>
      <HiiveModalHeader>Standing bid is in review</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {t(`your_standing_bid_is_in_review`, {
            companyName: standingBid.company.name,
            numShares: formatShares(numShares),
            pricePerShare: formatPricePerShare(standingBid.pricePerShare),
          })}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>{t`your_standing_bid_is_being_reviewed`}</Text>
          <Text>
            <Trans
              i18nKey="you_may_also_reach_out"
              ns="standingBids"
              components={{
                mailto: (
                  <SecuritySpecialistLink company={standingBid.company} />
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};

const PlaceInReviewStandingBidSuccessModalContent = ({
  standingBid,
  numShares,
}: {
  readonly numShares: number;
  readonly standingBid: PlaceStandingBidSuccessModalStandingBidFragment;
}) => {
  const { t } = useTranslation(`standingBids`);
  const securitySpecialistEmail = getSecuritySpecialistContact(
    standingBid.company,
  );
  const companyName = standingBid.company.name;

  return (
    <>
      <HiiveModalHeader>
        {t(`we_re_reviewing_your_company_standing_bid`, { companyName })}
      </HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {t(`standing_bid_values_summary`, {
            standingBidId: standingBid.displayId,
            companyName: standingBid.company.name,
            numShares: formatShares(numShares),
            pricePerShare: formatPricePerShare(
              standingBid.pricePerShare,
              false,
            ),
          })}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>{t(`we_ll_keep_you_updated`)}</Text>
          <Text>
            <Trans
              i18nKey="for_support_contact"
              ns="standingBids"
              values={{ email: securitySpecialistEmail }}
              components={{
                b: <Text as="span" fontWeight={500} />,
                mailto: (
                  <MailtoLink
                    email={securitySpecialistEmail}
                    subject={t(`why_is_my_posting_in_review_email_title`, {
                      companyName,
                    })}
                  />
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};

const PlaceStandingBidSuccessModalBuyerContent = ({
  numShares,
  standingBid,
}: {
  readonly numShares: number;
  readonly standingBid: PlaceStandingBidSuccessModalStandingBidFragment;
}) => {
  const { t } = useTranslation(`standingBids`);
  const isStandingBidsPricingInReviewEnabled = useStandingBidsPricingInReview();
  const isStandingBidInReview = standingBid.state === StandingBidState.InReview;

  if (isStandingBidsPricingInReviewEnabled && isStandingBidInReview)
    return (
      <PlaceInReviewStandingBidSuccessModalContent
        numShares={numShares}
        standingBid={standingBid}
      />
    );

  return (
    <>
      <HiiveModalHeader>Standing bid placed!</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {t(`your_standing_bid_is_live`, {
            companyName: standingBid.company.name,
            numShares: formatShares(numShares),
            pricePerShare: formatPricePerShare(standingBid.pricePerShare),
          })}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>
            {t(`potential_sellers_will_now_be_notified`, {
              companyName: standingBid.company.name,
            })}
          </Text>
          <Text>
            <Trans
              i18nKey="you_may_also_reach_out_for_assistance"
              ns="standingBids"
              components={{
                mailto: (
                  <SecuritySpecialistLink company={standingBid.company} />
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};

const PlaceStandingBidSuccessModal = ({
  standingBid,
}: {
  readonly standingBid: PlaceStandingBidSuccessModalStandingBidFragment | null;
}) => {
  const { closeModal } = useModal();

  const numShares = standingBid?.numSharesOriginal || 0;
  const brokerPlaced = !!standingBid?.brokerId;

  if (!standingBid) return null;
  return (
    <>
      {brokerPlaced ? (
        <PlaceStandingBidSuccessModalBrokerContent
          numShares={numShares}
          standingBid={standingBid}
        />
      ) : (
        <PlaceStandingBidSuccessModalBuyerContent
          numShares={numShares}
          standingBid={standingBid}
        />
      )}
      <HiiveModalFooter>
        <HiiveSubmitButton
          data-testId="placeStandingBidSuccessButton"
          observabilityLabel="[PlaceStandingBidSuccess/Submit]"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default PlaceStandingBidSuccessModal;
