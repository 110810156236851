import { Bank, Certificate } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Divider,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useTransactionExecution } from "@/hooks";

import EntityDetails from "./EntityDetails";

const SellerDetails = () => {
  const { t } = useTranslation(`execution`);
  const { transaction } = useTransactionExecution();
  const sellerEntity = transaction?.sellerEntity;
  const bankAccount = transaction?.sellerBankAccount;
  const showDetails = sellerEntity || bankAccount;

  if (!showDetails) return null;

  return (
    <VStack alignItems="start" gap={3}>
      <Text textStyle="heading-md" color="grey.900">
        {t(`my_details`)}
      </Text>
      <List as={VStack} gap={3} alignItems="flex-start" w="full">
        {sellerEntity && (
          <ListItem as={HStack} gap={6}>
            <Certificate size={24} />
            <EntityDetails entity={transaction?.sellerEntity} />
          </ListItem>
        )}
        {bankAccount && (
          <>
            <Divider borderColor="grey.200" />
            <ListItem as={HStack} gap={6}>
              <Bank size={24} />
              <VStack gap={0} alignItems="start">
                <Text
                  textStyle="heading-xs"
                  color="grey.700"
                >{t`bank_details`}</Text>
                <HStack>
                  <Text textStyle="text-sm" color="grey.900">{t`account`}</Text>
                  <Text textStyle="text-md" color="grey.700">
                    {t(`bank_accounts.redacted`, {
                      ns: `postings`,
                    })}
                    {` `}
                    {bankAccount?.last4}
                  </Text>
                </HStack>
              </VStack>
            </ListItem>
          </>
        )}
      </List>
    </VStack>
  );
};

export default SellerDetails;
