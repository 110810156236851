import { yupResolver } from "@hookform/resolvers/yup";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import {
  FormProvider,
  Resolver,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  HStack,
  VStack,
  Button,
  Flex,
  Card,
  CardBody,
  Text,
} from "@chakra-ui/react";

import { FormTextInput } from "@/components/react-hook-form";
import { useDelayTaskCompletion } from "@/components/transactions";
import {
  ExecutionCollectInvestorInfoTaskDataFragment,
  TransactionExecutionPageTaskFragment,
  useCompleteExecutionTaskMutation,
  ExecutionFundInvestorInputKeys,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

const orderedFields = [
  ExecutionFundInvestorInputKeys.BuyerTitle,
  ExecutionFundInvestorInputKeys.BuyerAdditionalSignerName,
  ExecutionFundInvestorInputKeys.BuyerK1ElectronicConsent,
  ExecutionFundInvestorInputKeys.BuyerMeetsQualification,
  ExecutionFundInvestorInputKeys.BuyerJurisdiction,
  ExecutionFundInvestorInputKeys.BuyerInvestmentCompany,
  ExecutionFundInvestorInputKeys.BuyerFormedToAcquire,
  ExecutionFundInvestorInputKeys.BuyerAssetCommitment,
  ExecutionFundInvestorInputKeys.BuyerBeneficialOwnerDiscretion,
  ExecutionFundInvestorInputKeys.BuyerBeneficialOwnerNumber,
  ExecutionFundInvestorInputKeys.BuyerRetirementAccount,
  ExecutionFundInvestorInputKeys.BuyerEmployeeBenefitPlan,
  ExecutionFundInvestorInputKeys.BuyerPlanAssets,
  ExecutionFundInvestorInputKeys.BuyerPlanAssetsPercent,
  ExecutionFundInvestorInputKeys.BuyerGovernmentalPlan,
  ExecutionFundInvestorInputKeys.BuyerChurchPlan,
  ExecutionFundInvestorInputKeys.BuyerInternationalEmployeeBenefitPlan,
  ExecutionFundInvestorInputKeys.BuyerInsuranceCompany,
  ExecutionFundInvestorInputKeys.BuyerInsuranceCompanyPercent,
  ExecutionFundInvestorInputKeys.BuyerKeoghPlan,
  ExecutionFundInvestorInputKeys.BuyerPlanParticipantsDecide,
  ExecutionFundInvestorInputKeys.BuyerFileIrs,
  ExecutionFundInvestorInputKeys.BuyerControlsSeriesAssets,
];

type AgreementFormValues = Record<ExecutionFundInvestorInputKeys, string>;

const AgreementFormFields = () => {
  const { control } = useFormContext<AgreementFormValues>();

  return (
    <VStack w="full" gap={4}>
      {orderedFields.map((fieldKey) => (
        <FormTextInput
          key={fieldKey}
          label={sentenceCase(fieldKey)}
          name={fieldKey}
          control={control}
        />
      ))}
    </VStack>
  );
};

const CollectInvestorInfoTaskCard = ({
  task,
  data,
  delayCompletion = false,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionCollectInvestorInfoTaskDataFragment;
  readonly delayCompletion?: boolean;
}) => {
  const { t } = useTranslation(`execution`);

  const { isCompleted } = useDelayTaskCompletion(task.status, delayCompletion);
  const [isPendingCompletion, setIsPendingCompletion] = useState(false);

  const validationSchema = Yup.object().shape({});

  const methods = useForm<AgreementFormValues>({
    resolver: yupResolver(
      validationSchema,
    ) as unknown as Resolver<AgreementFormValues>,
  });

  const [completeTask] = useMutationWithError(
    useCompleteExecutionTaskMutation(),
    `completeExecutionTask`,
  );

  const onSubmit = async (values: AgreementFormValues) => {
    setIsPendingCompletion(true);
    const response = await completeTask({
      variables: {
        taskId: task.id,
        input: {
          collectInvestorInfo: {
            answers: Object.entries(values).map(([key, value]) => ({
              prefillKey: key as ExecutionFundInvestorInputKeys,
              value: value || null,
            })),
          },
        },
      },
    });

    if (response?.completeExecutionTask.errors) {
      setIsPendingCompletion(false);
    }
  };

  useEffect(() => {
    if (isCompleted) {
      setIsPendingCompletion(false);
    }
  }, [isCompleted]);

  if (!!task.completedAt && isCompleted)
    return (
      <Card variant="flat">
        <CardBody>
          {data.answers.map((answer) => (
            <Text key={answer.prefillKey}>
              {answer.prefillKey}: {answer.value}
            </Text>
          ))}
        </CardBody>
      </Card>
    );

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <VStack alignItems="start" spacing={4}>
        <FormProvider {...methods}>
          <AgreementFormFields />
        </FormProvider>
      </VStack>
      <HStack w="full" justifyContent="flex-end" mt={2}>
        <Button
          variant="rounded-solid-grey"
          size={{ base: `md`, md: `xl` }}
          onClick={methods.handleSubmit(onSubmit)}
          colorScheme="primary"
          isLoading={isPendingCompletion}
        >
          {t(`confirm`)}
        </Button>
      </HStack>
    </Flex>
  );
};

export default CollectInvestorInfoTaskCard;
