import isNil from "lodash/isNil";

import { TransferMethod } from "@/gql";
import { Maybe } from "@/types";
import { constants } from "@/utils";

export const getAreFeesHigh = ({
  pricePerShare,
  numShares,
}: {
  readonly pricePerShare?: number | null;
  readonly numShares?: number | null;
}) =>
  !isNil(pricePerShare) && !isNil(numShares)
    ? pricePerShare * numShares < constants.high_fees_warning_threshold
    : false;

export const getAreFeesHighForListing = ({
  pricePerShare,
  numberOfShares,
}: {
  readonly pricePerShare?: Maybe<number>;
  readonly numberOfShares: number;
}) => {
  const isWithinWarningThreshold = pricePerShare
    ? getAreFeesHigh({ pricePerShare, numShares: numberOfShares })
    : false;

  return isWithinWarningThreshold;
};

export const getIsFund = (transferMethod: TransferMethod) =>
  transferMethod === TransferMethod.Fund;

export const getIsHiiveFund = (transferMethod: TransferMethod) =>
  transferMethod === TransferMethod.HiiveFund;

export const getIsEitherFund = (transferMethod: TransferMethod) =>
  getIsFund(transferMethod) || getIsHiiveFund(transferMethod);

export const getIsOtherMethod = (transferMethod: TransferMethod) =>
  transferMethod === TransferMethod.Other;

export const getBidLabel = (transferMethod: TransferMethod) =>
  getIsHiiveFund(transferMethod) ? `Order` : `Bid`;

export const getCountLabel = (transferMethod: TransferMethod) =>
  getIsEitherFund(transferMethod) ? `unit` : `share`;

export const abbrCountLabel = (transferMethod: TransferMethod) =>
  getIsEitherFund(transferMethod) ? `unit` : `sh`;
