import { match } from "ts-pattern";

import {
  ExecutionCondition,
  ExecutionMilestone,
  ExecutionWorkflowCondition,
  TransactionExecutionPageWorkflowFragment,
} from "@/gql";
import { Resources } from "@/i18n/locales";

const MILESTONE_OVERRIDE_CONDITIONS_SELLER = [
  ExecutionWorkflowCondition.IssuerApprovalDeclined,
];

const MILESTONE_OVERRIDE_CONDITIONS_BUYER = [
  ExecutionWorkflowCondition.Cancelled,
  ExecutionWorkflowCondition.Rofr,
];

export const hasCondition = (
  conditions: Pick<ExecutionCondition, `condition`>[],
  isBuySide = false,
) =>
  conditions.some(({ condition }) =>
    isBuySide
      ? MILESTONE_OVERRIDE_CONDITIONS_BUYER.includes(condition)
      : MILESTONE_OVERRIDE_CONDITIONS_SELLER.includes(condition),
  );

export const getMilestoneCopy = (milestone: ExecutionMilestone) =>
  match<ExecutionMilestone, keyof Resources[`execution`]>(milestone)
    .with(ExecutionMilestone.Accepted, () => `accepted_milestone_description`)
    .with(ExecutionMilestone.Confirmed, () => `confirmed_milestone_description`)
    .with(ExecutionMilestone.Submitted, () => `submitted_milestone_description`)
    .with(ExecutionMilestone.Approved, () => `approved_milestone_description`)
    .with(ExecutionMilestone.Signed, () => `signed_milestone_description`)
    .otherwise(() => `complete_milestone_description`);

export const isCancelled = (
  conditions: TransactionExecutionPageWorkflowFragment["conditions"],
) =>
  conditions.some(
    (item) => item.condition === ExecutionWorkflowCondition.Cancelled,
  );
