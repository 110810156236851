import { t } from "i18next";
import * as pluralize from "pluralize";
import { useTranslation } from "react-i18next";

import { Badge, Flex, Text } from "@chakra-ui/react";

import {
  FeeBreakdown,
  FocusedShareDetails,
  ListingNumShareAndPriceDetails,
} from "@/components/common";
import {
  ListingPageListingInfoCardDiscussionFragment,
  ListingPageListingInfoCardListingFragment,
} from "@/gql";
import { useCurrentActor, useConfig } from "@/hooks";
import {
  getHasExpired,
  getListingBidCount,
  getListingHasAcceptedBidPrices,
  getListingHasBids,
  getListingNumOfShares,
  getIsHiiveFund,
  shareTypeToString,
  toTimestamp,
  transferMethodToString,
} from "@/utils";

import BrokerStatusTile from "./BrokerStatusTile";
import {
  AcceptedBidSharePriceDetails,
  checkListingTerminalState,
} from "./ListingInfoCard";
import { ListingPartialBidDetails } from "./ListingPartialBidDetails";
import ListingSellerRoundingDisclaimer from "./ListingSellerRoundingDisclaimer";
import PropertyGrid from "./PropertyGrid";
import { getListingStatusText } from "./getListingStatusText";

const OtherDetailsSection = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="listing-notes"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`listing_notes`)}</Text>
        <Text>{listing.otherDetails}</Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const FromHiiveSection = ({ lister = false }: { lister?: boolean }) => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="hiive-listing-text"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`placed_by_hiive`)}</Text>
        <Text>
          {t(
            lister
              ? `broker_lister_listing_placed_by_hiive`
              : `listing_placed_by_hiive_description`,
          )}
        </Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const BidCountSection = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const bidCount = getListingBidCount(listing);

  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection>
      <Flex gap={4} direction="column" align="start">
        <Text textStyle="heading-sm">{t(`activity`)}</Text>
        <Badge variant="grey" as={Text} gap={4}>
          <Text as="strong" textStyle="heading-lg">
            {bidCount}
          </Text>
          {` `}
          {pluralize(t(`bid`), bidCount)}
        </Badge>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const BrokerSharedPropertyGridItems = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const formattedShareType = shareTypeToString(listing.shareTypeV2);
  const formattedTransferMethod = transferMethodToString(
    listing.transferMethod,
  );

  return (
    <>
      <PropertyGrid.Item>
        <PropertyGrid.ItemHeader>{t(`share_type`)}</PropertyGrid.ItemHeader>
        <PropertyGrid.ItemValue>{formattedShareType}</PropertyGrid.ItemValue>
      </PropertyGrid.Item>
      <PropertyGrid.Item>
        <PropertyGrid.ItemHeader>{t(`transfer_type`)}</PropertyGrid.ItemHeader>
        <PropertyGrid.ItemValue>
          {formattedTransferMethod}
        </PropertyGrid.ItemValue>
      </PropertyGrid.Item>
    </>
  );
};

const BrokerListerPropertyGrid = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const isListingInTerminalState = checkListingTerminalState(listing.state);

  const actualNumberOfShares = getListingNumOfShares(listing, false);

  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    listing.sellerCommission || {};

  return (
    <PropertyGrid.Grid>
      <BrokerSharedPropertyGridItems listing={listing} />
      {!isListingInTerminalState && (
        <PropertyGrid.GridItem colSpan={2}>
          <FeeBreakdown
            numShares={actualNumberOfShares}
            pricePerShare={listing.listingPricePerShare}
            feeDiscountApplications={listing.feeDiscountApplications}
            flatFeeAmount={flatFeeAmount}
            commissionAmount={commissionAmount}
            feeDiscountAmount={feeDiscountAmount}
            netFees={netFees}
            combineHiiveFeesLabel={t(`hiive_co_broker_fee`)}
            combineHiiveFeesTooltip={t(`hiive_co_broker_fee_tooltip`)}
            netProceedsTitle={t(`broker_seller_proceeds`)}
            netProceedsSubtitle={t(`gross_of_your_brokerage_fees`)}
            netProceedsTooltip={t(`broker_seller_proceeds_tooltip`)}
            disclaimerVariant="listingBroker"
          />
        </PropertyGrid.GridItem>
      )}
    </PropertyGrid.Grid>
  );
};

const BrokerListerStatusTile = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const actor = useCurrentActor();
  const { stnLoiHoursValidHours } = useConfig() ?? {};

  const statusText = getListingStatusText(listing, {
    actor,
    stnLoiHoursValidHours,
  });

  const hasExpired = getHasExpired(listing.expireAt);
  const formattedExpireAt = !!listing.expireAt
    ? toTimestamp(listing.expireAt)
    : ` - `;

  const formattedPlacedAt = !!listing.placedAt
    ? toTimestamp(listing.placedAt)
    : ` - `;

  const showStatusTile = !!listing.placedAt || !!listing.expireAt;

  if (!showStatusTile) {
    return null;
  }

  return (
    <BrokerStatusTile.Tile>
      {!!listing.placedAt && (
        <BrokerStatusTile.TileRow>
          <BrokerStatusTile.TileHeader>Placed</BrokerStatusTile.TileHeader>
          <Text>{formattedPlacedAt}</Text>
        </BrokerStatusTile.TileRow>
      )}
      {!!listing.expireAt && (
        <BrokerStatusTile.TileRow>
          <BrokerStatusTile.TileHeader>
            {hasExpired ? `Expired` : `Expires`}
          </BrokerStatusTile.TileHeader>
          <Text>{formattedExpireAt}</Text>
        </BrokerStatusTile.TileRow>
      )}
      {statusText && (
        <BrokerStatusTile.TileRow>
          <BrokerStatusTile.TileHeader>
            {t(`status`)}
          </BrokerStatusTile.TileHeader>
          <Text>{statusText}</Text>
        </BrokerStatusTile.TileRow>
      )}
    </BrokerStatusTile.Tile>
  );
};

export const BrokerListerInfoCard = ({
  listing,
  discussion,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
  readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
}) => {
  const { t } = useTranslation();

  const hasAcceptedBidPrices = getListingHasAcceptedBidPrices(listing);
  const hasBids = getListingHasBids(listing);

  const title = !!discussion
    ? t(`inquiry_for_listing_display_id`, { displayId: listing.displayId })
    : t(`listing_display_id`, { displayId: listing.displayId });

  const isListingV1 = listing.version === 1;

  const showFromHiive =
    listing.fromHiive && !getIsHiiveFund(listing.transferMethod);

  return (
    <FocusedShareDetails.Card variant={discussion ? `discussion` : `listing`}>
      <FocusedShareDetails.Header title={title} company={listing.company}>
        <FocusedShareDetails.HeaderCard>
          <ListingNumShareAndPriceDetails listing={listing} />
          {hasAcceptedBidPrices && (
            <AcceptedBidSharePriceDetails listing={listing} />
          )}
        </FocusedShareDetails.HeaderCard>
        <ListingSellerRoundingDisclaimer />
      </FocusedShareDetails.Header>
      <FocusedShareDetails.Content>
        <FocusedShareDetails.ContentSection>
          <BrokerListerStatusTile listing={listing} />
        </FocusedShareDetails.ContentSection>
        <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
          <Flex direction="column" gap={8}>
            <BrokerListerPropertyGrid listing={listing} />
            {isListingV1 && <ListingPartialBidDetails listing={listing} />}
          </Flex>
        </FocusedShareDetails.ContentSection>
        {listing.otherDetails && <OtherDetailsSection listing={listing} />}
        {showFromHiive && <FromHiiveSection lister />}
        {hasBids && <BidCountSection listing={listing} />}
      </FocusedShareDetails.Content>
    </FocusedShareDetails.Card>
  );
};
