import { AnimatePresence } from "framer-motion";
import { intersection } from "lodash";

import { HStack } from "@chakra-ui/react";

import {
  FadeInOut,
  MessageNotificationCount,
  TotalCount,
  WithQuery,
} from "@/components/common";
import { useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery } from "@/gql";

import { useUnaccreditedSellerInlineNotifications } from "./useUnaccreditedSellerInlineNotifications";

const UnaccreditedSellerTransactionNotificationsCountV2 = ({
  id,
}: {
  id: string;
}) => {
  const { newAcceptedBidIds, newMessagesCountByInquiryId } =
    useUnaccreditedSellerInlineNotifications();

  const query =
    useUnaccreditedSellerByCompanyIdTransactionNotificationsCountQuery({
      variables: { id },
    });

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          companyById: {
            activity: {
              myOpenTransactions: pendingSales,
              myCompletedTransactions: completedSales,
            },
          },
        },
      }) => {
        const inquiryIds = pendingSales.flatMap(({ bid: { discussion } }) => {
          if (!discussion) return [];
          return [discussion.id];
        });

        const numNewMessages = inquiryIds.reduce(
          (prev, curr) => prev + (newMessagesCountByInquiryId[curr] || 0),
          0,
        );
        const hasNewMessages = numNewMessages > 0;

        const acceptedBidIds = [...pendingSales].flatMap(({ bid }) => {
          if (!bid) return [];
          return [bid.id];
        });

        const successfullyClosedCompletedSales = completedSales.filter(
          ({ cancelledAt, issuerApprovalDeclinedAt }) =>
            !cancelledAt && !issuerApprovalDeclinedAt,
        );

        const totalTransactions =
          pendingSales.length + successfullyClosedCompletedSales.length;
        const hasTransactions = totalTransactions > 0;

        const numNewAcceptedBids = intersection(
          newAcceptedBidIds,
          acceptedBidIds,
        ).length;
        const hasNewAcceptedBids = numNewAcceptedBids > 0;

        return (
          <HStack>
            <AnimatePresence mode="wait">
              {hasNewMessages && (
                <FadeInOut key={numNewMessages}>
                  <MessageNotificationCount count={numNewMessages} />
                </FadeInOut>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {hasTransactions && (
                <FadeInOut>
                  <TotalCount
                    totalCount={totalTransactions}
                    hasNewNotifications={hasNewAcceptedBids}
                  />
                </FadeInOut>
              )}
            </AnimatePresence>
          </HStack>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerTransactionNotificationsCountV2;
