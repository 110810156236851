import { match } from "ts-pattern";

import {
  UserWithInstitutionFragment,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";

export const getTransactionIsBuySide = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: Pick<
    TransactionExecutionPageTransactionFragment,
    "buyerId" | "buyerBrokerId" | "buyerInstitutionId"
  >;
}) =>
  actor.id === transaction?.buyerId ||
  actor.id === transaction?.buyerBrokerId ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.buyerInstitutionId);

export const getTransactionIsSellSide = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: Pick<
    TransactionExecutionPageTransactionFragment,
    "sellerId" | "sellerBrokerId" | "sellerInstitutionId"
  >;
}) =>
  actor.id === transaction.sellerId ||
  actor.id === transaction.sellerBrokerId ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.sellerInstitutionId);

export const getTransactionCounterpartyName = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const isSellSide = getTransactionIsSellSide({ actor, transaction });
  const isBuySide = getTransactionIsBuySide({ actor, transaction });

  return match({ isSellSide, isBuySide })
    .with({ isSellSide: true }, () => {
      if (!!transaction.buyerBroker) {
        return transaction.buyerBroker.name;
      }

      if (!!transaction.buyerInstitution) {
        return transaction.buyerInstitution.legalName;
      }

      if (!!transaction.representedBuyer) {
        return `${transaction.representedBuyer.firstName} ${transaction.representedBuyer.lastName}`;
      }

      return transaction.buyer.name;
    })
    .with({ isBuySide: true }, () => {
      if (!!transaction.sellerBroker) {
        return transaction.sellerBroker.name;
      }

      if (!!transaction.sellerInstitution) {
        return transaction.sellerInstitution.legalName;
      }

      if (!!transaction.representedSeller) {
        return `${transaction.representedSeller.firstName} ${transaction.representedSeller.lastName}`;
      }

      return transaction.seller.name;
    })
    .otherwise(() => {
      throw new Error(`Unrecognized party`);
    });
};

export const getCounterPartyCountry = (
  transaction: Pick<
    TransactionExecutionPageTransactionFragment,
    `buyer` | `seller` | `buyerInstitution` | `sellerInstitution`
  >,
  isBuyer: boolean,
) =>
  match({ isBuyer })
    .with({ isBuyer: false }, () => {
      if (transaction.buyerInstitution) {
        return {
          country: transaction.buyerInstitution?.country?.name,
          name: transaction.buyerInstitution?.legalName,
        };
      }

      return {
        country: transaction.buyer?.country?.name,
        name: transaction.buyer?.name,
      };
    })
    .otherwise(() => {
      if (transaction.sellerInstitution) {
        return {
          country: transaction.sellerInstitution?.country?.name,
          name: transaction.sellerInstitution?.legalName,
        };
      }

      return {
        country: transaction.seller?.country?.name,
        name: transaction.seller?.name,
      };
    });

export const getSuggestedLegalName = (actor: UserWithInstitutionFragment) => {
  if (actor.institutionId) {
    return actor.institution?.legalName;
  }

  return actor.verifiedFullName ?? actor.name;
};
