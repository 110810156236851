import { TabPanel, TabPanelProps, VStack } from "@chakra-ui/react";

import {
  ActiveTransactionItemTransactionFragment,
  ExecutionTaskStatus,
} from "@/gql";

import ActiveTransactionItemCard from "./ActiveTransactionItemCard";
import EmptyActiveTransactionTabPanel from "./EmptyActiveTransactionTabPanel";

type ActiveTransactionTabPanelProps = {
  transactions?: ActiveTransactionItemTransactionFragment[];
} & TabPanelProps;

const ActiveTransactionTabPanel = ({
  transactions = [],
  ...props
}: ActiveTransactionTabPanelProps) => {
  const showTransactions = transactions?.length > 0;

  return (
    <TabPanel as={VStack} gap={4} p={0} {...props}>
      {showTransactions &&
        transactions?.map((transaction) => (
          <ActiveTransactionItemCard
            key={transaction.id}
            taskCount={
              transaction.myTasks.filter(
                (task) => task.status === ExecutionTaskStatus.InProgress,
              )?.length
            }
            transaction={transaction}
          />
        ))}
      {!showTransactions && <EmptyActiveTransactionTabPanel />}
    </TabPanel>
  );
};

export default ActiveTransactionTabPanel;
