/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useField } from "formik";
import i18next from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  GridItem,
  HStack,
  ModalBody,
  Radio,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  CompanyRestrictedAlert,
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  FormRadioInput,
  OtherDetailsInput,
  StepPropsV2,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  ShareSeriesMakeupElement,
  TransferMethodInput,
} from "@/components/postings";
import { ListingOtherDetailsTooltip } from "@/components/tooltip";
import {
  ListingState,
  ModifyListingModalListingFragment,
  ShareSeries,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCustomToast, useModal, useStepValidator } from "@/hooks";
import {
  useListingNotesUpdates,
  useRestrictiveInReview,
  useRsuRestrictionAndEligibleBuyers,
} from "@/hooks/featureFlags";
import {
  checkContainsStockUnits,
  checkTransferMethodManual,
  fromBool,
  toBool,
} from "@/utils";
import { pipe } from "@/utils/pipe";

import {
  ModifyListingModalStepFormContext,
  useModifyListingModalStepFormContext,
} from "./ModifyListingModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { ModifyListingModalFormValues } from "./types";

type ValidationSchemaOptions = {
  readonly canSellSharesDirectlyOnRestrictedCompany: boolean;
};

export const validationSchema = ({
  canSellSharesDirectlyOnRestrictedCompany,
}: ValidationSchemaOptions) =>
  Yup.object().shape({
    transferMethod: Yup.string().required(`Required`),
    hasExpirationDate: Yup.bool().required(`Required`),
    expireAt: Yup.string()
      .nullable()
      .test({
        name: `checkRequired`,
        message: `Required`,
        test: function () {
          if (this.parent.hasExpirationDate) {
            return !!this.parent.expireAt;
          }
          return true;
        },
      }),
    otherDetails: Yup.string().nullable(),
    muteNotifyWatchers: Yup.boolean().nullable(),
    manualSigningProcedure: Yup.boolean(),
    userPlacedExplanation: Yup.string()
      .nullable()
      .when(`transferMethod`, {
        is: () => canSellSharesDirectlyOnRestrictedCompany,
        then: (schema) => schema.required(i18next.t(`please_enter_a_reason`)),
      }),
  });

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, ModifyListingModalFormValues> {
  readonly listing: ModifyListingModalListingFragment;
  readonly actor: UserWithInstitutionFragment;
}

const RESTRICTED_SHARE_SERIES = [
  ShareSeries.Common,
  ShareSeries.Options,
  ShareSeries.RestrictedStockUnits,
];

const AdditionalDetails = ({
  actor,
  values,
  listing,
  isSubmitting,
  stepRouter,
}: AdditionalDetailsProps) => {
  const rsuRestrictionAndEligibleBuyers = useRsuRestrictionAndEligibleBuyers();
  const restrictiveInReview = useRestrictiveInReview();
  const isListingNotesUpdatesEnabled = useListingNotesUpdates();

  const { closeModal } = useModal();
  const { successToast } = useCustomToast();
  const { stepControls } = stepRouter;
  const { t } = useTranslation();
  const { t: companyT } = useTranslation(`company`);

  const { t: listingsT } = useTranslation(`listings`);

  const { transferMethod, shareSeriesMakeup } = values;
  const { company } = listing;
  const { isHiiveUser } = actor;

  const { submitMutation } = useModifyListingModalStepFormContext();

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const isCompanyRestricted = !!restrictiveInReview && company?.restricted;

  const isCompanyShareSeriesRestricted = useMemo(() => {
    const [shareSeriesElement] = shareSeriesMakeup;

    if (!company || !shareSeriesElement) {
      return false;
    }

    const { shareSeries } = shareSeriesElement;

    const isShareSeriesRestricted =
      !!shareSeries && RESTRICTED_SHARE_SERIES.includes(shareSeries);

    const isRestricted = isShareSeriesRestricted && isCompanyRestricted;

    return isRestricted && !isHiiveUser;
  }, [restrictiveInReview, company, shareSeriesMakeup]);

  const canSellSharesDirectlyOnRestrictedCompany = useMemo(() => {
    const isDirectTransferMethod = transferMethod === TransferMethod.Direct;

    if (isDirectTransferMethod && isCompanyShareSeriesRestricted) {
      return true;
    }

    return false;
  }, [isCompanyShareSeriesRestricted, transferMethod]);

  const onSuccess = () =>
    submitMutation().then((value) => {
      const modifiedListing = value.modifyListing.listing;

      const isModifiedInReviewListingLive =
        listing.state === ListingState.InReview &&
        modifiedListing?.state === ListingState.Open;
      successToast(`Listing modified.`);

      // if user modifies listing so that the state is open from an in-review listing, show the success modal again
      if (isModifiedInReviewListingLive) {
        stepControls.nextStep();
      } else closeModal();
    });

  useStepValidator({
    Context: ModifyListingModalStepFormContext,
    stepKey: stepKeys.modifyListingAdditionalDetails,
    validator: {
      validationSchema: validationSchema({
        canSellSharesDirectlyOnRestrictedCompany,
      }),
      onSuccess,
    },
    values,
  });

  const [_field, _, { setValue: setManualSigningProcedure }] = useField(
    `manualSigningProcedure`,
  );
  const [, , { setValue: setUserPlacedExplanation }] = useField(
    `userPlacedExplanation`,
  );

  const isTransferMethodManual = checkTransferMethodManual(transferMethod);

  const hasRestrictedStockUnit = useMemo(() => {
    const containsRestrictedStockUnit = pipe(shareSeriesMakeup)
      .step((shareSeriesElements) =>
        shareSeriesElements.filter(
          (element): element is ShareSeriesMakeupElement => !!element,
        ),
      )
      .step((shareSeries) =>
        checkContainsStockUnits(shareSeries, [
          ShareSeries.RestrictedStockUnits,
        ]),
      )
      .end();

    return !!rsuRestrictionAndEligibleBuyers && containsRestrictedStockUnit;
  }, [rsuRestrictionAndEligibleBuyers, shareSeriesMakeup]);

  const handleChangeTransferMethod = (transferMethod: TransferMethod) => {
    const restricted = company?.restricted;
    const isCompanyRestricted = !!restrictiveInReview && restricted;

    if (checkTransferMethodManual(transferMethod)) {
      return setManualSigningProcedure(true);
    }

    if (isCompanyRestricted) {
      return setUserPlacedExplanation(``);
    }

    return setManualSigningProcedure(false);
  };

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {isCompanyShareSeriesRestricted && company && (
            <GridItem colSpan={2}>
              <CompanyRestrictedAlert companyName={company.name} />
            </GridItem>
          )}
          {!isPartiallySold && (
            <GridItem colSpan={2} data-testid="transfer-method-input">
              <TransferMethodInput
                onChange={handleChangeTransferMethod}
                name="transferMethod"
                label="How will these be transferred?"
                isDisabled={hasRestrictedStockUnit}
              />
            </GridItem>
          )}
          {canSellSharesDirectlyOnRestrictedCompany && (
            <GridItem colSpan={2} data-testid="user-placed-explanation-input">
              <OtherDetailsInput
                name="userPlacedExplanation"
                label={companyT(`why_do_you_believe_direct`)}
                placeholder={companyT(`please_explain_how_direct_example`)}
                helperText={companyT(`not_visible_to_other_parties`)}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <FormRadioInput
              label={t(`end_date_question`)}
              name="hasExpirationDate"
              mapper={{ from: fromBool, to: toBool }}
              data-testid="modify-listing-end-date-question"
              tooltipContent={t(
                `listing_additional_details_expiry_date_tooltip`,
              )}
            >
              <HStack spacing={12}>
                <Radio variant="base" value="true">
                  {t(`yes`)}
                </Radio>
                <Radio variant="base" value="false">
                  {t(`no`)}
                </Radio>
              </HStack>
            </FormRadioInput>
          </GridItem>
          {values.hasExpirationDate && (
            <GridItem colSpan={2}>
              <DateInput
                name="expireAt"
                label={t(`end_date_input_label`)}
                tooltipContent={t(`end_date_input_tooltip`)}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <OtherDetailsInput
              name="otherDetails"
              label="Additional listing notes"
              placeholder="Add details"
              tooltipContent={<ListingOtherDetailsTooltip />}
            />
            {!actor.isHiiveUser && isListingNotesUpdatesEnabled && (
              <Text
                mt={1.5}
                textStyle="text-sm"
              >{listingsT`this_note_will_be_visible_to_everyone`}</Text>
            )}
          </GridItem>
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptionsDropdown
                validationSchema={validationSchema({
                  canSellSharesDirectlyOnRestrictedCompany,
                })}
                fieldNames={[`manualSigningProcedure`, `muteNotifications`]}
              >
                <VStack spacing={4} alignItems="flex-start">
                  {!!values.transferMethod && (
                    <CheckboxInput
                      isDisabled={isTransferMethodManual}
                      name="manualSigningProcedure"
                      label={t(`manual_signing_procedure`)}
                    />
                  )}
                  <CheckboxInput
                    name="muteNotifications"
                    label="Do not send notifications about this change."
                  />
                </VStack>
              </HiiveAdvancedOptionsDropdown>
            </GridItem>
          )}
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[ModifyListing/AdditionalDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveBackButton
          observabilityLabel="[ModifyListing/AdditionalDetails/Back]"
          onClick={stepControls.previousStep}
        />
        <HiiveSubmitButton
          observabilityLabel="[ModifyListing/AdditionalDetails/Submit]"
          isLoading={isSubmitting}
          submitText="Modify Listing"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default withCurrentActor(AdditionalDetails);
