import { AnimatePresence, motion } from "framer-motion";
import pluralize from "pluralize";
import { useEffect, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";

import {
  Box,
  SimpleGrid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  Button,
  VStack,
  Divider,
  Link,
} from "@chakra-ui/react";

import { MarkdownDocument, SensitiveText, Skeleton } from "@/components/common";
import {
  BuyerCommissionInfoFragment,
  Commission,
  BandWithCommissionFragment,
  VirtualCommission,
  useCommissionStructureBandCommissionsLazyQuery,
} from "@/gql";
import { useDebounce } from "@/hooks";
import { constants } from "@/utils";
import {
  currencyValue,
  formatCurrency,
  formatPricePerShare,
  formatShares,
} from "@/utils/format";

import { BOX_STYLES, FIRST_ITEM_STYLES, LAST_ITEM_STYLES } from "./constants";

interface NormalizedCommission {
  totalValue: string;
  netFees: string;
  netValue: string;
  feeDiscountAmount: string;
}

function mapToNormalizedCommission(
  buyerCommissionInfo?: BuyerCommissionInfoFragment | null,
  commission?: VirtualCommission | Commission | null,
  numShares = 0,
  pricePerShare = 0,
): NormalizedCommission {
  if (buyerCommissionInfo) {
    return {
      totalValue: buyerCommissionInfo.totalValue,
      netFees: buyerCommissionInfo.netFees,
      netValue: buyerCommissionInfo.netValue,
      feeDiscountAmount: `0`,
    };
  }

  const totalValue = numShares * pricePerShare;
  const netFees = commission?.netFees ?? 0;
  const netValue = totalValue + netFees;
  const feeDiscountAmount = commission?.feeDiscountAmount ?? 0;

  return {
    totalValue: totalValue.toString(),
    netFees: netFees.toString(),
    netValue: netValue.toString(),
    feeDiscountAmount: feeDiscountAmount.toString(),
  };
}

function CommissionBand({ band }: { band: BandWithCommissionFragment }) {
  const { commission, bandText } = band;
  const color = commission === 0 ? `grey.400` : undefined;

  return (
    <HStack justifyContent="space-between" width="100%">
      <SensitiveText color={color} textStyle="text-sm">
        <MarkdownDocument markdown={bandText} />
      </SensitiveText>
      <SensitiveText color={color}>
        {formatCurrency(commission, {
          fromCents: true,
        })}
      </SensitiveText>
    </HStack>
  );
}

interface FeeExpandedProps {
  normalizedCommission: NormalizedCommission;
  isLoading?: boolean;
  bands?: BandWithCommissionFragment[];
}

function FeeExpanded({
  normalizedCommission,
  isLoading,
  bands = [],
}: FeeExpandedProps) {
  const { t } = useTranslation();
  const { netFees, feeDiscountAmount } = normalizedCommission;

  const sortedBands = useMemo(
    () =>
      bands.toSorted((a, b) => {
        const lowerA = a.lower ?? 0;
        const lowerB = b.lower ?? 0;
        return lowerA - lowerB;
      }),
    [bands],
  );

  const showFeeDiscountRow = feeDiscountAmount !== `0`;

  return (
    <VStack spacing={2} alignItems="flex-start" pt={2}>
      <Text textStyle="text-xs">
        {bands?.length > 1 && t(`buyer_breakdown_description_multiple_bands`)}
      </Text>
      {isLoading ? (
        <HStack justifyContent="space-between" width="100%">
          <Skeleton w={40} h={4} my={1} />
          <Skeleton w={40} h={4} my={1} />
        </HStack>
      ) : (
        sortedBands.map((band) => <CommissionBand key={band.id} band={band} />)
      )}
      {showFeeDiscountRow && (
        <>
          <Divider pr={6} my={2} borderColor="grey.200" />
          <HStack justifyContent="space-between" width="100%">
            <Text textStyle="heading-2xs">{t(`discount`)}</Text>
            <SensitiveText>
              {`(${formatCurrency(feeDiscountAmount, {
                fromCents: true,
              })})`}
            </SensitiveText>
          </HStack>
        </>
      )}
      <Divider pr={6} my={2} borderColor="grey.200" />
      <HStack justifyContent="space-between" width="100%">
        <Text textStyle="heading-2xs">{t(`total_fees`)}</Text>
        {isLoading ? (
          <Skeleton w={40} h={4} my={1} />
        ) : (
          <SensitiveText>
            {formatCurrency(netFees, {
              fromCents: true,
            })}
          </SensitiveText>
        )}
      </HStack>
    </VStack>
  );
}

interface BuyerCommissionBreakdownProps {
  buyerCommissionInfo?: BuyerCommissionInfoFragment | null;
  commission?: VirtualCommission | Commission | null;
  numShares?: number;
  pricePerShare?: number;
  isLoadingCommission?: boolean;
  buyerCommissionStructureId?: string | null;
}

export function BuyerCommissionBreakdown({
  buyerCommissionInfo,
  commission,
  numShares = 0,
  pricePerShare = 0,
  isLoadingCommission = false,
  buyerCommissionStructureId,
}: BuyerCommissionBreakdownProps) {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  const { debounce, isDebouncing } = useDebounce();

  const [load, { data, loading: isLoadingBands }] =
    useCommissionStructureBandCommissionsLazyQuery({
      fetchPolicy: `cache-and-network`,
    });

  const normalizedCommission = useMemo(
    () =>
      mapToNormalizedCommission(
        buyerCommissionInfo,
        commission,
        numShares,
        pricePerShare,
      ),
    [buyerCommissionInfo, commission, numShares, pricePerShare],
  );

  const { totalValue, netFees, netValue } = normalizedCommission;

  useEffect(() => {
    if (!buyerCommissionStructureId || !numShares || !pricePerShare) return;

    debounce(() => {
      const variables = {
        buyerCommissionStructureId,
        totalShares: numShares,
        pricePerShare: currencyValue(pricePerShare, { fromCents: true }),
      };

      load({ variables });
    }, constants.commission_calculation_debounce_time);
  }, [buyerCommissionStructureId, numShares, pricePerShare]);

  const bands = data?.buyerCommissionInfo?.commissionStructure?.bands ?? [];

  const hasMultipleBands = bands?.length > 1;

  const showLoadingSkeleton =
    isLoadingBands || isLoadingCommission || isDebouncing;

  const showBreakdown = (isOpen || !hasMultipleBands) && !showLoadingSkeleton;

  return (
    <Box>
      <SimpleGrid {...BOX_STYLES} {...FIRST_ITEM_STYLES}>
        <GridItem colSpan={2}>
          <HStack justifyContent="space-between" width="100%">
            <SensitiveText textStyle="heading-2xs">
              {`${formatShares(numShares)} ${pluralize(`share`, numShares)} @ ${formatPricePerShare(pricePerShare)}`}
            </SensitiveText>
            {showLoadingSkeleton ? (
              <Skeleton w={40} h={4} my={1} />
            ) : (
              <SensitiveText>
                {formatCurrency(totalValue, {
                  fromCents: true,
                })}
              </SensitiveText>
            )}
          </HStack>
        </GridItem>
      </SimpleGrid>

      <SimpleGrid {...BOX_STYLES} bg="grey.50">
        <GridItem colSpan={2}>
          <HStack justifyContent="space-between">
            <HStack>
              <Text textStyle="heading-2xs">{t(`transaction_fee`)}</Text>

              {hasMultipleBands && (
                <Button variant="text-ghost" onClick={onToggle}>
                  <Text textStyle="text-xs">
                    {isOpen ? t(`hide_breakdown`) : t(`show_breakdown`)}
                  </Text>
                </Button>
              )}
            </HStack>

            {showLoadingSkeleton ? (
              <Skeleton
                w={40}
                h={4}
                my={1}
                visibility={showBreakdown ? `hidden` : `visible`}
              />
            ) : (
              <SensitiveText
                lineHeight="inherit"
                visibility={showBreakdown ? `hidden` : `visible`}
              >
                {formatCurrency(netFees, {
                  fromCents: true,
                })}
              </SensitiveText>
            )}
          </HStack>
          <AnimatePresence>
            {showBreakdown && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: `auto` }}
                exit={{ opacity: 0, height: 0 }}
              >
                <FeeExpanded
                  normalizedCommission={normalizedCommission}
                  isLoading={showLoadingSkeleton}
                  bands={bands}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </GridItem>
      </SimpleGrid>

      <SimpleGrid {...BOX_STYLES} bg="teal.25">
        <GridItem colSpan={2}>
          <HStack justifyContent="space-between" width="100%">
            <Text textStyle="heading-2xs">{t(`total_amount`)}</Text>
            {showLoadingSkeleton ? (
              <Skeleton w={40} h={4} my={1} />
            ) : (
              <SensitiveText>
                {formatCurrency(netValue, {
                  fromCents: true,
                })}
              </SensitiveText>
            )}
          </HStack>
        </GridItem>
      </SimpleGrid>

      <SimpleGrid {...BOX_STYLES} {...LAST_ITEM_STYLES}>
        <GridItem colSpan={2}>
          <Text textStyle="text-xs">
            <Trans
              i18nKey="buyer_breakdown_disclaimer_text"
              components={{
                a: (
                  <Link
                    fontWeight={500}
                    href={`${constants.customer_relationship_summary_url}`}
                    target="_blank"
                  />
                ),
              }}
            />
          </Text>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
}
