import pluralize from "pluralize";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Badge,
  Box,
  Flex,
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCardListingSolicitedCardBody,
  BuyerCommissionBreakdown,
  FeeBreakdown,
  FocusedShareDetails,
  ListingNumShareAndPriceDetails,
  MailtoLink,
  MarkdownDocument,
  SensitiveText,
  Tile,
  TileHeader,
} from "@/components/common";
import {
  ConditionallySoldOutBadge,
  findHighestAcceptedBidSharePrice,
  findLowestAcceptedBidSharePrice,
  hasMultipleAcceptedBidSharePrices,
  hasSingleAcceptedBidSharePrice,
  PartiallySoldBadge,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import { ConfigProvider } from "@/components/providers";
import {
  AcceptedBidSharePriceDetailsListingFragment,
  InvestorType,
  ListingPageListingInfoCardDiscussionFragment,
  ListingPageListingInfoCardListingFragment,
  ListingPermission,
  ListingState,
  UserPermissionV2,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  iHaveEntityPermission,
  useCurrentActor,
  useIHavePermission,
} from "@/hooks";
import {
  useRestrictiveInReview,
  useBuySideFees,
  useListingNotesUpdates,
} from "@/hooks/featureFlags";
import {
  abbrCountLabel,
  getBidLabel,
  calculateListingNumSharesSoldConditionally,
  constants,
  getCountLabel,
  formatPricePerShare,
  formatShares,
  getAvailableListingActions,
  getIsBroker,
  getIsBrokerForListing,
  getIsConditionallySoldListing,
  getIsPartiallySoldListing,
  getIsSellerForListing,
  getNumOfShares,
  getShowListingSolicitedState,
  getIsFund,
  getIsHiiveFund,
  shareTypeToString,
  transferMethodToString,
  getIsFeeDiscountAdmin,
} from "@/utils";

import { BrokerListerInfoCard } from "./BrokerInfoCard";
import { ListingActionsTile } from "./ListingActionsTile";
import ListingBuyerRoundingDisclaimer from "./ListingBuyerRoundingDisclaimer";
import ListingHiiveUserTile from "./ListingHiiveUserTile";
import { ListingPartialBidDetails } from "./ListingPartialBidDetails";
import ListingSellerRoundingDisclaimer from "./ListingSellerRoundingDisclaimer";
import ListingStatusTile from "./ListingStatusTile";
import ListingViewerRoundingDisclaimer from "./ListingViewerRoundingDisclaimer";

const TERMINAL_LISTING_STATES = [
  ListingState.Closed,
  ListingState.ConditionallySold,
  ListingState.Expired,
  ListingState.Rejected,
  ListingState.Withdrawn,
];

export function checkListingTerminalState(state: ListingState) {
  return TERMINAL_LISTING_STATES.includes(state);
}

const SensitivePropertyGridItem = ({
  property,
  value,
  gridTemplateColumns = {
    base: `minmax(6rem, 1fr) 1fr`,
    md: `minmax(6rem, auto) 1fr`,
  },
  forceSensitive,
  ...gridItemProps
}: GridItemProps & {
  readonly property: string;
  readonly value: string | number;
  readonly gridTemplateColumns?: GridProps["gridTemplateColumns"];
  readonly forceSensitive?: boolean;
}) => (
  <GridItem {...gridItemProps}>
    <Tile
      h="full"
      display="grid"
      gridTemplateColumns={gridTemplateColumns}
      data-testid={property}
    >
      <TileHeader w="full" minW={24}>
        {property}
      </TileHeader>
      {forceSensitive ? (
        <Text cursor="default" w="full">
          <Box as="span" layerStyle="sensitive" data-dd-privacy="mask">
            {value}
          </Box>
        </Text>
      ) : (
        <SensitiveText w="full">{value}</SensitiveText>
      )}
    </Tile>
  </GridItem>
);

interface PropertyGridProps {
  readonly listing: ListingPageListingInfoCardListingFragment;
  readonly isSeller: boolean;
  readonly isBuyerCommissionVisible: boolean;
}

const PropertyGridV1 = ({
  listing,
  isSeller,
  isBuyerCommissionVisible,
}: PropertyGridProps) => {
  const { t } = useTranslation();
  const isListingInTerminalState = checkListingTerminalState(listing.state);

  const actualNumberOfShares = getNumOfShares(listing, false);

  const {
    commissionAmount,
    flatFeeAmount,
    feeDiscountAmount,
    netFees,
    previousCommission,
  } = listing.sellerCommission || {};

  const { buyerCommission } = listing;

  return (
    <Grid gap={3} gridTemplateColumns={{ base: `1fr`, md: `repeat(2, 1fr)` }}>
      <SensitivePropertyGridItem
        property="Share Type"
        value={shareTypeToString(listing.shareTypeV2)}
      />

      <SensitivePropertyGridItem
        property="Transfer Type"
        value={transferMethodToString(listing.transferMethod)}
      />

      {!isListingInTerminalState && isBuyerCommissionVisible && (
        <GridItem colSpan={{ base: 1, sm: 2 }}>
          <Text textStyle="heading-lg" mb={2}>
            {t(`buyer`)}
          </Text>
          <BuyerCommissionBreakdown
            numShares={actualNumberOfShares ?? 0}
            pricePerShare={listing.listingPricePerShare ?? 0}
            commission={buyerCommission}
            buyerCommissionStructureId={
              buyerCommission?.commissionStructure?.id
            }
          />
        </GridItem>
      )}

      {!isListingInTerminalState && isSeller && (
        <GridItem colSpan={{ base: 1, sm: 2 }}>
          {isBuyerCommissionVisible && (
            <Text textStyle="heading-lg" mb={2}>
              {t(`seller`)}
            </Text>
          )}
          <FeeBreakdown
            numShares={actualNumberOfShares}
            pricePerShare={listing.listingPricePerShare}
            feeDiscountApplications={listing.feeDiscountApplications}
            flatFeeAmount={flatFeeAmount}
            commissionAmount={commissionAmount}
            feeDiscountAmount={feeDiscountAmount}
            netFees={netFees}
            disclaimerVariant="listingSeller"
            previousCommission={previousCommission}
          />
        </GridItem>
      )}
    </Grid>
  );
};

export const AcceptedBidSharePriceDetails = ({
  listing,
}: {
  readonly listing: AcceptedBidSharePriceDetailsListingFragment;
}) => {
  const { acceptedBidPrices, transferMethod } = listing;

  const numSharesSoldConditionally =
    calculateListingNumSharesSoldConditionally(listing);

  const shareTextAbbreviation = abbrCountLabel(listing.transferMethod);
  const shareText = `${getCountLabel(listing.transferMethod)}s`;

  return (
    <Stack
      direction={{ base: `column`, sm: `row` }}
      w="full"
      wrap="wrap"
      textAlign={{ base: `center`, sm: `left` }}
      borderTopColor="grey.100 !important"
      borderTop="1px"
      align="center"
      justify="center"
      pt={3}
      spacing={{ base: 2, sm: 3 }}
    >
      {hasMultipleAcceptedBidSharePrices(acceptedBidPrices) && (
        <SensitiveText color="grey.700" textStyle="text-sm">
          <Trans
            ns="listings"
            i18nKey="listing_sold_at_range"
            components={{ b: <Text as="span" textStyle="heading-xs" /> }}
            values={{
              numShares: formatShares(numSharesSoldConditionally),
              listingFraction: shareText,
              minPps: formatPricePerShare(
                findLowestAcceptedBidSharePrice(acceptedBidPrices),
              ),
              maxPps: formatPricePerShare(
                findHighestAcceptedBidSharePrice(acceptedBidPrices),
              ),
              listingFractionAbbreviation: shareTextAbbreviation,
            }}
          />
        </SensitiveText>
      )}
      {hasSingleAcceptedBidSharePrice(acceptedBidPrices) && (
        <SensitiveText color="grey.700" textStyle="text-sm">
          <Trans
            ns="listings"
            i18nKey="listing_sold_at"
            components={{ b: <Text as="span" textStyle="heading-xs" /> }}
            values={{
              numShares: formatShares(numSharesSoldConditionally),
              listingFraction: shareText,
              pps: formatPricePerShare(acceptedBidPrices[0]),
              listingFractionAbbreviation: shareTextAbbreviation,
            }}
          />
        </SensitiveText>
      )}
      {getIsPartiallySoldListing(listing) && (
        <PartiallySoldBadge transferMethod={transferMethod} />
      )}
      {getIsConditionallySoldListing(listing) && (
        <ConditionallySoldOutBadge transferMethod={transferMethod} />
      )}
    </Stack>
  );
};

const RoundingDisclaimer = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const actor = useCurrentActor();
  const isSeller = getIsSellerForListing(actor, listing);

  const hasPlaceBidPermission = useIHavePermission(UserPermissionV2.PlaceBid);
  const canPlaceBidOnListing = iHaveEntityPermission(
    listing,
    ListingPermission.PlaceBid,
  );

  const shareText = getCountLabel(listing.transferMethod);

  const canActOnListingAsBuyer = hasPlaceBidPermission && canPlaceBidOnListing;

  return match({ isSeller, canActOnListingAsBuyer })
    .with({ isSeller: true }, () => <ListingSellerRoundingDisclaimer />)
    .with({ isSeller: false, canActOnListingAsBuyer: true }, () => (
      <ListingBuyerRoundingDisclaimer />
    ))
    .with({ isSeller: false, canActOnListingAsBuyer: false }, () => (
      <ListingViewerRoundingDisclaimer shareText={shareText} />
    ))
    .exhaustive();
};

interface ListingInfoCardProps {
  readonly listing: ListingPageListingInfoCardListingFragment;
  readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
}

const ListingInfoCardV1 = ({ listing, discussion }: ListingInfoCardProps) => {
  const actor = useCurrentActor();
  const restrictiveInReview = useRestrictiveInReview();
  const isListingNotesUpdatesEnabled = useListingNotesUpdates();

  const { canActOnListing } = getAvailableListingActions(
    listing,
    !!restrictiveInReview,
  );

  const { t } = useTranslation();
  const { t: tListings } = useTranslation(`listings`);

  const bidCount = listing.numActiveBids + listing.numCounteredBids;
  const isSeller = getIsSellerForListing(actor, listing);

  const buySideFeesEnabled = useBuySideFees();
  const { buyerCommission } = listing;

  const isBuyerCommissionVisible =
    !!buySideFeesEnabled &&
    isSeller &&
    getIsFeeDiscountAdmin(actor) &&
    !!buyerCommission;

  const isListingV1 = listing.version === 1;

  const showStatusTile =
    !!listing.expireAt || listing.state !== ListingState.Open;

  const isBrokerActor = getIsBroker(actor);
  const showListingSolicitedState = getShowListingSolicitedState(
    listing,
    actor,
  );

  const hasAcceptedBidPrices = listing.acceptedBidPrices.length >= 1;
  const canSeeAcceptedBidPrices =
    hasAcceptedBidPrices && !showListingSolicitedState;

  const showFromHiive =
    listing.fromHiive && !getIsHiiveFund(listing.transferMethod);
  const bidCountLabel = getBidLabel(listing.transferMethod).toLowerCase();

  return (
    <FocusedShareDetails.Card variant={discussion ? `discussion` : `listing`}>
      <FocusedShareDetails.Header
        title={
          discussion
            ? `Inquiry for Listing ${listing.displayId}`
            : `Listing ${listing.displayId}`
        }
        company={listing.company}
      >
        <FocusedShareDetails.HeaderCard>
          {showListingSolicitedState ? (
            <ActivityCardListingSolicitedCardBody
              spacing={0}
              p={5}
              alignItems="center"
              textAlign="center"
              company={listing.company}
              data-testid="unquotable-on-hiive-connect"
            />
          ) : (
            <ListingNumShareAndPriceDetails listing={listing} />
          )}
          {canSeeAcceptedBidPrices && (
            <AcceptedBidSharePriceDetails listing={listing} />
          )}
        </FocusedShareDetails.HeaderCard>
        {showListingSolicitedState ? null : (
          <RoundingDisclaimer listing={listing} />
        )}
      </FocusedShareDetails.Header>

      <FocusedShareDetails.Content>
        {(canActOnListing || showStatusTile) && (
          <FocusedShareDetails.ContentSection>
            <Flex direction="column" gap={7}>
              {canActOnListing && <ListingActionsTile listing={listing} />}
              {showStatusTile && !actor.isHiiveUser && (
                <ListingStatusTile listing={listing} />
              )}
              {actor.isHiiveUser && <ListingHiiveUserTile listing={listing} />}
            </Flex>
          </FocusedShareDetails.ContentSection>
        )}

        <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
          <Flex direction="column" gap={isBuyerCommissionVisible ? 4 : 8}>
            <PropertyGridV1
              listing={listing}
              isSeller={isSeller}
              isBuyerCommissionVisible={isBuyerCommissionVisible}
            />
            {isListingV1 && !isBrokerActor && (
              <ListingPartialBidDetails listing={listing} />
            )}
          </Flex>
        </FocusedShareDetails.ContentSection>

        {getIsFund(listing.transferMethod) && (
          <FocusedShareDetails.ContentSection
            p={{ base: 4, md: 6 }}
            pb={5}
            data-testid="hiive-listing-text-for-fund"
          >
            <Flex gap={3} direction="column">
              <Text textStyle="heading-sm">{t(`single_asset_fund`)}</Text>
              <Text>
                <Trans
                  i18nKey="fund_listing_description"
                  t={t}
                  components={[
                    <Link
                      key="guide"
                      href={constants.fund_diligence_guide}
                      fontWeight="medium"
                      target="_blank"
                    />,
                  ]}
                />
              </Text>
            </Flex>
          </FocusedShareDetails.ContentSection>
        )}

        {getIsHiiveFund(listing.transferMethod) && (
          <FocusedShareDetails.ContentSection
            p={{ base: 4, md: 6 }}
            pb={5}
            data-testid="hiive-listing-text-for-hiive-fund"
          >
            <Flex gap={3} direction="column">
              <Text textStyle="heading-sm">{t(`hiive_single_asset_fund`)}</Text>
              <Text>
                {t(`hiive_fund_listing_description`, {
                  companyName: listing.company.name,
                })}
              </Text>
              <Text>
                <Trans
                  i18nKey="hiive_fund_more_information"
                  t={t}
                  components={[
                    <Link
                      key="faq"
                      href={constants.hiive_faq_url}
                      fontWeight="medium"
                      target="_blank"
                    />,
                    <MailtoLink
                      key="team_email"
                      fontWeight="medium"
                      email={constants.email_funds}
                    />,
                  ]}
                />
              </Text>
              <Text>{t(`hiive_fund_this_listing`)}</Text>
            </Flex>
          </FocusedShareDetails.ContentSection>
        )}

        {listing.otherDetails && (
          <FocusedShareDetails.ContentSection
            p={{ base: 4, md: 6 }}
            pb={5}
            data-testid="listing-notes"
          >
            {isListingNotesUpdatesEnabled && !listing.fromHiive ? (
              <Flex gap={3} direction="column">
                <Text textStyle="heading-sm">{tListings(`sellers_notes`)}</Text>
                <Box
                  bg="grey.25"
                  borderRadius="md"
                  padding={4}
                  fontStyle="italic"
                >
                  <SensitiveText>
                    <div className="listing-details-md">
                      <MarkdownDocument markdown={listing.otherDetails} />
                    </div>
                  </SensitiveText>
                </Box>
                {!isSeller && (
                  <Text textStyle="text-xs">
                    {tListings(
                      `hiive_has_not_reviewed_or_validated_sellers_notes`,
                    )}
                  </Text>
                )}
              </Flex>
            ) : (
              <Flex gap={3} direction="column">
                <Text textStyle="heading-sm">{t(`listing_notes`)}</Text>
                <SensitiveText>
                  <div className="listing-details-md">
                    <MarkdownDocument markdown={listing.otherDetails} />
                  </div>
                </SensitiveText>
              </Flex>
            )}
          </FocusedShareDetails.ContentSection>
        )}

        {showFromHiive && (
          <FocusedShareDetails.ContentSection
            p={{ base: 4, md: 6 }}
            pb={5}
            data-testid="hiive-listing-text"
          >
            <Flex gap={3} direction="column">
              <Text textStyle="heading-sm">{t(`placed_by_hiive`)}</Text>
              <Text>{t(`listing_placed_by_hiive_description`)}</Text>
            </Flex>
          </FocusedShareDetails.ContentSection>
        )}

        {bidCount > 0 && (
          <FocusedShareDetails.ContentSection>
            <Flex gap={4} direction="column" align="start">
              <Text textStyle="heading-sm">Activity</Text>
              <Badge variant="grey" as={Text} gap={4}>
                <SensitiveText as="strong" textStyle="heading-lg">
                  {bidCount}
                </SensitiveText>
                {` `}
                {pluralize(bidCountLabel, bidCount)}
              </Badge>
            </Flex>
          </FocusedShareDetails.ContentSection>
        )}
      </FocusedShareDetails.Content>
    </FocusedShareDetails.Card>
  );
};

export const ListingInfoCard = withCurrentActor(
  ({
    listing,
    actor,
    discussion,
  }: {
    readonly listing: ListingPageListingInfoCardListingFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
  }) => {
    const isBrokerForListing = getIsBrokerForListing(actor, listing);

    return (
      <ConfigProvider>
        {match(actor.investorType)
          .with(InvestorType.Broker, () => {
            // New SSBP changes don't need to be behind refactor flag
            if (isBrokerForListing)
              return (
                <BrokerListerInfoCard
                  listing={listing}
                  discussion={discussion}
                />
              );

            return (
              <ListingInfoCardV1 listing={listing} discussion={discussion} />
            );
          })
          .with(InvestorType.Trader, () => (
            <ListingInfoCardV1 listing={listing} discussion={discussion} />
          ))
          .with(InvestorType.UnaccreditedSeller, () => (
            // TODO Refactor
            <ListingInfoCardV1 listing={listing} discussion={discussion} />
          ))
          .with(InvestorType.Seller, () => (
            // TODO Refactor
            <ListingInfoCardV1 listing={listing} discussion={discussion} />
          ))
          .otherwise(() => {
            throw new Error(`Invalid investor type <ListingInfoCard/>`);
          })}
      </ConfigProvider>
    );
  },
);
