import { ReactElement } from "react";

import { Flex } from "@chakra-ui/react";

import { Loader } from "@/components/common";
import { NavBar } from "@/components/nav";
import {
  FrontChatProvider,
  KnockProvider,
  ModalProvider,
  NullableCurrentActorContext,
} from "@/components/providers";
import {
  useCheckAuthentication,
  useCheckOnboarding,
  useCorrectPortal,
  useIdentifyUser,
  useNullableCurrentActor,
} from "@/hooks";

import Layout from "./Layout";

interface AuthenticatedLayoutProps {
  readonly children: JSX.Element | readonly JSX.Element[];
  readonly title?: string;
  readonly renderNavBar?: boolean;
}

const AuthenticatedProviders = ({
  children,
}: {
  readonly children: ReactElement;
}) => (
  <ModalProvider>
    <KnockProvider>
      <FrontChatProvider>{children}</FrontChatProvider>
    </KnockProvider>
  </ModalProvider>
);

const useAuthenticationFlow = ({
  actor,
  loading,
}: NullableCurrentActorContext) => {
  useCheckAuthentication({ actor, loading });
  useIdentifyUser({ actor });
  useCorrectPortal({ actor });
  const { redirectingToOnboarding } = useCheckOnboarding({ actor });
  return { redirectingToOnboarding };
};

const AuthenticatedLayout = ({
  children,
  title,
  renderNavBar = true,
}: AuthenticatedLayoutProps) => {
  const { actor, loading } = useNullableCurrentActor();

  const { redirectingToOnboarding } = useAuthenticationFlow({ actor, loading });

  if (!actor || loading || redirectingToOnboarding) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <AuthenticatedProviders>
      <Layout title={title}>
        <Flex
          direction="column"
          minH="100vh"
          flex="1 1 auto"
          position="relative"
          {...(!renderNavBar && { as: `main`, id: `main-element` })}
        >
          {renderNavBar ? (
            <>
              <NavBar />
              <Flex
                as="main"
                id="main-element"
                flex="1 1 auto"
                direction="column"
                align="center"
              >
                {children}
              </Flex>
            </>
          ) : (
            children
          )}
        </Flex>
      </Layout>
    </AuthenticatedProviders>
  );
};

export default AuthenticatedLayout;
