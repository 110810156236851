import { Card, CardBody } from "@chakra-ui/react";

import { useTransactionExecution } from "@/hooks";

import BuyerDetails from "./BuyerDetails";
import OriginalPostingDetailsCard from "./OriginalPostingDetailsCard";
import SellerDetails from "./SellerDetails";
import TransactionExecutionPageInfo from "./TransactionExecutionPageInfo";
import TransactionSummaryCard from "./TransactionSummaryCard";

const TransactionDetailsCard = () => {
  const { transaction, isBuyer } = useTransactionExecution();
  const hasEntities = transaction?.sellerEntity || transaction?.buyerEntity;

  return (
    <Card variant="no-border-radius-base">
      <CardBody p={6}>
        <TransactionExecutionPageInfo />
      </CardBody>
      {hasEntities && (
        <CardBody p={0} px={6} py={4}>
          {isBuyer ? <BuyerDetails /> : <SellerDetails />}
        </CardBody>
      )}
      <CardBody p={6} bgColor="grey.15">
        <TransactionSummaryCard transaction={transaction} />
      </CardBody>
      <CardBody p={6}>
        <OriginalPostingDetailsCard />
      </CardBody>
    </Card>
  );
};

export default TransactionDetailsCard;
