import { t } from "i18next";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  CompanyMarketActivity,
  CompanyPricing,
  CompanySecuritySpecialistContact,
} from "@/components/companies";
import { CompanyPageCompanyDescriptionCardCompanyFragment } from "@/gql";

export const DescriptionCard = ({
  company,
}: {
  readonly company: CompanyPageCompanyDescriptionCardCompanyFragment;
}) => (
  <Card variant="darkened-footer">
    <CardHeader>
      <HStack minH={10}>
        {company.logoUrl && (
          <Image
            h={{ base: `24px`, sm: `30px` }}
            src={company.logoUrl}
            alt={company.name}
            mr={{ base: 2, sm: 4 }}
          />
        )}
        <Text
          as="h1"
          w="full"
          lineHeight="initial"
          textStyle={{
            base: `heading-2xl`,
            md: `heading-4xl`,
          }}
        >
          {company.name}
        </Text>
      </HStack>
    </CardHeader>
    <CardBody>
      <VStack gap={[2, 4]}>
        <Text as="h4" w="full" textStyle="heading-sm">
          {t(`current_pricing`)}
        </Text>
        <CompanyPricing company={company} />
        <Text as="h4" w="full" textStyle="heading-sm">
          {t(`market_activity`)}
        </Text>
        <CompanyMarketActivity company={company} />
      </VStack>
    </CardBody>
    <CardBody>
      <Text as="h4" w="full" textStyle="heading-sm" mb={2}>
        {t(`about`)}
      </Text>
      <Text>{company.description}</Text>
    </CardBody>
    <CardFooter>
      <CompanySecuritySpecialistContact company={company} />
    </CardFooter>
  </Card>
);
