import { Certificate } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { HStack, List, ListItem, Text, VStack } from "@chakra-ui/react";

import { useTransactionExecution } from "@/hooks";

import EntityDetails from "./EntityDetails";

const BuyerDetails = () => {
  const { t } = useTranslation(`execution`);
  const { transaction } = useTransactionExecution();
  const buyerEntity = transaction?.buyerEntity;
  const showDetails = !!buyerEntity;

  if (!showDetails) return null;

  return (
    <VStack alignItems="start" gap={3}>
      <Text textStyle="heading-md" color="grey.900">
        {t(`my_details`)}
      </Text>
      <List as={VStack} gap={3} alignItems="flex-start" w="full">
        {buyerEntity && (
          <ListItem as={HStack} gap={6}>
            <Certificate size={24} />
            <EntityDetails entity={transaction?.buyerEntity} />
          </ListItem>
        )}
      </List>
    </VStack>
  );
};

export default BuyerDetails;
