import currency from "currency.js";
import dayjs from "dayjs";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { AnimatedModalStep } from "@/components/form";
import { PlaceStandingBidSequenceModalFormValues } from "@/components/modals";
import {
  CompanyWatchlistActorDocument,
  MarketActivityDocument,
  CompanyPageActivityCompanyByIdDocument,
  PlaceStandingBidMutationVariables,
  PlaceStandingBidSelectCompanyInputCompanyFragment,
  StandingBidAcceptsTransferMethodsOptions,
  usePlaceStandingBidMutation,
  UserActivityMyActivityDocument,
  PlaceStandingBidSuccessModalStandingBidFragment,
  PlaceStandingBidMutation,
} from "@/gql";
import { useCurrentActor, useStepRouter } from "@/hooks";
import { useBuyerInformationDisclosure } from "@/hooks/featureFlags";
import { useFormQL } from "@/hooks/react-hook-form";
import { constants } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  PlaceStandingBidModal,
  stepKeys,
  BidDetailsStepFields,
  StepKeys,
  PlaceStandingBidSuccessModal,
} from "./steps";
import {
  getBidDetailsValidationSchema,
  getAdditionalDetailsValidationSchema,
} from "./validation";

const createInitialValues = (
  initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment,
  initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>,
): PlaceStandingBidSequenceModalFormValues => ({
  affiliate: undefined,
  company: initialCompany || null,
  numShares: undefined,
  // @ts-expect-error due to v1 type mismatch
  acceptsShares: null,
  minPartialAcceptNumShares: undefined,
  allowPartialAccept: true,
  acceptsTransferMethods: [StandingBidAcceptsTransferMethodsOptions.Direct],
  // @ts-expect-error due to v1 type mismatch
  pricePerShare: null,
  otherDetails: ``,
  expireAt: datetime
    .add(`day`, constants.default_standing_bid_expire_after_days, dayjs())
    .toDate(),
  confirmed: false,
  confirmedInformationDisclosure: false,
  muteNotifyWatchers: false,
  representedEmail: ``,
  representedFirstName: ``,
  representedLastName: ``,
  notifyRepresentingEmail: true,
  solicited: undefined,
  ...initialValues,
});

const mapVariables = ({
  confirmed: _confirmed,
  confirmedInformationDisclosure: _confirmedInformationDisclosure,
  company,
  expireAt,
  pricePerShare,
  representedEmail,
  representedFirstName,
  representedLastName,
  ...values
}: PlaceStandingBidSequenceModalFormValues): PlaceStandingBidMutationVariables => ({
  input: {
    ...values,
    numShares: values.numShares || 0,
    companyId: company?.id || ``,
    expireAt: dayjs(expireAt).endOf(`day`).format(),
    pricePerShare: currency(pricePerShare).intValue,
    representedUser: {
      email: representedEmail,
      firstName: representedFirstName,
      lastName: representedLastName,
    },
  },
});

interface PlaceStandingBidSequenceModalProps {
  readonly initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment;
  readonly initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>;
}

export const PlaceStandingBidSequenceModalContent = ({
  initialCompany,
  initialValues,
}: PlaceStandingBidSequenceModalProps) => {
  const router = useRouter();
  const { t } = useTranslation(`standingBids`);
  const [standingBid, setStandingBid] =
    useState<PlaceStandingBidSuccessModalStandingBidFragment | null>(null);
  const actor = useCurrentActor();
  const buyerInformationDisclosureEnabled = useBuyerInformationDisclosure();

  const bidDetailsValidationSchema = getBidDetailsValidationSchema(t);
  const additionalDetailsValidationSchema =
    getAdditionalDetailsValidationSchema(
      t,
      actor,
      !!buyerInformationDisclosureEnabled,
    );
  const validationSchema = additionalDetailsValidationSchema.concat(
    bidDetailsValidationSchema,
  );

  const mutation = usePlaceStandingBidMutation({
    refetchQueries: [
      MarketActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
      CompanyPageActivityCompanyByIdDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.placeStandingBidBidDetails,
      stepKeys.placeStandingBidAdditionalDetails,
      stepKeys.placeStandingBidSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
    stepControls,
  } = stepRouter;

  const _initialValues = createInitialValues(initialCompany, initialValues);

  const placeStandingBidModalStepKeys: readonly StepKeys[] = [
    stepKeys.placeStandingBidBidDetails,
    stepKeys.placeStandingBidAdditionalDetails,
  ];

  const formProps = useFormQL({
    mutation,
    mapVariables,
    initialValues: _initialValues,
    onSuccess: (response?: PlaceStandingBidMutation | null | undefined) => {
      const newStandingBid = response?.placeStandingBid?.standingBid;
      setStandingBid(newStandingBid || null);
      router.push(`/standing-bids/${newStandingBid?.id}`);
      stepControls.nextStep();
    },
    onError: (errors) => {
      // FIXME: server right now only handles validation for min/max pps so if we get here, we most likely need to redirect to the first step to show the error
      const fields = errors?.map((fieldError) => fieldError.field);
      const shouldRedirect = fields?.some((field) =>
        BidDetailsStepFields.includes(field),
      );
      if (!!shouldRedirect)
        stepRouter.stepControls.jumpToStep(stepKeys.placeStandingBidBidDetails);
    },
    validationSchema,
  });

  return (
    <form>
      <AnimatePresence mode="wait" initial={false}>
        {placeStandingBidModalStepKeys.includes(currentStepKey) && (
          <AnimatedModalStep key={placeStandingBidModalStepKeys.join()}>
            <PlaceStandingBidModal
              formProps={formProps}
              stepRouter={stepRouter}
              initialCompany={initialCompany}
            />
          </AnimatedModalStep>
        )}
        {stepKeys.placeStandingBidSuccess === currentStepKey && (
          <AnimatedModalStep key={stepKeys.placeStandingBidSuccess}>
            <PlaceStandingBidSuccessModal standingBid={standingBid} />
          </AnimatedModalStep>
        )}
      </AnimatePresence>
    </form>
  );
};
