import { useTranslation } from "react-i18next";

import { EntityType } from "@/gql";

import useCountryList from "./useCountryList";

const useTranslatedEntityNames = () => {
  const { t } = useTranslation(`account`, {
    keyPrefix: `entities`,
  });
  const { getName } = useCountryList();

  const getFormattedEntityName = (key: EntityType) => {
    const entityTypesMap: Partial<Record<EntityType, string>> = {
      [EntityType.Corporation]: t(`corporation`),
      [EntityType.Llc]: t(`llc`),
      [EntityType.Partnership]: t(`partnership`),
      [EntityType.Other]: t(`other`),
      [EntityType.RetirementAccount]: t(`retirement_account`),
      [EntityType.Trust]: t(`trust`),
      [EntityType.Individual]: t(`individual`),
    };

    return entityTypesMap[key] || key.toString().toLowerCase();
  };

  const getCountryName = (name?: string) => (name ? getName(name) : null);

  return {
    getCountryName,
    getFormattedEntityName,
  };
};

export default useTranslatedEntityNames;
