import { t } from "i18next";

import { useRouter } from "next/router";

import {
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { InitialsBadge } from "@/components/nav";
import { UserWithInstitutionFragment } from "@/gql";
import { useSignOut } from "@/hooks";
import { constants, ROUTES } from "@/utils";

export const ProfileMenu = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const disclosure = useDisclosure();
    const router = useRouter();
    const signOut = useSignOut();
    const accountPageLink = ROUTES.ACCOUNT_SETTINGS_CHANGE_EMAIL;

    return (
      <Menu {...disclosure} placement="bottom-end">
        <MenuButton data-testid="account-icon">
          <InitialsBadge />
        </MenuButton>
        <MenuList minW={72} boxShadow="card" borderRadius="md" bg="white" p={0}>
          <HStack p={5} data-dd-privacy="mask">
            <InitialsBadge />
            <VStack alignItems="flext-start" spacing={0}>
              <Text textStyle="heading-md">{actor.name}</Text>
              <Text textStyle="text-xs">{actor.email}</Text>
            </VStack>
          </HStack>
          <MenuDivider />
          <MenuItem
            borderTopRadius="0px"
            textStyle="heading-xs"
            onClick={() => router.push(accountPageLink)}
          >
            {t(`account`)}
          </MenuItem>
          <MenuItem
            onClick={() =>
              router.push(
                `mailto:${constants.email_support}?subject=${t(
                  `contact_hiive`,
                )}`,
              )
            }
          >
            {t(`help`)}
          </MenuItem>
          <MenuItem textStyle="heading-xs" onClick={() => signOut()}>
            {t(`sign_out`)}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  },
);
