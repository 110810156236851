import { X } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  IconButton,
  ModalHeader,
  ModalHeaderProps,
} from "@chakra-ui/react";

import { useModal } from "@/hooks";

const ExecutionModalHeader = ({
  children,
  closeModal,
}: {
  readonly children: ReactNode;
  readonly closeModal?: () => void;
} & ModalHeaderProps) => {
  const { closeModal: modalProviderCloseModal, ...props } = useModal();
  const { t } = useTranslation();

  return (
    <ModalHeader {...props}>
      <Box as="section">{children}</Box>
      <IconButton
        aria-label={t(`close_modal`)}
        onClick={closeModal || modalProviderCloseModal}
        variant="icon-close"
        icon={<X size={18} />}
      />
    </ModalHeader>
  );
};

export default ExecutionModalHeader;
