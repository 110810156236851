import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import { FlowKind, ModalKind } from "@/components/providers";
import {
  BidState,
  PlaceBidSuccessModalBidFragment,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import {
  constants,
  getAvailableCompanyActions,
  getIsBroker,
  getIsHiiveFund,
} from "@/utils";

const SubmitBidTranslations = {
  title: `submit_bid_success_in_review_title`,
  heading: `submit_bid_success_in_review_heading`,
  body: `submit_bid_success_in_review_body`,
  contactUs: `submit_bid_success_in_review_contact_us`,
} as const;

const PlaceBidTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_success_body`,
  contactUs: `place_bid_success_contact_us`,
} as const;

const HiiveFundTranslations = {
  title: `hiive_fund_bid_success_title`,
  heading: `hiive_fund_bid_success_heading`,
  body: `hiive_fund_bid_success_body`,
  contactUs: `hiive_fund_bid_success_contact_us`,
} as const;

const getPlaceBidSuccessModalTranslationKeys = (
  bid: PlaceBidSuccessModalBidFragment,
) =>
  match({
    state: bid.state,
    method: bid.listing.transferMethod,
  })
    .with({ method: TransferMethod.HiiveFund }, () => HiiveFundTranslations)
    .with({ state: BidState.InReview }, () => SubmitBidTranslations)
    .otherwise(() => PlaceBidTranslations);

const getContactUsComponent = (
  listing: PlaceBidSuccessModalBidFragment["listing"],
) => {
  if (getIsHiiveFund(listing.transferMethod)) {
    return <MailtoLink fontWeight="medium" email={constants.email_funds} />;
  }

  return (
    <SecuritySpecialistLink key={listing.displayId} company={listing.company} />
  );
};

const PlaceBidSuccessModal = ({
  bid,
  actor,
}: {
  readonly bid: PlaceBidSuccessModalBidFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { closeModal, addModalCloseHandler, removeModalCloseHandler } =
    useModal();
  const { t } = useTranslation();
  const { showFlow } = useFlowLauncher();

  const translationKeys = getPlaceBidSuccessModalTranslationKeys(bid);

  const contactUsComponent = getContactUsComponent(bid.listing);

  const isBroker = getIsBroker(actor);

  const { canPlaceStandingBid } = getAvailableCompanyActions(
    bid.listing.company,
  );

  const isHiiveFund = getIsHiiveFund(bid.listing.transferMethod);

  useEffect(() => {
    addModalCloseHandler(ModalKind.PlaceBidSuccess, () => {
      // skip standing bid CTA for Hiive Fund
      if (canPlaceStandingBid && !isHiiveFund) {
        showFlow(
          isBroker
            ? FlowKind.BrokerSubmitBidSuccessStandingBidCTA
            : FlowKind.BuyerPlaceBidSuccessStandingBidCTA,
        );
      }
    });

    return () => {
      removeModalCloseHandler(ModalKind.PlaceBidSuccess);
    };
  }, [isBroker]);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>{t(translationKeys.title)}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="start">
          <Text textStyle="heading-lg">
            {t(translationKeys.heading, {
              companyName: bid.listing.company.name,
              displayId: bid.listing.displayId,
            })}
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.body}
              values={{
                companyName: bid.listing.company.name,
                displayId: bid.listing.displayId,
              }}
            />
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.contactUs}
              t={t}
              components={[contactUsComponent]}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[PlaceBidSuccess/Submit]"
          submitText={t(`okay`)}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default withCurrentActor(PlaceBidSuccessModal);
