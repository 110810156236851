import { CaretUp, CaretRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  HStack,
  Text,
  Divider,
  Card,
  CardBody,
  Box,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

import { MarkdownDocument } from "@/components/common";
import {
  TransactionExecutionPageTransactionFragment,
  useCommissionStructureBandCommissionsQuery,
} from "@/gql";
import { formatCurrency } from "@/utils";

type CommissionBandProps = {
  band: {
    id: string;
    bandText: string;
    commission?: number | null;
  };
};

function CommissionBand({ band }: CommissionBandProps) {
  const color = band.commission === 0 ? `grey.400` : undefined;

  return (
    <HStack w="full" justifyContent="space-between">
      <Box textStyle="text-sm" color={color}>
        <MarkdownDocument markdown={band.bandText} />
      </Box>
      <Text textStyle="text-sm" color={color}>
        {formatCurrency(band.commission, { fromCents: true })}
      </Text>
    </HStack>
  );
}

export function BuyerCommissionBreakdown({
  numShares,
  pricePerShare,
  commission,
}: {
  numShares: number;
  pricePerShare: number;
  commission: TransactionExecutionPageTransactionFragment["buyerCommission"];
}) {
  const { t } = useTranslation(`execution`);
  const { netFees, feeDiscountAmount, commissionStructure } = commission ?? {};
  const totalValue = pricePerShare * numShares;
  const netValue = totalValue + (netFees ?? 0);

  const { data } = useCommissionStructureBandCommissionsQuery({
    variables: {
      buyerCommissionStructureId: commissionStructure?.id ?? ``,
      totalShares: numShares,
      pricePerShare,
    },
    skip: !commissionStructure?.id,
    fetchPolicy: `network-only`,
  });

  const bands = data?.buyerCommissionInfo?.commissionStructure?.bands?.toSorted(
    (a, b) => (a.lower ?? 0) - (b.lower ?? 0),
  );

  return (
    <>
      <Accordion allowToggle w="full">
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                as={HStack}
                w="full"
                justifyContent="space-between"
                p={0}
                _hover={{ bg: `grey.25`, cursor: `pointer` }}
                _focus={{ boxShadow: `none` }}
              >
                <VStack alignItems="start" gap={1}>
                  <Text textStyle="heading-xs">{t(`transaction_fee`)}</Text>
                </VStack>
                <HStack>
                  <Text visibility={isExpanded ? `hidden` : `visible`}>
                    {formatCurrency(netFees, { fromCents: true })}
                  </Text>
                  {isExpanded ? (
                    <CaretUp width="16" height="16" />
                  ) : (
                    <CaretRight width="16" height="16" />
                  )}
                </HStack>
              </AccordionButton>
              <AccordionPanel p={0} pt={4}>
                <Card variant="flat" bg="grey.50" border="none" w="full">
                  <CardBody
                    p={4}
                    pr={6}
                    gap={2}
                    display="flex"
                    flexDirection="column"
                  >
                    <Text textStyle="text-xs">
                      {!!bands &&
                        bands?.length > 1 &&
                        t(`buyer_breakdown_description_multiple_bands`)}
                    </Text>
                    {bands?.map((band) => (
                      <CommissionBand band={band} key={band.id} />
                    ))}
                    {!!feeDiscountAmount && feeDiscountAmount !== 0 && (
                      <HStack w="full" justifyContent="space-between">
                        <Text textStyle="text-sm">{t(`discount`)}</Text>
                        <Text textStyle="text-sm">
                          -
                          {formatCurrency(feeDiscountAmount, {
                            fromCents: true,
                          })}
                        </Text>
                      </HStack>
                    )}
                  </CardBody>
                </Card>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <Divider borderColor="grey.200" />
      <HStack w="full" justifyContent="space-between" pr={6}>
        <Text textStyle="heading-xs">{t(`total_amount`)}</Text>
        <Text>{formatCurrency(netValue, { fromCents: true })}</Text>
      </HStack>
    </>
  );
}
