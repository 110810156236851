import {
  DiscussionState,
  UseMessageSenderDisplayNameDiscussionFragment,
  UseMessageSenderDisplayNameDiscussionMessageFragment,
} from "@/gql";
import {
  getIsSender,
  getIsSenderInstitution,
  getIsBuyerForDiscussion,
} from "@/utils";

import { useCurrentActor } from "./useCurrentActor";

const useMessageSenderDisplayName = ({
  discussion,
  message,
}: {
  readonly message: UseMessageSenderDisplayNameDiscussionMessageFragment;
  readonly discussion: UseMessageSenderDisplayNameDiscussionFragment;
}) => {
  const actor = useCurrentActor();

  const { sender } = message;

  const { isHiiveUser: amIHiiveUser } = actor;

  const senderName = sender.institution
    ? sender.institution.legalName
    : sender.name;

  const amIBuyer = getIsBuyerForDiscussion({ user: actor, discussion });
  const isMessageFromCounterparty =
    !getIsSender(actor, message) && !getIsSenderInstitution(actor, message);
  const areEitherHiiveUsers = amIHiiveUser || sender.isHiiveUser;

  if (areEitherHiiveUsers || !isMessageFromCounterparty) return senderName;

  if (!amIBuyer) {
    return discussion.state === DiscussionState.Pending ? `Buyer` : senderName;
  }

  return `Seller`;
};

export default useMessageSenderDisplayName;
