import { t } from "i18next";

import { Text, VStack } from "@chakra-ui/react";

import { useIsDesktop, useTransactionExecution } from "@/hooks";
import { formatCurrency, getIsHiiveFund } from "@/utils";

type GrossProceedsProps = {
  buySideAmount: number;
  counterpartyName: string;
  isSellSide: boolean;
  netProceeds: number;
};

const GrossProceeds = ({
  buySideAmount,
  counterpartyName,
  isSellSide,
  netProceeds,
}: GrossProceedsProps) => {
  const isDesktop = useIsDesktop();
  const { transaction } = useTransactionExecution();
  const isHiiveFund = getIsHiiveFund(transaction.transferMethod);

  return (
    <VStack
      gap={1}
      alignItems={isDesktop ? `flex-end` : `flex-start`}
      spacing={0}
    >
      <Text textStyle="heading-xl">
        {isSellSide
          ? formatCurrency(netProceeds, { fromCents: true })
          : formatCurrency(buySideAmount, { fromCents: true })}
      </Text>
      {!isHiiveFund && (
        <Text textStyle="text-xs" color="grey.500">
          {t(`transaction_with`, { counterpartyName })}
        </Text>
      )}
    </VStack>
  );
};

export default GrossProceeds;
