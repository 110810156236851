import React, { ReactNode } from "react";

import {
  Box,
  SimpleGrid,
  GridItem,
  Flex,
  VStack,
  Card,
  CardHeader,
} from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { TransactionExecutionPageProvider } from "@/components/providers";
import { MilestonesCard } from "@/components/transactions";
import {
  ExecutionWorkflowStatus,
  UserWithInstitutionFragment,
  useTransactionExecutionPageOnTransactionUpdatedSubscription,
} from "@/gql";
import { useIsDesktop, useTransactionExecution } from "@/hooks";
import { getTransactionIsBuySide } from "@/hooks/transaction";

import InformativeArticlesCard from "./InformativeArticlesCard";
import TransactionExecutionPageCompletedCard from "./TransactionExecutionPageCompletedCard";
import { TransactionExecutionPageSummaryCardBody } from "./TransactionExecutionPageSummaryCardBody";
import TransactionExecutionPageTabNav from "./TransactionExecutionPageTabNav";

type TransactionExecutionPageLayoutProps = {
  readonly children: ReactNode;
  readonly actor: UserWithInstitutionFragment;
};

const TransactionExecutionPageLayoutContent = withCurrentActor(
  ({ children, actor }: TransactionExecutionPageLayoutProps) => {
    const { transaction } = useTransactionExecution();

    const workflow = transaction?.workflow;

    useTransactionExecutionPageOnTransactionUpdatedSubscription({
      variables: { transactionId: transaction?.id },
    });

    const isDesktop = useIsDesktop();

    const workflowCompleted =
      !!workflow &&
      workflow.status === ExecutionWorkflowStatus.Completed &&
      !transaction.cancelledAt &&
      !transaction.issuerApprovalDeclinedAt;

    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    return (
      <FullContentWrapper
        px={{ base: 0, md: 4, lg: 8 }}
        pt={{ base: 4, md: 7 }}
      >
        <Box w="full" maxW={workflowCompleted ? `2xl` : `278`}>
          {workflowCompleted ? (
            <TransactionExecutionPageCompletedCard transaction={transaction} />
          ) : (
            <SimpleGrid columns={workflowCompleted ? 8 : 12} gap={8}>
              <GridItem colSpan={{ base: workflowCompleted ? 8 : 12, lg: 8 }}>
                <Card
                  variant="no-border-radius-base"
                  borderRadius={0}
                  borderTopRadius={{ base: 0, md: `md` }}
                  borderBottomColor="grey.50"
                >
                  <CardHeader bg="teal.50" borderBottomColor="teal.100" />
                  <TransactionExecutionPageSummaryCardBody
                    transaction={transaction}
                  />
                </Card>
                <Flex direction="column" gap={4}>
                  <TransactionExecutionPageTabNav transaction={transaction} />
                  {children}
                </Flex>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <VStack spacing={4}>
                  {isDesktop && !!workflow && (
                    <MilestonesCard workflow={workflow} isBuySide={isBuySide} />
                  )}
                  {isDesktop && (
                    <InformativeArticlesCard transaction={transaction} />
                  )}
                </VStack>
              </GridItem>
            </SimpleGrid>
          )}
        </Box>
      </FullContentWrapper>
    );
  },
);

const TransactionExecutionPageLayout = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => (
  <TransactionExecutionPageProvider>
    <TransactionExecutionPageLayoutContent>
      {children}
    </TransactionExecutionPageLayoutContent>
  </TransactionExecutionPageProvider>
);

export default TransactionExecutionPageLayout;
