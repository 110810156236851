import { useEffect, useState } from "react";
import { match } from "ts-pattern";

import { Flex, VStack } from "@chakra-ui/react";

import { Logo, WithQuery } from "@/components/common";
import {
  AlternateUserPageAlternateUserTaskInfoFragment,
  ExecutionTaskStatus,
  ExecutionTaskType,
  useAlternateUserTaskInfoQuery,
} from "@/gql";
import { useClearAuth } from "@/hooks";

import { AnvilFillDocumentTask, AnvilSignDocumentTask } from "./Task";

function NavBar() {
  return (
    <Flex
      px={{ base: 12, lg: 20 }}
      py={{ base: 8, lg: 6 }}
      bg="white"
      w="full"
      justify="space-between"
      alignItems="center"
      borderBottom="0.5px solid"
      borderColor="grey.100"
    >
      <Logo.RedBlack maxW={22} />
    </Flex>
  );
}

function AlternateUserTask({
  executionTaskAlternateUserInfo,
  token,
  onTaskCompletedLocally,
}: {
  readonly executionTaskAlternateUserInfo: AlternateUserPageAlternateUserTaskInfoFragment;
  readonly token: string;
  readonly onTaskCompletedLocally: () => void;
}) {
  return (
    <Flex direction="column" w="full" maxW="45rem" gap={8} paddingTop={20}>
      {match(executionTaskAlternateUserInfo.task.type)
        .with(ExecutionTaskType.AnvilFillDocument, () => (
          <AnvilFillDocumentTask
            token={token}
            alternateUserTaskInfo={executionTaskAlternateUserInfo}
            onTaskCompletedLocally={onTaskCompletedLocally}
          />
        ))
        .with(ExecutionTaskType.AnvilSignDocument, () => (
          <AnvilSignDocumentTask
            token={token}
            alternateUserTaskInfo={executionTaskAlternateUserInfo}
            onTaskCompletedLocally={onTaskCompletedLocally}
          />
        ))
        .otherwise(() => null)}
    </Flex>
  );
}

const AlternateUserPage = ({
  token,
}: {
  readonly token: string | undefined;
}) => {
  useClearAuth();
  const query = useAlternateUserTaskInfoQuery({
    variables: { token: token as string },
  });

  const { startPolling, stopPolling, data } = query;
  const [taskLocallyCompleted, setTaskLocallyCompleted] = useState(false);

  useEffect(() => {
    const taskStatus = data?.executionTaskAlternateUserInfo?.task?.status;

    if (taskLocallyCompleted && taskStatus === ExecutionTaskStatus.Completed) {
      stopPolling();
    }
  }, [taskLocallyCompleted, stopPolling]);

  return (
    <WithQuery query={query}>
      {({ data }) => (
        <VStack minH="100vh" bg="grey.50">
          <NavBar />
          {token && (
            <AlternateUserTask
              onTaskCompletedLocally={() => {
                startPolling(1000);
                setTaskLocallyCompleted(true);
              }}
              token={token}
              executionTaskAlternateUserInfo={
                data?.executionTaskAlternateUserInfo
              }
            />
          )}
        </VStack>
      )}
    </WithQuery>
  );
};

export default AlternateUserPage;
