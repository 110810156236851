const useIsHiiveConnect = () => {
  const { host, hash, search } = window.location;
  const connectSubdomains = (
    process.env.NEXT_PUBLIC_HIIVE_CONNECT_SUBDOMAINS || ``
  )
    .split(`,`)
    .filter((subdomain) => !!subdomain);

  const urlParams = new URLSearchParams(search);
  const hasConnectQueryParam = urlParams.has(`connect`);

  return (
    connectSubdomains.some((connectSubdomain) =>
      host.startsWith(connectSubdomain),
    ) ||
    hash === `#connect` ||
    hasConnectQueryParam
  );
};

export default useIsHiiveConnect;
