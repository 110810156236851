import capitalize from "lodash/capitalize";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Spacer, Text } from "@chakra-ui/react";

import {
  MailtoLink,
  SensitiveText,
  TileRow,
  TileHeader,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import {
  BidState,
  BidStatusBidFragment,
  SigningProcedure,
  TransactionState,
  TransferMethod,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useBidsPricingInReview } from "@/hooks/featureFlags";
import { constants, toTimestamp } from "@/utils";

const BidStatus = ({ bid }: { readonly bid: BidStatusBidFragment }) => {
  const actor = useCurrentActor();
  const isBidPricingInReviewEnabled = useBidsPricingInReview();

  const { t } = useTranslation();
  const { t: tBids } = useTranslation(`bids`);

  const { bidStatus, statusDescription } = match(bid)
    .with(
      {
        transaction: {
          state: TransactionState.InReview,
        },
      },
      () => ({
        bidStatus: `In Review: A Security Specialist will contact you soon to clarify a few details and help advance your transaction.`,
        statusDescription: (
          <>
            For any questions or information contact:
            <br />
            <MailtoLink
              email={constants.email_accounts}
              subject="Transaction Review Inquiry"
              fontWeight="medium"
            >
              {constants.email_accounts}
            </MailtoLink>
          </>
        ),
      }),
    )
    .with(
      { transaction: { state: TransactionState.IssuerApprovalDeclined } },
      () => ({
        bidStatus: `Cancelled: Issuer approval declined`,
        statusDescription: null,
      }),
    )
    .with(
      {
        transaction: { state: TransactionState.Cancelled },
      },
      () => ({ bidStatus: `Cancelled`, statusDescription: null }),
    )
    .with(
      {
        listing: {
          transferMethod: TransferMethod.Fund,
        },
        transaction: {
          state: TransactionState.BidAccepted,
        },
      },
      () => ({
        bidStatus: capitalize(bid.state),
        statusDescription: (
          <TileRow>
            <TileHeader />
            <Trans
              i18nKey="transaction_status_description"
              t={t}
              components={[
                <MailtoLink
                  key="team_email"
                  fontWeight="medium"
                  email={constants.email_funds}
                />,
              ]}
            />
          </TileRow>
        ),
      }),
    )
    .with(
      {
        transaction: {
          state: TransactionState.BidAccepted,
          signingProcedure: SigningProcedure.Manual,
          transferMethod: TransferMethod.Other,
        },
      },
      () => ({
        bidStatus: `Accepted - Pending closing.`,
        statusDescription: (
          <Text>
            For support, contact our{` `}
            <SecuritySpecialistLink company={bid.listing.company} />.
          </Text>
        ),
      }),
    )
    .with(
      {
        transaction: {
          state: TransactionState.BidAccepted,
          signingProcedure: SigningProcedure.Manual,
        },
      },
      () => ({
        bidStatus: capitalize(bid.state),
        statusDescription: null,
      }),
    )
    .with(
      {
        transaction: { signingProcedure: SigningProcedure.Manual },
      },
      () => ({
        bidStatus: capitalize(bid.state),
        statusDescription: null,
      }),
    )
    .with({ state: BidState.InReview }, () => ({
      bidStatus: isBidPricingInReviewEnabled
        ? tBids(`bid_in_review_by_hiive`, {
            insertedAt: toTimestamp(bid.insertedAt),
          })
        : `In review: Your bid is being reviewed, and Hiive will notify you once it is placed on the seller’s listing.`,
      statusDescription: null,
    }))
    .with({ state: BidState.Active, brokerId: actor.id }, () => ({
      bidStatus: `Live: The seller can accept or counter your bid.`,
      statusDescription: null,
    }))
    .with(
      {
        listing: { transferMethod: TransferMethod.HiiveFund },
        state: BidState.Superseded,
      },
      () => ({
        bidStatus: `Superseded - Another order has been offered on this listing`,
        statusDescription: null,
      }),
    )
    .with(
      {
        state: BidState.Superseded,
      },
      () => ({
        bidStatus: `Superseded - Another bid offer has been made on this listing`,
        statusDescription: null,
      }),
    )
    .with(
      {
        brokerId: actor.id,
        transaction: { rofr: true },
      },
      () => ({
        bidStatus: `ROFR Exercised  - Your bid triggered a right of first refusal which was then exercised. The party exercising its ROFR will therefore be substituted for you as the buyer in this transaction.`,
        statusDescription: (
          <Text>
            Please contact the Hiive Security Specialist representing you{` `}
            <MailtoLink
              email={bid.buyerEmail || constants.email_security_specialist}
              subject={`Broker questions on ${bid.company.name}`}
              fontWeight="medium"
            >
              {bid.buyerEmail || constants.email_security_specialist}
            </MailtoLink>
            {` `}
            to discuss possible next steps.
          </Text>
        ),
      }),
    )
    .with(
      {
        buyerId: actor.id,
        transaction: { rofr: true },
      },
      () => ({
        bidStatus: `ROFR Exercised  - Your bid triggered a right of first refusal which was then exercised. The party exercising its ROFR will therefore be substituted for you as the buyer in this transaction.`,
        statusDescription: (
          <Text>
            Please contact the{` `}
            <SecuritySpecialistLink company={bid.listing.company} /> to discuss
            possible next steps.
          </Text>
        ),
      }),
    )
    .with(
      {
        counterpartyId: actor.id,
        transaction: { rofr: true },
      },
      () => ({
        bidStatus: `ROFR Exercised - Your shares are being purchased by a different buyer, who either matched the bid you initially accepted, or exercised a right of first refusal to substitute itself for the original buyer.`,
        statusDescription: null,
      }),
    )
    .otherwise(() => ({
      bidStatus: capitalize(bid.state),
      statusDescription: null,
    }));

  return (
    <>
      <TileHeader>Status</TileHeader>
      <div data-testid="bid-status">
        <SensitiveText>{bidStatus}</SensitiveText>
      </div>
      {statusDescription && (
        <>
          <Spacer />
          <SensitiveText>{statusDescription}</SensitiveText>
        </>
      )}
    </>
  );
};

export default BidStatus;
