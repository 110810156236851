import { useTranslation } from "react-i18next";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  InquiryEntityPostingCard,
  WithQuery,
  BidPostingCard,
  ListingPostingCard,
  StandingBidYourActivityPostingCard,
} from "@/components/common";
import {
  CompanyPageYourActivityBidFragment,
  CompanyPageYourActivityCompanyFragment,
  CompanyPageYourActivityDiscussionFragment,
  CompanyPageYourActivityListingFragment,
  CompanyPageYourActivityStandingBidFragment,
  useCompanyPageYourActivityCompanyByIdV2Query,
} from "@/gql";
import { useCurrentActor, useIsDesktop } from "@/hooks";
import { getIsInstitutionUser } from "@/utils";

import { YourActivitySkeleton } from "./YourActivitySkeleton";

type YourActivityListingsProps = {
  readonly listings: readonly CompanyPageYourActivityListingFragment[];
  readonly company: CompanyPageYourActivityCompanyFragment;
};

type YourActivityBidsProps = {
  readonly bids: readonly CompanyPageYourActivityBidFragment[];
};

type YourActivityStandingBidsProps = {
  readonly standingBids: readonly CompanyPageYourActivityStandingBidFragment[];

  readonly company: CompanyPageYourActivityCompanyFragment;
};

type YourActivityDiscussionsProps = {
  readonly discussions: readonly CompanyPageYourActivityDiscussionFragment[];
};

type YourActivityContentProps = {
  readonly company: CompanyPageYourActivityCompanyFragment;
};

type YourActivityProps = {
  readonly companyId: string;
};

function YourActivityListings({
  listings,
  company,
}: YourActivityListingsProps) {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t(`listings`)}>
      {listings.map((listing) => (
        <ListingPostingCard
          key={listing.id}
          listing={{ ...listing, company }}
        />
      ))}
    </ActivityGroup>
  );
}

const YourActivityBids = ({ bids }: YourActivityBidsProps) => {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t(`bids`)}>
      {bids
        .filter((bid) => !bid?.transaction?.rofr)
        .map((bid) => {
          const { id } = bid;

          return <BidPostingCard key={id} bid={bid} />;
        })}
    </ActivityGroup>
  );
};

const YourActivityStandingBids = ({
  standingBids,
  company,
}: YourActivityStandingBidsProps) => {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t(`standing_bids`)}>
      {standingBids.map((standingBid) => (
        <StandingBidYourActivityPostingCard
          key={standingBid.id}
          standingBid={{ ...standingBid, company }}
        />
      ))}
    </ActivityGroup>
  );
};

function YourActivityDiscussions({
  discussions,
}: YourActivityDiscussionsProps) {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t(`inquiries`)}>
      {discussions.map((discussion) => (
        <InquiryEntityPostingCard key={discussion.id} discussion={discussion} />
      ))}
    </ActivityGroup>
  );
}

const YourActivityContent = ({ company }: YourActivityContentProps) => {
  const actor = useCurrentActor();

  const isDesktopView = useIsDesktop();
  const isInstitutionUser = getIsInstitutionUser(actor);

  const { t } = useTranslation(`company`);

  const { institution: myInstitution } = actor;

  const activities = company.activity;

  const {
    myBids,
    myStandingBids,
    myListings,
    myActiveDiscussions,
    myInstitutionBids,
    myInstitutionStandingBids,
    myInstitutionListings,
  } = activities;

  const hasPersonalActivity =
    myBids.length > 0 ||
    myStandingBids.length > 0 ||
    myListings.length > 0 ||
    myActiveDiscussions.length > 0;

  const hasInstitutionalActivity =
    myInstitutionBids.length > 0 ||
    myInstitutionStandingBids.length > 0 ||
    myInstitutionListings.length > 0;

  const myActivityTitle = isInstitutionUser
    ? t(`your_activity_on_behalf_of_institution`, {
        legalName: myInstitution?.legalName,
      })
    : t(`your_activity`);

  const myInstitutionActivityTitle = hasPersonalActivity
    ? t(`other_institution_activity`, {
        legalName: myInstitution?.legalName,
      })
    : t(`institution_activity`, {
        legalName: myInstitution?.legalName,
      });

  const showEmptyState =
    !isDesktopView && !hasPersonalActivity && !hasInstitutionalActivity;

  return (
    <>
      {hasPersonalActivity && (
        <ActivitySection title={myActivityTitle}>
          <YourActivityListings listings={myListings} company={company} />
          <YourActivityBids bids={myBids} />
          <YourActivityStandingBids
            standingBids={myStandingBids}
            company={company}
          />
          <YourActivityDiscussions discussions={myActiveDiscussions} />
        </ActivitySection>
      )}
      {hasInstitutionalActivity && (
        <ActivitySection title={myInstitutionActivityTitle}>
          <YourActivityListings
            listings={myInstitutionListings}
            company={company}
          />
          <YourActivityBids bids={myInstitutionBids} />
          <YourActivityStandingBids
            standingBids={myInstitutionStandingBids}
            company={company}
          />
        </ActivitySection>
      )}
      {showEmptyState && (
        <ActivitySection title={t(`your_activity`)}>
          <ActivityGroupEmptyState
            w="full"
            message={t(`currently_no_activity`, {
              name: company.name,
            })}
          />
        </ActivitySection>
      )}
    </>
  );
};

export const YourActivity = ({ companyId }: YourActivityProps) => {
  const query = useCompanyPageYourActivityCompanyByIdV2Query({
    variables: { id: companyId },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<YourActivitySkeleton />}>
      {({ data: { company } }) => <YourActivityContent company={company} />}
    </WithQuery>
  );
};
