import { createContext, useEffect, useMemo } from "react";

import { useRouter } from "next/router";

import { TransactionExecutionPageSkeleton } from "@/components/layouts";
import {
  TransactionExecutionPageTransactionFragment,
  useTransactionExecutionPageTransactionByIdQuery,
} from "@/gql";
import {
  useCurrentActor,
  getTransactionIsBuySide,
  getTransactionIsSellSide,
} from "@/hooks";

type TransactionExecutionPageContextProps = {
  isBuyer: boolean;
  isSeller: boolean;
  transaction: TransactionExecutionPageTransactionFragment;
};

export const TransactionExecutionPageContext = createContext<
  TransactionExecutionPageContextProps | undefined
>(undefined);

const TransactionExecutionPageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const actor = useCurrentActor();
  const router = useRouter();
  const { id } = router.query;

  const { data, loading, startPolling, stopPolling } =
    useTransactionExecutionPageTransactionByIdQuery({
      variables: {
        id: id as string,
      },
    });

  const transaction = data?.transactionById;
  // This is a workaround for a possible race condition for assigning
  // a workflow to a transaction. Will need to remove polling here in the future.
  useEffect(() => {
    startPolling(1000);
    if (transaction?.workflow) {
      stopPolling();
    }
  }, [transaction?.workflow]);

  const context = useMemo(
    () =>
      transaction
        ? {
            transaction,
            isBuyer: getTransactionIsBuySide({
              actor,
              transaction,
            }),
            isSeller: getTransactionIsSellSide({
              actor,
              transaction,
            }),
          }
        : undefined,
    [actor, transaction],
  );

  if (loading || !transaction) {
    return <TransactionExecutionPageSkeleton />;
  }

  return (
    <TransactionExecutionPageContext.Provider value={context}>
      {children}
    </TransactionExecutionPageContext.Provider>
  );
};

export default TransactionExecutionPageProvider;
