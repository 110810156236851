import { Form } from "formik";
import { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  CardBody,
  CardFooter,
  HStack,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  CreateBuyerEntityModal,
  CreateSellerEntityModal,
} from "@/components/entities";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  useLinkEntityMutation,
  TransactionPageTransactionByIdDocument,
  LinkEntityInput,
  LinkEntityMutationVariables,
  UserWithInstitutionFragment,
  EntityType,
  BasicUserEntityFragment,
  TransactingEntityItemEntityFragment,
  AddEntityInput,
} from "@/gql";
import { getSuggestedLegalName, useCustomToast } from "@/hooks";

import CreateSuggestedEntityConfirmationButton from "./CreateSuggestedEntityConfirmationButton";
import { EntitiesSelect } from "./EntitiesSelect";
import { EntityCardTypes } from "./EntityCard";
import { EntityItem } from "./EntityItem";

type EntityFormProps = {
  actor: UserWithInstitutionFragment;
  displayId: string;
  entities: TransactingEntityItemEntityFragment[];
  isInstitution: boolean;
  transactionId: string;
  type: EntityCardTypes;
};

export const linkEntityValidationSchema = (
  t: TFunction<"transactions", undefined>,
) =>
  Yup.object().shape({
    entityId: Yup.string().required(t(`select_entity_required`)),
    transactionId: Yup.string().required(t(`transaction_id_required`)),
  });

const mapLinkEntityVariables = (
  input: LinkEntityInput,
): LinkEntityMutationVariables => ({
  input,
});

const EntityForm = withCurrentActor(
  ({
    actor,
    displayId,
    entities,
    isInstitution,
    transactionId,
    type,
  }: EntityFormProps) => {
    const { t } = useTranslation(`transactions`);

    const { successToast } = useCustomToast();
    const onSuccess = () => {
      successToast(
        t(`entities.entity_saved_successfully`, {
          ns: `account`,
        }),
      );
    };
    const mutation = useLinkEntityMutation({
      refetchQueries: [TransactionPageTransactionByIdDocument],
    });

    const hasMultipleEntities = entities?.length > 1;
    const hasSingleEntitiy = entities?.length === 1;
    const isBuyer = type === EntityCardTypes.Buyer;

    const { isOpen, onClose, onOpen } = useDisclosure();
    const showSuggestedEntity = entities.length === 0;
    const suggestedInstitutionEntity = !!actor?.institution?.entityType
      ? ({
          jurisdictionOfFormation: actor.institution?.country,
          legalName: actor.institution?.legalName,
          type: actor?.institution?.entityType as string,
        } as BasicUserEntityFragment)
      : ({
          jurisdictionOfFormation: actor.institution?.country,
          legalName: getSuggestedLegalName(actor),
          type: EntityType.Other,
          otherType: `Other`,
        } as BasicUserEntityFragment);

    const suggestedEntity = isInstitution
      ? suggestedInstitutionEntity
      : ({
          jurisdictionOfFormation: actor.country,
          legalName: getSuggestedLegalName(actor),
          type: EntityType.Individual,
        } as BasicUserEntityFragment);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { jurisdictionOfFormation, ...otherSuggestedEntityFields } =
      suggestedEntity;

    const suggestedEntityInput = {
      ...otherSuggestedEntityFields,
      jurisdictionOfFormationId:
        suggestedEntity.jurisdictionOfFormation?.id ?? ``,
      transactionId,
    };

    const getIndividuali18Key = match<
      { isBuyer: boolean; hasMultipleEntities: boolean },
      | `confirm_multiple_buyer_information_description`
      | `confirm_multiple_seller_information_description`
      | `confirm_single_buyer_information_description`
      | `confirm_single_seller_information_description`
    >({
      isBuyer,
      hasMultipleEntities,
    })
      .with(
        {
          hasMultipleEntities: true,
          isBuyer: true,
        },
        () => `confirm_multiple_buyer_information_description`,
      )
      .with(
        {
          hasMultipleEntities: true,
          isBuyer: false,
        },
        () => `confirm_multiple_seller_information_description`,
      )
      .with(
        {
          hasMultipleEntities: false,
          isBuyer: true,
        },
        () => `confirm_single_buyer_information_description`,
      )
      .otherwise(() => `confirm_single_seller_information_description`);

    const getInstituionali18Key = match<
      { isBuyer: boolean; hasMultipleEntities: boolean },
      | `confirm_multiple_purchasing_entity_description`
      | `confirm_multiple_selling_entity_description`
      | `confirm_single_purchasing_entity_description`
      | `confirm_single_selling_entity_information_description`
    >({
      isBuyer,
      hasMultipleEntities,
    })
      .with(
        {
          hasMultipleEntities: true,
          isBuyer: true,
        },
        () => `confirm_multiple_purchasing_entity_description`,
      )
      .with(
        {
          hasMultipleEntities: true,
          isBuyer: false,
        },
        () => `confirm_multiple_selling_entity_description`,
      )
      .with(
        {
          hasMultipleEntities: false,
          isBuyer: true,
        },
        () => `confirm_single_purchasing_entity_description`,
      )
      .otherwise(() => `confirm_single_selling_entity_information_description`);

    return (
      <>
        <FormikQL
          initialValues={{
            entityId: hasSingleEntitiy ? entities[0].id : ``,
            transactionId,
          }}
          mapVariables={mapLinkEntityVariables}
          mutation={mutation}
          mutationNames={[]}
          onSuccess={onSuccess}
          validationSchema={linkEntityValidationSchema(t)}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <CardBody>
                <Stack gap={4}>
                  {isInstitution && (
                    <Text>
                      <Trans
                        components={{
                          a: (
                            <Link
                              fontWeight={500}
                              href="mailto:execution@hiive.com"
                              rel="noreferrer"
                              target="_blank"
                            />
                          ),
                          bold: <strong />,
                        }}
                        i18nKey={getInstituionali18Key}
                        ns="transactions"
                        values={{
                          email: `execution@hiive.com`,
                          displayId,
                        }}
                      />
                    </Text>
                  )}
                  {!isInstitution && (
                    <Text>
                      <Trans
                        components={{
                          a: (
                            <Link
                              fontWeight={500}
                              href="mailto:execution@hiive.com"
                              rel="noreferrer"
                              target="_blank"
                            />
                          ),
                          bold: <strong />,
                        }}
                        i18nKey={getIndividuali18Key}
                        ns="transactions"
                        values={{ displayId }}
                      />
                    </Text>
                  )}
                  {hasMultipleEntities && (
                    <EntitiesSelect
                      entities={entities}
                      onChange={(item) => setFieldValue(`entityId`, item.id)}
                    />
                  )}
                  {hasSingleEntitiy && (
                    <EntityItem
                      entity={entities[0]}
                      showDate={false}
                      showStatus={false}
                      showRemoveButton={false}
                    />
                  )}
                  {showSuggestedEntity && (
                    <EntityItem
                      entity={suggestedEntity}
                      showDate={false}
                      showStatus={false}
                      showRemoveButton={false}
                    />
                  )}
                </Stack>
              </CardBody>
              <CardFooter py={6}>
                <HStack justifyContent="flex-end" w="full">
                  <HiiveButton
                    maxW="unset"
                    onClick={() => onOpen()}
                    observabilityLabel="[TransactingEntityCard] Add Entity"
                    size="xl"
                    variant="rounded-outline-grey"
                    w={{ base: `full`, md: `170px` }}
                  >
                    {t(hasMultipleEntities ? `add_entity` : `change`)}
                  </HiiveButton>
                  {!showSuggestedEntity && (
                    <HiiveButton
                      isLoading={isSubmitting}
                      maxW="unset"
                      observabilityLabel="[TransactingEntityCard] Confirm"
                      size="xl"
                      type="submit"
                      variant="rounded-solid-salmon"
                      w={{ base: `full`, md: `170px` }}
                    >
                      {t(`confirm`, {
                        ns: `translation`,
                      })}
                    </HiiveButton>
                  )}
                  {showSuggestedEntity && (
                    <CreateSuggestedEntityConfirmationButton
                      input={suggestedEntityInput as AddEntityInput} // Suggested Entity Fields will always have all the required information
                    />
                  )}
                </HStack>
              </CardFooter>
            </Form>
          )}
        </FormikQL>
        {isBuyer && (
          <CreateBuyerEntityModal
            actor={actor}
            isOpen={isOpen}
            onClose={onClose}
            transactionId={transactionId}
          />
        )}
        {!isBuyer && (
          <CreateSellerEntityModal
            actor={actor}
            isOpen={isOpen}
            onClose={onClose}
            transactionId={transactionId}
          />
        )}
      </>
    );
  },
);

export default EntityForm;
