import { ReactNode, createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useRouter } from "next/router";

import { Center, Spinner } from "@chakra-ui/react";

import { getAccessToken } from "@/auth";
import { RootState } from "@/state";
import { constants } from "@/utils";
import { isUnauthenticatedRoute } from "@/utils/routes";

type SessionState = {
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
};

const DEFAULT_SESSION: SessionState = {
  token: null,
  isAuthenticated: false,
  loading: true,
};
const { AUTH0_ENABLED } = constants;

export const Session = createContext<SessionState>(DEFAULT_SESSION);

export function SessionProvider({ children }: { children: ReactNode }) {
  const [session, setSession] = useState(DEFAULT_SESSION);
  const hiiveSession = useSelector((state: RootState) => state.auth);
  const { asPath } = useRouter();
  const onAuthenticatedPage = !isUnauthenticatedRoute(asPath);
  useEffect(() => {
    (async () => {
      if (AUTH0_ENABLED) {
        const token = onAuthenticatedPage ? await getAccessToken() : null;

        setSession((session) => ({
          ...session,
          loading: false,
          isAuthenticated: !!token,
          token,
        }));
      } else {
        const { token } = hiiveSession;

        setSession((session) => ({
          ...session,
          loading: false,
          isAuthenticated: !!token,
          token,
        }));
      }
    })();
  }, [hiiveSession]);

  if (session.loading && onAuthenticatedPage) {
    return (
      <Center m={20}>
        <Spinner />
      </Center>
    );
  }
  return <Session.Provider value={session}>{children}</Session.Provider>;
}
