import { WarningCircle } from "@phosphor-icons/react";
import { t } from "i18next";
import { Trans } from "react-i18next";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  Button,
  Circle,
} from "@chakra-ui/react";

import {
  ExecutionModalFooter,
  ExecutionModalHeader,
  PoweredByModernTreasury,
} from "@/components/common";
import { useColors } from "@/hooks";

export type VerifyBankAccountErrorType = "incorrect_amount" | "general";

const errorContent: Record<
  VerifyBankAccountErrorType,
  { titleKey: string; descriptionKey: string; ctaKey: string }
> = {
  incorrect_amount: {
    titleKey: `verify_error_incorrect_amount_title`,
    descriptionKey: `verify_error_incorrect_amount_description`,
    ctaKey: `try_again`,
  },
  general: {
    titleKey: `verify_error_general_title`,
    descriptionKey: `verify_error_general_description`,
    ctaKey: `okay`,
  },
};

const VerifyBankAccountErrorModal = ({
  isOpen,
  onClose,
  type = `general`,
}: {
  isOpen: boolean;
  onClose: () => void;
  type?: VerifyBankAccountErrorType;
}) => {
  const [red800] = useColors([`red.800`]);

  return (
    <Modal variant="texas" isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent w="2xl" height={{ base: `full`, md: `auto` }}>
        <ExecutionModalHeader>
          <Text>{t(`verify_your_bank_account`, { ns: `execution` })}</Text>
        </ExecutionModalHeader>
        <ModalBody>
          <VStack spacing={6} textAlign="center">
            <Circle size="108" bgColor="red.50">
              <WarningCircle size={64} weight="fill" color={red800} />
            </Circle>
            <Text textStyle="heading-3xl" color="grey.700">
              {/* @ts-expect-error dynamic key */}
              <Trans i18nKey={errorContent[type].titleKey} ns="execution" />
            </Text>
            <Text textStyle="text-sm">
              <Trans
                // @ts-expect-error dynamic key
                i18nKey={errorContent[type].descriptionKey}
                ns="execution"
              />
            </Text>
            <PoweredByModernTreasury />
          </VStack>
        </ModalBody>
        <ExecutionModalFooter>
          <Button onClick={onClose}>
            {/* @ts-expect-error dynamic key */}
            <Trans i18nKey={errorContent[type].ctaKey} ns="execution" />
          </Button>
        </ExecutionModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerifyBankAccountErrorModal;
