import { useCurrentActor } from "./useCurrentActor";

const useMyInstitutionDisplayName = () => {
  const actor = useCurrentActor();

  if (actor.institutionId) {
    return actor.institution?.legalName || `your institution`;
  }

  return null;
};

export default useMyInstitutionDisplayName;
