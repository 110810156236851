import { useTranslation } from "react-i18next";

import { Card, CardBody, Text, VStack } from "@chakra-ui/react";

import {
  ActivityGroup,
  ActivitySection,
  MailtoLink,
} from "@/components/common";
import { ListingPageBidCard } from "@/components/postings";
import {
  ListingPermission,
  UserPermissionV2,
  BidState,
  ListingPageBrokerCounterpartyActivityListingFragment,
  ListingPageBrokerCounterpartyActivityBidFragment,
} from "@/gql";
import { iHaveEntityPermission, useIHavePermission } from "@/hooks";
import { getSecuritySpecialistContact, getIsEitherFund } from "@/utils";

import { PlaceStandingBidCTACard } from "./PlaceStandingBidCTACard";
import { SubmitBidCard } from "./SubmitBidCard";

const OpenBids = ({
  bids,
}: {
  readonly bids: readonly ListingPageBrokerCounterpartyActivityBidFragment[];
}) => (
  <ActivityGroup title="Your Bid for this Listing">
    {bids.map((bid) => (
      <ListingPageBidCard key={bid.id} bid={bid} />
    ))}
  </ActivityGroup>
);

export const BrokerCounterpartyActivity = ({
  listing,
}: {
  readonly listing: ListingPageBrokerCounterpartyActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const canPlaceStandingBid = useIHavePermission(
    UserPermissionV2.PlaceStandingBid,
  );
  const actorHasPlaceBidPermission = useIHavePermission(
    UserPermissionV2.PlaceBid,
  );
  const canPlaceBid = iHaveEntityPermission(
    listing,
    ListingPermission.PlaceBid,
  );
  const isSolicited = listing.solicited;

  const openBids = listing.bids.filter(
    ({ state }) =>
      ![BidState.Expired, BidState.Rejected, BidState.Withdrawn].includes(
        state,
      ),
  );
  const securitySpecialistContact = getSecuritySpecialistContact(
    listing.company,
  );

  const showStandingBidCTACard =
    isSolicited &&
    canPlaceStandingBid &&
    !getIsEitherFund(listing.transferMethod);

  const showSecuritySpecialistCard = isSolicited;

  const showSubmitBidCard =
    !isSolicited && actorHasPlaceBidPermission && canPlaceBid;

  const showCTAs =
    showStandingBidCTACard || showSecuritySpecialistCard || showSubmitBidCard;

  return (
    <ActivitySection>
      {showCTAs && (
        <VStack spacing={4} w="full">
          {showStandingBidCTACard && (
            <PlaceStandingBidCTACard company={listing.company} />
          )}
          {showSecuritySpecialistCard && (
            <Card w="full">
              <CardBody>
                <VStack alignItems="flex-start">
                  <Text>
                    {t(`reach_out_to_hiive_security_specialist`, {
                      companyName: listing.company.name,
                    })}
                  </Text>
                  <MailtoLink
                    email={securitySpecialistContact}
                    subject={`Questions on ${listing.company.name}`}
                    fontWeight="medium"
                  >
                    {securitySpecialistContact}
                  </MailtoLink>
                </VStack>
              </CardBody>
            </Card>
          )}
          {showSubmitBidCard && <SubmitBidCard listing={listing} />}
        </VStack>
      )}
      <OpenBids bids={openBids} />
    </ActivitySection>
  );
};
