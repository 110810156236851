import { Trans, useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PleaseNote,
  WithQuery,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { BidHighFeesWarning } from "@/components/postings";
import {
  AcceptBidModalBidFragment,
  AcceptBidModalCompanyFragment,
  AcceptBidMutation,
  BidState,
  UserWithInstitutionFragment,
  useAcceptBidModalCompanyByIdQuery,
  useAcceptBidModalCompanyByIdV2Query,
} from "@/gql";
import { useCurrentActor, useModal } from "@/hooks";
import { useBaseFeeRemoval, useTexasCopy } from "@/hooks/featureFlags";
import {
  abbrCountLabel,
  getBidLabel,
  getCountLabel,
  constants,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getAreFeesHighForBid,
  getIsEitherFund,
  getIsOtherMethod,
  getLongDocumentTitleByTransferMethod,
  makeUrl,
  getIsUnaccreditedSeller,
  appendSellerCompanyIdToUrl,
} from "@/utils";

import { useAcceptBidSequenceModalStepFormContext } from "./AcceptBidSequenceModalStepFormContext";
import { StepKeys } from "./steps";

const ResidualListingWarning = () => (
  <PleaseNote>
    <Text textStyle="deprecated-heading-lg">
      By accepting this bid, the remaining shares in your listing will drop
      below our minimum of {constants.min_listing_size.text} and will no longer
      be listed for sale.
    </Text>
  </PleaseNote>
);

const RejectExcessBidsWarning = () => (
  <PleaseNote>
    <Text textStyle="deprecated-heading-lg">
      After you accept this bid, some other bids will be automatically rejected
      as they cannot be fulfilled with the remaining shares in your listing.
    </Text>
  </PleaseNote>
);

interface AcceptBidConfirmationModalProps
  extends StepPropsV2<StepKeys, Record<string, never>> {
  readonly bid: AcceptBidModalBidFragment;
  readonly actor: UserWithInstitutionFragment;
}

type AcceptBidConfirmationModalV2Props = {
  readonly bid: AcceptBidModalBidFragment;
} & StepPropsV2<StepKeys, Record<string, never>>;

const AcceptBidConfirmationModalContent = ({
  bid,
  company,
  isSubmitting,
  actor,
  stepRouter: { stepControls },
}: AcceptBidConfirmationModalProps & {
  readonly company: AcceptBidModalCompanyFragment;
}) => {
  const { t } = useTranslation();
  const { t: bidsT } = useTranslation(`bids`);
  const isTexasCopyEnabled = useTexasCopy();
  const isBaseFeeReductionEnabled = useBaseFeeRemoval();

  const { closeModal } = useModal();

  const router = useRouter();

  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const documentTitle = getLongDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );

  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const listingNumSharesAfter =
    bid.listing.numSharesAvailable - bid.numSharesActual;

  const useLOITerms =
    !getIsEitherFund(bid.listing.transferMethod) &&
    !getIsOtherMethod(bid.listing.transferMethod);
  const bidType = getBidLabel(bid.listing.transferMethod).toLowerCase();
  const share = getCountLabel(bid.listing.transferMethod);
  const abbrShare = abbrCountLabel(bid.listing.transferMethod);
  const textKey = getIsOtherMethod(bid.listing.transferMethod)
    ? `accept_bid_terms_and_conditions`
    : `seller_accept_bid_terms_and_conditions`;

  const willRejectExcessBids = bid.listing.bids
    .filter(
      (otherBid) =>
        otherBid.id !== bid.id && otherBid.state === BidState.Active,
    )
    .some((otherBid) => otherBid.numShares > listingNumSharesAfter);

  const { submitMutation } = useAcceptBidSequenceModalStepFormContext();

  const onClickSubmit = () =>
    submitMutation().then((response: AcceptBidMutation) => {
      const transaction = response.acceptBid.bid?.transaction;

      if (!transaction) return;

      const nextUrl = isUnaccreditedSeller
        ? appendSellerCompanyIdToUrl(makeUrl(transaction), company.id)
        : makeUrl(transaction);

      router.push(nextUrl);
      stepControls.nextStep();
    });

  const isHighFeesWarningVisible =
    !isBaseFeeReductionEnabled &&
    getAreFeesHighForBid({
      pricePerShare: bid.pricePerShare,
      numberOfShares: getBidNumSharesActual(bid),
    });

  return (
    <>
      <HiiveModalHeader>
        {isTexasCopyEnabled
          ? bidsT(`accept_bid_bid_id`, { bidId: bid.displayId, bidType })
          : t(`are_you_sure_accept`, { bidType })}
      </HiiveModalHeader>
      <ModalBody>
        <VStack align="flex-start" spacing={3}>
          <Text textStyle={isTexasCopyEnabled ? `heading-md` : `heading-lg`}>
            {`${bid.displayId}: ${numShares} ${share}s @ ${pricePerShare}/${abbrShare}`}
          </Text>
          {isHighFeesWarningVisible && <BidHighFeesWarning bid={bid} />}
          {willRejectExcessBids && <RejectExcessBidsWarning />}
          {bid.listingBelowMinLotOnAcceptance && <ResidualListingWarning />}
          {isTexasCopyEnabled ? (
            <Text>
              <Trans
                ns="bids"
                i18nKey="accepting_this_bid_counts_as"
                values={{ bidType }}
                components={{
                  italic: (
                    <Link
                      key="terms-and-conditions"
                      target="_blank"
                      textDecorationLine="underline"
                      href={constants.t_and_c_url}
                    />
                  ),
                }}
              />
              <Text mt={3}>
                {bidsT(
                  `once_you_ve_accepted_the_transaction_process_will_begin`,
                  { bidType },
                )}
              </Text>
            </Text>
          ) : (
            <Text>
              <Trans
                i18nKey={textKey}
                components={{
                  italic: (
                    <Link
                      key="terms-and-conditions"
                      href={constants.t_and_c_url}
                      target="_blank"
                      textDecorationLine="underline"
                    />
                  ),
                }}
              />
            </Text>
          )}
          {useLOITerms && !isTexasCopyEnabled && (
            <Text>
              {t(`loi_terms`, { documentTitle, buyerOrSeller: `buyer` })}
            </Text>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[AcceptBidConfirmation/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          observabilityLabel="[AcceptBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText={t(`yes_accept_bid`, { bidType })}
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};

const AcceptBidConfirmationModal = ({
  bid,
  ...props
}: AcceptBidConfirmationModalProps) => {
  const query = useAcceptBidModalCompanyByIdQuery({
    variables: { id: bid.company.id },
  });

  return (
    <WithQuery query={query}>
      {({ data: { companyById: company } }) => (
        <AcceptBidConfirmationModalContent
          company={company}
          bid={bid}
          {...props}
        />
      )}
    </WithQuery>
  );
};

export const AcceptBidConfirmationModalV2 = ({
  bid,
  ...props
}: AcceptBidConfirmationModalV2Props) => {
  const actor = useCurrentActor();

  const query = useAcceptBidModalCompanyByIdV2Query({
    variables: { id: bid.company.id },
  });

  return (
    <WithQuery query={query}>
      {({ data: { company } }) => (
        <AcceptBidConfirmationModalContent
          company={company}
          bid={bid}
          actor={actor}
          {...props}
        />
      )}
    </WithQuery>
  );
};

export default withCurrentActor(AcceptBidConfirmationModal);
