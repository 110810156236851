import { useFormikContext } from "formik";
import { isNil } from "lodash/fp";
import { forwardRef, useDeferredValue } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { CompanyEligibilityCriteriaAlert } from "@/components/common";
import { FormField, MoneyInput } from "@/components/form";
import {
  AskPriceComparisonChart,
  AskPriceComparisonChartDisclaimer,
  FeesDisclaimer,
  getHasAskPriceChart,
  HighFeesWarning,
  ListingFeeBreakdown,
} from "@/components/listings";
import { UnaccreditedSellerCreateListingPageByIdCompanyFragment } from "@/gql";
import { useDebouncedCallback } from "@/hooks";
import { useBaseFeeRemoval } from "@/hooks/featureFlags";
import { constants, getAreFeesHigh, Nullable } from "@/utils";

import { CreateListingFormValues } from "./types";

const SharePriceCardContent = forwardRef<
  HTMLDivElement,
  { readonly company: UnaccreditedSellerCreateListingPageByIdCompanyFragment }
>(({ company }, ref) => {
  const {
    values: { pricePerShare, numShares },
    touched,
    setFieldTouched,
    errors,
  } = useFormikContext<Nullable<CreateListingFormValues>>();

  const { t } = useTranslation();
  const isBaseFeeReductionEnabled = useBaseFeeRemoval();

  const areFeesHigh = getAreFeesHigh({ pricePerShare, numShares });
  const showHighFeesWarning =
    !!touched.numShares && areFeesHigh && !isBaseFeeReductionEnabled;

  const deferredPricePerShare = useDeferredValue(pricePerShare);

  const askPriceInDollars = !isNil(deferredPricePerShare)
    ? deferredPricePerShare
    : 0;

  const hasAskPriceChart = getHasAskPriceChart(company);

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  const onChangeLotFields = useDebouncedCallback(() => {
    if (!touched.numShares && areFeesHigh) {
      setFieldTouched(`numShares`, true);
      setFieldTouched(`pricePerShare`, true);
    }
  }, 500);

  const eligibilityCriterionText = company.companyEligibilityCriterion?.text;
  const showEligibilityCriterionText = !isNil(eligibilityCriterionText);

  return (
    <Card w="full" ref={ref}>
      <CardHeader>
        <Text textStyle="heading-sm">1. {t(`share_price`)}</Text>
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={12} columnGap={6} rowGap={4}>
          <GridItem colSpan={12}>
            <VStack alignItems="flex-start">
              <Text textStyle="heading-2xs">
                {t(`how_to_think_about_pricing`, { companyName: company.name })}
              </Text>
              <Text>
                {t(`how_to_think_about_pricing_description`, {
                  companyName: company.name,
                })}
              </Text>
              {showEligibilityCriterionText && (
                <CompanyEligibilityCriteriaAlert
                  markdown={eligibilityCriterionText}
                  companyName={company.name}
                />
              )}
            </VStack>
          </GridItem>
        </SimpleGrid>
      </CardBody>
      <CardBody>
        <SimpleGrid columns={12} columnGap={6} rowGap={4}>
          <GridItem colSpan={12}>
            <Text textStyle="heading-2xs">{t(`share_details`)}</Text>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 5 }}>
            <VStack spacing={7}>
              <FormField.Control name="numShares">
                <FormField.NumberInput
                  label={t(`how_many_shares_wish_to_sell`)}
                  onChange={onChangeLotFields}
                />
                {errors.numShares !== minTransactionSizeError && (
                  <FormField.Error />
                )}
              </FormField.Control>
              <MoneyInput
                label={t(`at_what_price`)}
                name="pricePerShare"
                onChange={onChangeLotFields}
              />
            </VStack>
          </GridItem>
          <GridItem
            colStart={{ base: 0, xl: 6, "2xl": 7 }}
            colSpan={{ base: 12, xl: 7, "2xl": 6 }}
          >
            <VStack w="full" spacing={4}>
              <ListingFeeBreakdown
                pricePerShare={pricePerShare}
                numShares={numShares}
                companyId={company.id}
              />
              {showHighFeesWarning && <HighFeesWarning />}
            </VStack>
          </GridItem>
          <GridItem colSpan={12}>
            <FeesDisclaimer />
          </GridItem>
        </SimpleGrid>
      </CardBody>
      {hasAskPriceChart && (
        <CardBody>
          <VStack alignItems="flex-start" spacing={4}>
            <Text textStyle="heading-2xs">
              {t(`how_your_ask_price_compares`)}
            </Text>
            <AskPriceComparisonChart
              company={company}
              askPriceInDollars={askPriceInDollars}
            />
            <AskPriceComparisonChartDisclaimer />
          </VStack>
        </CardBody>
      )}
    </Card>
  );
});

export const SharePriceCardV2 = forwardRef<
  HTMLDivElement,
  { readonly company: UnaccreditedSellerCreateListingPageByIdCompanyFragment }
>(({ company }, ref) => <SharePriceCardContent ref={ref} company={company} />);
