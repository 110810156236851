import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { hasSubscription } from "@jumpn/utils-graphql";
import { Socket as PhoenixSocket } from "phoenix";

import { getAccessToken } from "@/auth";
import { ApolloClientConfig } from "@/hooks/useApolloClient";
import { getApiUrl } from "@/hooks/useServerPreview";
import { constants } from "@/utils";

import errorLink from "./error-link";

const { AUTH0_ENABLED } = constants;

const APOLLO_CACHE = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listCompanies: relayStylePagination(),
      },
    },
  },
});

const getApiHost = (url: string | null, port: string | number) =>
  url || process.env.NEXT_PUBLIC_API_HOST || `http://localhost:${port}/`;

const getApiWsHost = (url: string | null, port: string | number) =>
  url
    ? url.replace(/^(https|http)/, `wss`)
    : process.env.NEXT_PUBLIC_API_WS_HOST || `ws://localhost:${port}/`;

const createClient = ({ apiUrl, token }: ApolloClientConfig) => {
  const port = process.env.NEXT_PUBLIC_PORT || 4000;
  const apiHost = getApiHost(apiUrl || null, port);
  const apiWsHost = getApiWsHost(apiUrl || null, port);
  const version = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
  const graphqlUri = `${apiHost}graphql`;
  const websocketUri = `${apiWsHost}socket`;

  const httpLink = createHttpLink({
    uri: graphqlUri,
  });

  const authLink = setContext(
    async (
      _: unknown,
      { headers }: { readonly headers: Record<string, string> },
    ) => {
      const bearerToken = AUTH0_ENABLED ? await getAccessToken() : token;
      return {
        headers: {
          ...headers,
          "x-hiive-client-version": version ? version.slice(0, 7) : null,
          ...(bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {}),
        },
      };
    },
  );

  const authedHttpLink = authLink.concat(httpLink);

  const absintheSocketLink = () => {
    const phoenixSocket = new PhoenixSocket(websocketUri, {
      params: () => (token ? { authToken: token } : {}),
    });

    const absintheSocket = AbsintheSocket.create(phoenixSocket);
    return createAbsintheSocketLink(absintheSocket);
  };

  // websockets break SSR, therefore enabling it only in the browser
  const link = process.browser
    ? split(
        ({ query }) => hasSubscription(query),
        absintheSocketLink() as unknown as ApolloLink,
        authedHttpLink,
      )
    : authedHttpLink;

  const client = new ApolloClient({
    link: from([errorLink, link]),
    cache: APOLLO_CACHE,
  });

  return client;
};

const client = createClient({ apiUrl: getApiUrl() });

export { client, createClient };
