export default {
  accepted: `accepted`,
  active_transactions: `active transactions`,
  active_task_one: `active task`,
  active_task_other: `active tasks`,
  approved: `approved`,
  awaiting_closing: `awaiting closing`,
  awaiting_signatures: `Awaiting signatures on the {{documentTitle}}`,
  awaiting_your_signature: `Awaiting your signature on the {{documentTitle}}`,
  bid_accepted: `bid accepted`,
  cancelled: `cancelled`,
  closed_fee_paid: `closed fee paid`,
  closed_fee_pending: `closed fee pending`,
  complete: `complete`,
  confirmed: `confirmed`,
  expired: `expired`,
  in_review: `in review`,
  issuer_approval_declined: `issuer approval declined`,
  issuer_approved: `issuer approved`,
  issuer_pending_approval: `issuer pending approval`,
  new_tasks: `New Tasks`,
  my_transactions: `my transactions`,
  other_transactions: `other {{name}} transactions`,
  pending: `pending`,
  review_and_sign: `Review and Sign`,
  send_to_customer: `Send {{type}} to customer`,
  signed: `signed`,
  submitted: `submitted`,
  task_one: `{{count}} task`,
  task_other: `{{count}} tasks`,
  watchlist_highest_bid: `Highest Bid`,
  watchlist_lowest_ask: `Lowest Ask`,
  watchlist_last_accepted_bid: `Last Accepted Bid`,
  company_is_not_currently_tradeable_on_hiive: `{{companyName}} is not currently tradable on Hiive`,
  unfortunately_shares_cannot_be_bought: `Unfortunately, {{companyName}} shares cannot be bought or sold on Hiive at this time.`,
  any_active_listings_have_been_removed: `Any active listings for {{companyName}} have been removed from our platform.`,
  if_you_are_currently_in_the_middle_of_a_transaction: `If you're currently in the middle of a {{companyName}} transaction, our team will be in touch.`,
  if_you_have_any_questions: `If you'd like more information about this or have any questions, feel free to reach out to us at <mailto>support@hiive.com</mailto>.`,
  listings: `Listings`,
  standing_bids: `Standing Bids`,
  your_listings_on_behalf_of: `Your Listings on behalf of {{institutionName}}`,
  your_listings: `Your Listings`,
  your_standing_bids_on_behalf_of: `Your Standing Bids on behalf of {{institutionName}}`,
  your_standing_bids: `Your Standing Bids`,
  your_bids_on_behalf_of: `Your Bids on behalf of {{institutionName}}`,
  your_bids: `Your Bids`,
  other_institution_listings: `Other {{institutionName}} Listings`,
  other_institution_bids: `Other {{institutionName}} Bids`,
  other_institution_standing_bids: `Other {{institutionName}} Standing Bids`,
  your_activity: `Your Activity`,
};
