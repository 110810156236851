import { Trans } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { FeeBreakdown, FeeDiscountApplicationItems } from "@/components/common";
import {
  BidPageFeeBreakdownCommissionFragment,
  FeeDiscountApplicationState,
  TransactionState,
} from "@/gql";

interface FeeBreakdownProps {
  readonly pricePerShare: number;
  readonly numberOfShares: number;
  readonly commission?: BidPageFeeBreakdownCommissionFragment | null;
  readonly transactionState: TransactionState;
  readonly feeDiscountApplications?: FeeDiscountApplicationItems;
  readonly feeDiscountApplicationState?: FeeDiscountApplicationState;
}

const AdditionalDisclaimerText = () => (
  <Text align="left" mt={4} textStyle="text-xs">
    <Trans
      i18nKey="bid_fee_breakdown_disclaimer_text"
      components={{ bold: <strong /> }}
    />
  </Text>
);

export const BidFeeBreakdown = ({
  pricePerShare,
  numberOfShares,
  commission,
  transactionState,
  feeDiscountApplications,
  feeDiscountApplicationState,
}: FeeBreakdownProps) => {
  const {
    flatFeeAmount,
    commissionAmount,
    feeDiscountAmount,
    netFees,
    previousCommission,
  } = commission || {};

  const pendingIssuerApproval =
    transactionState === TransactionState.IssuerPendingApproval;

  return (
    <FeeBreakdown
      numShares={numberOfShares}
      pricePerShare={pricePerShare}
      feeDiscountApplications={feeDiscountApplications}
      feeDiscountApplicationState={feeDiscountApplicationState}
      flatFeeAmount={flatFeeAmount}
      commissionAmount={commissionAmount}
      feeDiscountAmount={feeDiscountAmount}
      previousCommission={previousCommission}
      netFees={netFees}
      disclaimerVariant="seller"
      disclaimer={pendingIssuerApproval && <AdditionalDisclaimerText />}
    />
  );
};
