import { getAccessToken as getAuth0AccessToken } from "@auth0/nextjs-auth0";
import { Auth0Client } from "@auth0/nextjs-auth0/server";

import constants from "@/utils/constants";

const { AUTH0_CONFIG, DEV_AUTH_ENABLED } = constants;

const clientBaseUrl =
  process.env.AUTH0_APP_BASE_URL ||
  `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;

const brokerBaseUrl =
  process.env.AUTH0_APP_BROKER_BASE_URL ||
  `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;

export const auth0Client = new Auth0Client({
  ...AUTH0_CONFIG,
  appBaseUrl: clientBaseUrl,
});
export const auth0BrokerClient = new Auth0Client({
  ...AUTH0_CONFIG,
  appBaseUrl: brokerBaseUrl,
});

export async function getAccessToken() {
  if (DEV_AUTH_ENABLED) {
    const response = await fetch(`/api/dev-access-token`);
    return response.json();
  }
  return getAuth0AccessToken();
}
