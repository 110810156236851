import { useEffect } from "react";

import { useListingFeeBreakdownBuyerCommissionInfoLazyQuery } from "@/gql";
import { useDebounce } from "@/hooks";
import { constants, currencyValue } from "@/utils";

import { BuyerCommissionBreakdown } from "./BuyerCommissionBreakdown";

interface FeeBreakdownBuyerCommissionInfoProps {
  readonly numShares?: number;
  readonly pricePerShare?: number;
  readonly buyerCommissionStructureId?: string | null;
}

export function FeeBreakdownBuyerCommissionInfo({
  numShares,
  pricePerShare,
  buyerCommissionStructureId,
}: FeeBreakdownBuyerCommissionInfoProps) {
  const { debounce, isDebouncing } = useDebounce();
  const [load, { data, loading }] =
    useListingFeeBreakdownBuyerCommissionInfoLazyQuery({
      fetchPolicy: `cache-and-network`,
    });

  const shouldSkipInitialLoad =
    (!numShares || !pricePerShare || !buyerCommissionStructureId) && !data;

  useEffect(() => {
    if (shouldSkipInitialLoad) return;
    if (!buyerCommissionStructureId) return;

    debounce(() => {
      const variables = {
        totalShares: numShares ?? 0,
        pricePerShare: currencyValue(pricePerShare ?? 0),
        buyerCommissionStructureId,
      };

      load({ variables });
    }, constants.commission_calculation_debounce_time);
  }, [buyerCommissionStructureId, numShares, pricePerShare]);

  const { buyerCommissionInfo } = data || {};

  const showLoader = isDebouncing || loading;

  if (!buyerCommissionStructureId) {
    return null;
  }

  return (
    <BuyerCommissionBreakdown
      buyerCommissionInfo={buyerCommissionInfo}
      numShares={numShares}
      pricePerShare={(pricePerShare ?? 0) * 100}
      isLoadingCommission={showLoader}
      buyerCommissionStructureId={buyerCommissionStructureId}
    />
  );
}
