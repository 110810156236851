/* eslint-disable functional/immutable-data */
import { onError } from "@apollo/client/link/error";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { GraphQLError } from "graphql";

import { redactDataPure } from "@/components/datadog";
import toastr, { NotificationDuration } from "@/libs/toastr";

interface GqlError extends GraphQLError {
  readonly code: string;
  readonly metadata: readonly string[];
}

const notFoundCodes = [`NOT_FOUND`, `UNAUTHORIZED`, `EXPIRED`, `FORBIDDEN`];
const somethingWentWrongCodes = [`INVALID_STATE`, `INTERNAL_SERVER_ERROR`];
const invitationExpiredCode = `INVITATION_EXPIRED`;

const filteredErrorCodes = [
  `UNAUTHORIZED`,
  `FORBIDDEN`,
  `INVALID_STATE`,
  `INTERNAL_SERVER_ERROR`,
];

const captureErrors = async (gqlErrors: readonly GqlError[]) => {
  gqlErrors
    .filter((error) => filteredErrorCodes.includes(error.code))
    .forEach((error) => {
      datadogRum.addError(error);
    });
};

const maybeRedirect = (
  gqlErrors: readonly GqlError[],
  fullErrorString: string,
) => {
  const apiUrl = sessionStorage.getItem(`api`);
  switch (true) {
    case gqlErrors.some((err) => err.code === `UNAUTHENTICATED`):
      // eslint-disable-next-line no-console
      console.error(`Apollo error(unauthenticated): errors`, fullErrorString);
      window.sessionStorage.clear();

      if (apiUrl) sessionStorage.setItem(`api`, apiUrl);

      return;
    case gqlErrors.some((err) => err.code === invitationExpiredCode):
      // eslint-disable-next-line no-console
      console.error(`Apollo error(invitationExpired): errors`, fullErrorString);
      window.location.href = `/invitation-expired`;
      return;
    case gqlErrors.some((err) => notFoundCodes.includes(err.code)):
      // eslint-disable-next-line no-console
      console.error(`Apollo error(notFound): errors`, fullErrorString);
      window.location.href = `/page-not-found`;
      return;
    case gqlErrors.some((err) => somethingWentWrongCodes.includes(err.code)):
      // eslint-disable-next-line no-console
      console.error(
        `Apollo error(somethingWentWrong): errors`,
        fullErrorString,
      );
      window.location.href = `/something-went-wrong`;
      return;
    case gqlErrors.some((err) => err.code === `INPUT_ERROR`):
      gqlErrors.forEach((err) => {
        toastr().error(
          err.message,
          err.metadata[0],
          NotificationDuration.NEVER,
        );
      });
      return;
    default:
      throw new Error(
        `Unhandled GraphQl Error Code: ${gqlErrors.map((e) => e.message).join(`, `)}`,
      );
  }
};

const errorLink = onError((params) => {
  const gqlErrors = params.graphQLErrors as readonly GqlError[];
  const { networkError } = params;
  const errorString = JSON.stringify(redactDataPure(params));

  // We get a high volume of `/something-went-wrong` views and should
  // log the errors to Datadog to help diagnose any issues.
  if (typeof window !== `undefined`) {
    (gqlErrors || []).forEach((error) => {
      datadogLogs.logger.error(`GraphQL Error`, {}, error);
    });

    if (params.networkError) {
      datadogLogs.logger.error(`Network Error`, {}, params.networkError);
    }
  }

  if (
    window.location.pathname === `/something-went-wrong` ||
    window.location.pathname === `/page-not-found`
  ) {
    // eslint-disable-next-line no-console
    console.error(
      `Apollo Error(already at error screen): errors`,
      errorString,
      `window.location.pathname:`,
      window.location.pathname,
    );
    return;
  }

  // Actual Server based Errors should contain a status code.
  // Since the networkError is a union of ServerError, ServerParseError, and
  // Error TS will only let us access shared fields without complaining. So we
  // check there's a statusCode in the error and redirect to something went
  // wrong.
  const isServerNetworkError = networkError && `statusCode` in networkError;
  if (isServerNetworkError) {
    // eslint-disable-next-line no-console
    console.error(
      `Apollo Error(redirecting to /something-went-wrong) errors:`,
      errorString,
    );
    window.location.href = `/something-went-wrong`;
    return;
  }

  // if there's a network error without a status and no gql errors, do nothing.
  // this is likely caused by a page refresh with inflight requests.
  if (networkError || !gqlErrors) {
    // eslint-disable-next-line no-console
    console.error(
      `Apollo Error(no NetworkError status or gqlErrors) errors:`,
      errorString,
    );
    return;
  }

  captureErrors(gqlErrors).then(() => maybeRedirect(gqlErrors, errorString));
});

export default errorLink;
