import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { Listing, useListingPageListingByIdQuery } from "@/gql";
import { useModal } from "@/hooks";
import {
  abbrCountLabel,
  getCountLabel,
  formatPricePerShare,
  formatShares,
  getIsHiiveFund,
  getListingNumOfShares,
  getSecuritySpecialistContact,
} from "@/utils";

type ModifyListingSuccessModalProps = {
  readonly listingId: string;
};

const getSuccessMessage = (
  listing: Pick<Listing, "listingPricePerShare"> & {
    readonly company: Pick<Listing["company"], "name">;
  },
  numShares: number,
  shareText: string,
  shareAbbreviation: string,
) =>
  !!listing.listingPricePerShare
    ? `Your ${listing.company.name} Listing for ${formatShares(
        numShares,
      )} ${shareText} at ${formatPricePerShare(
        listing.listingPricePerShare,
      )}/${shareAbbreviation} is now live on Hiive!`
    : `Your ${listing.company.name} Listing for ${formatShares(
        numShares,
      )} ${shareText} is now live on Hiive!`;

const ModifyListingSuccessModal = ({
  listingId,
}: ModifyListingSuccessModalProps) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const { data, loading } = useListingPageListingByIdQuery({
    variables: { id: listingId },
  });

  const listing = data?.listingById;

  if (!listing || loading) return null;

  const numShares = getListingNumOfShares(listing, false);

  const shareText = `${getCountLabel(listing.transferMethod)}s`;
  const shareAbbreviation = abbrCountLabel(listing.transferMethod);

  const message = getSuccessMessage(
    listing,
    numShares,
    shareText,
    shareAbbreviation,
  );

  const securitySpecialistContact = getSecuritySpecialistContact(
    listing.company,
  );

  const listingSuccessMsg = getIsHiiveFund(listing.transferMethod)
    ? `create_hiive_fund_listing_success_body`
    : `create_listing_success_body`;

  return (
    <div data-testid="create-listing-success-modal">
      <HiiveModalHeader>{t`listing_updated`}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-xl">{message}</Text>
          <Text>{t(listingSuccessMsg)}</Text>
          <Text>
            <Trans
              i18nKey="you_can_also_reach_out_anytime"
              values={{
                companyName: listing.company.name,
                email: securitySpecialistContact,
              }}
              components={{
                mailto: (
                  <Text as="span" fontWeight="medium">
                    <MailtoLink
                      email={securitySpecialistContact}
                      subject={`Contact ${listing.company.name} Security Specialist`}
                    />
                  </Text>
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[CreateListingSuccess/Submit]"
          type="submit"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </div>
  );
};

export default ModifyListingSuccessModal;
