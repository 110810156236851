import { isEmpty } from "lodash";
import { useEffect } from "react";

import { useCorrectPortalRedirect, useIsOnCorrectPortal } from "@/hooks";

import { AuthenticatedLayoutHookProps } from "./types";

export const useCorrectPortal = ({ actor }: AuthenticatedLayoutHookProps) => {
  const hasActor = !isEmpty(actor);
  const redirectToCorrectPortal = useCorrectPortalRedirect();
  const isOnCorrectPortal = useIsOnCorrectPortal();

  useEffect(() => {
    if (!hasActor || isOnCorrectPortal(actor)) {
      return;
    }

    (async () => {
      await redirectToCorrectPortal(actor);
    })();
  }, [actor]);
};
