import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { useRouter } from "next/router";

import { HStack, VStack } from "@chakra-ui/react";

import { InternalLink, Logo } from "@/components/common";
import { CompaniesSearch, NotificationMenu } from "@/components/nav";
import { UserWithInstitutionFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";
import config from "@/utils/constants";

import { NavLink } from "./NavLink";
import { ProfileMenu } from "./ProfileMenu";

const HiiveLogo = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  return getIsBroker(actor) ? (
    <InternalLink href="/dashboard" aria-label={t(`hiive_connect`)}>
      <Logo.ConnectGrey />
    </InternalLink>
  ) : (
    <InternalLink href="/dashboard" aria-label={t(`hiive`)}>
      <Logo.Grey />
    </InternalLink>
  );
};

const MainNav = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();

  const backofficeUrl =
    config.back_office_url[process.env.NEXT_PUBLIC_INFRA_ENV || `local`];

  return (
    <HStack gap={{ base: 1, xl: 4 }}>
      <NavLink href="/dashboard">{t(`dashboard`)}</NavLink>
      <NavLink href="/companies/browse">{t(`browse_companies`)}</NavLink>
      <NavLink href="/hiive50">{t(`hiive_50`)}</NavLink>
      {actor.isSuperadmin && (
        <NavLink href={backofficeUrl}>{t(`admin`)}</NavLink>
      )}
    </HStack>
  );
};

const DesktopNavBar = () => {
  const { pathname } = useRouter();

  const actor = useCurrentActor();

  const maxW = match(pathname)
    .with(`/`, () => `max-width-lg`)
    .with(`/dashboard`, () => `max-width-lg`)
    .with(`/companies/browse`, () => `max-width-xl`)
    .with(`/create-listing`, () => `max-width-lg`)
    .with(
      P.when((pathname: string) => pathname.startsWith(`/account`)),
      () => `max-width-lg`,
    )
    .with(
      P.when((pathname: string) => pathname.endsWith(`connect-account`)),
      () => `max-width-lg`,
    )
    .with(P.any, () => `max-width-md`)
    .exhaustive();

  return (
    <VStack
      h="desktop-navbar-height"
      bg="white"
      px={8}
      borderBottom="0.5px solid"
      borderColor="grey.200"
    >
      <HStack maxW={maxW} justifyContent="space-between" h="full" w="full">
        <HStack spacing={{ base: 2, xl: 10 }}>
          <HiiveLogo actor={actor} />
          <MainNav actor={actor} />
        </HStack>
        <HStack spacing={{ base: 2, xl: 5 }}>
          <CompaniesSearch />
          <NotificationMenu key={maxW} />
          <ProfileMenu />
        </HStack>
      </HStack>
    </VStack>
  );
};

export default DesktopNavBar;
