import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  InquiryPostingCard,
  WithQuery,
  InReviewActivity,
} from "@/components/common";
import { ListingPageBidCard } from "@/components/postings";
import {
  ListingPageSellerActivityBidFragment,
  ListingPageSellerActivityListingFragment,
  ListingState,
  MessageTopicType,
  MyDiscussionListDiscussionFragment,
  useMyDiscussionListDiscussionsQuery,
} from "@/gql";
import { useRestrictiveInReview } from "@/hooks/featureFlags";
import { getIsBidDiscussion } from "@/utils";
import { filterOpenBids, filterPastBids } from "@/utils/bid";

import { OpenBidsSkeleton } from "./OpenBidsSkeleton";

const Inquiries = ({
  discussions,
}: {
  readonly discussions: readonly MyDiscussionListDiscussionFragment[];
}) => {
  const { t } = useTranslation();
  return (
    <ActivityGroup title={t`inquiries`}>
      {discussions?.map((discussion) => (
        <InquiryPostingCard key={discussion.id} discussion={discussion} />
      ))}
    </ActivityGroup>
  );
};

const Bids = ({
  bids,
}: {
  readonly bids: readonly ListingPageSellerActivityBidFragment[];
}) => {
  const { t } = useTranslation();
  return (
    <ActivityGroup title={t`looking_to_buy`}>
      {bids.map((bid) => (
        <ListingPageBidCard bid={bid} key={bid.id} />
      ))}
    </ActivityGroup>
  );
};

const OpenBids = ({
  listing,
}: {
  readonly listing: ListingPageSellerActivityListingFragment;
}) => {
  const query = useMyDiscussionListDiscussionsQuery({
    variables: {
      topicFilter: { id: listing.id, type: MessageTopicType.Listing },
    },
  });

  const { t } = useTranslation();

  return (
    <WithQuery query={query} fallback={<OpenBidsSkeleton />}>
      {({ data: { myDiscussions } }) => {
        const openBids = filterOpenBids(listing.bids);

        const discussions = myDiscussions.filter(
          (discussion) => !getIsBidDiscussion(discussion, listing.bids),
        );

        const hasBids = openBids.length > 0;
        const hasDiscussions = discussions?.length > 0;

        return hasBids || hasDiscussions ? (
          <ActivitySection data-testid="active-bids">
            <Inquiries discussions={discussions} />
            <Bids bids={openBids} />
          </ActivitySection>
        ) : (
          <ActivityGroupEmptyState
            data-testid="no-active-bids"
            message={t(`no_active_bids_on_listing`)}
          />
        );
      }}
    </WithQuery>
  );
};

const PastBids = ({
  listing,
}: {
  readonly listing: ListingPageSellerActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const pastBids = filterPastBids(listing.bids);

  return pastBids.length > 0 ? (
    <VStack spacing={4}>
      {pastBids.map((bid) => (
        <ListingPageBidCard bid={bid} key={bid.id} />
      ))}
    </VStack>
  ) : (
    <ActivityGroupEmptyState message={t(`no_past_bids`)} />
  );
};

function SellerActivityContent({
  listing,
}: {
  readonly listing: ListingPageSellerActivityListingFragment;
}) {
  const { t } = useTranslation(`listings`);

  return (
    <Tabs variant="underline">
      <TabList>
        <Tab>{t(`open_bids`)}</Tab>
        <Tab>{t(`past_bids`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <OpenBids listing={listing} />
        </TabPanel>
        <TabPanel>
          <PastBids listing={listing} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

const SellerActivity = ({
  listing,
}: {
  readonly listing: ListingPageSellerActivityListingFragment;
}) => {
  const restrictiveInReview = useRestrictiveInReview();

  const { state } = listing;

  return match({ state, restrictiveInReview })
    .with({ state: ListingState.InReview, restrictiveInReview: true }, () => (
      <InReviewActivity listing={listing} />
    ))
    .otherwise(() => <SellerActivityContent listing={listing} />);
};
export default SellerActivity;
