import { useTranslation } from "react-i18next";

import { Flex, Grid, Text } from "@chakra-ui/react";

import {
  BuyerCommissionBreakdown,
  FocusedShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  BidInfoCardStatusTile,
  AcceptedBidDetails,
  DocumentActions,
} from "@/components/postings";
import {
  SellerTransactionInfoCardBidFragment,
  TransactionFeeBreakdownTransactionFragment,
} from "@/gql";
import { useCurrentActor, useDocumentSigners } from "@/hooks";
import { useBuySideFees } from "@/hooks/featureFlags";
import { getIsBroker, getShowDocumentActions } from "@/utils";
import { getBidNumSharesActual } from "@/utils/bid";

import { BidSellerRoundingDisclaimer, TransactionFeeBreakdown } from "./shared";

interface FeeBreakdownCardsProps {
  readonly transaction: TransactionFeeBreakdownTransactionFragment;
}

const FeeBreakdownCards = ({ transaction }: FeeBreakdownCardsProps) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const buySideFeesEnabled = useBuySideFees();
  const showFeeBreakdown = !getIsBroker(actor);

  if (!transaction || !showFeeBreakdown) return null;

  const { numShares, pricePerShare, buyerCommission } = transaction;

  if (!buySideFeesEnabled) {
    return <TransactionFeeBreakdown transaction={transaction} />;
  }

  if (buyerCommission) {
    return (
      <>
        <Grid gap={2}>
          <Text textStyle="heading-lg">{t(`buyer`)}</Text>
          <BuyerCommissionBreakdown
            numShares={numShares}
            pricePerShare={pricePerShare}
            commission={buyerCommission}
            buyerCommissionStructureId={buyerCommission.commissionStructure?.id}
          />
        </Grid>
        <Grid gap={2}>
          <Text textStyle="heading-lg">{t(`seller`)}</Text>
          <TransactionFeeBreakdown transaction={transaction} />
        </Grid>
      </>
    );
  }

  return <TransactionFeeBreakdown transaction={transaction} />;
};

const SellerTransactionInfoCard = ({
  bid,
}: {
  readonly bid: SellerTransactionInfoCardBidFragment;
}) => {
  const actor = useCurrentActor();

  const { transaction } = bid;

  const {
    canSignTransactionDocument,
    canInviteAlternateSigner,
    canRevokeAlternateSignerInvitation,
    haveISigned,
  } = useDocumentSigners(transaction);

  if (!transaction) return null;

  const { company, numShares, pricePerShare } = transaction;

  const shouldRenderDocumentsFooterActions = getShowDocumentActions({
    bid,
    actor,
    haveISigned,
    canInviteAlternateSigner,
    canSignTransactionDocument,
    canRevokeAlternateSignerInvitation,
  });

  const isRoundingDisclaimerVisible =
    getBidNumSharesActual(bid) !== bid.numShares;

  return (
    <Flex direction="column" gap={7} data-testid="bid-info-card">
      <FocusedShareDetails.Card variant="transaction">
        <FocusedShareDetails.Header
          title={`Transaction ${bid.displayId}`}
          company={company}
        >
          <FocusedShareDetails.HeaderCard>
            <ShareDetailsStats
              numberOfShares={numShares}
              pricePerShare={pricePerShare}
            />
          </FocusedShareDetails.HeaderCard>
          {isRoundingDisclaimerVisible && (
            <BidSellerRoundingDisclaimer numSharesOriginal={bid.numShares} />
          )}
        </FocusedShareDetails.Header>
        <FocusedShareDetails.Content>
          <FocusedShareDetails.ContentSection>
            <Flex direction="column" gap={6}>
              <BidInfoCardStatusTile bid={bid} showCounterBidDetails={false} />
              <FeeBreakdownCards transaction={transaction} />
              <AcceptedBidDetails bid={bid} />
            </Flex>
          </FocusedShareDetails.ContentSection>
          {shouldRenderDocumentsFooterActions && (
            <FocusedShareDetails.ContentFooter
              flexDirection="column"
              justifyContent="flex-end"
            >
              <DocumentActions bid={bid} />
            </FocusedShareDetails.ContentFooter>
          )}
        </FocusedShareDetails.Content>
      </FocusedShareDetails.Card>
    </Flex>
  );
};

export default SellerTransactionInfoCard;
