/* eslint-disable functional/immutable-data */

/* eslint-disable consistent-return */
import { OperationVariables, QueryResult } from "@apollo/client";
import isNil from "lodash/isNil";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";

import { Spinner, Center } from "@chakra-ui/react";

const useArtificialDelay = (delay?: number) => {
  const [isDelayed, setIsDelayed] = useState(!isNil(delay));
  const timeoutIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (isNil(delay)) return;

    timeoutIdRef.current = window.setTimeout(() => {
      setIsDelayed(false);
    }, delay);

    return () => {
      if (!isNil(timeoutIdRef.current)) {
        window.clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return { isDelayed };
};

interface WithQueryProps<TData, TVariables extends OperationVariables> {
  readonly query: QueryResult<TData, TVariables>;
  readonly children: ({
    data,
    fetchMore,
  }: {
    readonly data: NonNullable<TData>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    readonly fetchMore?: Function;
  }) => ReactElement | null | undefined;
  readonly hideLoadingSpinner?: boolean;
  readonly fallback?: ReactNode;
  readonly artificialDelay?: number;
}

/**
 * Ensures that the query has returned data before rendering children.
 * Renders Error/Spinner on error/loading state.
 */
const WithQuery = <TData, TVariables extends OperationVariables>({
  query,
  children,
  hideLoadingSpinner = false,
  fallback,
  artificialDelay,
}: WithQueryProps<TData, TVariables>) => {
  const { data, loading, error, fetchMore } = query;

  const { isDelayed } = useArtificialDelay(artificialDelay);

  const loadingState = !!fallback ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{fallback}</>
  ) : (
    <Center m={20}>
      <Spinner />
    </Center>
  );

  if (error) return null;

  if (loading || !data || isDelayed) {
    return hideLoadingSpinner ? null : loadingState;
  }

  return children({ data, fetchMore }) || null;
};

export default WithQuery;
