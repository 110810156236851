import { useTranslation } from "react-i18next";

import { Box, HStack, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import {
  ConditionallySoldOutBadge,
  InquiriesBadge,
  OpenBidsBadge,
  PartiallySoldBadge,
  calculateTotalOpenBids,
} from "@/components/companies";
import {
  ListingYourActivityPostingCardFooterFragment,
  ListingState,
} from "@/gql";
import { countTotalUnreadBidDiscussionMessages } from "@/utils/bid";

type ListingYourActivityPostingCardFooterProps = {
  listing: ListingYourActivityPostingCardFooterFragment;
};

export function ListingYourActivityPostingCardFooter({
  listing: {
    acceptedBidPrices: { length: acceptedBidPricesLength },
    transferMethod,
    numActiveBids,
    numCounteredBids,
    state,
    bids,
    numInquiries,
    numUnreadMessages,
  },
}: ListingYourActivityPostingCardFooterProps) {
  const { t } = useTranslation(`listings`);

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const totalUnreadBidDiscussionMessages =
    countTotalUnreadBidDiscussionMessages(bids);
  const numUnreadMessagesAsideFromBids =
    numUnreadMessages - totalUnreadBidDiscussionMessages;

  const isInReview = state === ListingState.InReview;
  const isConditionallySold = state === ListingState.ConditionallySold;
  const isPartiallySold =
    state === ListingState.Open && acceptedBidPricesLength >= 1;

  const hasOpenBids = totalOpenBids >= 1;
  const hasInquiries = numInquiries > 0;

  const showFooter =
    isInReview ||
    isConditionallySold ||
    isPartiallySold ||
    hasOpenBids ||
    hasInquiries;

  if (!showFooter) {
    return null;
  }

  return (
    <PostingCard.Footer>
      {isInReview && <Text>{t(`in_review_by_hiive`)}</Text>}

      <Box flexGrow={1} />

      <HStack wrap="wrap" align="flex-end" spacing={{ base: 2, md: 3 }}>
        {isConditionallySold && (
          <ConditionallySoldOutBadge transferMethod={transferMethod} />
        )}
        {isPartiallySold && (
          <PartiallySoldBadge transferMethod={transferMethod} />
        )}
        {hasInquiries && (
          <InquiriesBadge
            numInquiries={numInquiries}
            numUnreadMessages={numUnreadMessagesAsideFromBids}
          />
        )}
        {hasOpenBids && (
          <OpenBidsBadge
            totalOpenBids={totalOpenBids}
            bids={bids}
            transferMethod={transferMethod}
          />
        )}
      </HStack>
    </PostingCard.Footer>
  );
}
