import { Code } from "@phosphor-icons/react";
import { Fragment, useContext, useState } from "react";

import { useRouter } from "next/navigation";

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  Portal,
  SimpleGrid,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";

import { LDStatus, LDStatusContext } from "@/components/async-with-ld-provider";
import { useToggleFeatureFlagMutation } from "@/gql";
import {
  useIsDesktop,
  useMutationWithError,
  useNullableCurrentActor,
  useSession,
} from "@/hooks";
import { FlagValue } from "@/hooks/featureFlags";
import constants from "@/utils/constants";

import { UserManagement } from "./UserManagement";

const { Z_INDEX_OVERRIDES, DEV_AUTH_ENABLED, AUTH0_ENABLED } = constants;

const API_PARAM = `api`;
const IS_PRODUCTION = process.env.NEXT_PUBLIC_INFRA_ENV === `production`;
const DEFAULT_API_URL =
  process.env.NEXT_PUBLIC_API_HOST ||
  `http://localhost:${process.env.NEXT_PUBLIC_PORT || 4000}`;

const IS_E2E =
  process.env.NEXT_PUBLIC_E2E === `true` &&
  process.env.NODE_ENV === `development`;

function ShowFlagsButton({
  onClick,
  showFlags,
}: {
  readonly onClick: () => void;
  readonly showFlags: boolean;
}) {
  return (
    <Button
      onClick={onClick}
      bg="grey.900"
      color="white"
      _hover={{
        bg: `grey.800`,
      }}
      _active={{
        bg: `grey.700`,
      }}
      borderRadius={0}
      borderTopRightRadius="md"
      minW="unset"
      px={4}
      py={2}
      h="unset"
      m={0}
    >
      <HStack>
        <Text color="white" fontSize="sm">
          {showFlags ? `Hide` : `Show`} Developer Menu
        </Text>
        <Code color="white" />
      </HStack>
    </Button>
  );
}

function setApiQueryParam(apiUrl: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(API_PARAM);
  url.searchParams.append(API_PARAM, apiUrl);
  window.location.replace(url);
}

function useToggleFeatureFlags() {
  const mutation = useToggleFeatureFlagMutation();
  const [, { loading }] = mutation;
  const [execute] = useMutationWithError(mutation, `toggleFeatureFlag`);

  return [execute, !loading] as const;
}

const DevHelper = (props: BoxProps) => {
  const router = useRouter();

  const initApiUrl = sessionStorage.getItem(API_PARAM) || DEFAULT_API_URL;
  const [apiUrl, setApiUrl] = useState(initApiUrl);
  const { status, client } = useContext(LDStatusContext);

  const [showFlags, setShowFlags] = useState(false);
  const flags = client?.allFlags();

  const { isAuthenticated } = useSession();
  const isDesktopView = useIsDesktop();
  const { actor } = useNullableCurrentActor();

  const [executeFeatureFlagToggle, canToggleFeatureFlags] =
    useToggleFeatureFlags();

  const nothingToShow = !isAuthenticated && !AUTH0_ENABLED && !DEV_AUTH_ENABLED;

  if (
    IS_PRODUCTION ||
    !flags ||
    !isDesktopView ||
    Object.keys(flags).length === 0 ||
    nothingToShow
  ) {
    return null;
  }

  const handleUseDefaultApiUrl = () => {
    setApiUrl(DEFAULT_API_URL);
  };

  const handleSetApiUrl = () => {
    setApiQueryParam(apiUrl);
  };

  const handleClickShowFlags = () => {
    setShowFlags(!showFlags);
  };

  const handleToggleFlag = (flagKey: string) => {
    executeFeatureFlagToggle({
      variables: { input: { flagKey } },
    });
  };

  const flagEntries = Object.entries(flags)
    .filter(([, flagValue]) => flagValue !== undefined)
    .sort(([flagKeyA], [flagKeyB]) => flagKeyA.localeCompare(flagKeyB));

  const handleVerifyEmail = async () => {
    const email = actor?.email || ``;
    const encodedEmail = encodeURIComponent(email);
    const response = await fetch(
      `${apiUrl}/e2e/email_verification_tlt?email=${encodedEmail}`,
    );

    const { token } = await response.json();

    setShowFlags(false);
    return router.replace(`/verify-email/${token}`);
  };

  return (
    <Portal>
      <Box
        zIndex={Z_INDEX_OVERRIDES.DEV_HELPERS}
        position="fixed"
        bottom="0"
        left="0"
        {...props}
      >
        <ShowFlagsButton showFlags={showFlags} onClick={handleClickShowFlags} />
        {showFlags && (
          <Flex
            direction="column"
            gap={4}
            borderTopRightRadius="md"
            p={4}
            bg="grey.900"
            maxH="80vh"
            overflow="auto"
          >
            <UserManagement actor={actor} />
            <Divider borderColor="grey.600" />
            {isAuthenticated && (
              <>
                <VStack h="16">
                  <VStack alignItems="flex-start" w="full">
                    <Text textStyle="heading-2xs" color="grey.100">
                      API URL
                    </Text>
                    <HStack w="full">
                      <Input
                        bg="grey.800"
                        color="grey.100"
                        value={apiUrl}
                        onChange={(event) => setApiUrl(event.target.value)}
                      />
                      <Button
                        minW="unset"
                        h="full"
                        bg="grey.800"
                        borderColor="grey.600"
                        _hover={{
                          bg: `grey.700`,
                        }}
                        borderWidth="1px"
                        color="grey.100"
                        onClick={handleUseDefaultApiUrl}
                      >
                        Use Default
                      </Button>
                      <Button
                        minW="unset"
                        h="full"
                        bg="white"
                        isDisabled={apiUrl === initApiUrl}
                        onClick={handleSetApiUrl}
                      >
                        Set
                      </Button>
                    </HStack>
                  </VStack>
                </VStack>
                <Divider borderColor="grey.600" />

                {IS_E2E && (
                  <Text
                    color="grey.100"
                    fontSize={12}
                    fontWeight="medium"
                    textAlign="center"
                  >
                    ⚠️ NEXT_PUBLIC_E2E is set to true. Turn this off to enable
                    local targetting! ⚠️
                  </Text>
                )}

                <SimpleGrid
                  columns={2}
                  gridTemplateColumns="min-content"
                  columnGap={10}
                  rowGap={1}
                >
                  {status === LDStatus.Failure && (
                    <Text color="grey.100" textStyle="heading-xl">
                      LaunchDarkly failed to load
                    </Text>
                  )}
                  {flagEntries.map(
                    ([flagKey, flagValue]: readonly [string, FlagValue]) => (
                      <Fragment key={flagKey}>
                        <Text color="grey.100" gridColumn={1}>
                          {flagKey}
                        </Text>
                        <Switch
                          gridColumn={2}
                          onChange={() => handleToggleFlag(flagKey)}
                          isChecked={!!flagValue}
                          isDisabled={!actor || !canToggleFeatureFlags}
                          disabled={IS_E2E}
                        />
                      </Fragment>
                    ),
                  )}
                </SimpleGrid>
              </>
            )}
            {actor && process.env.NEXT_PUBLIC_ENV === `dev` && (
              <>
                <Divider borderColor="grey.600" />
                <HStack
                  alignItems="center"
                  w="full"
                  justifyContent="space-between"
                >
                  <Text textStyle="heading-2xs" color="grey.100">
                    Bypass Identity Verification
                  </Text>
                  <Button
                    minW="unset"
                    bg="grey.800"
                    borderColor="grey.600"
                    _hover={{
                      bg: `grey.700`,
                    }}
                    borderWidth="1px"
                    color="grey.100"
                    onClick={() => {
                      fetch(`${apiUrl}/e2e/start_identity_verification`, {
                        headers: {
                          Accept: `application/json`,
                          "Content-Type": `application/json`,
                        },
                        method: `POST`,
                        body: JSON.stringify({ user_id: actor.id }),
                      });
                    }}
                  >
                    Fire
                  </Button>
                </HStack>
                <Divider borderColor="grey.600" />
                <HStack
                  alignItems="center"
                  w="full"
                  justifyContent="space-between"
                >
                  <Text textStyle="heading-2xs" color="grey.100">
                    Fake Persona Webhook Request
                  </Text>
                  <Button
                    minW="unset"
                    bg="grey.800"
                    borderColor="grey.600"
                    _hover={{
                      bg: `grey.700`,
                    }}
                    borderWidth="1px"
                    color="grey.100"
                    onClick={() => {
                      fetch(`${apiUrl}/e2e/fake_persona_webhook_call`, {
                        headers: {
                          Accept: `application/json`,
                          "Content-Type": `application/json`,
                        },
                        method: `POST`,
                        body: JSON.stringify({ user_id: actor.id }),
                      });
                    }}
                  >
                    Fire
                  </Button>
                </HStack>
                <Divider borderColor="grey.600" />
                <HStack
                  alignItems="center"
                  w="full"
                  justifyContent="space-between"
                >
                  <Text textStyle="heading-2xs" color="grey.100">
                    Verify Email
                  </Text>
                  <Button
                    minW="unset"
                    bg="grey.800"
                    borderColor="grey.600"
                    _hover={{
                      bg: `grey.700`,
                    }}
                    borderWidth="1px"
                    color="grey.100"
                    onClick={handleVerifyEmail}
                  >
                    Fire
                  </Button>
                </HStack>
              </>
            )}
          </Flex>
        )}
      </Box>
    </Portal>
  );
};

export default DevHelper;
