import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Text,
} from "@chakra-ui/react";

import { Listbox } from "@/components/form";
import { useListbox } from "@/hooks";

type FormListBoxProps<TItem, TFieldValues extends FieldValues> = {
  readonly name: Path<TFieldValues>;
  readonly placeholder?: string;
  readonly items: Array<TItem>;
  readonly itemToString: (item: TItem) => string;
  readonly getItemKey: (item: TItem) => string;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps[`srOnly`];
  readonly description?: string;
  readonly isDisabled?: boolean;
  readonly control: Control<TFieldValues>;
  readonly isLoading?: boolean;
  readonly isRequired?: boolean;
};

const FormListBox = <TItem, TFieldValues extends FieldValues>({
  control,
  description,
  getItemKey,
  items,
  placeholder,
  isDisabled,
  itemToString,
  label,
  name,
  labelSrOnly,
  isLoading,
  isRequired,
}: FormListBoxProps<TItem, TFieldValues>) => {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control, disabled: isDisabled });

  const { buttonProps, menuProps, labelProps, itemProps } = useListbox<TItem>({
    onClickItem: (item) => {
      onChange(item);
    },
    items,
    itemToString,
    selectedItem: value,
  });

  return (
    <FormControl id={name} isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly} {...labelProps}>
        {label}
        {isRequired ? ` *` : ``}
      </FormLabel>
      {description && <Text textStyle="text-xs">{description}</Text>}
      <Listbox.Container>
        <Listbox.Button
          isDisabled={isDisabled}
          placeholder={placeholder}
          {...buttonProps}
        />
        <Listbox.Menu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <Listbox.Item
              key={getItemKey(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              {itemToString(item)}
            </Listbox.Item>
          ))}
        </Listbox.Menu>
      </Listbox.Container>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormListBox;
