import { CaretLeft, CheckCircle, Seal } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  Button,
  HStack,
  Circle,
} from "@chakra-ui/react";

import {
  ExecutionModalHeader,
  ExecutionModalFooter,
  PoweredByModernTreasury,
} from "@/components/common";
import {
  ExecutionCollectBankAccountTaskDataFragment,
  TransactionExecutionPageTaskFragment,
} from "@/gql";
import { useColors, useModal } from "@/hooks";

import AccountCollectionContent from "./AccountCollectionContent";
import CountryCollectionContent from "./CountryCollectionContent";
import DisclaimerContent from "./DisclaimerContent";
import { CollectBankAccountSteps, Country } from "./types";

const VerifyDisclaimerContent = ({
  onClose,
  selectedCountry,
}: {
  onClose: () => void;
  selectedCountry?: Country;
}) => {
  const { t } = useTranslation(`execution`);
  const isOutsideUS = selectedCountry?.iso !== `USA`;

  return (
    <>
      <ModalBody>
        <VStack spacing={4} py={4} px={{ base: 6, md: 0 }} textAlign="center">
          <Circle size="108" bgColor="grey.50">
            <Seal size={64} />
          </Circle>
          {isOutsideUS ? (
            <>
              <Text textStyle="heading-3xl" color="grey.700">
                {t(`account_pending_verification`)}
              </Text>
              <Text textStyle="text-sm">
                {t(`manual_verification_disclaimer`)}
              </Text>
            </>
          ) : (
            <>
              <Text textStyle="heading-3xl" color="grey.700">
                {t(`verify_your_account`)}
              </Text>
              <Text textStyle="text-sm">{t(`verify_confirm_need`)}</Text>
              <Text textStyle="text-sm">
                {t(`verify_confirm_send_deposits`)}
              </Text>
              <Text textStyle="text-sm">
                {t(`verify_confirm_use_deposits`)}
              </Text>
            </>
          )}
          <PoweredByModernTreasury />
        </VStack>
      </ModalBody>
      <ExecutionModalFooter>
        <Button onClick={onClose}>{t(`got_it`)}</Button>
      </ExecutionModalFooter>
    </>
  );
};

const SuccessContent = ({
  setStep,
}: {
  setStep: (step: CollectBankAccountSteps) => void;
}) => {
  const { t } = useTranslation(`execution`);

  const [emerald600] = useColors([`emerald.600`]);

  return (
    <>
      <ModalBody>
        <VStack spacing={4} py={4} px={{ base: 6, md: 0 }}>
          <Circle size="108" bgColor="emerald.100">
            <CheckCircle size={64} weight="fill" color={emerald600} />
          </Circle>
          <Text textStyle="heading-3xl" color="grey.700">
            {t(`bank_account_added`)}!
          </Text>
          <Text textStyle="text-sm" textAlign="center">
            {t(`bank_account_connected`)}
          </Text>
          <HStack>
            <Text
              textStyle="text-sm"
              textTransform="uppercase"
              fontWeight={500}
            >
              {t(`next_step`)}:
            </Text>
            <Text textStyle="text-sm">{t(`verify_your_bank_account`)}</Text>
          </HStack>
          <PoweredByModernTreasury />
        </VStack>
      </ModalBody>
      <ExecutionModalFooter>
        <Button onClick={() => setStep(`verify_disclaimer`)}>
          {t(`next`)}
        </Button>
      </ExecutionModalFooter>
    </>
  );
};

const CollectBankAccountModal = ({
  entity,
  task,
}: {
  entity: ExecutionCollectBankAccountTaskDataFragment["entity"];
  task: TransactionExecutionPageTaskFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const { closeModal, currentModal } = useModal();

  const [step, setStep] = useState<CollectBankAccountSteps>(`intro_disclaimer`);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [clientToken, setClientToken] = useState<string>();

  const setStepWithDelay = (newStep: CollectBankAccountSteps) => {
    setTimeout(() => setStep(newStep), 500);
  };

  const handleClose = () => {
    setStep(`intro_disclaimer`);
    setSelectedCountry(undefined);
    setClientToken(undefined);
    closeModal();
  };

  return (
    <Modal
      variant="texas-full"
      isOpen={!!currentModal}
      onClose={handleClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent w="2xl" height={{ base: `full`, md: `auto` }}>
        <ExecutionModalHeader>
          <HStack>
            {match(step)
              .with(`account_collection`, () => (
                <CaretLeft
                  size={24}
                  onClick={() => setStep(`country_collection`)}
                />
              ))
              .otherwise(() => null)}
            <Text>{t(`add_bank_account`)}</Text>
          </HStack>
        </ExecutionModalHeader>
        {match(step)
          .with(`intro_disclaimer`, () => (
            <DisclaimerContent onClose={handleClose} setStep={setStep} />
          ))
          .with(`country_collection`, () => (
            <CountryCollectionContent
              onClose={handleClose}
              setStep={setStep}
              entity={entity}
              taskId={task.id}
              setClientToken={setClientToken}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          ))
          .with(`account_collection`, () => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {clientToken && (
                <AccountCollectionContent
                  task={task}
                  clientToken={clientToken}
                  setStep={setStepWithDelay}
                />
              )}
            </>
          ))
          .with(`collection_success`, () => (
            <SuccessContent setStep={setStep} />
          ))
          .with(`verify_disclaimer`, () => (
            <VerifyDisclaimerContent
              onClose={handleClose}
              selectedCountry={selectedCountry}
            />
          ))
          .exhaustive()}
      </ModalContent>
    </Modal>
  );
};

export default CollectBankAccountModal;
