const locale = {
  successfully_updated_holding: `Successfully updated holding`,
  update_shares_of_company: `Update shares of {{companyName}}`,
  enter_number_of_shares: `Enter number of shares`,
  number_of_shares: `Number of shares`,
  submit: `Submit`,
  cancel: `Cancel`,
  edit_holding: `Edit Holding`,
  please_enter_num_shares: `Please enter number of shares`,
};

export default locale;
