import { CaretDown } from "@phosphor-icons/react";
import { ReactElement } from "react";

import {
  Box,
  useFormControl,
  chakra,
  useMultiStyleConfig,
} from "@chakra-ui/react";

import { useColors } from "@/hooks";

import { UseListboxGetToggleButtonProps } from "./types";

interface ListboxButtonProps<TItem> {
  readonly getToggleButtonProps: UseListboxGetToggleButtonProps;
  readonly itemToString: (itemValue: TItem) => string;
  readonly itemRenderer?: (itemValue: TItem) => ReactElement;
  readonly selectedItem: TItem | null;
  readonly placeholder?: string;
  readonly isDisabled?: boolean;
  readonly style?: React.CSSProperties;
}

export const ListboxButton = <TItem,>({
  getToggleButtonProps,
  isDisabled,
  selectedItem,
  itemToString,
  itemRenderer,
  placeholder = ``,
  style,
}: ListboxButtonProps<TItem>) => {
  const formControlProps = useFormControl({ disabled: isDisabled });
  const [grey900] = useColors([`grey.900`]);

  // Reconcile toggle button styles from the `<Input/>` component
  const styles = useMultiStyleConfig(`Input`);

  return (
    <chakra.div
      __css={styles.field}
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      color={!selectedItem && `grey.600`}
      cursor="pointer"
      style={style}
      {...getToggleButtonProps({ ...formControlProps })}
    >
      <Box textStyle="text-md" w="full" noOfLines={1}>
        {!selectedItem && placeholder}
        {selectedItem && !!itemRenderer && itemRenderer(selectedItem)}
        {selectedItem && !itemRenderer && itemToString(selectedItem)}
      </Box>
      <Box pl={4}>
        <CaretDown color={grey900} />
      </Box>
    </chakra.div>
  );
};
