import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { RadioGroup, Box, Radio, HStack, VStack, Text } from "@chakra-ui/react";

import { QuestionTooltip } from "@/components/form";
import { UserWithInstitutionFragment } from "@/gql";
import { useTranslatedEntityNames } from "@/hooks";

export enum PartyType {
  Individual = `Individual`,
  Entity = `Entity`,
}

const RadioWithSubtext = ({
  toolTipContent,
  title,
  subText,
  value,
  selected,
}: {
  readonly toolTipContent?: ReactNode;
  readonly title: string;
  readonly value: PartyType;
  readonly subText: string | ReactNode;
  readonly selected: boolean;
}) => (
  <Radio spacing={4} variant="base" value={value}>
    <VStack align="start" spacing={1}>
      <HStack>
        <Text textStyle="heading-lg">{title}</Text>
        {toolTipContent && (
          <QuestionTooltip
            iconColor="grey.900"
            tooltipContent={toolTipContent}
          />
        )}
      </HStack>
      {selected && (
        <Text textStyle="text-lg" color="grey.700">
          {subText}
        </Text>
      )}
    </VStack>
  </Radio>
);

const PartyTypeRadio = ({
  actor,
  onChange,
  value,
}: {
  actor: UserWithInstitutionFragment;
  value: PartyType | null;
  onChange?: (value: string) => void;
}) => {
  const { getCountryName } = useTranslatedEntityNames();
  const fullName = `${actor.firstName} ${actor.lastName}`;
  const countryName = getCountryName(actor.country?.name);

  const { t } = useTranslation(`account`);
  const individualSubText = (
    <>
      {fullName} {countryName && <>&#x2022; {countryName}</>}
    </>
  );

  return (
    <RadioGroup w="full" onChange={onChange} value={value as string}>
      <Box
        bg="grey.25"
        borderColor="grey.100"
        borderWidth={1}
        w="full"
        p={4}
        borderTopRadius={8}
      >
        <RadioWithSubtext
          title={t(`entities.individual`)}
          selected={value === PartyType.Individual}
          subText={individualSubText}
          toolTipContent={t(`individual_entity_tooltip`, { ns: `execution` })}
          value={PartyType.Individual}
        />
      </Box>
      <Box
        bg="grey.25"
        borderColor="grey.100"
        borderWidth={1}
        borderTopWidth={0}
        w="full"
        p={4}
        borderBottomRadius={8}
      >
        <RadioWithSubtext
          title={t(`entities.entity`)}
          toolTipContent={t(`other_entity_tooltip`, { ns: `execution` })}
          value={PartyType.Entity}
          selected={value === PartyType.Entity}
          subText={t(`entities.complete_entity_details`)}
        />
      </Box>
    </RadioGroup>
  );
};

export default PartyTypeRadio;
