import { ArrowSquareOut } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import Link from "next/link";

import {
  Card,
  CardBody,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";

import { useTransactionExecution } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getNumOfShares,
  lot,
} from "@/utils";

const OriginalPostingDetailsCard = () => {
  const { t } = useTranslation(`execution`);
  const { transaction, isBuyer } = useTransactionExecution();
  const { logoUrl, name } = transaction.company;

  const { numShares, pricePerShare, displayId, link, title } = transaction.bid
    .standingBid
    ? {
        numShares: transaction.bid.standingBid?.numSharesOriginal,
        pricePerShare: transaction.bid.standingBid?.pricePerShare ?? 0,
        displayId: transaction.bid.standingBid?.displayId,
        link: `/standing-bids/${transaction.bid.standingBid?.id}`,
        title: t(`original_standing_bid`),
      }
    : {
        numShares: getNumOfShares(transaction.bid.listing, isBuyer),
        pricePerShare: transaction.bid.listing.listingPricePerShare ?? 0,
        displayId: transaction.bid.listing.displayId,
        link: `/listings/${transaction.bid.listing.id}`,
        title: t(`original_listing`),
      };
  return (
    <VStack alignItems="flex-start" spacing={4}>
      <Text textStyle="heading-md">{title}</Text>
      <Card
        w="full"
        _hover={{
          bg: `grey.50`,
        }}
        as={Link}
        boxShadow="none"
        href={link}
        transition=".1s background-color ease-in-out"
      >
        <CardBody color="grey.900" as={HStack} justifyContent="space-between">
          <HStack>
            {logoUrl && (
              <VStack h="6" minW="6">
                <Image h="full" src={logoUrl} alt={name} />
              </VStack>
            )}
            <Text textStyle="text-sm">{displayId}</Text>
          </HStack>
          <HStack textStyle="text-sm" color="grey.700" gap={4}>
            <Flex
              direction={{ base: `column`, md: `row` }}
              alignItems="end"
              textStyle="text-sm"
              color="grey.700"
              gap={1}
            >
              <Text fontWeight={500}>
                {formatShares(numShares!)} @{` `}
                {formatPricePerShare(pricePerShare)}
              </Text>
              <Text fontWeight={400}>
                {lot(numShares!, pricePerShare, `$0,0.00`)}
              </Text>
            </Flex>
            <ArrowSquareOut size={16} />
          </HStack>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default OriginalPostingDetailsCard;
