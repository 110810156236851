import { ComponentType } from "react";

import { useRouter } from "next/router";

import { CurrentActorContext } from "@/components/providers";
import { canAccessPlatform, useNullableCurrentActor } from "@/hooks";
import { FeatureFlags, useFeatureFlags } from "@/hooks/featureFlags";
import { useSession } from "@/hooks/useSession";
import { ROUTES, getIsValidRedirectURL } from "@/utils";

const getAuthenticatedRedirectRoute = (
  user: CurrentActorContext,
  _featureFlags: FeatureFlags,
  redirectURL?: string,
) => {
  if (!canAccessPlatform(user)) {
    return ROUTES.WELCOME;
  }

  if (!!redirectURL && getIsValidRedirectURL(redirectURL)) {
    return redirectURL;
  }

  return ROUTES.DASHBOARD;
};

export function withGuestRoute<T>(WrappedComponent: ComponentType<T>) {
  const GuestComponent = (props: T) => {
    const { isAuthenticated } = useSession();
    const featureFlags = useFeatureFlags();
    const router = useRouter();
    const { actor } = useNullableCurrentActor();

    if (isAuthenticated && actor) {
      const route = getAuthenticatedRedirectRoute(
        actor,
        featureFlags,
        router.query.redirect as string,
      );

      router.push(route);

      return null;
    }

    if (isAuthenticated && !actor) {
      return null;
    }

    return <WrappedComponent {...props} key="wrappedGuestRoute" />;
  };

  return GuestComponent;
}
