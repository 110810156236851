const locale = {
  add_your_details: `Add your details`,
  advertisement: `Advertisement`,
  event: `Event`,
  first_name: `First name`,
  first: `First`,
  friend_colleague: `Friend/Colleague`,
  google_search: `Google search`,
  hear_about_us: `How did you hear about us?`,
  last_name: `Last name`,
  last: `Last`,
  newsletter: `Newsletter`,
  next: `Next`,
  other: `Other`,
  press_release: `Press release`,
  complete_your_account: `To create your account, complete the following information.`,
  select_option: `Select option`,
  social_media: `Social media`,
  telephone: `Telephone`,
};

export default locale;
