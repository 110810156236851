import { Flex, Text, FlexProps } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";

import { useCountryList, useTransactionExecution } from "@/hooks";
import { getCounterPartyCountry } from "@/hooks/transaction";

type CounterpartyDetailsProps = FlexProps;

const CounterpartyDetails = ({ ...props }: CounterpartyDetailsProps) => {
  const { transaction, isBuyer } = useTransactionExecution();
  const { getName } = useCountryList();
  const { country, name } = getCounterPartyCountry(transaction, isBuyer);

  return (
    <Flex
      flexDirection={{ base: `column`, md: `row` }}
      textStyle="text-sm"
      gap={1}
      {...props}
    >
      <Text as={chakra.span}>
        {name}
        {country && `, ${getName(country)}`}
      </Text>
    </Flex>
  );
};

export default CounterpartyDetails;
