import { Check, Copy } from "@phosphor-icons/react";
import React, { Fragment, useEffect, useState } from "react";

import { useRouter } from "next/navigation";

import {
  VStack,
  HStack,
  Text,
  Input,
  Button,
  Divider,
  Flex,
  IconButton,
  useClipboard,
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { UserWithInstitutionFragment } from "@/gql";
import { useCustomToast, useSignOut } from "@/hooks";
import constants from "@/utils/constants";

const { DEV_AUTH_ENABLED, AUTH0_ENABLED } = constants;

function UserInformation({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) {
  const { onCopy: onCopyUserId, hasCopied: hasCopiedUserId } = useClipboard(
    actor.id,
  );

  const actorProperties = {
    "User ID": (
      <Flex gap={5} alignItems="center">
        <Text>{actor.id}</Text>
        <IconButton
          variant="unstyled"
          m="0 auto"
          justifySelf="center"
          h={6}
          margin={0}
          minW="unset"
          aria-label={hasCopiedUserId ? `Copied` : `Copy`}
          onClick={onCopyUserId}
          icon={
            <Box
              as={hasCopiedUserId ? Check : Copy}
              size={24}
              weight="fill"
              color={hasCopiedUserId ? `green.400` : `white`}
              _hover={hasCopiedUserId ? {} : { color: `grey.200` }}
            />
          }
        />
      </Flex>
    ),
    "User Name": actor.name,
    "User Email": actor.email,
    "Institution ID": actor.institution?.id || `None`,
    Superadmin: actor.isSuperadmin.toString() === `true` ? `Yes` : `No`,
  };

  return (
    <Grid
      templateColumns="max-content 1fr"
      columnGap={6}
      rowGap={1}
      alignItems="center"
    >
      {Object.entries(actorProperties).map(([actorProperty, value]) => (
        <Fragment key={actorProperty}>
          <GridItem>
            <Text textStyle="heading-2xs" color="grey.100">
              {actorProperty}
            </Text>
          </GridItem>
          <GridItem>
            <Text color="grey.100" textStyle="text-sm">
              {value}
            </Text>
          </GridItem>
        </Fragment>
      ))}
    </Grid>
  );
}

export const UserManagement = ({
  actor,
}: {
  actor: UserWithInstitutionFragment | null;
}) => {
  const actorEmail = actor?.email || undefined;
  const [currentEmail, setCurrentEmail] = useState(actorEmail);
  const router = useRouter();
  const signOut = useSignOut();

  const { errorToast } = useCustomToast();
  const handleSignIn = async () => {
    if (!currentEmail) {
      return;
    }

    const response = await fetch(
      `/api/dev-login?email=${encodeURIComponent(currentEmail.trim())}`,
    );

    if (!response.ok) {
      const body = await response.json();
      errorToast(body);
      return;
    }

    router.refresh();
  };

  useEffect(() => {
    setCurrentEmail(actorEmail);
  }, [actorEmail]);

  return (
    <>
      {DEV_AUTH_ENABLED && AUTH0_ENABLED && (
        <VStack h="16">
          <VStack alignItems="flex-start" w="full">
            <Text textStyle="heading-2xs" color="grey.100">
              USER
            </Text>
            <HStack w="full">
              <Input
                bg="grey.800"
                color="grey.100"
                value={currentEmail}
                onChange={(event) => setCurrentEmail(event.target.value)}
                placeholder={currentEmail || `email`}
                isDisabled={!!actor}
              />
              <Button
                minW="unset"
                bg="white"
                onClick={!!actor ? () => signOut() : handleSignIn}
                isDisabled={!currentEmail}
              >
                {!!actor ? `Sign out` : `Sign in`}
              </Button>
            </HStack>
          </VStack>
        </VStack>
      )}

      {actor && (
        <>
          <Divider borderColor="grey.600" />
          <UserInformation actor={actor} />
        </>
      )}
    </>
  );
};
