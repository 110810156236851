import React, { ReactNode } from "react";

import { Box, ModalFooter, ModalFooterProps } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";

interface ExecutionModalFooterProps extends ModalFooterProps {
  readonly children: ReactNode;
}

const ExecutionModalFooter = ({
  children,
  ...footerProps
}: ExecutionModalFooterProps) => {
  const isDesktop = useIsDesktop();

  return (
    <ModalFooter {...footerProps}>
      <Box
        as="section"
        sx={{
          "& > *": { flexGrow: { base: 1, md: `inherit` } },
          "& > :only-child": {
            minWidth: !isDesktop ? `100%` : undefined,
          },
        }}
      >
        {children}
      </Box>
    </ModalFooter>
  );
};

export default ExecutionModalFooter;
