import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import { FlowKind, ModalKind } from "@/components/providers";
import { UserWithInstitutionFragment } from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import { useTexasCopy } from "@/hooks/featureFlags";
import {
  abbrCountLabel,
  getBidLabel,
  getCountLabel,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getIsUnaccreditedSeller,
  getLongDocumentTitleByTransferMethod,
  getIsEitherFund,
  getIsOtherMethod,
} from "@/utils";

import { useAcceptBidSequenceModalStepFormContext } from "./AcceptBidSequenceModalStepFormContext";

const AcceptBidSuccessModal = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const { t: tBids } = useTranslation(`bids`);
  const { mutationData } = useAcceptBidSequenceModalStepFormContext();
  const { showFlow } = useFlowLauncher();
  const { closeModal, addModalCloseHandler, removeModalCloseHandler } =
    useModal();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const isTexasCopyEnabled = useTexasCopy();

  useEffect(() => {
    if (isUnaccreditedSeller) {
      addModalCloseHandler(ModalKind.AcceptBid, () => {
        showFlow(FlowKind.U16RTransactionCelebration);
      });
    }

    return () => {
      removeModalCloseHandler(ModalKind.AcceptBid);
    };
  }, [isUnaccreditedSeller]);

  const bid = mutationData?.acceptBid.bid;
  const listing = bid?.listing;

  if (!bid || !listing) return null;
  const useLOITerms =
    !getIsEitherFund(bid.listing.transferMethod) &&
    !getIsOtherMethod(bid.listing.transferMethod);
  const textKey = getIsOtherMethod(bid.listing.transferMethod)
    ? `bid_accepted_congrats`
    : `bid_accepted_you_can_message`;

  const bidOrOrder = getBidLabel(bid.listing.transferMethod);
  const share = getCountLabel(bid.listing.transferMethod);
  const abbrShare = abbrCountLabel(bid.listing.transferMethod);
  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const documentTitle = getLongDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );

  return (
    <>
      <HiiveModalHeader>
        {isTexasCopyEnabled
          ? tBids(`you_accepted_bid`, { bidId: bid.displayId, bidOrOrder })
          : `${bidOrOrder} accepted!`}
      </HiiveModalHeader>
      <ModalBody>
        <Text
          textStyle={isTexasCopyEnabled ? `heading-md` : `heading-xl`}
          mb={4}
        >
          {`${bid.displayId}: ${numShares} ${share}s @ ${pricePerShare}/${abbrShare}`}
        </Text>
        {isTexasCopyEnabled ? (
          <>
            <Text>{tBids`the_transaction_process_will_now_start`}</Text>
            <Text mt={3}>
              <Trans
                ns="bids"
                i18nKey="for_support_reach_out"
                values={{ companyName: listing.company.name }}
                components={{
                  mailto: <SecuritySpecialistLink company={listing.company} />,
                }}
              />
            </Text>
          </>
        ) : (
          <VStack mb={4} alignItems="start">
            {useLOITerms && (
              <Text>{t(`bid_accepted_next_steps`, { documentTitle })}</Text>
            )}
            <Text>{t(textKey)}</Text>
            <Text>
              {t(`for_support`)}
              {` `}
              <SecuritySpecialistLink company={listing.company} />.
            </Text>
          </VStack>
        )}
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[AcceptBidSuccess/Submit]"
          submitText={isTexasCopyEnabled ? tBids`okay` : `Done`}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default withCurrentActor(AcceptBidSuccessModal);
