import { CheckCircle, Circle } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";

import { Tasks } from "@/components/transactions";
import {
  ExecutionStepStatus,
  ExecutionTaskActorType,
  ExecutionTaskStatus,
  TransactionExecutionPageTransactionFragment,
  TransactionExecutionPageWorkflowStepFragment,
} from "@/gql";
import { useColors } from "@/hooks";

import {
  STEP_EXIT_ANIMATION_LENGTH,
  STEP_EXIT_TRANSITION_LENGTH,
} from "./constants";

const MotionCard = motion(Card);
const MotionText = motion(Text);

const HEADER_HEIGHT = 56;

const InProgressStep = ({
  step,
  isBuySide,
  transaction,
  collapsed,
}: {
  readonly step: TransactionExecutionPageWorkflowStepFragment;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
  readonly collapsed: boolean;
}) => {
  const { t } = useTranslation(`execution`);

  const [salmon900, white, teal900] = useColors([
    `salmon.900`,
    `white`,
    `teal.900`,
    `clear`,
  ]);
  // filter out completed steps on initial render
  const [shouldRender, setShouldRender] = useState(
    step.status !== ExecutionStepStatus.Completed &&
      step.status !== ExecutionStepStatus.Completing,
  );
  // we're not managing the exit animation with an actual framer exit state
  // because the component never exits, we return null instead
  const [isExiting, setIsExiting] = useState(false);
  const cardBodyRef = useRef<HTMLDivElement>(null);
  const wasVisibleWhenCompleted = useRef(true);

  const copy = isBuySide ? step.instructions.buyer : step.instructions.seller;

  const actorType = isBuySide
    ? ExecutionTaskActorType.Buyer
    : ExecutionTaskActorType.Seller;

  const isActionableByActor = step.tasks
    .filter((task) => task.status === ExecutionTaskStatus.InProgress)
    .map((task) => task.actorType)
    .includes(actorType);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === `hidden`) {
        wasVisibleWhenCompleted.current = false;
      } else {
        wasVisibleWhenCompleted.current = true;
      }
    };

    document.addEventListener(`visibilitychange`, handleVisibilityChange);
    return () =>
      document.removeEventListener(`visibilitychange`, handleVisibilityChange);
  }, []);

  useEffect(() => {
    // if page is not focused don't play animation, straight to "exit"
    if (!wasVisibleWhenCompleted.current) {
      setShouldRender(false);
    }
  }, [collapsed]);

  const isStarting = step.status === ExecutionStepStatus.Starting;

  if (!shouldRender) return null;

  return (
    <motion.div
      // "task complete" animation fade out wrapper or "exit" animation
      initial={{ opacity: 1, scaleY: 1, height: `auto`, marginBottom: `1rem` }}
      animate={{
        opacity: isExiting ? 0 : 1,
        scaleY: isExiting ? 0 : 1,
        height: isExiting ? 0 : `auto`,
        marginBottom: isExiting ? 0 : `1rem`,
      }}
      exit={{
        opacity: 0,
        scaleY: 0,
        height: 0,
        marginBottom: 0,
      }}
      transition={{
        duration: STEP_EXIT_TRANSITION_LENGTH,
        ease: `easeInOut`,
      }}
      style={{ width: `100%`, transformOrigin: `top` }}
      onAnimationComplete={() => {
        // once the "exit" animation is complete don't render the component
        if (isExiting) setShouldRender(false);
      }}
    >
      <MotionCard
        // task collapse into "task complete" animation
        w="full"
        variant="no-border-radius-base"
        initial={{ height: `auto`, backgroundColor: white }}
        animate={{
          height: collapsed ? HEADER_HEIGHT : `auto`,
          backgroundColor: collapsed ? teal900 : white,
          padding: collapsed ? 0 : undefined,
        }}
        transition={{
          duration: STEP_EXIT_TRANSITION_LENGTH,
          ease: `easeInOut`,
        }}
        position="relative"
        overflow={collapsed ? `hidden` : `visible`}
        onAnimationComplete={() => {
          // once task is collapsed, start the "exit" animation
          if (collapsed) {
            setTimeout(() => setIsExiting(true), STEP_EXIT_ANIMATION_LENGTH);
          }
        }}
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: isExiting ? 0 : 1 }}
          transition={{ duration: 0.3, ease: `easeOut` }}
          style={{ width: `100%` }}
        >
          <CardHeader
            as={HStack}
            position="absolute"
            top={0}
            left={0}
            align="center"
            opacity={collapsed ? 1 : 0}
            pointerEvents="none"
            transition="opacity 0.3s ease-out"
            color="white"
            w="full"
            h={`${HEADER_HEIGHT}px`}
            borderBottomColor="transparent"
          >
            <CheckCircle size={20} weight="fill" />
            <MotionText
              key="complete-text"
              initial={{ opacity: 0, x: -28 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -28 }}
              transition={{ duration: 0.5, ease: `easeOut` }}
            >
              <Trans
                i18nKey="task_complete"
                components={{ bold: <strong style={{ fontWeight: 500 }} /> }}
              />
            </MotionText>
          </CardHeader>
        </motion.div>

        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: collapsed ? 0 : 1 }}
          transition={{ duration: 0.5, ease: `easeInOut` }}
          style={{ width: `100%` }}
        >
          <CardHeader
            as={HStack}
            py={4}
            borderBottomColor={collapsed ? `transparent` : `inherit`}
            w="full"
            h={`${HEADER_HEIGHT}px`}
          >
            {isActionableByActor && (
              <Circle size={8} weight="fill" color={salmon900} />
            )}
            <Text textStyle="heading-md">{step.name}</Text>
          </CardHeader>
        </motion.div>

        <motion.div
          initial={false}
          animate={{
            opacity: collapsed ? 0 : 1,
            display: collapsed ? `none` : `flex`,
          }}
          transition={{ duration: 0.3 }}
          style={{ width: `100%` }}
        >
          {isStarting ? (
            <CardBody p={0} px={{ base: 4, md: 6 }} py={{ base: 4, md: 6 }}>
              <Card variant="flat" bg="grey.15">
                <CardBody>
                  <Text>{t(`preparing_task`)}</Text>
                </CardBody>
              </Card>
            </CardBody>
          ) : (
            <CardBody
              p={0}
              px={{ base: 4, md: 6 }}
              py={{ base: 4, md: 6 }}
              ref={cardBodyRef}
              w="full"
            >
              <Flex gap={{ base: 4, md: 6 }} direction="column">
                {!!copy && <Text whiteSpace="pre-line">{copy}</Text>}
                {step.tasks.length > 0 && (
                  <Tasks
                    tasks={step.tasks}
                    isBuySide={isBuySide}
                    transaction={transaction}
                    delayCompletion
                  />
                )}
              </Flex>
            </CardBody>
          )}
        </motion.div>
      </MotionCard>
    </motion.div>
  );
};

export default InProgressStep;
