import { AnimatePresence } from "framer-motion";
import isNil from "lodash/isNil";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { AnimatedModalStep } from "@/components/form";
import { CreateListingSuccessModalListingFragment } from "@/gql";
import { useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getListingNumOfShares,
  constants,
} from "@/utils";

type UnaccreditedSellerCreateListingSuccessInReviewModalContentProps = {
  readonly listing: CreateListingSuccessModalListingFragment;
};

const { email_connect } = constants;

export function UnaccreditedSellerCreateListingSuccessInReviewModalContent({
  listing,
}: UnaccreditedSellerCreateListingSuccessInReviewModalContentProps) {
  const { t } = useTranslation(`listings`);
  const { closeModal } = useModal();

  const { listingPricePerShare, company, displayId } = listing;

  const { name: companyName } = company;

  const numShares = getListingNumOfShares(listing, false);

  const formattedShares = formatShares(numShares);
  const formattedPrice = !isNil(listingPricePerShare)
    ? formatPricePerShare(listingPricePerShare)
    : ``;

  return (
    <HiiveModalContentWrapper>
      <AnimatePresence mode="wait" initial={false}>
        <AnimatedModalStep>
          <HiiveModalHeader>
            {t(`were_reviewing_your_listing`, {
              companyName,
            })}
          </HiiveModalHeader>
          <ModalBody>
            <VStack alignItems="flex-start">
              <Text textStyle="heading-xl">
                {t(`were_reviewing_your_listing_subheading`, {
                  displayId,
                  numShares: formattedShares,
                  pricePerShare: formattedPrice,
                })}
              </Text>
              <Text>{t(`currently_reviewing_your_listing`)}</Text>
              <Text>
                <Trans
                  ns="listings"
                  i18nKey="to_support_your_listing_connect"
                  components={[
                    <MailtoLink
                      key="mail_to_link"
                      email={email_connect}
                      subject={t(`why_is_my_posting_in_review_email_title`, {
                        companyName,
                      })}
                      fontWeight={500}
                    />,
                  ]}
                  values={{
                    connectEmail: email_connect,
                  }}
                />
              </Text>
            </VStack>
          </ModalBody>
          <HiiveModalFooter>
            <HiiveSubmitButton
              observabilityLabel="[CreateListingSuccess/Submit]"
              type="submit"
              submitText={t(`okay`)}
              onClick={closeModal}
            />
          </HiiveModalFooter>
        </AnimatedModalStep>
      </AnimatePresence>
    </HiiveModalContentWrapper>
  );
}
