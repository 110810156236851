import { useField } from "formik";
import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardFooter, CardHeader, Text, VStack } from "@chakra-ui/react";

import {
  TransferTypeChoiceInput,
  ShareSeriesInput,
  transferTypeChoices,
  ExplainSellSharesDirectlyInput,
} from "@/components/listings";
import { UnaccreditedSellerModifyListingByIdPageCompanyFragment } from "@/gql";
import { useRestrictiveInReview } from "@/hooks/featureFlags";
import { Nullable } from "@/utils";

import { ModifyListingFormValues } from "./types";

type TransferTypeCardProps = {
  company: UnaccreditedSellerModifyListingByIdPageCompanyFragment;
  values: Nullable<ModifyListingFormValues>;
};

export const TransferTypeCard = forwardRef<
  HTMLDivElement,
  TransferTypeCardProps
>(
  (
    {
      company: { name: companyName, restricted },
      values: { transferTypeChoice },
    },
    ref,
  ) => {
    const { t } = useTranslation(`listings`);

    const restrictiveInReview = useRestrictiveInReview();
    const isRestricted = !!restrictiveInReview && restricted;

    const isDirect = transferTypeChoice === transferTypeChoices.Yes;
    const canSellSharesDirectlyOnRestrictedCompany = isRestricted && isDirect;

    const [, , { setValue: setUserPlacedExplanation }] = useField(
      `userPlacedExplanation`,
    );

    useEffect(() => {
      if (isRestricted && !isDirect) {
        setUserPlacedExplanation(``);
      }
    }, [canSellSharesDirectlyOnRestrictedCompany]);

    return (
      <Card w="full" ref={ref}>
        <CardHeader>
          <Text
            textStyle="heading-sm"
            _before={{
              content: `counter(section) ". "`,
            }}
          >
            {t(`transfer_type`)}
          </Text>
        </CardHeader>
        <CardFooter>
          <VStack spacing={10}>
            <TransferTypeChoiceInput
              isRestricted={isRestricted}
              companyName={companyName}
            />
            {canSellSharesDirectlyOnRestrictedCompany && (
              <ExplainSellSharesDirectlyInput />
            )}
            <ShareSeriesInput />
          </VStack>
        </CardFooter>
      </Card>
    );
  },
);
