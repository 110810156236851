import { useTranslation, Trans } from "react-i18next";
import { match, P } from "ts-pattern";

import { HStack, Link, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  AcceptDiscussionRequestFormDiscussionFragment,
  BidPageBidByIdDocument,
  DiscussionPermission,
  InvestorType,
  ListingPageListingByIdDocument,
  useAcceptDiscussionRequestMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { iHaveEntityPermission, useMutationWithError } from "@/hooks";

function useCounterpartyText(
  actor: UserWithInstitutionFragment,
  discussion: AcceptDiscussionRequestFormDiscussionFragment,
) {
  const { t } = useTranslation(`discussions`);

  return match(discussion)
    .with({ initiatorId: actor.id }, () => null)
    .with({ topic: { __typename: `Listing`, sellerId: actor.id } }, () =>
      t(`buyer_initiated_inquiry_listing`),
    )
    .with({ topic: { __typename: `Listing`, sellerId: P.not(actor.id) } }, () =>
      t(`seller_initiated_inquiry_listing`),
    )
    .with({ topic: { __typename: `StandingBid`, buyerId: actor.id } }, () =>
      t(`seller_initiated_inquiry_standing_bid`),
    )
    .with(
      {
        topic: {
          __typename: `StandingBid`,
          buyerInstitutionId: actor.institutionId,
        },
      },
      () =>
        t(`seller_initiated_inquiry_standing_bid_institution`, {
          legalName: actor.institution?.legalName || t(`your_institution`),
        }),
    )
    .otherwise(() => null);
}

function useCounterpartyTermsAndConditions(
  actor: UserWithInstitutionFragment,
  discussion: AcceptDiscussionRequestFormDiscussionFragment,
) {
  const { t } = useTranslation(`discussions`);
  return match({ discussion, actor })
    .with(
      {
        discussion: { topic: { __typename: `Listing`, sellerId: actor.id } },
        actor: { investorType: InvestorType.UnaccreditedSeller },
      },
      () => t(`seller_only_hiive_terms_and_conditions`),
    )
    .otherwise(() => t(`hiive_terms_and_conditions`));
}

export const AcceptDiscussionRequestForm = withCurrentActor(
  ({
    discussion,
    actor,
  }: {
    readonly discussion: AcceptDiscussionRequestFormDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`discussions`);
    const counterPartyText = useCounterpartyText(actor, discussion);
    const counterPartyTermsAndConditionsText =
      useCounterpartyTermsAndConditions(actor, discussion);

    const [acceptDiscussionRequestMutation] = useMutationWithError(
      useAcceptDiscussionRequestMutation(),
      `acceptDiscussionRequest`,
    );

    const handleAcceptDiscussionRequest = () => {
      acceptDiscussionRequestMutation({
        variables: { discussionId: discussion.id },
        refetchQueries: [
          BidPageBidByIdDocument,
          ListingPageListingByIdDocument,
        ],
      });
    };

    const canAcceptDiscussionRequest = iHaveEntityPermission(
      discussion,
      DiscussionPermission.AcceptDiscussionRequest,
    );

    return (
      <HStack
        w="full"
        borderRadius="md"
        bg="grey.50"
        borderWidth={0.5}
        borderColor="grey.200"
        p={5}
      >
        <VStack alignItems="start">
          <Text textStyle="heading-lg" textTransform="uppercase">
            {t(`inquiry_request`)}
          </Text>
          <Text>
            <Trans
              ns="discussions"
              i18nKey="accepting_this_inquiry_request"
              components={[
                <Link
                  key="link"
                  href="/terms-and-conditions"
                  textDecoration="underline"
                  target="_blank"
                />,
              ]}
              values={{
                termsAndConditions: counterPartyTermsAndConditionsText,
                initiated: counterPartyText,
              }}
            />
          </Text>
        </VStack>
        {canAcceptDiscussionRequest && (
          <HiiveButton
            variant="rounded-solid-salmon"
            size="xl"
            w="full"
            onClick={handleAcceptDiscussionRequest}
            observabilityLabel="[AcceptDiscussionRequestForm] Accept"
          >
            {t(`accept`)}
          </HiiveButton>
        )}
      </HStack>
    );
  },
);
