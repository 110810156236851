import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  CardBody,
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { MilestonesMobile } from "@/components/transactions";
import {
  TransactionExecutionPageTransactionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { useBuySideFees } from "@/hooks/featureFlags";
import {
  getTransactionIsSellSide,
  getTransactionIsBuySide,
  getTransactionCounterpartyName,
} from "@/hooks/transaction";
import {
  formatCurrency,
  formatPricePerShare,
  formatShares,
  getCountLabel,
} from "@/utils";

import GrossProceeds from "./GrossProceeds";

const TransactionDetailItem = ({
  title,
  value,
}: {
  readonly title: string;
  readonly value: string;
}) => (
  <HStack
    data-testid={`${title}-header-section`}
    gap={2}
    w={{ base: `full`, lg: `auto` }}
    justifyContent={{ base: `space-between`, lg: `auto` }}
  >
    <Text color="grey.700" textStyle="heading-xs">
      {title}
    </Text>
    <Text color="grey.700" textStyle="text-sm">
      {value}
    </Text>
  </HStack>
);

export const TransactionExecutionPageSummaryCardBody = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionExecutionPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`execution`);
    const buySideFeesEnabled = useBuySideFees();

    const { buyerCommission, sellerCommission, grossProceeds } = transaction;
    const isBuySide = getTransactionIsBuySide({ actor, transaction });
    const isSellSide = getTransactionIsSellSide({ actor, transaction });

    const { netFees = 0 } = sellerCommission || {};
    const netProceeds = Math.max(grossProceeds - netFees, 0);

    const { netFees: buyerNetFees = 0 } = buyerCommission || {};
    const buyerTotalAmount = grossProceeds + buyerNetFees;
    const buySideAmount = buySideFeesEnabled ? buyerTotalAmount : grossProceeds;

    const counterpartyName = getTransactionCounterpartyName({
      actor,
      transaction,
    });

    const grossProceedsLabel = match({ buySideFeesEnabled, isBuySide })
      .with({ buySideFeesEnabled: true, isBuySide: true }, () =>
        t(`gross_value`),
      )
      .with({ isBuySide: true }, () => t(`total`))
      .otherwise(() => t(`gross_proceeds`));

    const isDesktop = useIsDesktop();

    const { workflow } = transaction;

    return (
      <CardBody
        position="relative"
        p={0}
        pt="44px"
        pb={6}
        borderBottomColor="grey.50"
      >
        {!!transaction.company.logoUrl && (
          <Center
            bg="white"
            w="14"
            h="14"
            borderWidth="0.5px"
            borderColor="grey.200"
            borderRadius="md"
            p={2}
            position="absolute"
            top="0"
            left={6}
            transform="auto"
            translateY="-50%"
          >
            <Image
              src={transaction.company.logoUrl}
              alt={transaction.company.name}
            />
          </Center>
        )}
        <Flex
          px={6}
          mb={4}
          w="full"
          gap={4}
          flexDirection={{ base: `column`, md: `row` }}
          justifyContent="space-between"
          alignItems={{ base: `auto`, md: `flex-end` }}
        >
          <VStack alignItems="flex-start" spacing={0}>
            <Text textStyle="heading-4xl">{transaction.company.name}</Text>
            <Text color="teal.900" textStyle="text-xs">
              <chakra.span fontWeight="medium" mr={1}>
                {t(`transaction`)}
              </chakra.span>
              {transaction.displayId}
            </Text>
          </VStack>
          <GrossProceeds
            buySideAmount={buySideAmount}
            counterpartyName={counterpartyName}
            isSellSide={isSellSide}
            netProceeds={netProceeds}
          />
        </Flex>
        {!!workflow && !isDesktop ? (
          <MilestonesMobile workflow={workflow} isBuySide={isBuySide} />
        ) : (
          <Box px={6}>
            <Divider mb={4} borderColor="grey.50" />
          </Box>
        )}
        <HStack px={6} gap={6} rowGap={2} flexWrap="wrap">
          <TransactionDetailItem
            title={t(`${getCountLabel(transaction.transferMethod)}s`)}
            value={`${formatShares(
              transaction.numShares,
            )} @ ${formatPricePerShare(transaction.pricePerShare)}`}
          />
          <TransactionDetailItem
            title={grossProceedsLabel}
            value={formatCurrency(grossProceeds, { fromCents: true })}
          />
          {isSellSide && (
            <TransactionDetailItem
              title={t(`net_proceeds`)}
              value={formatCurrency(netProceeds, { fromCents: true })}
            />
          )}
          {buySideFeesEnabled && isBuySide && (
            <TransactionDetailItem
              title={t(`total_amount`)}
              value={formatCurrency(buyerTotalAmount, { fromCents: true })}
            />
          )}
        </HStack>
      </CardBody>
    );
  },
);
