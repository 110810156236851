import { useTranslation } from "react-i18next";

import {
  ExecutionConfirmTransactionModificationApprovalStatus,
  ExecutionConfirmTransactionModificationTaskDataAcknowledgment,
} from "@/gql";

export enum TransactionModificationStatus {
  Approved = `APPROVED`,
  Rejected = `REJECTED`,
  Pending = `PENDING`,
}

export const useTransactionModificationStatusTitle = () => {
  const { t } = useTranslation(`execution`);

  return {
    [TransactionModificationStatus.Approved]: {
      title: t(`approved`),
      variant: `status-active`,
    },
    [TransactionModificationStatus.Rejected]: {
      title: t(`rejected`),
      variant: `status-rejected`,
    },
    [TransactionModificationStatus.Pending]: {
      title: t(`pending`),
      variant: `status-pending`,
    },
  };
};

export const getTransactionModificationStatus = (
  acknowledgments: ExecutionConfirmTransactionModificationTaskDataAcknowledgment[],
): TransactionModificationStatus => {
  if (
    acknowledgments.some(
      ({ status }) =>
        status ===
        ExecutionConfirmTransactionModificationApprovalStatus.Declined,
    )
  ) {
    return TransactionModificationStatus.Rejected;
  }

  if (acknowledgments.every(({ status }) => status === `APPROVED`)) {
    return TransactionModificationStatus.Approved;
  }

  return TransactionModificationStatus.Pending;
};
