import React, { useState, useCallback, useMemo } from "react";

import { HStack, Portal, Text } from "@chakra-ui/react";

import { useSession } from "@/hooks";
import { useStagingBanner } from "@/hooks/featureFlags";
import constants from "@/utils/constants";

const { Z_INDEX_OVERRIDES, IS_LOCAL, IS_STAGING } = constants;

const STAGING_BANNER_DISMISSED_KEY = `staging_banner_dismissed`;
const STAGING_BANNER_DISMISSED_TRUTHY = `yes`;

function useIsStagingBannerDismissed() {
  const isStagingBannerDismissed = sessionStorage.getItem(
    STAGING_BANNER_DISMISSED_KEY,
  );

  return useState(isStagingBannerDismissed === STAGING_BANNER_DISMISSED_TRUTHY);
}

const StagingBanner = () => {
  const { isAuthenticated } = useSession();
  const stagingBannerEnabled = useStagingBanner();

  const [isStagingBannerDismissed, setIsStagingBannerDismissed] =
    useIsStagingBannerDismissed();

  const shouldDisplay =
    stagingBannerEnabled && [IS_STAGING, IS_LOCAL].includes(true);
  const shouldHideBanner = useMemo(
    () => isStagingBannerDismissed || !isAuthenticated,
    [isStagingBannerDismissed, isAuthenticated],
  );

  const onDismiss = useCallback(() => {
    sessionStorage.setItem(
      STAGING_BANNER_DISMISSED_KEY,
      STAGING_BANNER_DISMISSED_TRUTHY,
    );

    return setIsStagingBannerDismissed(true);
  }, [setIsStagingBannerDismissed]);

  if (!shouldDisplay || shouldHideBanner) {
    return null;
  }

  return (
    <Portal>
      <HStack
        zIndex={Z_INDEX_OVERRIDES.DEV_HELPERS}
        position="fixed"
        top="0"
        left="0"
        w="full"
        alignItems="center"
        justifyContent="center"
        h={6}
        boxShadow="card"
        bg="yellow.600"
        color="white"
        gap={2}
        opacity={0.9}
      >
        <Text
          color="white"
          textStyle="heading-xs"
        >{`You're in ${IS_LOCAL ? `local` : `staging`}.`}</Text>
        <Text
          role="button"
          cursor="pointer"
          textDecor="underline"
          fontSize={14}
          fontWeight={600}
          onClick={onDismiss}
          // eslint-disable-next-line i18next/no-literal-string
        >
          Dismiss for now
        </Text>
      </HStack>
    </Portal>
  );
};

export default StagingBanner;
