import { CheckCircle } from "@phosphor-icons/react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, VStack, Text, Button, HStack } from "@chakra-ui/react";

import { ExecutionModalFooter } from "@/components/common";
import { useColors } from "@/hooks";

import MTPrivacyPolicyLink from "./MTPrivacyPolicyLink";
import { CollectBankAccountSteps } from "./types";

const DisclaimerContent = ({
  setStep,
}: {
  onClose: () => void;
  setStep: (step: CollectBankAccountSteps) => void;
}) => {
  const { t } = useTranslation(`execution`);
  const [teal900] = useColors([`teal.900`]);

  return (
    <>
      <ModalBody>
        <VStack
          spacing={6}
          alignItems="start"
          h="full"
          justifyContent="space-between"
        >
          <VStack spacing={6} alignItems="start">
            <Text textStyle="heading-xl">
              {t(`modern_treasury_disclaimer`)}
            </Text>
            <VStack spacing={4} alignItems="start">
              <HStack spacing={4}>
                <CheckCircle size={18} color={teal900} weight="fill" />
                <Text textStyle="heading-xs">
                  {t(`modern_treasury_feature_1`)}
                </Text>
              </HStack>
              <HStack spacing={4}>
                <CheckCircle size={18} color={teal900} weight="fill" />
                <Text textStyle="heading-xs">
                  {t(`modern_treasury_feature_2`)}
                </Text>
              </HStack>
              <HStack spacing={4}>
                <CheckCircle size={18} color={teal900} weight="fill" />
                <Text textStyle="heading-xs">
                  {t(`modern_treasury_feature_3`)}
                </Text>
              </HStack>
            </VStack>
          </VStack>
          <Text textStyle="text-xs" mt={2} color="grey.700">
            <Trans
              i18nKey="modern_treasury_terms"
              components={{ italic: <MTPrivacyPolicyLink /> }}
              ns="execution"
            />
          </Text>
        </VStack>
      </ModalBody>
      <ExecutionModalFooter>
        <Button onClick={() => setStep(`country_collection`)}>
          {t(`next`)}
        </Button>
      </ExecutionModalFooter>
    </>
  );
};

export default DisclaimerContent;
