import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  CardBody,
  VStack,
  CardFooter,
  Flex,
  Text,
  Link,
} from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { TransactionExecutionPageTransactionFragment } from "@/gql";
import { constants, getCC } from "@/utils";

const marketingWebsiteLinks = [
  constants.marketing_website_links.guide_transaction_approval_closing,
  constants.marketing_website_links.guide_carta_holdings_verification,
  constants.marketing_website_links.faq_transaction_approval_closing,
  constants.marketing_website_links.faq_us_taxes,
];

const InformativeArticlesCard = ({
  transaction,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation();

  const executionEmail = `execution@hiive.com`;
  const cc = getCC(transaction.company, true);

  return (
    <Card w="full" variant="no-border-radius-base">
      <CardHeader py={4}>
        <Text textStyle="heading-md">{t(`transaction_support_guides`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          {marketingWebsiteLinks.map(({ title, url }) => (
            <Link
              target="_blank"
              textDecoration="underline"
              href={url}
              key={url}
            >
              {title}
            </Link>
          ))}
        </VStack>
      </CardBody>
      <CardFooter bg="grey.25">
        <Flex direction="column" gap={4}>
          <Text>{t(`for_support_contact`)}</Text>
          <MailtoLink email={executionEmail} cc={cc} fontWeight="medium">
            {executionEmail}
          </MailtoLink>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export default InformativeArticlesCard;
