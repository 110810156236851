import { useTranslation } from "react-i18next";

import Link from "next/link";

import { Button, Text } from "@chakra-ui/react";

import { Tile, TileHeader, TileRow, SensitiveText } from "@/components/common";
import {
  ListingPageListingHiiveUserTileListingFragment,
  ListingPageListingHiiveUserTileBrokerFragment,
  ListingState,
} from "@/gql";
import { useConfig, useCurrentActor } from "@/hooks";
import { toTimestamp, getHasExpired } from "@/utils";
import { getTransactionBackOfficeLink } from "@/utils/backOffice";

import { getListingStatusText } from "./getListingStatusText";

interface BackOfficeLinkProps {
  readonly listing: ListingPageListingHiiveUserTileListingFragment;
}

const BackOfficeLink = ({ listing }: BackOfficeLinkProps) => {
  const { t } = useTranslation();
  return (
    <Link
      target="_blank"
      href={getTransactionBackOfficeLink(listing.id)}
      passHref
    >
      <Button variant="text-salmon" px={0}>
        <TileHeader>{t`details`}</TileHeader>
      </Button>
    </Link>
  );
};

const PartnerBrokerTile = ({
  listingState,
  broker,
}: {
  readonly listingState: ListingState;
  readonly broker: ListingPageListingHiiveUserTileBrokerFragment;
}) => {
  const { t } = useTranslation();

  return listingState === ListingState.InReview ? (
    <>
      <TileHeader>{t(`representing_hiive_connect_header`)}</TileHeader>
      <div>{`${broker.firstName} ${broker.lastName} - ${broker.email}`}</div>
    </>
  ) : (
    <>
      <TileHeader>Representing</TileHeader>
      <Text>
        <Text>{t(`listing_placed_by_hiive`)}</Text>
        <Text>Name: {`${broker.firstName} ${broker.lastName}`}</Text>
        <Text>Email: {broker.email}</Text>
      </Text>
    </>
  );
};

interface ListingHiiveUserTileProps {
  readonly listing: ListingPageListingHiiveUserTileListingFragment;
}

const ListingHiiveUserTile = ({ listing }: ListingHiiveUserTileProps) => {
  const { t } = useTranslation();

  const actor = useCurrentActor();
  const { stnLoiHoursValidHours } = useConfig() ?? {};

  const statusText = getListingStatusText(listing, {
    actor,
    stnLoiHoursValidHours,
  });

  return (
    <Tile
      py={2}
      gap={4}
      display="grid"
      gridTemplateColumns={{
        base: `1fr`,
        md: `auto 1fr`,
      }}
      flexDirection="column"
      alignItems="flex-start"
      data-testid="listing-hiive-user-tile"
    >
      {listing.placedAt && (
        <TileRow>
          <TileHeader>{t`placed`}</TileHeader>
          <SensitiveText>{toTimestamp(listing.placedAt)}</SensitiveText>
        </TileRow>
      )}
      {listing.expireAt && (
        <TileRow>
          <TileHeader>
            {getHasExpired(listing.expireAt) ? t`expired` : t`expires`}
          </TileHeader>
          <SensitiveText>{toTimestamp(listing.expireAt)}</SensitiveText>
        </TileRow>
      )}
      {statusText && (
        <TileRow>
          <TileHeader>{t(`status`)}</TileHeader>
          <Text>{statusText}</Text>
        </TileRow>
      )}
      {!!listing.broker && (
        <TileRow>
          <PartnerBrokerTile
            listingState={listing.state}
            broker={listing.broker}
          />
        </TileRow>
      )}
      <TileRow>
        <TileHeader>
          <BackOfficeLink listing={listing} />
        </TileHeader>
        <Text>
          {t(
            listing.solicited
              ? `listing_solicitation_solicited`
              : `listing_solicitation_unsolicited`,
          )}
        </Text>
      </TileRow>
    </Tile>
  );
};

export default ListingHiiveUserTile;
