import { useContext } from "react";

import { LDStatusContext } from "@/components/async-with-ld-provider";

export type FlagValue = boolean | string | string[];

export type FeatureFlags = {
  readonly "compsec.new_auth_system": FlagValue;
  readonly "hiive.enable_maintenance": FlagValue;
  readonly "marketplace.suppress_notifications": FlagValue;
  readonly "marketplace.front_chat_u16r": FlagValue;
  readonly "marketplace.front_chat_trader": FlagValue;
  readonly "marketplace.rsu_restriction_and_eligible_buyers": FlagValue;
  readonly "marketplace.slugs_as_company_url": FlagValue;
  readonly "marketplace.restrictive_in_review": FlagValue;
  readonly "marketplace.base_fee_removal": FlagValue;
  readonly "marketplace.texas_copy": FlagValue;
  readonly "funds.buy_side_fees": FlagValue;
  readonly "funds.texas_for_funds": FlagValue;
  readonly "marketplace.staging_banner": FlagValue;
  readonly "marketplace.listing_note_updates": FlagValue;
  readonly "marketplace.sb_notes_updates": FlagValue;
  readonly "growth.front_chat_u16r_onboarding": FlagValue;
  readonly "marketplace.client_seller_holdings_updates": FlagValue;
  readonly "marketplace.pricing_in_review_bids": FlagValue;
  readonly "marketplace.pricing_in_review_standing_bids": FlagValue;
  readonly "issuer.issuer_portal.buyer_information_disclosure": FlagValue;
};

export const useFeatureFlags = (): FeatureFlags => {
  const { flags } = useContext(LDStatusContext);

  return (flags as FeatureFlags) || {};
};

export const useMaintenanceMode = () =>
  useFeatureFlags()[`hiive.enable_maintenance`];

export const useSuppressNotifications = () =>
  useFeatureFlags()[`marketplace.suppress_notifications`];

export const useFrontChatU16r = () =>
  useFeatureFlags()[`marketplace.front_chat_u16r`];

export const useFrontChatTrader = () =>
  useFeatureFlags()[`marketplace.front_chat_trader`];

export const useRsuRestrictionAndEligibleBuyers = () =>
  useFeatureFlags()[`marketplace.rsu_restriction_and_eligible_buyers`];

export const useSlugsAsCompanyUrl = () =>
  useFeatureFlags()[`marketplace.slugs_as_company_url`];

export const useRestrictiveInReview = () =>
  useFeatureFlags()[`marketplace.restrictive_in_review`];

export const useBaseFeeRemoval = () =>
  useFeatureFlags()[`marketplace.base_fee_removal`];

export const useTexasCopy = () => useFeatureFlags()[`marketplace.texas_copy`];

export const useBuySideFees = () => useFeatureFlags()[`funds.buy_side_fees`];

export const useTEXASForFunds = () =>
  useFeatureFlags()[`funds.texas_for_funds`];

export const useStagingBanner = () =>
  useFeatureFlags()[`marketplace.staging_banner`];

export const useListingNotesUpdates = () =>
  useFeatureFlags()[`marketplace.listing_note_updates`];

export const useStandingBidNotesUpdates = () =>
  useFeatureFlags()[`marketplace.sb_notes_updates`];

export const useFrontChatU16rOnboarding = () =>
  useFeatureFlags()[`growth.front_chat_u16r_onboarding`];

export const useClientSellerHoldingsUpdates = () =>
  useFeatureFlags()[`marketplace.client_seller_holdings_updates`];

export const useBidsPricingInReview = () =>
  useFeatureFlags()[`marketplace.pricing_in_review_bids`];

export const useStandingBidsPricingInReview = () =>
  useFeatureFlags()[`marketplace.pricing_in_review_standing_bids`];

export const useBuyerInformationDisclosure = () =>
  useFeatureFlags()[`issuer.issuer_portal.buyer_information_disclosure`];
