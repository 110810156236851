import { Flex } from "@chakra-ui/react";

import {
  BuyerCommissionBreakdown,
  FocusedShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  BidInfoCardStatusTile,
  DocumentActions,
  AcceptedBidDetails,
  BidBuyerRoundingDisclaimer,
} from "@/components/postings";
import { BuyerTransactionInfoCardBidFragment } from "@/gql";
import { useCurrentActor, useDocumentSigners } from "@/hooks";
import { useBuySideFees } from "@/hooks/featureFlags";
import {
  abbrCountLabel,
  getBidLabel,
  getCountLabel,
  getShowDocumentActions,
} from "@/utils";
import { getBidNumSharesActual } from "@/utils/bid";

const BuyerTransactionInfoCard = ({
  bid,
}: {
  readonly bid: BuyerTransactionInfoCardBidFragment;
}) => {
  const actor = useCurrentActor();
  const buySideFeesEnabled = useBuySideFees();
  const { transaction, listing } = bid;

  const {
    canSignTransactionDocument,
    canInviteAlternateSigner,
    canRevokeAlternateSignerInvitation,
    haveISigned,
  } = useDocumentSigners(transaction);

  if (!transaction) return null;

  const { company, numShares, pricePerShare, buyerCommission } = transaction;

  const shouldRenderDocumentsFooterActions = getShowDocumentActions({
    bid,
    actor,
    haveISigned,
    canInviteAlternateSigner,
    canSignTransactionDocument,
    canRevokeAlternateSignerInvitation,
  });

  const isRoundingDisclaimerVisible =
    getBidNumSharesActual(bid) !== bid.numShares;

  const bidText = getBidLabel(listing.transferMethod).toLowerCase();
  const shareText = getCountLabel(listing.transferMethod);

  return (
    <Flex direction="column" gap={7} data-testid="bid-info-card">
      <FocusedShareDetails.Card variant="transaction">
        <FocusedShareDetails.Header
          title={`Transaction ${bid.displayId}`}
          company={company}
        >
          <FocusedShareDetails.HeaderCard>
            <ShareDetailsStats
              numberOfShares={numShares}
              pricePerShare={pricePerShare}
              shareText={shareText}
              shareTextAbbreviation={abbrCountLabel(listing.transferMethod)}
            />
          </FocusedShareDetails.HeaderCard>
          {isRoundingDisclaimerVisible && (
            <BidBuyerRoundingDisclaimer
              bidText={bidText}
              shareText={shareText}
              numSharesOriginal={bid.numShares}
            />
          )}
        </FocusedShareDetails.Header>
        <FocusedShareDetails.Content>
          <FocusedShareDetails.ContentSection>
            <Flex direction="column" gap={6}>
              <BidInfoCardStatusTile bid={bid} showCounterBidDetails={false} />
              {buySideFeesEnabled && buyerCommission && (
                <BuyerCommissionBreakdown
                  numShares={numShares}
                  pricePerShare={pricePerShare}
                  commission={buyerCommission}
                  buyerCommissionStructureId={
                    buyerCommission.commissionStructure?.id
                  }
                />
              )}
              <AcceptedBidDetails bid={bid} />
            </Flex>
          </FocusedShareDetails.ContentSection>
          {shouldRenderDocumentsFooterActions && (
            <FocusedShareDetails.ContentFooter
              flexDirection="column"
              justifyContent="flex-end"
            >
              <DocumentActions bid={bid} />
            </FocusedShareDetails.ContentFooter>
          )}
        </FocusedShareDetails.Content>
      </FocusedShareDetails.Card>
    </Flex>
  );
};

export default BuyerTransactionInfoCard;
