import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";

import { Card, GridItem, SimpleGrid, Text } from "@chakra-ui/react";

import {
  FocusedShareDetails,
  ListingNumShareAndPriceDetails,
  MarkdownDocument,
} from "@/components/common";
import {
  AcceptedBidSharePriceDetails,
  ListingSellerRoundingDisclaimer,
  getListingStatusText,
} from "@/components/postings";
import {
  ListingState,
  PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useRestrictiveInReview } from "@/hooks/featureFlags";
import { transferMethodToString } from "@/utils";

interface ListingInfoCardProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment;
}

export const ListingInfoCard = ({ listing }: ListingInfoCardProps) => {
  const { acceptedBidPrices, otherDetails, transferMethod, state } = listing;

  const { t } = useTranslation(`listings`);
  const restrictiveInReview = useRestrictiveInReview();
  const actor = useCurrentActor();

  const hasAcceptedBidPrices = acceptedBidPrices.length >= 1;

  const isInReview = state === ListingState.InReview;
  const shouldShowInReview = isInReview && restrictiveInReview;
  const listingStatus = getListingStatusText(listing, { actor });

  return (
    <Card w="full" variant="no-border">
      <FocusedShareDetails.Header title={`Listing ${listing.displayId}`}>
        <FocusedShareDetails.HeaderCard>
          <ListingNumShareAndPriceDetails listing={listing} />
          {hasAcceptedBidPrices && (
            <AcceptedBidSharePriceDetails listing={listing} />
          )}
        </FocusedShareDetails.HeaderCard>
        <ListingSellerRoundingDisclaimer />
      </FocusedShareDetails.Header>
      <FocusedShareDetails.Content>
        <FocusedShareDetails.ContentSection>
          <SimpleGrid
            alignItems="baseline"
            columnGap={6}
            gridTemplateColumns="auto 1fr"
            rowGap={6}
          >
            <GridItem>
              <Text textStyle="heading-sm">{t(`transfer_type`)}</Text>
            </GridItem>
            <GridItem>
              <Text data-testid="transfer-type">
                {transferMethodToString(transferMethod)}
              </Text>
            </GridItem>
            {!isNil(otherDetails) && (
              <>
                <GridItem>
                  <Text textStyle="heading-sm">{t(`listing_notes`)}</Text>
                </GridItem>
                <GridItem>
                  <Text data-testid="listing-notes">
                    <div className="listing-details-md">
                      <MarkdownDocument markdown={otherDetails} />
                    </div>
                  </Text>
                </GridItem>
              </>
            )}
            {shouldShowInReview && (
              <>
                <GridItem>
                  <Text textStyle="heading-sm">{t(`status`)}</Text>
                </GridItem>
                <GridItem>
                  <Text data-testid="status">{listingStatus}</Text>
                </GridItem>
              </>
            )}
          </SimpleGrid>
        </FocusedShareDetails.ContentSection>
      </FocusedShareDetails.Content>
    </Card>
  );
};
