import { match } from "ts-pattern";

import { InvestorType, UserWithInstitutionFragment } from "@/gql";

import useIsHiiveConnect from "./useIsHiiveConnect";

export function useIsOnCorrectPortal() {
  const isHiiveConnect = useIsHiiveConnect();

  return ({ investorType }: UserWithInstitutionFragment) =>
    match(investorType)
      .with(InvestorType.Broker, () => isHiiveConnect)
      .with(InvestorType.Issuer, () => false)
      .otherwise(() => !isHiiveConnect);
}
