import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardFooterCountsWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTransactionStatus,
} from "@/components/common";
import {
  ActiveTransactionItemStateBadge,
  TaskCountBadge,
} from "@/components/dashboard";
import {
  ExecutionMilestone,
  StandingBidTransactionCardBidFragment,
  StandingBidTransactionCardCompanyFragment,
} from "@/gql";

const StandingBidTransactionCard = ({
  bid,
  company,
}: {
  readonly bid: StandingBidTransactionCardBidFragment;
  readonly company: StandingBidTransactionCardCompanyFragment;
}) => {
  const router = useRouter();

  const { transaction, displayId } = bid;
  const taskCount = transaction?.myTasks?.length ?? 0;
  const isTexasEnabled = transaction?.texasEnabled;
  const showTasksBadge =
    isTexasEnabled &&
    transaction.workflow?.lastCompletedMilestone !==
      ExecutionMilestone.Complete;

  if (!transaction) return null;

  const { numShares, pricePerShare } = transaction;

  return (
    <Card
      variant="activity"
      as="button"
      onClick={() => router.push(`/listings/bids/${bid.id}`)}
    >
      <CardHeader>
        <HStack>
          <ActivityCardBadge
            flexBasis={{ base: `min-content`, sm: `auto` }}
            variant="transaction"
            title="Transaction on Standing Bid"
          />
          <Text
            textStyle={{ base: `text-md`, md: `text-lg` }}
            whiteSpace="nowrap"
          >
            {displayId}
          </Text>
        </HStack>
      </CardHeader>
      <CardBody>
        <HStack justifyContent="space-between">
          <ActivityCardPricingWrapper>
            <ActivityCardPricingNumSharesAndPrice
              numShares={numShares}
              price={pricePerShare}
            />
            <ActivityCardPricingTotal
              numShares={numShares}
              price={pricePerShare}
            />
          </ActivityCardPricingWrapper>
        </HStack>
      </CardBody>
      <CardFooter>
        {!isTexasEnabled && (
          <ActivityCardFooterCountsWrapper>
            <ActivityCardFooterStatusWrapper>
              <ActivityCardTransactionStatus
                bid={bid}
                transaction={transaction}
                company={company}
              />
            </ActivityCardFooterStatusWrapper>
          </ActivityCardFooterCountsWrapper>
        )}
        {isTexasEnabled && (
          <HStack justifyContent="space-between" w="full">
            <ActiveTransactionItemStateBadge transaction={transaction} />
            {showTasksBadge && (
              <TaskCountBadge showCount={false} taskCount={taskCount} />
            )}
          </HStack>
        )}
      </CardFooter>
    </Card>
  );
};

export default StandingBidTransactionCard;
