import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, theme } from "@chakra-ui/react";

import { Button } from "./Button";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    my: { base: 0, md: `auto` },
    mx: { base: 0, md: `auto` },
    borderColor: `grey.200`,
    borderRadius: { base: 0, md: `lg` },
    bg: `white`,
    boxShadow: `card`,
  },
  header: {
    borderWidth: 0.5,
    borderColor: `grey.700`,

    bg: `grey.900`,
    py: { base: 4, md: 6 },
    px: { base: 5, md: 10 },
    borderTopRadius: { base: 0, md: `lg` },

    color: `white`,
    textStyle: `heading-2xl`,
    fontWeight: `medium`,
  },
  closeButton: {
    color: `white`,
    size: `xl`,
    position: `absolute`,
  },
  body: {
    bg: `white`,
    borderColor: `grey.200`,
    borderWidth: 0.5,
    borderTopWidth: 0,
    "&:last-child": {
      borderBottomRadius: `md`,
    },
    py: 6,
    px: { base: 5, md: 10 },
  },
  footer: {
    bg: `grey.25`,
    borderColor: `grey.200`,
    borderWidth: { base: 0, md: 0.5 },
    borderTopWidth: { base: 0, md: 0 },
    borderBottomRadius: { base: 0, md: `md` },
    py: 6,
  },
});

const texasStyle = definePartsStyle({
  dialog: {
    maxH: {
      base: `full`,
      md: `calc(100% - 128px)`,
    },
  },
  closeButton: {
    color: `grey.900`,
    borderColor: `grey.100`,
    position: `absolute`,
    top: 5,
    right: 5,
    p: 1,
    borderWidth: 1,
    bg: `red`,
  },
  footer: {
    border: `none`,
    display: `flex`,
    justifyContent: `flex-end`,
    gap: theme.space[2],
    px: theme.space[6],
    py: theme.space[4],
    Button: {
      ...Button.sizes?.xl,
    },
    [`Button:last-child`]: {
      ...Button.variants?.[`rounded-solid-grey`],
    },
    [`Button:not(:last-child)`]: {
      ...Button.variants?.[`rounded-outline-grey`],
    },
    section: {
      display: `flex`,
      gap: theme.space[2],
      justifyContent: `flex-end`,
      w: `full`,
    },
  },
  header: {
    alignItems: `center`,
    bg: `white`,
    display: `flex`,
    color: `grey.900`,
    borderStyle: `solid`,
    borderBottom: `1px`,
    borderColor: `grey.100 !important`,
    justifyContent: `space-between`,
    py: theme.space[4],
    px: 0,
    gap: theme.space[4],
    w: `full`,
    section: {
      textStyle: `heading-md`,
      pl: theme.space[6],
    },
    Button: {
      border: `1px solid`,
      borderColor: `grey.100`,
      color: `grey.800`,
      p: theme.space[1],
      mr: theme.space[4],
      minH: theme.space[1.5],
      minW: theme.space[1.5],
    },
  },
  body: {
    borderColor: `grey.100`,
  },
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: {
    texas: {
      ...texasStyle,
    },
    [`texas-full`]: {
      ...texasStyle,
      dialog: {
        maxW: {
          base: `full`,
          md: `calc(100% - 128px)`,
        },
        maxH: {
          base: `full`,
          md: `calc(100% - 128px)`,
        },
      },
    },
  },
});
