import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import { CheckCircle, CircleDashed, Signature } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Box,
  Badge,
  Button,
  Card,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  Divider,
} from "@chakra-ui/react";

import { ExecutionModalHeader } from "@/components/common";
import {
  ExecutionAnvilSignDocumentTaskData,
  ExecutionTaskActorType,
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  useExecutionTaskActionMutation,
  ExecutionTaskActionType,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { getIsUnaccreditedSeller } from "@/utils";
import { toFullDate, toFormattedDate } from "@/utils/datetime";

import AlternateUserActions from "./AlternateUserActions";
import AnvilDownloadTaskCard from "./AnvilDownloadTaskCard";
import { AnvilEmbedEvent } from "./types";

const YourSignature = ({
  task,
  isCompleted,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly isCompleted: boolean;
}) => {
  const { t } = useTranslation(`execution`);
  if (!!task.alternateUser) {
    return <AlternateUserActions task={task} isCompleted={isCompleted} />;
  }
  return (
    <Text textStyle="heading-md" color="grey.700">
      {t(`your_signature`)}
    </Text>
  );
};

const PendingTaskCard = () => {
  const { t } = useTranslation(`execution`);
  const [grey500] = useColors([`grey.500`]);

  return (
    <Card
      variant="flat"
      px={4}
      py={3}
      bg="grey.25"
      border="1px dashed"
      borderColor="grey.300"
      sx={{
        borderStyle: `dashed`,
        borderWidth: `1px`,
        borderColor: `grey.100`,
        borderRadius: `md`,
        backgroundClip: `padding-box`,
        borderImageSlice: 1,
      }}
    >
      <HStack w="full" justifyContent="space-between">
        <HStack gap={4}>
          <Center>
            <Signature width="24px" height="24px" color={grey500} />
          </Center>
          <VStack alignItems="start" spacing={0.5}>
            <Text textStyle="heading-md" color="grey.500">
              {t(`review_and_sign`)}
            </Text>
          </VStack>
        </HStack>
        <Badge
          px={2}
          py={1}
          variant="rounded-light-grey"
          bg="grey.50"
          borderColor="grey.100"
          borderWidth="0.25px"
        >
          <Text
            textStyle="text-sm"
            lineHeight="unset"
            textTransform="none"
            whiteSpace="nowrap"
          >
            {t(`upcoming_task`)}
          </Text>
        </Badge>
      </HStack>
    </Card>
  );
};

const AnvilSignDocumentTaskCard = ({
  task,
  data,
  isBuySide,
  transaction,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionAnvilSignDocumentTaskData;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const [executionTaskActionMutation, isLoading] = useMutationWithError(
    useExecutionTaskActionMutation(),
    `executionTaskAction`,
  );

  const actor = useCurrentActor();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const { t } = useTranslation(`execution`);

  const [embedUrl, setEmbedUrl] = useState(``);

  const handleOpen = async () => {
    const response = await executionTaskActionMutation({
      variables: {
        id: task.id,
        type: ExecutionTaskActionType.AnvilSignDocumentGenerateEmbedUrl,
      },
    });

    if (
      !response ||
      response.executionTaskAction.result?.__typename !==
        `ExecutionTaskActionAnvilSignDocumentGenerateEmbedUrl`
    )
      return;

    setEmbedUrl(response.executionTaskAction.result.url);
  };

  const handleClose = () => setEmbedUrl(``);

  const handleEvent = (event: AnvilEmbedEvent) => {
    if (event.action !== `signerComplete`) return;
    handleClose();
  };

  const [grey900] = useColors([`grey.900`]);

  const pendingSignatures = data.signers.find((signer) => !signer.signedAt);

  const hasAlternateUser = !!task.alternateUser;

  const isPending = task.status === ExecutionTaskStatus.Pending;
  const isInProgress = task.status === ExecutionTaskStatus.InProgress;

  const isCompleted = !isInProgress && !!task.completedAt;
  const showCompletedAt = isCompleted && !pendingSignatures;
  const showOpenDocumentButton = isInProgress && !hasAlternateUser;
  const showAlternateSignerCTA =
    isInProgress && !isUnaccreditedSeller && !hasAlternateUser;

  const sortedSigners = [...data.signers].sort((a) => {
    if (isBuySide) {
      return a.actorType === ExecutionTaskActorType.Buyer ? -1 : 1;
    }
    return a.actorType === ExecutionTaskActorType.Seller ? -1 : 1;
  });

  if (isPending) {
    return <PendingTaskCard />;
  }

  if (isCompleted && data.completedFileUploadId) {
    return (
      <AnvilDownloadTaskCard
        transaction={transaction}
        completedFileUploadId={data.completedFileUploadId}
      />
    );
  }

  return (
    <>
      <Card variant="flat">
        <Box px={4} py={3}>
          <VStack gap={4}>
            <HStack w="full" justifyContent="space-between">
              <HStack gap={4}>
                <Center>
                  <Signature width="24px" height="24px" />
                </Center>
                <Text textStyle="heading-md">{t(`review_and_sign`)}</Text>
              </HStack>
              {showOpenDocumentButton && (
                <Button
                  isLoading={isLoading}
                  variant="rounded-solid-grey"
                  size={{ base: `sm`, md: `md` }}
                  onClick={handleOpen}
                >
                  {t(`open_document`)}
                </Button>
              )}
              {showCompletedAt && (
                <Text textStyle="text-sm">
                  {t(`completed`)}, {toFullDate(task.completedAt)}
                </Text>
              )}
            </HStack>
          </VStack>
          {showAlternateSignerCTA && (
            <>
              <Divider w="calc(100% + 1rem)" my={3} />
              <AlternateUserActions task={task} />
            </>
          )}
        </Box>
        {pendingSignatures && (
          <>
            {sortedSigners.map((signer) => {
              const isMySide = isBuySide
                ? signer.actorType === ExecutionTaskActorType.Buyer
                : signer.actorType === ExecutionTaskActorType.Seller;
              if (isMySide && !hasAlternateUser && !signer.signedAt)
                return null;

              return (
                <React.Fragment key={signer.taskId}>
                  {isMySide && !!task.alternateUser && (
                    <Divider w="calc(100%-1rem)" ml={4} />
                  )}
                  <Box
                    bg={isMySide ? `inherit` : `grey.15`}
                    borderTop={isMySide ? `none` : `1px solid`}
                    borderTopColor="grey.75"
                    borderBottomRadius="md"
                    px={4}
                    py={3}
                  >
                    <HStack w="full" justifyContent="space-between">
                      <HStack gap={4} w="full">
                        <Center width="24px" height="24px">
                          {!!signer.signedAt ? (
                            <CheckCircle
                              color={grey900}
                              weight="fill"
                              size="18px"
                            />
                          ) : (
                            <CircleDashed color={grey900} size="18px" />
                          )}
                        </Center>
                        {match(signer.actorType)
                          .with(ExecutionTaskActorType.Seller, () =>
                            isBuySide ? (
                              <Text textStyle="heading-3xs" color="grey.700">
                                {t(`counterpartys_signature`)}
                              </Text>
                            ) : (
                              <YourSignature
                                task={task}
                                isCompleted={!!signer.signedAt}
                              />
                            ),
                          )
                          .with(ExecutionTaskActorType.Buyer, () =>
                            isBuySide ? (
                              <YourSignature
                                task={task}
                                isCompleted={!!signer.signedAt}
                              />
                            ) : (
                              <Text textStyle="heading-3xs" color="grey.700">
                                {t(`counterpartys_signature`)}
                              </Text>
                            ),
                          )
                          .otherwise(() => ``)}
                      </HStack>
                      {!!signer.signedAt && (
                        <Text textStyle="text-xs" flexShrink={0}>
                          {t(`signed`)} {toFormattedDate(signer.signedAt)}
                        </Text>
                      )}
                      {!signer.signedAt && !isMySide && (
                        <Badge px={2} py={1} variant="rounded-lighter-sky">
                          <Text
                            textStyle="text-sm"
                            lineHeight="unset"
                            textTransform="none"
                          >
                            {t(`pending`)}
                          </Text>
                        </Badge>
                      )}
                    </HStack>
                  </Box>
                </React.Fragment>
              );
            })}
          </>
        )}
      </Card>
      <Modal variant="texas-full" isOpen={!!embedUrl} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent height="full">
          <ExecutionModalHeader closeModal={handleClose}>
            {t(`document_signing`)}
          </ExecutionModalHeader>
          <ModalBody p={0} px={0} overflow="hidden">
            <Box
              as={AnvilEmbedFrame}
              onEvent={(event: AnvilEmbedEvent) => handleEvent(event)}
              iframeURL={embedUrl}
              w="full"
              h="full"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnvilSignDocumentTaskCard;
