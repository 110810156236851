import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import {
  ExecutionStepStatus,
  ExecutionStepType,
  ExecutionStepVisibility,
  ExecutionWorkflowStatus,
  TransactionExecutionPageTransactionFragment,
  TransactionExecutionPageWorkflowFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";

import CompletedStep from "./Step/CompletedStep";
import InProgressStep from "./Step/InProgressStep";

const NoStepsCard = () => (
  <Card w="full" variant="no-border-radius-base">
    <CardBody p={3}>
      <Text px={3}>
        <Trans i18nKey="no_tasks_explainer" />
      </Text>
    </CardBody>
  </Card>
);

const PendingStep = ({
  step,
}: {
  readonly step: TransactionExecutionPageWorkflowFragment["steps"][0];
}) => {
  const { t } = useTranslation();

  return (
    <Card
      w="full"
      variant="no-border-radius-base"
      borderColor="grey.100"
      boxShadow="none"
    >
      <CardBody p={0} px={{ base: 4, md: 6 }} py={{ base: 2, md: 4 }}>
        <HStack justifyContent="space-between" w="full">
          <Text textStyle="heading-md">{step.name}</Text>
          <Text textStyle="text-xs" whiteSpace="nowrap">
            {t(`upcoming_task`)}
          </Text>
        </HStack>
      </CardBody>
    </Card>
  );
};

const PendingStepCards = ({
  steps,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
}) => (
  <Stack direction="column" spacing={2}>
    {steps.map((step) => (
      <PendingStep key={step.id} step={step} />
    ))}
  </Stack>
);

const CompletedStepsCard = ({
  steps,
  isBuySide,
  transaction,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);

  const numCompletedSteps = steps.length;

  if (numCompletedSteps === 0)
    return (
      <Card w="full" boxShadow="none" variant="no-border-radius-base">
        <CardBody>
          <Text>{t(`no_completed_tasks`)}</Text>
        </CardBody>
      </Card>
    );

  return (
    <Stack direction="column" spacing={2}>
      {steps.map((step) => (
        <CompletedStep
          key={step.id}
          step={step}
          isBuySide={isBuySide}
          transaction={transaction}
        />
      ))}
    </Stack>
  );
};

const StepTabs = ({
  workflow,
  isBuySide,
  transaction,
}: {
  readonly workflow?: TransactionExecutionPageWorkflowFragment | null;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const [collapsedSteps, setCollapsedSteps] = useState<Record<string, boolean>>(
    {},
  );
  const prevStepStatuses = useRef<Record<string, ExecutionStepStatus>>({});

  // keep track of in progress steps that have been completed
  useEffect(() => {
    if (!workflow?.steps) return;

    workflow.steps.forEach((step) => {
      const wasInProgress =
        prevStepStatuses.current[step.id] === ExecutionStepStatus.InProgress;
      const isNowCompleted = [
        ExecutionStepStatus.Completed,
        ExecutionStepStatus.Completing,
      ].includes(step.status);

      if (wasInProgress && isNowCompleted) {
        setCollapsedSteps((prev) => ({
          ...prev,
          [step.id]: true,
        }));
      }

      prevStepStatuses.current[step.id] = step.status;
    });
  }, [workflow?.steps]);

  if (!workflow || workflow.status === ExecutionWorkflowStatus.Pending) {
    return <NoStepsCard />;
  }

  const userType = isBuySide
    ? ExecutionStepVisibility.Buyer
    : ExecutionStepVisibility.Seller;

  const inProgressSteps = workflow.steps.filter(
    (step) =>
      [ExecutionStepStatus.InProgress, ExecutionStepStatus.Starting].includes(
        step.status,
      ) && step.visibility.includes(userType),
  );

  const inProgressAndCompletedSteps = workflow.steps.filter(
    (step) =>
      [
        ExecutionStepStatus.Starting,
        ExecutionStepStatus.InProgress,
        ExecutionStepStatus.Completed,
        ExecutionStepStatus.Completing,
      ].includes(step.status) && step.visibility.includes(userType),
  );

  const pendingSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.Pending &&
      step.type !== ExecutionStepType.Milestone &&
      step.visibility.includes(userType),
  );

  const completedSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.Completed &&
      step.type !== ExecutionStepType.Milestone &&
      step.visibility.includes(userType),
  );

  const tabList = (
    <TabList>
      <Tab>{t(`now`)}</Tab>
      <Tab>{t(`next`)}</Tab>
      <Tab>{t(`done`)}</Tab>
    </TabList>
  );

  return (
    <Tabs variant="boxed-teal" w="full">
      {isDesktop ? (
        <Box mb={4}>{tabList}</Box>
      ) : (
        <Card variant="no-border-radius-base" top={-4} border="none">
          <CardBody>{tabList}</CardBody>
        </Card>
      )}
      <TabPanels>
        <TabPanel>
          <Text textStyle="text-xs" px={{ base: 4, md: 0 }} mb={4}>
            {t(`in_progress_tasks_description`)}
          </Text>
          {/* // spacing managed on step level */}
          <Box>
            {inProgressAndCompletedSteps.map((step) => (
              // passing both in progress and completed steps to allow for completion animation
              <InProgressStep
                key={step.id}
                step={step}
                isBuySide={isBuySide}
                transaction={transaction}
                collapsed={collapsedSteps[step.id] || false}
              />
            ))}
          </Box>
          {inProgressSteps.length === 0 &&
            workflow.status !== ExecutionWorkflowStatus.Completed && (
              <NoStepsCard />
            )}
        </TabPanel>
        <TabPanel>
          <Stack direction="column" spacing={4}>
            <Text textStyle="text-xs" px={{ base: 4, md: 0 }}>
              {t(`pending_tasks_description`)}
            </Text>
            <PendingStepCards steps={pendingSteps} />
          </Stack>
        </TabPanel>
        <TabPanel>
          <CompletedStepsCard
            steps={completedSteps}
            isBuySide={isBuySide}
            transaction={transaction}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default StepTabs;
