import { useEffect } from "react";

import { FeeBreakdown } from "@/components/common";
import { useListingFeeBreakdownCommissionInfoLazyQuery } from "@/gql";
import { useDebounce } from "@/hooks";
import { constants, currencyValue, getParsedAmount } from "@/utils";

type FeeBreakdownCommissionInfoProps = React.ComponentProps<
  typeof FeeBreakdown
> & {
  readonly numShares?: number | null;
  readonly pricePerShare?: number | null;
  readonly listingId?: string;
  readonly companyId?: string;
};

export default function FeeBreakdownCommissionInfo({
  numShares,
  pricePerShare,
  listingId,
  companyId,
  ...props
}: FeeBreakdownCommissionInfoProps) {
  const { debounce, isDebouncing } = useDebounce();

  const [load, { data, loading }] =
    useListingFeeBreakdownCommissionInfoLazyQuery({
      fetchPolicy: `cache-and-network`,
    });

  const shouldSkipInitialLoad = (!numShares || !pricePerShare) && !data;

  useEffect(() => {
    if (shouldSkipInitialLoad) return;

    debounce(() => {
      const variables = {
        totalShares: numShares ?? 0,
        pricePerShare: currencyValue(pricePerShare ?? 0),
        ...(listingId ? { listingId } : {}),
        ...(companyId ? { companyId } : {}),
      };

      load({ variables });
    }, constants.commission_calculation_debounce_time);
  }, [companyId, listingId, numShares, pricePerShare]);

  const { sellerCommissionInfo } = data || {};
  const {
    flatFeeAmount,
    commissionAmount,
    feeDiscountAmount,
    feeDiscountApplications,
    netFees,
    previousCommission,
  } = sellerCommissionInfo || {};

  const showLoader = isDebouncing || loading;

  return (
    <FeeBreakdown
      {...props}
      isLoading={showLoader}
      numShares={numShares}
      pricePerShare={(pricePerShare || 0) * 100}
      feeDiscountApplications={feeDiscountApplications}
      flatFeeAmount={getParsedAmount(flatFeeAmount)}
      commissionAmount={getParsedAmount(commissionAmount)}
      feeDiscountAmount={getParsedAmount(feeDiscountAmount)}
      netFees={getParsedAmount(netFees)}
      previousCommission={previousCommission}
    />
  );
}
