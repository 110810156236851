import { useEffect, useRef, useState } from "react";

import { STEP_EXIT_ANIMATION_LENGTH } from "@/components/transactions";
import { ExecutionTaskStatus } from "@/gql";

export const useDelayTaskCompletion = (
  taskStatus: ExecutionTaskStatus,
  delayCompletion: boolean,
) => {
  const [isCompleted, setIsCompleted] = useState(
    taskStatus === ExecutionTaskStatus.Completed,
  );
  const initialStatus = useRef(taskStatus);

  useEffect(() => {
    if (
      initialStatus.current === ExecutionTaskStatus.InProgress &&
      [ExecutionTaskStatus.Completing, ExecutionTaskStatus.Completed].includes(
        taskStatus,
      )
    ) {
      setTimeout(
        () => setIsCompleted(true),
        delayCompletion ? STEP_EXIT_ANIMATION_LENGTH : 0,
      );
    }
  }, [taskStatus, delayCompletion]);

  return { isCompleted, initialStatus: initialStatus.current };
};
