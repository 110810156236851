import { Text } from "@chakra-ui/react";

const ActivityCardDisplayId = ({
  displayId,
}: {
  readonly displayId: string;
}) => (
  <Text textStyle={{ base: `text-sm`, lg: `text-lg` }} whiteSpace="nowrap">
    {displayId}
  </Text>
);

export default ActivityCardDisplayId;
