import { datadogRum } from "@datadog/browser-rum";
import {
  initialize,
  LDClient,
  LDEvaluationDetail,
} from "launchdarkly-js-client-sdk";
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  createContext,
  useMemo,
} from "react";

import { useNullableCurrentActor } from "@/hooks";
import { FeatureFlags } from "@/hooks/featureFlags";

interface ProviderProps {
  clientSideId?: string;
  children?: ReactNode;
}

const LD_TIMEOUT = 5;

export enum LDStatus {
  Unfetched,
  Success,
  Failure,
}

const defaultFlags: FeatureFlags = {
  "compsec.new_auth_system": false,
  "hiive.enable_maintenance": false,
  "marketplace.suppress_notifications": false,
  "marketplace.front_chat_u16r": true,
  "marketplace.front_chat_trader": true,
  "marketplace.rsu_restriction_and_eligible_buyers": false,
  "marketplace.slugs_as_company_url": false,
  "marketplace.restrictive_in_review": false,
  "marketplace.base_fee_removal": true,
  "marketplace.texas_copy": false,
  "funds.buy_side_fees": false,
  "funds.texas_for_funds": false,
  "marketplace.staging_banner": false,
  "marketplace.listing_note_updates": false,
  "marketplace.sb_notes_updates": false,
  "growth.front_chat_u16r_onboarding": false,
  "marketplace.client_seller_holdings_updates": false,
  "marketplace.pricing_in_review_bids": false,
  "marketplace.pricing_in_review_standing_bids": false,
  "issuer.issuer_portal.buyer_information_disclosure": false,
};

export const LDStatusContext = createContext<{
  readonly status: LDStatus;
  readonly client?: LDClient;
  readonly flags: FeatureFlags;
}>({
  status: LDStatus.Unfetched,
  client: undefined,
  flags: defaultFlags,
});

const AsyncLDProvider: React.FC<ProviderProps> = ({
  children,
  clientSideId,
}) => {
  const LDClientRef = useRef<LDClient | undefined>();
  const [flags, setFlags] = useState<FeatureFlags>(defaultFlags);
  const [status, setStatus] = useState<LDStatus>(LDStatus.Unfetched);
  const { actor } = useNullableCurrentActor();

  useEffect(() => {
    if (clientSideId) {
      // eslint-disable-next-line functional/no-let
      let user;
      const isE2E = process.env.NEXT_PUBLIC_E2E === `true`;
      const isFeatured = actor?.lastName === `Client Feature`;

      if (isE2E) {
        user = {
          key: isFeatured
            ? `feature_client_e2e_context`
            : `default_client_e2e_context`,
          anonymous: false,
        };
      } else {
        user = actor
          ? { key: actor.id, anonymous: false }
          : { anonymous: true };
      }

      const client = initialize(clientSideId, user, {
        streaming: true,
        bootstrap: defaultFlags,
        inspectors: [
          {
            type: `flag-used`,
            name: `dd-inspector`,
            method: (key: string, detail: LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value);
            },
          },
        ],
      });

      client.on(`change`, (items) => {
        const newFlags = Object.keys(items).reduce(
          (acc, key) => ({ ...acc, [key]: items[key].current }),
          {},
        );
        setFlags((currentFlags) => ({ ...currentFlags, ...newFlags }));
        setStatus(LDStatus.Success);
      });
      client.on(`failed`, () => {
        // eslint-disable-next-line no-console
        console.error(`LD Failed to load`);
        setStatus(LDStatus.Failure);
      });

      client.waitForInitialization(LD_TIMEOUT).catch(() => {
        // eslint-disable-next-line no-console
        console.error(`LD failed to initialize after ${LD_TIMEOUT} seconds`);
        setStatus(LDStatus.Failure);
      });

      LDClientRef.current = client;
    }

    return () => {
      LDClientRef.current?.close();
    };
  }, [clientSideId, actor]);

  const context = useMemo(
    () => ({ status, client: LDClientRef.current, flags }),
    [status, LDClientRef.current, flags],
  );

  if (status === LDStatus.Unfetched) {
    return null;
  }

  return (
    <LDStatusContext.Provider value={context}>
      {children}
    </LDStatusContext.Provider>
  );
};

export default AsyncLDProvider;
