import { ErrorResponse } from "@apollo/client/link/error";
import { LogsEvent } from "@datadog/browser-logs";

const REDACTED_KEYS = [`authorization`, `email`, `password`, `token`];

function redact(_data: unknown): string {
  return `[REDACTED]`;
}

function redactKey(key: string): boolean {
  if (typeof key === `string`) {
    return REDACTED_KEYS.includes(key);
  }

  return false;
}

export function redactDataPure(
  data: ErrorResponse | ErrorResponse[],
): ErrorResponse | ErrorResponse[] {
  if (data == null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => redactDataPure(item) as ErrorResponse);
  }

  if (typeof data === `object`) {
    return Object.fromEntries(
      Object.entries(data).map(([k, v]) =>
        redactKey(k) ? [k, redact(v)] : [k, redactDataPure(v)],
      ),
    );
  }

  return data;
}

export function redactDataMutate(data: LogsEvent): void {
  if (data == null) {
    return;
  }

  if (Array.isArray(data)) {
    data.forEach((item) => {
      redactDataMutate(item as LogsEvent);
    });
    return;
  }

  if (typeof data === `object`) {
    Object.entries(data).forEach(([key, value]) => {
      const record = data as Record<string, unknown>;
      if (redactKey(key)) {
        // since the goal here is to mutate
        // eslint-disable-next-line functional/immutable-data
        record[key] = redact(value);
      } else {
        redactDataMutate(value as LogsEvent);
      }
    });
  }
}
