import { useTranslation } from "react-i18next";

import { HStack, Show, Text, VStack } from "@chakra-ui/react";

import { TransactingEntityItemEntityFragment } from "@/gql";
import { useTranslatedEntityNames } from "@/hooks";
import * as datetime from "@/utils/datetime";

const EntitySelectorItemDesktop = ({
  entity,
}: {
  readonly entity: TransactingEntityItemEntityFragment;
}) => {
  const { t } = useTranslation();
  const { getCountryName } = useTranslatedEntityNames();
  const countryName = getCountryName(entity.jurisdictionOfFormation?.name);

  return (
    <HStack justify="space-between">
      <HStack>
        <Text textStyle="heading-xs">{entity.legalName}</Text>
        {countryName && <Text textStyle="text-sm">{countryName}</Text>}
      </HStack>
      <Text color="grey.600" textStyle="text-sm">
        {t(`added_date`, {
          date: datetime.format(`Do MMMM, YYYY`, entity.insertedAt),
        })}
      </Text>
    </HStack>
  );
};

const EntitySelectorItemMobile = ({
  entity,
}: {
  readonly entity: TransactingEntityItemEntityFragment;
}) => {
  const { getCountryName } = useTranslatedEntityNames();
  const countryName = getCountryName(entity.jurisdictionOfFormation?.name);

  return (
    <HStack justify="space-between">
      <VStack alignItems="start" gap={0}>
        <Text fontWeight={500} textStyle="text-sm">
          {entity.legalName}
        </Text>
        <Text textStyle="text-sm">{countryName}</Text>
      </VStack>
    </HStack>
  );
};

const EntitySelectorItem = ({
  entity,
}: {
  readonly entity: TransactingEntityItemEntityFragment;
}) => (
  <>
    <Show above="md" ssr={false}>
      <EntitySelectorItemDesktop entity={entity} />
    </Show>
    <Show below="md" ssr={false}>
      <EntitySelectorItemMobile entity={entity} />
    </Show>
  </>
);

export default EntitySelectorItem;
