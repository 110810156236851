import { t } from "i18next";

import {
  ActivityGroup,
  ActivitySection,
  WithQuery,
  ListingPostingCard,
  StandingBidPostingCard,
} from "@/components/common";
import {
  StandingBidAcceptsSharesOptions,
  UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment,
  UnaccreditedSellerCompanyByIdMarketActivityListingFragment,
  UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment,
  useUnaccreditedSellerCompanyByIdMarketActivityQuery,
} from "@/gql";
import {
  normalizeTruncatedListings,
  normalizeTruncatedStandingBids,
} from "@/utils";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";

const Listings = ({
  listings,
  standingBids,
  company,
}: {
  readonly listings: readonly UnaccreditedSellerCompanyByIdMarketActivityListingFragment[];
  readonly standingBids: readonly UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment[];
  readonly company: UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment;
}) => (
  <ActivityGroup
    title={t(`other_sellers`)}
    emptyState={
      <ListingsEmptyState
        company={company}
        numberOfStandingBids={standingBids.length}
      />
    }
  >
    {listings.map((listing) => (
      <ListingPostingCard key={listing.id} listing={{ ...listing, company }} />
    ))}
  </ActivityGroup>
);

const StandingBids = ({
  standingBids,
  listings,
  company,
}: {
  readonly standingBids: readonly UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment[];
  readonly listings: readonly UnaccreditedSellerCompanyByIdMarketActivityListingFragment[];
  readonly company: UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment;
}) => (
  <ActivityGroup
    title={t(`available_standing_bids`)}
    emptyState={
      <StandingBidsEmptyState
        company={company}
        numberOfListings={listings.length}
      />
    }
  >
    {standingBids.map((standingBid) => (
      <StandingBidPostingCard
        key={standingBid.id}
        standingBid={{ ...standingBid, company }}
      />
    ))}
  </ActivityGroup>
);

const MarketActivity = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerCompanyByIdMarketActivityQuery({
    variables: { id },
  });
  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({ data: { companyById: company } }) => {
        const { othersListings: listings, othersStandingBids: standingBids } =
          company.activity;

        const othersStandingBids = normalizeTruncatedStandingBids(standingBids);
        const othersListings = normalizeTruncatedListings(listings);

        // U16R cannot accept SBs whose share option is Preferred
        const filteredStandingBids = othersStandingBids.filter(
          (sb) => sb.acceptsShares !== StandingBidAcceptsSharesOptions.Pref,
        );

        return (
          <ActivitySection title={t(`market_activity`)}>
            <StandingBids
              standingBids={filteredStandingBids}
              listings={othersListings}
              company={company}
            />
            <Listings
              listings={othersListings}
              standingBids={othersStandingBids}
              company={company}
            />
          </ActivitySection>
        );
      }}
    </WithQuery>
  );
};

export default MarketActivity;
